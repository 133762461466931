import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'

const TermsofService = () => {
    return (
        <>
            <Container>
                <Row>
                    <Col lg="12">
                        <div className="iq-accordion career-style faq-style  ">
                            <Card className="iq-accordion-block accordion-active p-0">
                                <Card.Header className="active-faq clearfix d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Terms and Conditions</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body className="accordion-details">
                                    <p className="mb-0 p-0">Please read these Terms and Conditions ("Terms") carefully before using the Xblue website ("website") located at Xblue.ai Your access to and use of the website is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the website.
                                        <br />
                                        1. Acceptance of Terms
                                        <br />
                                        By accessing or using the website, you agree to be bound by these Terms. If you disagree with any part of the Terms, then you may not access the website.
                                        <br /><br />
                                        2. Use License
                                        <br />
                                        Subject to these Terms, we grant you a limited, non-exclusive, non-transferable, and revocable license to access and use the website for personal and non-commercial purposes.
                                        <br /><br />
                                        3. User Conduct
                                        <br />
                                        By using the website, you agree to abide by the following rules of conduct:
                                        <br />
                                        - You will not use the website for any unlawful purpose or in violation of these Terms.
                                        <br />
                                        - You will not engage in any activity that could harm, disable, overburden, or impair the website's functionality or interfere with other users' access to or use of the website.
                                        <br />
                                        - You will not attempt to gain unauthorized access to the website or any accounts, systems, or networks connected to the website.
                                        <br />
                                        - You will not upload or transmit any viruses, malware, or other harmful code or engage in any activity that disrupts, damages, or interferes with the operation of the website.
                                        <br />
                                        - You will not attempt to collect or store personal information about other users without their consent.
                                        <br />
                                        - You will comply with all applicable laws and regulations when using the website.
                                        <br />

                                        4. Intellectual Property
                                        <br />
                                        The website and its original content, features, and functionality are owned by Xblue and are protected by intellectual property laws. You acknowledge and agree that the website contains proprietary information that is protected by copyrights, trademarks, service marks, or other proprietary rights and laws.
                                        <br /><br />
                                        5. Disclaimer
                                        <br />
                                        The website and its content are provided on an "as-is" and "as available" basis. We make no warranties, expressed or implied, and disclaim all warranties, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not guarantee that the website will always be available, uninterrupted, or error-free, nor do we guarantee the accuracy or reliability of any content.
                                        <br /><br />
                                        6. Limitation of Liability
                                        <br />
                                        In no event shall Xblue or its directors, officers, employees, partners, or affiliates be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the website, whether based on warranty, contract, tort (including negligence), or any other legal theory.
                                        <br /><br />
                                        7. Indemnification
                                        <br />
                                        You agree to indemnify, defend, and hold harmless Xblue and its directors, officers, employees, partners, and affiliates from and against any claims, liabilities, damages, losses, costs, or expenses arising out of or in any way related to your use of the website or violation of these Terms.
                                        <br /><br />
                                        8. Modification and Termination
                                        <br />
                                        We reserve the right to modify, suspend, or terminate the website or these Terms at any time without prior notice or liability. We may also impose limits on certain features or restrict your access to parts or all of the website without notice or liability.
                                        <br /><br />
                                        9. Governing Law
                                        <br />
                                        These Terms shall be governed and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.
                                        <br /><br />
                                        10. Severability
                                        <br />
                                        If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect. The invalid
                                        ma'am one's we have links for this both terms and condition please share this with bhanupriya so she can add them on twitter authorize screen </p>
                                </Card.Body>
                            </Card>
                            {/* <div className="iq-accordion career-style faq-style  ">
                                <Card className="iq-accordion-block p-0">
                                    <Card.Header className="active-faq clearfix d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">consectetur adipiscing elit</h4>
                                        </div>
                                    </Card.Header>
                                    <Card.Body className="accordion-details">
                                        <p className="mb-0 p-0">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. </p>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="iq-accordion career-style faq-style  ">
                                <Card className="card iq-accordion-block p-0">
                                    <Card.Header className="active-faq clearfix d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">Etiam sit amet justo non</h4>
                                        </div>
                                    </Card.Header>
                                    <Card.Body className="accordion-details">
                                        <p className="mb-0 p-0">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. </p>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="iq-accordion career-style faq-style  ">
                                <Card className="iq-accordion-block p-0">
                                    <Card.Header className="active-faq clearfix d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">velit accumsan laoreet</h4>
                                        </div>
                                    </Card.Header>
                                    <Card.Body className="accordion-details">
                                        <p className="mb-0 p-0">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. </p>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="iq-accordion career-style faq-style">
                                <Card className="iq-accordion-block p-0">
                                    <Card.Header className="active-faq clearfix d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">Donec volutpat metus in erat</h4>
                                        </div>
                                    </Card.Header>
                                    <Card.Body className="accordion-details">
                                        <p className="mb-0 p-0">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. </p>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="iq-accordion career-style faq-style  ">
                                <Card className="card iq-accordion-block p-0">
                                    <Card.Header className="active-faq clearfix d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">quam quis massa tristique</h4>
                                        </div>
                                    </Card.Header>
                                    <Card.Body className="accordion-details">
                                        <p className="mb-0 p-0">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. </p>
                                    </Card.Body>
                                </Card>
                            </div> */}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default TermsofService
