import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'

const BlankPage = () => {
    return (
        <>
            <div style={{ backgroundColor: 'white', height: '62rem' }}>

                <Container>
                    <div className='text-center'>
                        <p>Extra authorization successfully saved.</p>
                        <p>
                            You can close this window now.</p>

                    </div>

                </Container>
            </div>
        </>
    )
}

export default BlankPage