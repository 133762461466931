import Header from "./Header";

import React, { Component } from "react";

// import TwitterLogin from 'react-twitter-auth'

import PropTypes from "prop-types";

import "swiper/swiper-bundle.min.css";
import { useParams } from "react-router-dom";

// install Swiper modules

export default class SignIn extends Component {
  static propTypes = {
    user: PropTypes.shape({
      name: PropTypes.string,
      profileImageUrl: PropTypes.string,
      twitterId: PropTypes.string,
      screenName: PropTypes.string,
      _id: PropTypes.string,
    }),
  };

  state = {
    user: {},
    error: null,
    authenticated: false,
  };

  componentDidMount() {


    // Fetch does not send cookies. So you should add credentials: 'include'

    // fetch("https://tweetnow.ai/TweetNow/login/twitter/callback", {
    fetch("https://xblue.ai/Xblue/login/twitter/callback", {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        console.log("resposne", response)
        if (response.status === 200) return response.json();
        throw new Error("failed to authenticate user");
      })
      .then((responseJson) => {
        alert('2nd ')
        console.log("respo", responseJson)
        this.setState({
          authenticated: true,
          user: responseJson.user,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          authenticated: false,
          error: "Failed to authenticate user",
        });
      });
  }

  render() {
    const { authenticated } = this.state;
    return (
      <div>
        <Header
          authenticated={authenticated}
          handleNotAuthenticated={this._handleNotAuthenticated}
        />
        <div>
          {!authenticated ? (
            console.log("authenticated")
          ) : (
            <div>
              <h1>You have login succcessfully!</h1>
            </div>
          )}
        </div>
      </div>
    );
  }

  _handleNotAuthenticated = () => {
    this.setState({ authenticated: false });
  };
}
