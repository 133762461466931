import axios from "axios";
// import { BASE_URL } from "../config/config";
import { BASE_URL } from "../../utilities/Config";

const userID = localStorage.getItem('UserID')
const tokenLogin = localStorage.getItem('accessToken')

const TweetsDataObject = {

  Myprofile: async (callback) => {
    var endpoint = `${BASE_URL}api/user/${userID}`;
    axios({
      method: "GET",
      url: `${endpoint}`,
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      return callback(response);
    }).catch((err) => {
      return callback(err.response);
    });
  },

  Acceptparties: async (id, callback) => {
    var endpoint = `https://api.wyaapp.com/api/v1/party-invitation/${id}/accept`;
    axios({
      method: "POST",
      url: `${endpoint}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }
    }).then((response) => {
      return callback(response);
    }).catch((err) => {
      return callback(err.response);
    });
  },


};

export default TweetsDataObject;