// import Libraries
import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
    CardElement,
    useElements,
    useStripe,
    AddressElement,
} from "@stripe/react-stripe-js";

import { Button, Row, Col, Container, Form, Spinner } from 'react-bootstrap'
import './PaymentStyle.css'
import Tweet from '../../../../assets/images/icon/tweetnow-logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTag } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom'
import Xblue from '../../../../assets/images/xblue-logo-white.png'
// import { Button } from 'reactstrap';

// import { Spinner } from "reactstrap";
import Swal from "sweetalert2";
// import { useHistory } from "react-router-dom";

import PropTypes from "prop-types"
import { BASE_URL } from "../../../../utilities/Config";

// import CSS file for styling
// import '../../assests/style/payment/stripe.css'

// import controller object for api calling
// import authDataObject from "../../services/Auth_Controller/Auth_Controller";

const stripePromise = loadStripe("pk_test_V9FAGZjUH1NYrsQqSpQmBJ8l004qIwxCFB");
// const stripePromise = loadStripe(
//     "pk_test_51NSZvlBqtpxNTfViMBZX6GCk5d6Uad2xCvNh7mepQSHUzVRlIesuvdpPd8TiL7bq5zwTHgh43S4j6RyvnfFwAsHJ00O5CMlkh6"
// );



function PaymentForm({ handler, status, ticketdetails }) {
    const Dropdownhandler = () => {
        handler();
    };



    return (
        <div className="FWH">
            <div className="cardCont">
                <Elements stripe={stripePromise}>
                    <Checkout Status={status} Handler={Dropdownhandler} ticketdetails={ticketdetails} />
                </Elements>
            </div>
        </div>
    );
}

export default PaymentForm;

export function Checkout({ Status, Handler, ticketdetails }) {
    const [loderButton, setLoderButton] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const [address, setaddress] = useState({});
    const [displayPaymentDetails, setDisplayPaymentDetails] = useState([])
    const [name, setName] = useState('')
    //   const history = useHistory();
    console.log("ticketdetails", ticketdetails)
    const [tokenPay, setTokenPay] = useState()
    const provider = localStorage.getItem('providerToken')
    const tokenLogin = localStorage.getItem('accessToken')
    const userID = localStorage.getItem('UserID')

    const emailTo = localStorage.getItem('email-to')
    const emailSwitch = localStorage.getItem('emailSwitch')

    let params = useParams()
    let navigate = useNavigate()

    console.log("params", params)



    const handledownload = async () => {
        try {
            const response = await fetch(`${BASE_URL}api/download/receipt/${userID}`, {
                headers: {
                    Authorization: `Bearer ${tokenLogin}`,
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            console.log("down,oad", data);

        } catch (error) {
            console.error('Error fetching buyer data:', error);
        }

    }

    const Dropdownhandler = () => {
        Handler();
    };

    const CARD_OPTIONS = {
        appearance: {
            /*...*/
        },
        iconStyle: "solid",
        style: {
            base: {
                iconColor: "dodgerblue",
                color: "black",
                fontWeight: 500,
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: "14px",
                fontSmoothing: "antialiased",
                ":-webkit-autofill": { color: "black" },
                "::placeholder": { color: "black" },
            },
            invalid: {
                iconColor: "#ffc7ee",
                color: "#ffc7ee",
                fontSmoothing: "antialiased",
                ":-webkit-autofill": { color: "#ffc7ee" },
                "::placeholder": { color: "#ffc7ee" },

            },
        },
    };

    useEffect(() => {
        handlePaymentDetails()
    }, [])
    const [amount, setAmount] = useState()


    const handlePaymentDetails = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/fetch/pricing/${params.paymentid}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("ka payment deta", data);
                    if (data.code == 200) {
                        setDisplayPaymentDetails(data.data.Prices[0])
                        // const ind = displayPaymentDetails.unit_amount
                        // const usd = ind / 82.07
                        // console.log("$44$$$$", usd.toFixed(3))
                        // setAmount(usd.toFixed(3))
                    }
                    else {
                        Swal.fire({
                            icon: "error",
                            title: "",
                            text: "Not able to load the payment details",
                            timer: 2000,
                            showConfirmButton: false,
                        });
                    }
                }
                else {
                    console.log("sajkbcsjahcsa cdscu")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/fetch/pricing/${params.paymentid}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("ka payment deta", data);
                    if (data.code == 200) {
                        setDisplayPaymentDetails(data.data.Prices[0])
                        // const ind = displayPaymentDetails.unit_amount
                        // const usd = ind / 82.07
                        // console.log("$44$$$$", usd.toFixed(3))
                        // setAmount(usd.toFixed(3))
                    }
                    else {
                        Swal.fire({
                            icon: "error",
                            title: "",
                            text: "Not able to load the payment details",
                            timer: 2000,
                            showConfirmButton: false,
                        });
                    }
                }
                else {
                    console.log("sajkbcsjahcsa cdscu")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }

    }


    // useEffect(() => {
    //     if (displayPaymentDetails.unit_amount) {
    //         const ind = displayPaymentDetails.unit_amount;
    //         const usd = ind / 82.07;
    //         setAmount(usd.toFixed(3));
    //     }
    // }, [displayPaymentDetails.unit_amount]);

    const handleSubmit = async (e) => {
        console.log("clickl", address)
        e.preventDefault(); // Prevent the default form submission
        setLoderButton(true);
        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            setLoderButton(false);
            Swal.fire({
                icon: "error",
                title: "",
                text: "All card details required",
                timer: 2000,
                showConfirmButton: false,
            });
            return;
        }

        // const cardElement = elements.getElement(CardElement);
        if (!cardElement._empty) {
            // Card details are not empty
            // Continue with the payment process
            // ...
        } else {
            // Card details are empty
            Swal.fire({
                icon: "error",
                title: "",
                text: "Please enter card details",
                timer: 2000,
                showConfirmButton: false,
            });
        }



        console.log("address.country0", address.country)
        if (name == undefined ||
            address.line1 == undefined ||
            address.city == undefined ||
            address.state == undefined ||
            address.postal_code == undefined ||
            address.country == undefined
        ) {
            setLoderButton(false);

            Swal.fire({
                icon: "error",
                title: "",
                text: "All fields are required",
                timer: 1000000,
                showConfirmButton: false,
            });

        }

        else if (

            address.postal_code == 5

        ) {
            setLoderButton(false);

            Swal.fire({
                icon: "error",
                title: "",
                text: "Postal code must be 5 digit",
                timer: 1000000,
                showConfirmButton: false,
            });

        }


        else if (cardElement._empty) {
            setLoderButton(false);
            Swal.fire({
                icon: "error",
                title: "",
                text: "Please enter card details",
                timer: 2000,
                showConfirmButton: false,
            });
        } else if (error) {
            setLoderButton(false);
            Swal.fire({
                icon: "error",
                title: "",
                text: "Invalid card details",
                timer: 2000,
                showConfirmButton: false,
            });
            return;
        } else {

            if (localStorage.getItem("userloggedin") === 'true') {
                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                    const targetUser = userDataArray.find(user => user.email === emailTo);

                    if (targetUser) {
                        const { userID, accessToken, providerToken } = targetUser;

                        e.preventDefault();

                        if (!elements || !stripe) return;
                        const cardElement = elements.getElement(CardElement);
                        let token = await stripe.createToken(cardElement, {
                            name: name,
                            address_line1: address.line1,
                            address_line2: address.line2 == null ? "" : address.line2,
                            address_city: address.city,
                            address_state: address.state,
                            address_zip: address.postal_code,
                            address_country: address.country,
                        });
                        console.log("token.token.id", token.token.id)
                        setTokenPay(token.token.id)
                        try {
                            setLoderButton(true)
                            let fdata = new FormData();

                            fdata.append('provider_token', providerToken);
                            fdata.append('price_id', params.paymentid)
                            fdata.append('token', token.token.id);

                            fetch(`${BASE_URL}api/process/plan`, {
                                method: 'POST',
                                body: fdata,
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                }
                            }).then(response => {

                                console.log("response API", response)
                                return response.json()
                            }).then(data => {
                                console.log("tweet payment", data);
                                if (data.code == 500) {
                                    Swal.fire({
                                        icon: "error",
                                        title: "",
                                        text: data.error_message,
                                        timer: 2000,
                                        showConfirmButton: false,
                                    });
                                    // navigate('/dashboard/app/notification')
                                }

                                else {

                                    window.open(data.data.authentication_url, '_blank');
                                    // navigate('/dashboard/app/notification')
                                    Swal.fire({
                                        icon: "success",
                                        title: "",
                                        text: data.message,
                                        timer: 2000,
                                        showConfirmButton: false,
                                    });
                                    if (data.data.authentication_url) {
                                        navigate('/dashboard/app/notification')
                                    }

                                }

                            })

                            setLoderButton(false)

                        } catch (error) {
                            console.log(error)
                        }

                        const body = {
                            sourceToken: token.token.id,
                            // ticketId: ticketdetails.ticketid,
                            // partyId: ticketdetails.partyid,
                        };
                        //                 authDataObject.StripePayment(body, ticketdetails.ticketid ,ticketdetails.partyid, async (res) => {
                        //                     console.log("ressss",res)
                        //                     if (res.status == 200) {
                        //                           setLoderButton(false);
                        //                           Swal.fire({
                        //                             icon: "error",
                        //                             title: "",
                        //                             text: "Payment failed!",
                        //                             timer: 2000,
                        //                             showConfirmButton: false,
                        //                           });
                        //                         } else {

                        //                           console.log("error",res.data.error);
                        //                           Swal.fire({
                        //                             icon: "error",
                        //                             title: "",
                        //                             text: "Payment failed!",
                        //                             timer: 2000,
                        //                             showConfirmButton: false,
                        //                           });
                        //                         }

                        // })

                        // authDataObject.StripePayment(body, async (res) => {
                        //   if (!res.data.status) {
                        //     setLoderButton(false);
                        //     Swal.fire({
                        //       icon: "error",
                        //       title: "",
                        //       text: "Payment failed!",
                        //       timer: 2000,
                        //       showConfirmButton: false,
                        //     });
                        //   } else if (
                        //     res.data.message == "Payment successful" &&
                        //     res.data.status
                        //   ) {
                        //     setLoderButton(false);
                        //     Swal.fire({
                        //       icon: "success",
                        //       timer: 2000,
                        //       title: "",
                        //       text: "Payment successful",
                        //       showConfirmButton: false,
                        //     });
                        //     if (Status) {
                        //       history.push("/destination_management");
                        //     } else {
                        //       Dropdownhandler();
                        //       history.push("/profile");
                        //     }
                        //   } else if (!res.data.sData.actionRequired && res.data.status) {
                        //     setLoderButton(false);
                        //     Swal.fire({
                        //       icon: "success",
                        //       timer: 2000,
                        //       title: "",
                        //       text: "Payment successful",
                        //       showConfirmButton: false,
                        //     });
                        //     if (Status) {
                        //       history.push("/destination_management");
                        //     } else {
                        //       Dropdownhandler();
                        //       history.push("/profile");
                        //     }
                        //   } else if (res.data.sData.actionRequired) {
                        //     const { paymentIntent, error } = await stripe.confirmCardPayment(
                        //       res.data.sData.clientSecret
                        //     );
                        //     const body1 = {
                        //       id: res.data.sData.id,
                        //     };
                        //     const threed = () =>
                        //       subcriptionDataObject.threedsecure(body1, async (res) => {});
                        //     if (error) {
                        //       threed();
                        //       setLoderButton(false);
                        //       Swal.fire({
                        //         icon: "error",
                        //         title: "",
                        //         timer: 2000,
                        //         text: "Payment failed!",
                        //         showConfirmButton: false,
                        //       });
                        //     }
                        //     if (paymentIntent.status === "succeeded") {
                        //       threed();
                        //       setLoderButton(false);
                        //       Swal.fire({
                        //         icon: "success",
                        //         title: "",
                        //         timer: 2000,
                        //         text: "Payment successful",
                        //         showConfirmButton: false,
                        //       });
                        //       if (Status) {
                        //         history.push("/destination_management");
                        //       } else {
                        //         Dropdownhandler();
                        //         history.push("/profile");
                        //       }
                        //     }
                        //   }
                        // });
                    }
                    else {
                        console.log("eroror aklaka shds 11")
                    }
                } catch (error) {

                }
            }
            else {
                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                    const targetUser = userDataArray.find(user => user.email === emailSwitch);

                    if (targetUser) {
                        const { userID, accessToken, providerToken } = targetUser;

                        e.preventDefault();

                        if (!elements || !stripe) return;
                        const cardElement = elements.getElement(CardElement);
                        let token = await stripe.createToken(cardElement, {
                            name: name,
                            address_line1: address.line1,
                            address_line2: address.line2 == null ? "" : address.line2,
                            address_city: address.city,
                            address_state: address.state,
                            address_zip: address.postal_code,
                            address_country: address.country,
                        });
                        console.log("token.token.id", token.token.id)
                        setTokenPay(token.token.id)
                        try {
                            setLoderButton(true)
                            let fdata = new FormData();

                            fdata.append('provider_token', providerToken);
                            fdata.append('price_id', params.paymentid)
                            fdata.append('token', token.token.id);

                            fetch(`${BASE_URL}api/process/plan`, {
                                method: 'POST',
                                body: fdata,
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                }
                            }).then(response => {

                                console.log("response API", response)
                                return response.json()
                            }).then(data => {
                                console.log("tweet payment", data);
                                if (data.code == 500) {
                                    Swal.fire({
                                        icon: "error",
                                        title: "",
                                        text: data.error_message,
                                        timer: 2000,
                                        showConfirmButton: false,
                                    });
                                    // navigate('/dashboard/app/notification')
                                }

                                else {

                                    window.open(data.data.authentication_url, '_blank');
                                    // navigate('/dashboard/app/notification')
                                    Swal.fire({
                                        icon: "success",
                                        title: "",
                                        text: data.message,
                                        timer: 2000,
                                        showConfirmButton: false,
                                    });
                                    if (data.data.authentication_url) {
                                        navigate('/dashboard/app/notification')
                                    }

                                }

                            })

                            setLoderButton(false)

                        } catch (error) {
                            console.log(error)
                        }

                        const body = {
                            sourceToken: token.token.id,
                            // ticketId: ticketdetails.ticketid,
                            // partyId: ticketdetails.partyid,
                        };
                        //                 authDataObject.StripePayment(body, ticketdetails.ticketid ,ticketdetails.partyid, async (res) => {
                        //                     console.log("ressss",res)
                        //                     if (res.status == 200) {
                        //                           setLoderButton(false);
                        //                           Swal.fire({
                        //                             icon: "error",
                        //                             title: "",
                        //                             text: "Payment failed!",
                        //                             timer: 2000,
                        //                             showConfirmButton: false,
                        //                           });
                        //                         } else {

                        //                           console.log("error",res.data.error);
                        //                           Swal.fire({
                        //                             icon: "error",
                        //                             title: "",
                        //                             text: "Payment failed!",
                        //                             timer: 2000,
                        //                             showConfirmButton: false,
                        //                           });
                        //                         }

                        // })

                        // authDataObject.StripePayment(body, async (res) => {
                        //   if (!res.data.status) {
                        //     setLoderButton(false);
                        //     Swal.fire({
                        //       icon: "error",
                        //       title: "",
                        //       text: "Payment failed!",
                        //       timer: 2000,
                        //       showConfirmButton: false,
                        //     });
                        //   } else if (
                        //     res.data.message == "Payment successful" &&
                        //     res.data.status
                        //   ) {
                        //     setLoderButton(false);
                        //     Swal.fire({
                        //       icon: "success",
                        //       timer: 2000,
                        //       title: "",
                        //       text: "Payment successful",
                        //       showConfirmButton: false,
                        //     });
                        //     if (Status) {
                        //       history.push("/destination_management");
                        //     } else {
                        //       Dropdownhandler();
                        //       history.push("/profile");
                        //     }
                        //   } else if (!res.data.sData.actionRequired && res.data.status) {
                        //     setLoderButton(false);
                        //     Swal.fire({
                        //       icon: "success",
                        //       timer: 2000,
                        //       title: "",
                        //       text: "Payment successful",
                        //       showConfirmButton: false,
                        //     });
                        //     if (Status) {
                        //       history.push("/destination_management");
                        //     } else {
                        //       Dropdownhandler();
                        //       history.push("/profile");
                        //     }
                        //   } else if (res.data.sData.actionRequired) {
                        //     const { paymentIntent, error } = await stripe.confirmCardPayment(
                        //       res.data.sData.clientSecret
                        //     );
                        //     const body1 = {
                        //       id: res.data.sData.id,
                        //     };
                        //     const threed = () =>
                        //       subcriptionDataObject.threedsecure(body1, async (res) => {});
                        //     if (error) {
                        //       threed();
                        //       setLoderButton(false);
                        //       Swal.fire({
                        //         icon: "error",
                        //         title: "",
                        //         timer: 2000,
                        //         text: "Payment failed!",
                        //         showConfirmButton: false,
                        //       });
                        //     }
                        //     if (paymentIntent.status === "succeeded") {
                        //       threed();
                        //       setLoderButton(false);
                        //       Swal.fire({
                        //         icon: "success",
                        //         title: "",
                        //         timer: 2000,
                        //         text: "Payment successful",
                        //         showConfirmButton: false,
                        //       });
                        //       if (Status) {
                        //         history.push("/destination_management");
                        //       } else {
                        //         Dropdownhandler();
                        //         history.push("/profile");
                        //       }
                        //     }
                        //   }
                        // });
                    }
                    else {
                        console.log("eroror aklaka shds 11")
                    }
                } catch (error) {

                }
            }
        }
    };



    const handleNavigateBack = () => {
        navigate('/app/payments')
    }

    const handleTerms = () => {
        navigate('/app/terms-condition')
    }

    const handlePrivavcy = () => {
        navigate('/app/privacy-policy')
    }


    return (
        <>
            <div className='payment-screen-body-' style={{ overflowX: 'hidden' }}>


                <Row>


                    <Col sm={6} md={6} lg={6} >

                        <Container>
                            <div>
                                <div className='title-cart'>

                                    <div className='icon-title'>
                                        <FontAwesomeIcon icon={faArrowLeft} className="font-arrow-back" onClick={handleNavigateBack} />    <img src={Xblue} className='icon material-symbols-outlined image-payment-form' ></img><h3>Xblue</h3>
                                    </div>

                                    <p>Try Xblue Grow {displayPaymentDetails.$duration && displayPaymentDetails.$duration.length > 0 ? displayPaymentDetails.$duration : ''} </p>
                                    <h2>7 days free </h2>
                                    <p>Then {displayPaymentDetails.nickname && displayPaymentDetails.nickname.length > 0 ? displayPaymentDetails.nickname[0] : ""}</p>
                                    <div>
                                        {/* <p className='para-topprio'>Start tweeting like the very best.
                                            Find high-performing tweets, organize your inspirations,
                                            and write better tweets 10x faster.
                                        </p> */}

                                    </div>
                                    <div className='display-subtitle-flex'>
                                        <div className='display-icon-cart'>
                                            <img src={Xblue} className='icon material-symbols-outlined image-payment-form-2' />

                                        </div>
                                        <div className='display-text-cart' >
                                            <div className='display-subcart-text'>
                                                <h5>Xblue grow {displayPaymentDetails.$duration && displayPaymentDetails.$duration.length > 0 ? displayPaymentDetails.$duration : ''}</h5>
                                                <span>{displayPaymentDetails.nickname && displayPaymentDetails.nickname.length > 0 ? displayPaymentDetails.nickname[0] : ""}</span>
                                            </div>
                                            <p>
                                                Start posting like the very best. Find high-performing posts.
                                            </p>


                                        </div>

                                    </div>

                                    <div className='breakout-1'>

                                        <p>Subtotal</p>


                                        <p>
                                            ${displayPaymentDetails.unit_amount}.00
                                        </p>



                                    </div>
                                    {/* 
                                    <div className='breakout-2'>
                                        <div>
                                            <button>
                                                < FontAwesomeIcon icon={faTag} /> INDIA50OFF
                                            </button>
                                            <p>50% off</p>
                                        </div>
                                        <div>
                                            <p style={{ color: 'white' }}>
                                                -$216.00
                                            </p>
                                        </div>
                                    </div> */}

                                    <div className='breakout-3'>

                                        <p>Total after trial</p>


                                        <p>
                                            {/* ${amount ? amount : '0.00'} */}
                                            ${displayPaymentDetails.unit_amount}.00
                                        </p>

                                    </div>

                                    <div className='breakout-4'>

                                        <p>Total due today</p>


                                        <p>
                                            $0.00
                                        </p>

                                    </div>



                                    {/* <div className='breakout-line'>
                                        <p>Legal refund</p>

                                    </div> */}


                                </div>



                            </div>
                        </Container>


                    </Col>
                    <Col sm={6} md={6} lg={6} style={{ backgroundColor: 'white' }} className="white-screen">

                        <Container>
                            <div className='form-payment-strpe'>
                                <h3>Enter payment details</h3>


                                <form style={{ width: "100%", marginTop: 20 }} onSubmit={handleSubmit}>
                                    <lable>Card details</lable>
                                    <fieldset
                                        className="FormGroup card-form-paymet"
                                        style={{
                                            // color: "#fff",
                                            // border: "0.5px solid lightgrey",
                                            // borderRadius: "5px",
                                            // marginBottom: "3%",
                                            // fontSize: "12px !important",
                                            padding: 15,
                                            // width: '78%',
                                            marginLeft: 10

                                        }}
                                    >
                                        {/* <div className="FormRow"> */}
                                        <CardElement options={CARD_OPTIONS} />
                                        {/* </div> */}
                                    </fieldset>
                                    <fieldset
                                        className="FormGroup input-form-paymet"
                                        style={{
                                            color: "#fff",
                                            // border: "0.5px solid grey",
                                            borderRadius: "10px",
                                            fontSize: "12px !important",

                                        }}
                                    >
                                        <AddressElement
                                            options={{
                                                mode: "billing",


                                            }}

                                            onChange={(event) => {
                                                if (event.complete) {
                                                    const address = event.value.address;
                                                    const name = event.value.name;
                                                    setaddress(address);
                                                    setName(name); // Assuming you have a state variable called 'name' to store the name value
                                                    console.log("address", address);
                                                    console.log("name", name);


                                                }
                                            }}
                                        />
                                    </fieldset>
                                    <fieldset
                                        style={{
                                            color: "#fff",
                                            border: "none",
                                        }}
                                    ></fieldset>

                                    <button className='button-card-form' type='submit' >
                                        {loderButton ?
                                            <Spinner animation="border" size="sm" variant="white" className='mr-5' style={{ marginRight: 5 }} />
                                            : 'Start trial'}
                                    </button>

                                </form>

                                <div className='text-left terms'>
                                    <p>By placing your order, you agree to our<span style={{ color: '#284E79', cursor: 'pointer', fontWeight: 600 }} onClick={handleTerms}> terms of service</span> and<span style={{ color: '#284E79', cursor: 'pointer', fontWeight: 600 }} onClick={handlePrivavcy}>  privacy policy</span>.</p>

                                    {/* <p>After your trial ends, you will be charged ${displayPaymentDetails.unit_amount} per year.</p> */}

                                    <p>{displayPaymentDetails?.trial_end_message}</p>

                                </div>
                                {/* <button className="" onClick={handledownload}>dscds</button> */}


                            </div>

                        </Container>


                    </Col>
                </Row>




            </div>
            {/* <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                <fieldset
                    className="FormGroup"
                    style={{
                        color: "#fff",
                        border: "0.5px solid grey",
                        borderRadius: "10px",
                        marginBottom: "3%",
                        fontSize: "12px !important",
                    }}
                >
                    <div className="FormRow">
                        <CardElement options={CARD_OPTIONS} />
                    </div>
                </fieldset>
                <fieldset
                    className="FormGroup"
                    style={{
                        color: "#fff",
                        border: "0.5px solid grey",
                        borderRadius: "10px",
                        fontSize: "12px !important",
                    }}
                >
                    <AddressElement
                        options={{
                            mode: "billing",
                        }}
                        onChange={(event) => {
                            if (event.complete) {
                                const address = event.value.address;
                                setaddress(address);
                            }
                        }}
                    />
                </fieldset>
                <fieldset
                    style={{
                        color: "#fff",
                        border: "none",
                    }}
                ></fieldset>
                <button className='sendcode-button' type='submit' >
                    Finish
                </button>
              
            </form> */}
        </>
    );
}

