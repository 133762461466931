import { useState, useEffect, memo, Fragment, useRef, useMemo } from "react";

//react-bootstrap
import { Offcanvas, Row, Col } from "react-bootstrap";

// Redux Selector / Action
import { useSelector } from "react-redux";

// Import selectors & action from setting store
import * as SettingSelector from "../../store/setting/selectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPaperPlane, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark, faFilm, faCaretDown, faSmile, faClock, faAngleDown, faCalendar, faBolt } from '@fortawesome/free-solid-svg-icons';
import ReactGiphySearchbox from "react-giphy-searchbox";

// Section Components
// Style Setting Section Components
import ThemeScheme from "./sections/theme-scheme";
import ColorCustomizer from "./sections/color-customizer";
import MenuStyle from "./sections/menu-style";
import MenuActiveStyle from "./sections/menu-active-style";
import Direction from "./sections/direction";
import Ellipse from "../../assets/images/Ellipse.png";
import noimage from "../../assets/noimage.png";
import Microlink from '@microlink/react';

import "./SettingStyle.css";
import { BASE_URL } from "../../utilities/Config";

// import controller for api calling

import TweetsDataObject from "../../services/Tweets_Controller/Tweets_Controller";

const SettingOffCanvas = memo((props, cont) => {

    console.log("contecxt-drafttt", props)
    const [show, setShow] = useState(false);

    // Define selectors
    const themeScheme = useSelector(SettingSelector.theme_scheme);
    const themeSchemeDirection = useSelector(
        SettingSelector.theme_scheme_direction
    );
    const themeColor = useSelector(SettingSelector.theme_color);
    const sidebarType = useSelector(SettingSelector.sidebar_type);
    const sidebarMenuStyle = useSelector(SettingSelector.sidebar_menu_style);
    const [test, setTest] = useState(props.textthread);

    const tokenLogin = localStorage.getItem("accessToken");
    const providerToken = localStorage.getItem("providerToken");
    const providerID = localStorage.getItem("providerID");
    const OauthToken = localStorage.getItem("oAuthToken");
    const timeZone = localStorage.getItem("TimeZone");
    const userID = localStorage.getItem("UserID");
    const emailSwitch = localStorage.getItem('emailSwitch')
    const emailTo = localStorage.getItem('email-to')


    // new code

    const [selectedGifpicker, setSelectedGifPicker] = useState(null);
    const [selectedGifpickerArr, setSelectedGifPickerArr] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState('');
    const [selectedGif, setSelectedGif] = useState([]);

    const [NewGif, setNewGif] = useState(null);
    const gifInputRef = useRef(null);
    const [showGif, setShowGif] = useState(false);
    const [imageNames, setImageNames] = useState([]);

    const [threads, setThreads] = useState([]);

    //images
    const [selectedImages, setSelectedImages] = useState([]);
    const [arrImg, setArrImg] = useState([]);
    const threadimageInputRef = useRef(null);

    // gifopen

    const [openGifIndex, setOpenGifIndex] = useState(null);
    const [displayProfileDetails, setDisplayProfileDetails] = useState()

    useEffect(() => {
        MyProfileApiCalling()
    }, [])

    const MyProfileApiCalling = async () => {

        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("profile-data", data.data.user)

                    if (data.code == 200) {

                        setDisplayProfileDetails(data.data.user)


                    }
                    else {
                        setDisplayProfileDetails([])


                        console.log("failed")


                    }
                }
                else {
                    console.log("sjncsbcajh cdciudshjb 2782727272")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                console.log("kakannn", targetUser)
                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("profile-data", data.data.user)

                    if (data.code == 200) {

                        setDisplayProfileDetails(data.data.user)


                    }
                    else {
                        setDisplayProfileDetails([])


                        console.log("failed")


                    }
                }
                else {
                    console.log("sjncsbcajh cdciudshjb 2782727272")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };

    useMemo(async (index) => {
        if (props.cont?.t?.thread) {
            const li = props.cont.t.thread;
            setSelectedImages(li);
            setSelectedGif(li)
            setSelectedVideo(li)


        }
        else {
            const li = props.cont.t;
            setSelectedImages(li);
            setSelectedGif(li)
            setSelectedVideo(li)

        }




    }, [props.cont, selectedImages]);


    const handleRemoveImage2 = (imgIndex, threadIndex) => {
        const updatedImages = [...selectedImages];
        if (updatedImages[threadIndex]?.image) {
            updatedImages[threadIndex].image.splice(imgIndex, 1);
            setSelectedImages(updatedImages);
        }

    }

    const handleRemoveGif2 = (imgIndex, threadIndex) => {
        const updatedGif = [...selectedGif];
        if (updatedGif[threadIndex]?.gif) {
            updatedGif[threadIndex].gif.splice(imgIndex, 1);
            setSelectedGif(updatedGif);
        }

    }

    const handleVideoRemove = (index) => {

        const updatedGif = { ...selectedVideo }; // Create a shallow copy of the object

        if (updatedGif[index]?.video) {
            updatedGif[index].video = "";
            setSelectedVideo(updatedGif);
        }

    }



    console.log("displayProfileDetails", selectedImages);

    //image handle
    let imageName = "";
    function randomImageName(len) {
        var alphabet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

        for (var i = len; i > 0; i--) {
            imageName += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
        }
        console.log("aplpha", imageName);

        return "[ img: " + imageName + "]";
    }



    const handleDeleteData = async () => {
        let threadIds = { tweet_id: "", thread_tweet_id: "" };
        for await (const element of props.textthread) {
            console.log("element", element)
            try {
                if (
                    element.value == "\n" ||
                    element.value == "" ||
                    element.value == null
                ) {
                } else {
                    let fdata = new FormData();
                    let textValue = getTextWithoutImg(element.value, "[ img: ");

                    // if (findNextIndex(element.value, "[ img: ").length > 0) {
                    //     findNextIndex(element.value, "[ img: ").map((imgind) => {
                    //         let imgnam = element.value.substring(imgind, imgind + 13);
                    //         if (
                    //             props.selectedImages[
                    //                 props.selectedImages &&
                    //                 props.selectedImages.findIndex((a) => a.name === imgnam)
                    //             ]?.imgFile
                    //         ) {
                    //             fdata.append(
                    //                 "image[]",
                    //                 props.selectedImages[
                    //                     props.selectedImages &&
                    //                     props.selectedImages.findIndex((a) => a.name === imgnam)
                    //                 ]?.imgFile
                    //             );
                    //         } else {
                    //             fdata.append("image[]", "");
                    //         }
                    //     });
                    // } else {
                    //     fdata.append("image[]", "");
                    // }

                    // arrImgThread?.map(a => {
                    //     fdata.append('image[]', a);
                    // })


                    threads.map((innerArray, outerIndex) => (

                        <div key={outerIndex}>
                            {innerArray.map((item, innerIndex) => (
                                // console.log("innerArrayt", item)
                                fdata.append('image[]', item.file)
                            ))}
                        </div>
                    ))



                    if (props.selectedGifpickerArr[0]?.gif) {
                        try {
                            const response = await fetch(props.selectedGifpickerArr[0]?.gif);
                            const blob = await response.blob();
                            const file = new File([blob], "selectedGif.gif");
                            // Use the file as needed
                            // ...
                            fdata.append("gif[]", file);
                        } catch (error) {
                            console.log(error);
                        }
                    } else {
                        fdata.append("gif[]", "");
                    }

                    fdata.append("text", textValue);
                    fdata.append("provider_token", providerToken);
                    fdata.append("oauth_token_secret", OauthToken);
                    fdata.append("id", "");
                    fdata.append(
                        "tweet_id",
                        threadIds.tweet_id ? threadIds.tweet_id : ""
                    );
                    fdata.append(
                        "thread_tweet_id",
                        threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
                    );
                    await fetch("https://app.tweetnow.ai/TweetNow/api/create/thread", {
                        method: "POST",
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${tokenLogin}`,
                        },
                    })
                        .then((response) => {
                            return response.json();
                        })
                        .then((data) => {
                            if (data.code == 200) {
                                threadIds.tweet_id = data.data.tweet_id;
                                threadIds.thread_tweet_id = data.data.thread_tweet_id;
                                alert("tweet posted successfully");
                            } else {
                                alert(JSON.stringify(data.message));
                            }
                        });
                }
            } catch (error) {
                console.log(error);
            }
        }
    };






    const deleteCall2 = () => {
        props.deleteCall();
        props.handleClosethread()

    };

    useEffect(() => {
        setTest(props.textthread);
        const onClick = (e) => {
            if (show) {
                if (
                    e.target.closest(".live-customizer") == null &&
                    e.target.closest("#settingbutton") == null
                ) {
                    setShow(false);
                }
            }
        };
        document.body.addEventListener("click", onClick);

        return () => {
            document.body.removeEventListener("click", onClick);
        };
    });

    function findAllOccurrences(str, word) {
        var regex = new RegExp("\\b" + word + "\\b", "gi");
        var occurrences = [];

        var match = regex.exec(str);
        while (match !== null) {
            occurrences.push(match.index);
            match = regex.exec(str);
        }
        return occurrences;
    }

    function getTextWithoutImg(sentence, word) {
        let indFound = sentence.indexOf(word);
        let formateedSentence = sentence;
        for (let i = 0; i < sentence.length; i++) {
            if (indFound > -1) {
                let imgNm = sentence.substring(indFound, indFound + 13);
                indFound = sentence.indexOf(word, indFound + 1);
                formateedSentence = formateedSentence.replace(imgNm, "");
            } else {
                break;
            }
        }
        return formateedSentence;
    }
    function findNextIndex(sentence, word) {
        let indFound = sentence.indexOf(word, 0);
        let occurrences = [];
        for (let i = 0; i < sentence.length; i++) {
            if (indFound > -1) {
                occurrences.push(indFound);
                indFound = sentence.indexOf(word, indFound + 1);
            } else {
                break;
            }
        }
        return occurrences;
    }

    console.log("props.textthread", props.textthread)

    // const handleImageSelect = (e) => {
    //   console.log("dkkfdsdfsdkkkkkkkk",e)
    // }

    // const handleGifopen = () => {
    //   console.log("dkkkkkkkkkk")
    //   setShowGif((prevInput) => !prevInput);
    // }

    const handleRemoveAll = () => {
        setSelectedImages([]);
        setArrImg([]);
    };
    const [selectedGifpicker2, setSelectedGifPicker2] = useState([]);


    const handleGifPicker = async (gif, index) => {
        // Handle the selected GIF with the corresponding index
        // You can access the selected GIF using the 'gif' parameter
        console.log("Selected GIF for index", index, ":", gif);
        let imgnm = randomImageName(5);
        // setSelectedGifPicker2((prevSelectedGifs) => [...prevSelectedGifs, gif.images.original.url]);
        setSelectedGifPicker2((prevSelectedGifs) => {
            const updatedSelectedGifs = [...prevSelectedGifs];
            updatedSelectedGifs[index] = gif.images.original.url; // Assign the value at index 3
            return updatedSelectedGifs;
        });

        // handleRemoveAll();
        // setSelectedGifPicker2(gif.images.original.url);

        threads[index] = [];

        //   newGifsPicker.push({ img: reader.result, name: imgnm });
        // setSelectedGifPickerArr((a) => [
        //   { gif: gif.images.original.url, name: imgnm },
        // ]);

        setShowGif((prevInput) => !prevInput);
        // try {
        //   const response = await fetch(gif.images.original.url);
        //   const blob = await response.blob();
        //   const file = new File([blob], "selectedGif.gif");

        //   // Use the file as needed
        //   // ...

        //   console.log("gif++++", file);
        //   setNewGif(file);
        // } catch (error) {
        //   console.log(error);
        // }

        // setSelectedGifIndex(gif.images.original.url);

        // console.log(selectionStartVal, "selectionStartVal-gif");
        // let a =
        //   tweetText.substring(0, selectionStartVal + 1) +
        //   imgnm +
        //   tweetText.substring(selectionStartVal + 1);
        // setTweetText(a);
        // const updatedTextThread = a.split("\n\n\n").map((text, index1) => {
        //   const item = {
        //     value: text,
        //     subValue: a.includes("\n\n\n"),
        //     gif: "",
        //     image: null,
        //   };

        //   if (index1 === selectedGifIndex) {
        //     item.gif = selectedGifIndex; // Assign the selected image to the item
        //     console.log("sel;ected", item.image);
        //   }
        //   if (item.subValue == true) {
        //     setShowthread(true);
        //   }
        //   console.log(item);
        //   return item;
        // });
        // console.log(
        //   "imagesThread-gif",
        //   updatedTextThread,
        //   selectedImages,
        //   textthread
        // );
        // setTextThread(updatedTextThread);
    };


    const [arrImgThread, setArrImgThread] = useState([])

    const handleImageUpload = (threadIndex) => (e) => {
        let imgnm = randomImageName(5);
        // handleRemoveGif();

        // console.log("yugyuihikn", threadIndex)

        const file = e.target.files[0]; // Extract the file from the event object
        const imageName = file.name; // Get the image name

        // console.log("threadIndex", threadIndex);


        // setSelectedGifPicker2((prevSelectedGifs) => {
        //   const updatedSelectedGifs = [...prevSelectedGifs];
        //   updatedSelectedGifs[threadIndex] = []; // Assign the value at index 3
        //   return updatedSelectedGifs;
        // });

        setSelectedGifPicker2((prevSelectedGifs) => {
            const updatedSelectedGifs = prevSelectedGifs.filter((_, index) => index !== threadIndex);
            return updatedSelectedGifs;
        });

        const files = Array.from(e.target.files);
        console.log("files", files)
        files.forEach((file) => {
            const reader = new FileReader();

            reader.onload = () => {
                var imgurl = reader.result
                setThreads((prevThreads) => {
                    const updatedThreads = [...prevThreads];
                    if (updatedThreads[threadIndex]) {
                        updatedThreads[threadIndex].push({ file, imgnm, imgurl }); // Store the file and image name together
                    } else {
                        updatedThreads[threadIndex] = [{ file, imgnm, imgurl }]; // Create a new array with the file and image name
                    }
                    console.log("updtaed", updatedThreads)
                    return updatedThreads;
                });

                // newImages.push({
                //   img: reader.result,
                //   name: imgnm,
                //   imgFile: e.target.files[0],
                // });
                // setSelectedImages(newImages);

            };

            reader.readAsDataURL(file);
        });
        console.log(e.target.files[0], 'e.target.files[0]-thread', e.target.files);
        setArrImgThread(a => [...a, e.target.files[0]])

        // setThreads((prevThreads) => {
        //   const updatedThreads = [...prevThreads];
        //   if (updatedThreads[threadIndex]) {
        //     updatedThreads[threadIndex].push({ file, imgnm }); // Store the file and image name together
        //   } else {
        //     updatedThreads[threadIndex] = [{ file, imgnm }]; // Create a new array with the file and image name
        //   }
        //   return updatedThreads;
        // });

        setImageNames((prevNames) => [...prevNames, imgnm]); // Add the image name to the state
    };

    const handleTextareaChange = (e) => {
        const textareaValue = e.target.value;
        const imageNamesToRemove = imageNames.filter((name) => !textareaValue.includes(name));
        setThreads((prevThreads) => {
            const updatedThreads = prevThreads.map((thread) =>
                thread.filter((image) => !imageNamesToRemove.includes(image.imageName))
            );
            return updatedThreads;
        });
        setImageNames((prevNames) => prevNames.filter((name) => !imageNamesToRemove.includes(name)));
    };

    console.log("threads", threads);
    const flattenedThreads = threads.flat();
    console.log("threads-img", flattenedThreads);

    console.log('selectedGifpicker2', selectedGifpicker2)
    const handleGifopen = (index) => {
        if (openGifIndex === index) {
            // If the clicked index is already open, close it
            setOpenGifIndex(null);
            setShowGif(false);
        } else {
            // Open the clicked index
            setOpenGifIndex(index);
            setShowGif(true);
        }
    };

    const handleRemoveImage = (threadIndex, imageIndex) => {
        setThreads((prevThreads) => {
            const updatedThreads = [...prevThreads];
            if (updatedThreads[threadIndex]) {
                updatedThreads[threadIndex].splice(imageIndex, 1); // Remove the image at the specified index
            }
            return updatedThreads;
        });
    };



    const handleDraftThread = async () => {
        let threadIds = { tweet_id: "", thread_tweet_id: "" };
        for await (const element of props.textthread) {
            console.log("element", element)
            try {
                if (
                    element.value == "\n" ||
                    element.value == "" ||
                    element.value == null
                ) {
                } else {
                    let fdata = new FormData();
                    let textValue = getTextWithoutImg(element.value, "[ img: ");

                    if (findNextIndex(element.value, "[ img: ").length > 0) {
                        findNextIndex(element.value, "[ img: ").map((imgind) => {
                            let imgnam = element.value.substring(imgind, imgind + 13);
                            if (
                                props.selectedImages[
                                    props.selectedImages &&
                                    props.selectedImages.findIndex((a) => a.name === imgnam)
                                ]?.imgFile
                            ) {
                                fdata.append(
                                    "image",
                                    props.selectedImages[
                                        props.selectedImages &&
                                        props.selectedImages.findIndex((a) => a.name === imgnam)
                                    ]?.imgFile
                                );
                            } else {
                                fdata.append("image", "");
                            }
                        });
                    } else {
                        fdata.append("image", "");
                    }
                    if (props.selectedGifpickerArr[0]?.gif) {
                        try {
                            const response = await fetch(props.selectedGifpickerArr[0]?.gif);
                            const blob = await response.blob();
                            const file = new File([blob], "selectedGif.gif");
                            // Use the file as needed
                            // ...
                            fdata.append("gif", file);
                        } catch (error) {
                            console.log(error);
                        }
                    } else {
                        fdata.append("gif", "");
                    }

                    fdata.append("text", textValue);
                    fdata.append("provider_token", providerToken);
                    fdata.append("oauth_token_secret", OauthToken);
                    fdata.append("id", "");
                    fdata.append("tweet_id", "");
                    fdata.append(
                        "thread_tweet_id",
                        threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
                    );
                    await fetch("https://app.tweetnow.ai/TweetNow/api/draft/thread", {
                        method: "POST",
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${tokenLogin}`,
                        },
                    })
                        .then((response) => {
                            return response.json();
                        })
                        .then((data) => {
                            console.log("data-code", data.data.thread_draft)
                            if (data.code == 200) {
                                threadIds.tweet_id = data.data.thread_draft.tweet_id;
                                threadIds.thread_tweet_id = data.data.thread_draft.thread_tweet_id;
                                alert("tweet posted successfully");
                            } else {
                                alert(JSON.stringify(data.message));
                            }
                        });
                }
            } catch (error) {
                console.log(error);
            }
        }
    }



    function findLinksInText(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        //const urlRegex = /(https?:\/\/[^\s"]+)|(?:")https?:\/\/[^\s"]+(?:")/g;
        return text.match(urlRegex) || [];
    }


    return (
        <Fragment>
            <Offcanvas
                show={props.showthread}
                onHide={props.handleClosethread}
                placement={`${themeSchemeDirection === "rtl" ? "start" : "end"}`}
                backdrop={false}
                scroll={true}
                className="live-customizer"
            >
                <Offcanvas.Header closeButton className="pb-0">
                    <div className="d-flex align-items-center">
                        <h4 className="offcanvas-title" id="live-customizer-label">
                            <FontAwesomeIcon icon={faEye} /> Preview
                        </h4>
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="thread-save-draft" onClick={handleDraftThread}>
                        {/* <span>Save thread</span> */}
                    </div>
                    {props.textthread?.map((item, index) => {
                        const lines = item.value.split("\n\n\n");

                        // const textLength = item?.value?.replace(/\n/g, ' ')?.length;
                        // const maxLength = 280;
                        // const isOverLimit = textLength == maxLength;

                        let pureText = item?.value
                        {
                            findNextIndex(item?.value, "[ img: ").forEach((imgind, imgIndex) => {
                                let imgnam = item?.value.substring(imgind, imgind + 13);
                                pureText = pureText.replace(imgnam, "")

                            })
                        }
                        let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                        let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);






                        const textLength = withoutGif?.replace(/\n/g, ' ')?.length;

                        // const textLength = item?.value?.replace(/\n/g, ' ')?.length;
                        const maxLength = 280;
                        const isOverLimit = textLength > maxLength;
                        console.log("text length bew", textLength, pureText, withoutVdo, withoutGif)

                        const linesWithLinks = lines.map((line, lineIndex) => {
                            const linkwithoutIMG = getTextWithoutImg(line, "[ ")
                            const linksInLine = findLinksInText(linkwithoutIMG);
                            console.log({ line, lineIndex, linksInLine }, 'linksInLinelinksInLine')
                            if (linksInLine.length > 0) {
                                // let lineWoutLink = line
                                let lineWoutLink = linkwithoutIMG
                                {
                                    linksInLine.forEach((el) => {
                                        lineWoutLink = lineWoutLink.replaceAll(el, "")
                                    })
                                }
                                return (
                                    <li className="thread-li" key={lineIndex}>
                                        {/* {line.replaceAll("a", "b")} */}
                                        {lineWoutLink}
                                        {linksInLine.map((link, linkIndex) => (
                                            <span key={linkIndex} style={{ color: 'dodgerblue' }}>
                                                {/* {link}{' '} */}
                                                {props.isChecked ? (

                                                    link


                                                ) : (

                                                    findNextIndex(item.value, "[ img: ").length > 0 ?
                                                        findNextIndex(item.value, "[ img: ").map((imgind, imgIndex) => {
                                                            let imgnam = item.value.substring(imgind, imgind + 13);
                                                            item.value.replace(imgnam, "");

                                                            return (
                                                                <li className="thread-li" key={imgIndex}>
                                                                    {props.selectedImages2 &&
                                                                        props.selectedImages2.findIndex((a) => a.name === imgnam) >= 0 ? (
                                                                        link
                                                                    ) : (
                                                                        <Microlink url={link}>
                                                                            {({ data, loading }) => {
                                                                                if (loading) return <p>Loading...</p>;
                                                                                if (data) {

                                                                                    console.log(data);
                                                                                    return (
                                                                                        <div>
                                                                                            <h1>{data.title}</h1>
                                                                                            <p>{data.description}</p>

                                                                                        </div>
                                                                                    );
                                                                                }
                                                                                return null;
                                                                            }}
                                                                        </Microlink>
                                                                    )}
                                                                </li>
                                                            );
                                                        }) :
                                                        findNextIndex(item.value, "[ vid: ").length > 0 ?
                                                            findNextIndex(item.value, "[ vid: ").map((imgind, imgIndex) => {
                                                                let imgnam = item.value.substring(imgind, imgind + 13);
                                                                item.value.replace(imgnam, "");

                                                                return (
                                                                    <li className="thread-li" key={imgIndex}>
                                                                        {props.videoPreviews2 &&
                                                                            props.videoPreviews2.findIndex((a) => a.name === imgnam) >= 0 ? (
                                                                            link
                                                                        ) : (
                                                                            <Microlink url={link}>
                                                                                {({ data, loading }) => {
                                                                                    if (loading) return <p>Loading...</p>;
                                                                                    if (data) {

                                                                                        console.log(data);
                                                                                        return (
                                                                                            <div>
                                                                                                <h1>{data.title}</h1>
                                                                                                <p>{data.description}</p>

                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                    return null;
                                                                                }}
                                                                            </Microlink>
                                                                        )}
                                                                    </li>
                                                                );
                                                            }) :
                                                            findNextIndex(item.value, "[ gif: ").length > 0 ?
                                                                findNextIndex(item.value, "[ gif: ").map((imgind, imgIndex) => {
                                                                    let imgnam = item.value.substring(imgind, imgind + 13);
                                                                    item.value.replace(imgnam, "");

                                                                    return (
                                                                        <li className="thread-li" key={imgIndex}>
                                                                            {props.selectedGifpickerArr &&
                                                                                props.selectedGifpickerArr.findIndex((a) => a.name === imgnam) >= 0 ? (
                                                                                link
                                                                            ) : (
                                                                                <Microlink url={link}>
                                                                                    {({ data, loading }) => {
                                                                                        if (loading) return <p>Loading...</p>;
                                                                                        if (data) {

                                                                                            console.log(data);
                                                                                            return (
                                                                                                <div>
                                                                                                    <h1>{data.title}</h1>
                                                                                                    <p>{data.description}</p>

                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                        return null;
                                                                                    }}
                                                                                </Microlink>
                                                                            )}
                                                                        </li>
                                                                    );
                                                                })
                                                                : (
                                                                    <Microlink url={link}>
                                                                        {({ data, loading }) => {
                                                                            if (loading) return <p>Loading...</p>;
                                                                            if (data) {

                                                                                console.log(data);
                                                                                return (
                                                                                    <div>
                                                                                        <h1>{data.title}</h1>
                                                                                        <p>{data.description}</p>

                                                                                    </div>
                                                                                );
                                                                            }
                                                                            return null;
                                                                        }}
                                                                    </Microlink>
                                                                )



                                                    // <Microlink url={link}>
                                                    //     {({ data, loading }) => {
                                                    //         if (loading) return <p>Loading...</p>;
                                                    //         if (data) {

                                                    //             console.log(data);
                                                    //             return (
                                                    //                 <div>
                                                    //                     <h1>{data.title}</h1>
                                                    //                     <p>{data.description}</p>

                                                    //                 </div>
                                                    //             );
                                                    //         }
                                                    //         return null;
                                                    //     }}
                                                    // </Microlink>


                                                )}
                                                {' '} {/* Add a space after the link */}
                                            </span>
                                        ))}
                                    </li>
                                );
                            }

                            // If no links, render the line as usual
                            return (
                                <li className="thread-li" key={lineIndex}>
                                    {/* {line}{" "} */}
                                    {getTextWithoutImg(line, "[ ")}{" "}
                                </li>
                            );
                        });


                        return (
                            <Fragment key={index}>
                                <div>
                                    <img
                                        src={displayProfileDetails?.image_url ? displayProfileDetails?.image_url : noimage}
                                        className="span-icon"
                                        style={{ width: 60 }}
                                    />
                                    <span className="span-thread" style={{ marginLeft: 20 }}>
                                        {displayProfileDetails?.name}
                                    </span>
                                </div>
                                <ul className="thread-ul" style={{ whiteSpace: 'pre-wrap' }}>
                                    {/* {lines.map((line, lineIndex) => (
                                        console.log("line", line)
                                        // <li className="thread-li" key={lineIndex}>
                                        //     {getTextWithoutImg(line, "[ ")}
                                        // </li>
                                    ))} */}



                                    {linesWithLinks && linesWithLinks?.map((line, lineIndex) => (


                                        <li className="thread-li" key={lineIndex} >

                                            {line}

                                        </li>

                                    ))}

                                    <span className={`word-count ${isOverLimit ? 'text-red' : ''}`}>

                                        {textLength}



                                    </span>
                                    {findNextIndex(item.value, "[ img: ").map((imgind, imgIndex) => {
                                        let imgnam = item.value.substring(imgind, imgind + 13);
                                        item.value.replace(imgnam, "");

                                        return (
                                            <li className="thread-li" key={imgIndex}>
                                                {props.selectedImages2 &&
                                                    props.selectedImages2.findIndex((a) => a.name === imgnam) >= 0 ? (
                                                    <img
                                                        src={
                                                            props.selectedImages2[
                                                                props.selectedImages2 &&
                                                                props.selectedImages2.findIndex((a) => a.name === imgnam)
                                                            ]?.img
                                                        }
                                                        className="image-img"
                                                    />
                                                ) : null}
                                                {props.selectedImages &&
                                                    props.selectedImages.findIndex((a) => a.name === imgnam) >= 0 ? (
                                                    <img
                                                        src={
                                                            props.selectedImages[
                                                                props.selectedImages &&
                                                                props.selectedImages.findIndex((a) => a.name === imgnam)
                                                            ]?.img
                                                        }
                                                        className="image-img"
                                                    />
                                                ) : null}
                                            </li>
                                        );
                                    })}

                                    {!item.value.includes("[ gif:") && !item.value.includes("[ vid:") && selectedImages && selectedImages[index]?.image?.map((im, imgIndex) => {
                                        return (
                                            <>
                                                {im ? (
                                                    <li className="thread-li">

                                                        <img src={im} className="image-img" alt={`Image ${imgIndex}`} style={{ marginBottom: 10 }} />
                                                        <FontAwesomeIcon
                                                            icon={faCircleXmark}
                                                            type="button"
                                                            onClick={() => handleRemoveImage2(imgIndex, index)}
                                                            className="cross-remove-thread-icon-image"
                                                        />
                                                    </li>
                                                ) : ""}



                                            </>
                                        );
                                    })}


                                    {/* new changes------------- */}
                                    {/* {selectedImages && selectedImages[index]?.image?.map((im, imgIndex) => {
                                        console.log("selctc", selectedImages, props.cont?.t?.thread)

                                        return props.cont?.t?.thread && item.value?.includes(props.cont?.t?.thread[index]?.image_code[imgIndex]) ?
                                            <Col lg={4} sm={4} md={4} key={index}
                                            >
                                                <img src={im} alt={`Selected ${index + 1}`} style={{ width: '80px', height: '80px', marginBottom: '10px' }} /> new img



                                            </Col>
                                            : null
                                    })} */}




                                    {findNextIndex(item.value, "[ gif: ").map((imgind, gifIndex) => {
                                        let imgnam = item.value.substring(imgind, imgind + 13);
                                        item.value.replace(imgnam, "");


                                        return (
                                            <li className="thread-li" key={gifIndex}>
                                                {props.selectedGifpickerArr &&
                                                    props.selectedGifpickerArr.findIndex((a) => a.name === imgnam) >= 0 ? (
                                                    <img
                                                        src={
                                                            props.selectedGifpickerArr[
                                                                props.selectedGifpickerArr &&
                                                                props.selectedGifpickerArr.findIndex((a) => a.name === imgnam)
                                                            ]?.gif
                                                        }
                                                        className="image-img"
                                                    />
                                                ) : null}

                                                {props.selectedGifOb &&
                                                    props.selectedGifOb.name === imgnam ? (
                                                    <img
                                                        src={
                                                            props.selectedGifOb?.gif
                                                        }
                                                        className="image-img"
                                                    />
                                                ) : null}
                                            </li>
                                        );
                                    })}

                                    {!item.value.includes("[ gif:") && !item.value.includes("[ vid:") && !item.value.includes("[ img:") && selectedGif && selectedGif[index]?.gif?.map((im, imgIndex) => {
                                        return (
                                            <>
                                                {im ? (
                                                    <li className="thread-li">
                                                        <img src={im} className="image-img" alt={`Image ${imgIndex}`} style={{ marginBottom: 10 }} />
                                                        <FontAwesomeIcon
                                                            icon={faCircleXmark}
                                                            type="button"
                                                            onClick={() => handleRemoveGif2(imgIndex, index)}
                                                            className="cross-remove-thread-icon-gif"
                                                        />
                                                    </li>

                                                ) : ""}

                                            </>
                                        );
                                    })}


                                    {/* new changes------------- */}
                                    {/* {selectedGif && selectedGif[index]?.gif?.map((im, imgIndex) => {

                                        return props.cont?.t?.thread && item.value?.includes(props.cont?.t?.thread[index]?.image_code[imgIndex]) ?
                                            <Col lg={4} sm={4} md={4} key={index}
                                            >
                                                <img src={im} alt={`Selected ${index + 1}`} style={{ width: '80px', height: '80px', marginBottom: '10px' }} /> new gif
                                             


                                            </Col>
                                            : null
                                    })} */}




                                    {findNextIndex(item.value, "[ vid: ").map((imgind, gifIndex) => {
                                        let imgnam = item.value.substring(imgind, imgind + 13);
                                        item.value.replace(imgnam, "");
                                        console.log("vIndex==>", props.videoPreviews2);

                                        return (
                                            <li className="thread-li" key={gifIndex}>
                                                {props.videoPreviews2 &&
                                                    props.videoPreviews2.findIndex((a) => a.name === imgnam) >= 0 ? (

                                                    <video width="100%" height="240"
                                                        controls

                                                    >
                                                        <source src={props.videoPreviews2[
                                                            props.videoPreviews2 &&
                                                            props.videoPreviews2.findIndex((a) => a.name === imgnam)
                                                        ]?.preview}
                                                            type="video/mp4" />

                                                    </video>

                                                ) : null}





                                                {props.videoPreviews &&
                                                    props.videoPreviews.name === imgnam ? (

                                                    <video width="100%" height="240"
                                                        controls

                                                    >
                                                        <source src={props?.videoPreviews?.preview}
                                                            type="video/mp4" />

                                                    </video>



                                                ) : null}
                                            </li>
                                        );
                                    })}



                                    {!item.value.includes("[ gif:") && !item.value.includes("[ vid:") && !item.value.includes("[ img:") && selectedVideo && selectedVideo[index]?.video ? (

                                        <li className="thread-li" >

                                            <video width="100%" height="240" controls>
                                                <source src={selectedVideo[index]?.video} type="video/mp4" />
                                                <source src={selectedVideo[index]?.video} type="video/quicktime" />
                                                Your browser does not support the video tag.
                                            </video>
                                            <FontAwesomeIcon
                                                icon={faCircleXmark}
                                                type="button"
                                                onClick={() => handleVideoRemove(index)}
                                                className="cross-remove-thread-icon"
                                                style={{ color: 'red' }}
                                            />



                                        </li>
                                    ) : ''}


                                    {/* new changes------------- */}
                                    {/* {
                                         selectedVideo && selectedVideo[index]?.video ? (



                                        props.cont?.t?.thread && item.value?.includes(props.cont?.t?.thread[index]?.image_code) ?
                                            <Col lg={4} sm={4} md={4} key={index}
                                            >
                                                <video width="100%" height="240" controls>
                                                    <source src={selectedVideo[index]?.video} type="video/mp4" />
                                                    <source src={selectedVideo[index]?.video} type="video/quicktime" />
                                                    Your browser does not support the video tag.
                                                </video> new video


                                            </Col>
                                            : 'jjjj'
                                         : ''
                                    } */}



                                </ul>
                                {/* <div className="thread-image-container-ui">
                                    <div> </div>
                                    <div>
                                        <label htmlFor={`threadimage-upload-${index}`}>
                                            <input
                                                id={`threadimage-upload-${index}`}
                                                type="file"
                                                accept="image/*"
                                                style={{ display: "none" }}
                                                onChange={(e) => handleImageUpload(index)(e)}
                                            />
                                            <i className="fa fa-image image-icon" />
                                        </label>
                                        <FontAwesomeIcon icon={faFilm}
                                            onClick={() => handleGifopen(index)}
                                        // onClick={handleGifopen}
                                        />
                                    </div>
                                </div> */}
                                {/* <Row>
                                    {threads[index]?.map((image, Imageindex) => (
                                        <Col
                                            lg={3}
                                            sm={3}
                                            md={3}
                                            key={index}
                                        // style={{ display: 'inline-block', marginRight: '10px' }}
                                        >
                                            <img
                                                src={image.imgurl}
                                                alt={`Selected ${index + 1}`}
                                                style={{
                                                    width: "80px",
                                                    height: "80px",
                                                    marginBottom: "10px",
                                                }}
                                            />
                                            <FontAwesomeIcon
                                                icon={faCircleXmark}
                                                type="button"
                                                onClick={() => handleRemoveImage(index, Imageindex)}
                                                className="cross-remove-thread-icon"
                                            />
                                        </Col>
                                    ))}
                                    {console.log("selectedGifpicker2[index]", selectedGifpicker2[index], index)}
                                    {selectedGifpicker2[index] && (
                                        <Col
                                            lg={3}
                                            sm={3}
                                            md={3}
                                            key={index}
                                        // style={{ display: 'inline-block', marginRight: '10px' }}
                                        >
                                            <img
                                                src={selectedGifpicker2[index]}
                                                alt={`Selected ${index + 1}`}
                                                style={{
                                                    width: "80px",
                                                    height: "80px",
                                                    marginBottom: "10px",
                                                }}
                                            />
                                            <FontAwesomeIcon
                                                icon={faCircleXmark}
                                                type="button"
                                                // onClick={() => handleRemoveImage(index)}
                                                className="cross-remove-thread-icon"
                                            />
                                        </Col>)}

                                </Row> */}
                                {/* {showGif && openGifIndex === index && (
                                    <div>
                                        <ReactGiphySearchbox
                                            apiKey="9Ixlv3DWC1biJRI57RanyL7RTbfzz0o7"
                                            onSelect={(gif) => handleGifPicker(gif, index)}
                                            masonryConfig={[
                                                { columns: 2, imageWidth: 110, gutter: 5 },
                                                { mq: "700px", columns: 3, imageWidth: 120, gutter: 5 },
                                            ]}
                                        />
                                    </div>
                                )} */}
                            </Fragment>
                        );
                    })}

                </Offcanvas.Body>
            </Offcanvas>
        </Fragment>
    );
});

SettingOffCanvas.displayName = "SettingOffCanvas";
export default SettingOffCanvas;




