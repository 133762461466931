import React, { useState, useEffect, useContext, memo, useMemo, Fragment, useRef } from "react";

//react-bootstrap
import { Offcanvas, Row, Col, Spinner } from "react-bootstrap";
import { MyContext, MySideBarContext, MyDraftContext } from '../../../MyQueue/MyContext';
import ProgressBar from 'react-bootstrap/ProgressBar';

// Redux Selector / Action
import { useSelector } from "react-redux";

// Import selectors & action from setting store
import * as SettingSelector from "../../../../store/setting/selectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEllipsisVertical, faFileMedical, faPaperPlane, faPenNib, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark, faFilm, faCaretDown, faSmile, faClock, faAngleDown, faCalendar, faBolt } from '@fortawesome/free-solid-svg-icons';
import ReactGiphySearchbox from "react-giphy-searchbox";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as moment from 'moment';
import Picker from 'emoji-picker-react';
import gifimage from '../../../../assets/images/gif2.png'
import Microlink from '@microlink/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Section Components
// Style Setting Section Components
// import ThemeScheme from "./sections/theme-scheme";
// import ColorCustomizer from "./sections/color-customizer";
// import MenuStyle from "./sections/menu-style";
// import MenuActiveStyle from "./sections/menu-active-style";
// import Direction from "./sections/direction";
import Ellipse from "../../../../assets/images/Ellipse.png";
import noimage from "../../../../assets/noimage.png";

import "../../../setting/SettingStyle.css";

// import controller for api calling

import TweetsDataObject from "../../../../services/Tweets_Controller/Tweets_Controller";
import { BASE_URL } from "../../../../utilities/Config";

const ThreadOverLay = memo((props) => {

    const [contextData, setContextData] = useContext(MySideBarContext);
    console.log("contecxt-drafttt-", props)
    const [show, setShow] = useState(false);
    const [context, setContext] = useContext(MyContext);
    const [dataa, setDataa] = useState()
    const [loadingDraft, setLoadingDraft] = useState(false)
    const [loadingQueue, setLoadingQueue] = useState(false)
    const [loadingTweet, setLoadingTweet] = useState(false)
    const [loadingSchdule, setLoadingSchdule] = useState(false)
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [emoji, setEmoji] = useState('')

    // Define selectors
    const themeScheme = useSelector(SettingSelector.theme_scheme);
    const themeSchemeDirection = useSelector(
        SettingSelector.theme_scheme_direction
    );
    const themeColor = useSelector(SettingSelector.theme_color);
    const sidebarType = useSelector(SettingSelector.sidebar_type);
    const sidebarMenuStyle = useSelector(SettingSelector.sidebar_menu_style);
    const [test, setTest] = useState(props.textthread);

    const tokenLogin = localStorage.getItem("accessToken");
    const providerToken = localStorage.getItem("providerToken");
    const providerID = localStorage.getItem("providerID");
    const OauthToken = localStorage.getItem("oAuthToken");
    const timeZone = localStorage.getItem("TimeZone");
    const userID = localStorage.getItem("UserID");
    const fileInputRef = useRef([null]);

    const [loader, setLoader] = useState(0)
    const [loadingpro, setLoadingpro] = useState(false)



    // new code
    const [selectedGif, setSelectedGif] = useState(null);
    const [selectedGifpicker, setSelectedGifPicker] = useState(null);
    const [selectedGifpickerArr, setSelectedGifPickerArr] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState('');
    const [NewGif, setNewGif] = useState(null);
    const gifInputRef = useRef(null);
    const [showGif, setShowGif] = useState(false);
    const [imageNames, setImageNames] = useState([]);

    const [threads, setThreads] = useState([]);
    const [gifthreads, setGifThreads] = useState([]);

    //images
    const [selectedImages, setSelectedImages] = useState([]);
    const [arrImg, setArrImg] = useState([]);
    const threadimageInputRef = useRef(null);

    // gifopen

    const [openGifIndex, setOpenGifIndex] = useState(null);
    const [displayProfileDetails, setDisplayProfileDetails] = useState()
    const [laodingGif, setLoadingGif] = useState(false)
    const [imgnmNew2, setImgnmNew2] = useState()
    const [isOpen, setIsOpen] = useState(false);


    const [selectedDateTime, setSelectedDateTime] = useState(null);


    const emailTo = localStorage.getItem('email-to')
    const emailSwitch = localStorage.getItem('emailSwitch')


    useEffect(() => {
        // MyProfileApiCalling()

        const fetchData = async () => {

            await MyProfileApiCalling()

        };
        fetchData();


    }, [])


    // useMemo(async () => {


    //     const li = props.cont ? props.cont?.t?.thread : ''


    //     setSelectedImages(li)
    //     console.log("propsps.thread.image", selectedImages)
    // }, [props.cont])

    const [queueTime, setQueueTime] = useState()
    const [selectedDate, setSelectedDate] = useState()
    const [selectedTime, setSelectedTime] = useState()
    const [selectedDay, setSelectedDay] = useState()


    useMemo(async (index) => {
        if (props.cont?.t?.thread) {
            const li = props.cont.t.thread;
            setSelectedImages(li);
            setSelectedGifPickerArr(li)
            setSelectedVideo(li)

            setSelectedDate(li[0].schedule_date)
            setSelectedTime(li[0].schedule_time)
            setSelectedDay(li[0].schedule_day)
        }
        else {
            const li = props.cont.t;
            setSelectedImages(li);
            setSelectedGifPickerArr(li)
            setSelectedVideo(li)

            setSelectedDate(li)
            setSelectedTime(li)
            setSelectedDay(li)

        }


        // if (props?.cont?.t?.time == undefined || props?.cont?.t?.time == '' || props?.cont?.t?.time == null) {
        //     setQueueTime(props?.cont?.t?.thread[0]?.time)
        // }
        // else {
        //     setQueueTime(props?.cont?.t ? props?.cont?.t?.time : '')
        // }
        setQueueTime(props?.cont?.t ? props?.cont?.t?.time : '')





    }, [props.cont, selectedImages, selectedGifpickerArr, selectedVideo]);

    // console.log("selctedVideo", selectedVideo)

    const MyProfileApiCalling = async () => {
        // TweetsDataObject.Myprofile(result => {
        //     if (result.data.code == 200) {
        //         setDisplayProfileDetails(result.data.data.user)
        //     }
        //     else {
        //         setDisplayProfileDetails([])
        //         console.log("failed")
        //     }

        // })

        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("profile-data", data.data.user)

                    if (data.code == 200) {

                        setDisplayProfileDetails(data.data.user)


                    }
                    else {
                        setDisplayProfileDetails([])


                        console.log("failed")


                    }
                }
                else {
                    console.log("sjncsbcajh cdciudshjb 2782727272")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                console.log("kakannn", targetUser)
                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("profile-data", data.data.user)

                    if (data.code == 200) {

                        setDisplayProfileDetails(data.data.user)


                    }
                    else {
                        setDisplayProfileDetails([])


                        console.log("failed")


                    }
                }
                else {
                    console.log("sjncsbcajh cdciudshjb 2782727272")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };



    const toggleDropdown = (e) => {
        e.preventDefault()
        // setIsOpen(!isOpen);
        setIsOpen(!isOpen);
    };




    const handleDateChange = (date) => {

        const currentDate = new Date(); // Current date and time


        const selectedDate = new Date(date);
        selectedDate.setSeconds(0); // Reset the seconds to avoid inconsistencies


        if (selectedDate < currentDate) {
            alert("Please select the available time and date slot")
            setSelectedDateTime(null); // Clear the date date and time
            return;
        }

        setSelectedDateTime(date);

    };

    const getDate = (dateTime) => {
        return dateTime ? dateTime.toLocaleDateString('en-GB') : '';
    };


    const getTime = (dateTime) => {
        if (!dateTime) return '';

        const currentDate = new Date();
        const selectedDate = new Date(dateTime);

        if (selectedDate < currentDate) {
            alert("Please select the available time and date slot")
            return ''
        }; // Return empty string for past times

        return dateTime.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });

        //   return dateTime ? dateTime.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' }) : '';
    };

    const selectedDat = getDate(selectedDateTime);
    const selectedTim = getTime(selectedDateTime);


    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <input
            className="custom-input-thread"
            value={value ? value.toLocaleString() : ''}
            onClick={onClick}
            ref={ref}
            placeholder="Select date and time"

        />
    ));



    console.log("displayProfileDetails", displayProfileDetails)


    //image handle
    let imageName = "";
    function randomImageName(len) {
        var alphabet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

        for (var i = len; i > 0; i--) {
            imageName += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
        }


        return "[ img: " + imageName + "]";
    }

    console.log("threads", threads)
    console.log("contecxt-drafttt-", props)



    // const handleDeleteData = async () => {
    //     console.log("clicked")
    //     let threadIds = { tweet_id: "", thread_tweet_id: "" };
    //     let count = 0;
    //     try {
    //         setLoadingTweet(true)
    //         let successCount = 0;
    //         let errorOccurred = false;

    //         for await (const element of props.textthread) {


    //             try {

    //                 if (
    //                     element.value == "\n" ||
    //                     element.value == "" ||
    //                     element.value == null
    //                 ) {
    //                 }
    //                 else {


    //                     let fdata = new FormData();
    //                     let textValue = getTextWithoutImg(element.value, "[ img: ");


    //                     {
    //                         threads[count]?.map((item, innerIndex) => {
    //                             if (item.type == 'gif' && item.imgnm == item.imgnm2) {
    //                                 fdata.append('gif[]', item.file)

    //                             } else if (item.type == 'img' && item.imgnm == item.imgnm2) {
    //                                 fdata.append('image[]', item.file)

    //                             }
    //                             else if (item.type == 'video' && item.imgnm == item.imgnm2) {
    //                                 fdata.append('video', item.file)
    //                             }

    //                         })
    //                     }
    //                     if (props?.cont?.t?.thread)
    //                         // fdata.append('image_url', props?.cont?.t?.thread[count]?.image.join(','));
    //                         fdata.append('image_url', props?.cont?.t?.thread[count]?.image ? props?.cont?.t?.thread[count]?.image.join(',') : '');
    //                     fdata.append('video_url', props?.cont?.t?.thread ? props?.cont?.t?.thread[count]?.video : '');

    //                     // props?.cont?.t?.thread && props?.cont?.t?.thread[count]?.gif?.forEach(item => {
    //                     //     // item.forEach(imageUrl => {

    //                     //     fdata.append('gif_url', item);
    //                     //     // });
    //                     // });

    //                     // if (Array.isArray(props?.cont?.t?.thread[count]?.gif)) {
    //                     //     props?.cont?.t?.thread[count]?.gif.forEach(item => {
    //                     //         console.log(item, 'itemm-gif');
    //                     //         fdata.append('gif_url', item);
    //                     //     });
    //                     // } else {
    //                     //     // Handle the case where it's not an array (e.g., if it's not defined or not an array)
    //                     //     console.error('props?.cont?.t?.thread[count]?.gif is not an array');
    //                     // }

    //                     const threadgif = props?.cont?.t?.thread;
    //                     if (Array.isArray(threadgif) && threadgif[count]?.gif) {
    //                         threadgif[count].gif.forEach(item => {
    //                             console.log(item, 'itemm-gif');
    //                             fdata.append('gif_url', item);
    //                         });
    //                     } else {
    //                         // Handle the case where the array or gif property doesn't exist
    //                         console.error('Cannot read properties of undefined or gif is not an array');
    //                     }

    //                     count++;

    //                     fdata.append("text", textValue);
    //                     fdata.append("provider_token", providerToken);
    //                     fdata.append("oauth_token_secret", OauthToken);

    //                     fdata.append("id", "")
    //                     fdata.append(
    //                         "tweet_id",
    //                         threadIds.tweet_id ? threadIds.tweet_id : ""
    //                     );
    //                     fdata.append(
    //                         "thread_tweet_id",
    //                         threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
    //                     );


    //                     fdata.append('count', props.textthread.length);

    //                     await fetch(`https://app.xblue.ai/Xblue/api/create/thread`, {
    //                         method: "POST",
    //                         body: fdata,
    //                         headers: {
    //                             Authorization: `Bearer ${tokenLogin}`,
    //                         },
    //                     }).then((response) => {
    //                         return response.json();
    //                     }).then((data) => {

    //                         setDataa(data)

    //                         if (data.code == 200) {
    //                             successCount++; // Increment the success count
    //                             threadIds.tweet_id = data.data.tweet_id;
    //                             threadIds.thread_tweet_id = data.data.thread_tweet_id;
    //                             setDataa('')
    //                             // setContextData({ publishedPost: true })
    //                         }
    //                         // else if (data.code == 429) {
    //                         //     toast.error('Too many request', {
    //                         //         position: "top-center",
    //                         //         autoClose: 5000,
    //                         //         hideProgressBar: false,
    //                         //         closeOnClick: true,
    //                         //         pauseOnHover: true,
    //                         //         draggable: true,
    //                         //         progress: undefined,
    //                         //         theme: "light",
    //                         //     });
    //                         // }
    //                         else if (data.code == 429) {


    //                             errorOccurred = true;
    //                         } else if (data.code == 500) {


    //                             errorOccurred = true;
    //                         } else if (data.code == 400) {
    //                             // toast.error(data.message, {
    //                             //     position: "top-center",
    //                             //     autoClose: 5000,
    //                             //     hideProgressBar: false,
    //                             //     closeOnClick: true,
    //                             //     pauseOnHover: true,
    //                             //     draggable: true,
    //                             //     progress: undefined,
    //                             //     theme: "light",
    //                             // });

    //                             errorOccurred = true;



    //                         }
    //                         else if (data.code == 401) {

    //                             errorOccurred = true;
    //                         }
    //                         else if (data.code == 403) {

    //                             errorOccurred = true;
    //                         }
    //                     });
    //                 }
    //             } catch (error) {
    //                 console.log(error);
    //                 errorOccurred = true; // Set errorOccurred flag to true
    //             }
    //         }

    //         if (successCount > 0) {

    //             if (errorOccurred) {
    //                 toast.error(dataa.message, {
    //                     position: "top-center",
    //                     autoClose: 5000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                     theme: "light",
    //                 });
    //                 setDataa('')


    //             } else {

    //                 toast.success('All threads posted successfully.', {
    //                     position: "top-center",
    //                     autoClose: 5000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                     theme: "light",
    //                 });
    //                 setContextData({ publishedPost: true })
    //                 // handleRemoveGif()

    //             }
    //         }
    //         else {

    //             toast.error(dataa?.message, {
    //                 position: "top-center",
    //                 autoClose: 5000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //                 theme: "light",
    //             });
    //             setDataa('')
    //         }
    //         setLoadingTweet(false)
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };




    const handleDeleteData = async () => {
        console.log("clicked")
        if (localStorage.getItem("userloggedin") === 'true') {
            let threadIds = { tweet_id: "", thread_tweet_id: "" };
            let count = 0;
            let errorMessages = [];
            let successMessages = [];

            try {
                setLoadingTweet(true)
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken, providerToken, secretKey } = targetUser;


                    for await (const element of props.textthread) {


                        try {


                            let fdata = new FormData();
                            let textValue = getTextWithoutImg(element.value, "[ img: ");


                            {
                                threads[count]?.map((item, innerIndex) => {
                                    if (item.type == 'gif' && item.imgnm == item.imgnm2) {
                                        fdata.append('gif[]', item.file)

                                    } else if (item.type == 'img' && item.imgnm == item.imgnm2) {
                                        fdata.append('image[]', item.file)

                                    }
                                    else if (item.type == 'video' && item.imgnm == item.imgnm2) {
                                        fdata.append('video', item.file)
                                    }

                                })
                            }
                            if (props?.cont?.t?.thread)
                                // fdata.append('image_url', props?.cont?.t?.thread[count]?.image.join(','));
                                fdata.append('image_url', props?.cont?.t?.thread[count]?.image ? props?.cont?.t?.thread[count]?.image.join(',') : '');
                            fdata.append('video_url', props?.cont?.t?.thread ? props?.cont?.t?.thread[count]?.video : '');



                            const threadgif = props?.cont?.t?.thread;
                            if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                                threadgif[count].gif.forEach(item => {
                                    console.log(item, 'itemm-gif');
                                    fdata.append('gif_url', item);
                                });
                            } else {
                                // Handle the case where the array or gif property doesn't exist
                                console.error('Cannot read properties of undefined or gif is not an array');
                            }

                            count++;

                            fdata.append("text", textValue);
                            fdata.append("provider_token", providerToken);
                            fdata.append("oauth_token_secret", secretKey);

                            fdata.append("id", "")
                            fdata.append(
                                "tweet_id",
                                threadIds.tweet_id ? threadIds.tweet_id : ""
                            );
                            fdata.append(
                                "thread_tweet_id",
                                threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
                            );


                            fdata.append('count', props.textthread.length);

                            await fetch(`https://app.xblue.ai/Xblue/api/create/thread`, {
                                method: "POST",
                                body: fdata,
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            }).then((response) => {
                                return response.json();
                            }).then((data) => {


                                if (data.code == 200) {
                                    threadIds.tweet_id = data.data.tweet_id;
                                    threadIds.thread_tweet_id = data.data.thread_tweet_id;

                                    successMessages.push(data.message);
                                    setContextData({ publishedPost: true })
                                } else {
                                    // Collect specific error messages from the backend
                                    if (data.code === 400 && data.message && !errorMessages.includes(data.message)) {
                                        errorMessages.push(data.message);
                                    } else {
                                        // Handle other error cases
                                        console.error(`Unexpected error: ${data.message}`);
                                    }
                                }



                            });


                        } catch (error) {
                            console.log(error);


                        }
                    }
                } else {
                    console.log("not found email")
                }


                setLoadingTweet(false)

                if (successMessages.length > 0) {
                    toast.success(`${successMessages.length} threads posted successfully!`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }

                if (errorMessages.length > 0) {
                    errorMessages.forEach((errorMessage) => {
                        toast.error(errorMessage, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    });
                }

                // if (errorMessages.length === 0) {
                //     toast.success("All threads posted successfully!", {
                //         position: "top-center",
                //         autoClose: 5000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         draggable: true,
                //         progress: undefined,
                //         theme: "light",
                //     });
                // } else {
                //     // Display specific error messages if there are any
                //     errorMessages.forEach((errorMessage) => {
                //         toast.error(errorMessage, {
                //             position: "top-center",
                //             autoClose: 5000,
                //             hideProgressBar: false,
                //             closeOnClick: true,
                //             pauseOnHover: true,
                //             draggable: true,
                //             progress: undefined,
                //             theme: "light",
                //         });
                //     });
                // }
            } catch (error) {
                console.log(error);
            }
        }
        else {
            let threadIds = { tweet_id: "", thread_tweet_id: "" };
            let count = 0;
            let errorMessages = [];
            let successMessages = [];

            try {
                setLoadingTweet(true)
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];

                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken, providerToken, secretKey } = targetUser;
                    console.log("taregte user", targetUser)

                    for await (const element of props.textthread) {


                        try {


                            let fdata = new FormData();
                            let textValue = getTextWithoutImg(element.value, "[ img: ");


                            {
                                threads[count]?.map((item, innerIndex) => {
                                    if (item.type == 'gif' && item.imgnm == item.imgnm2) {
                                        fdata.append('gif[]', item.file)

                                    } else if (item.type == 'img' && item.imgnm == item.imgnm2) {
                                        fdata.append('image[]', item.file)

                                    }
                                    else if (item.type == 'video' && item.imgnm == item.imgnm2) {
                                        fdata.append('video', item.file)
                                    }

                                })
                            }
                            if (props?.cont?.t?.thread)
                                // fdata.append('image_url', props?.cont?.t?.thread[count]?.image.join(','));
                                fdata.append('image_url', props?.cont?.t?.thread[count]?.image ? props?.cont?.t?.thread[count]?.image.join(',') : '');
                            fdata.append('video_url', props?.cont?.t?.thread ? props?.cont?.t?.thread[count]?.video : '');



                            const threadgif = props?.cont?.t?.thread;
                            if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                                threadgif[count].gif.forEach(item => {
                                    console.log(item, 'itemm-gif');
                                    fdata.append('gif_url', item);
                                });
                            } else {
                                // Handle the case where the array or gif property doesn't exist
                                console.error('Cannot read properties of undefined or gif is not an array');
                            }

                            count++;

                            // fdata.append("text", textValue);

                            const linkRegex = /(https?:\/\/[^\s]+)/g;
                            const formattedText = textValue.replace(linkRegex, (_, link) => `"${link}"`);
                            if (props.isChecked) {

                                fdata.append('text', formattedText);

                            }
                            else {
                                fdata.append('text', textValue);
                            }

                            fdata.append("provider_token", providerToken);
                            fdata.append("oauth_token_secret", secretKey);

                            fdata.append("id", "")
                            fdata.append(
                                "tweet_id",
                                threadIds.tweet_id ? threadIds.tweet_id : ""
                            );
                            fdata.append(
                                "thread_tweet_id",
                                threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
                            );


                            fdata.append('count', props.textthread.length);

                            await fetch(`https://app.xblue.ai/Xblue/api/create/thread`, {
                                method: "POST",
                                body: fdata,
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            }).then((response) => {
                                return response.json();
                            }).then((data) => {


                                if (data.code == 200) {
                                    threadIds.tweet_id = data.data.tweet_id;
                                    threadIds.thread_tweet_id = data.data.thread_tweet_id;

                                    successMessages.push(data.message);
                                    setContextData({ publishedPost: true })
                                } else {
                                    // Collect specific error messages from the backend
                                    if (data.code === 400 && data.message && !errorMessages.includes(data.message)) {
                                        errorMessages.push(data.message);
                                    } else {
                                        // Handle other error cases
                                        console.error(`Unexpected error: ${data.message}`);
                                    }
                                }



                            });


                        } catch (error) {
                            console.log(error);


                        }
                    }
                } else {
                    console.log("not found email")
                }


                setLoadingTweet(false)

                if (successMessages.length > 0) {
                    toast.success(`${successMessages.length} threads posted successfully!`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }

                if (errorMessages.length > 0) {
                    errorMessages.forEach((errorMessage) => {
                        toast.error(errorMessage, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    });
                }

                // if (errorMessages.length === 0) {
                //     toast.success("All threads posted successfully!", {
                //         position: "top-center",
                //         autoClose: 5000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         draggable: true,
                //         progress: undefined,
                //         theme: "light",
                //     });
                // } else {
                //     // Display specific error messages if there are any
                //     errorMessages.forEach((errorMessage) => {
                //         toast.error(errorMessage, {
                //             position: "top-center",
                //             autoClose: 5000,
                //             hideProgressBar: false,
                //             closeOnClick: true,
                //             pauseOnHover: true,
                //             draggable: true,
                //             progress: undefined,
                //             theme: "light",
                //         });
                //     });
                // }
            } catch (error) {
                console.log(error);
            }
        }
    };





    const deleteCall2 = (index, imageIndex, threadIndex) => {
        props.deleteCall();
        props.handleClosethread()
        // handleRemoveImage(threadIndex, imageIndex);
        setThreads([])
        setSelectedImages(null)


    };

    useEffect(() => {
        setTest(props.textthread);
        const onClick = (e) => {
            if (show) {
                if (
                    e.target.closest(".live-customizer") == null &&
                    e.target.closest("#settingbutton") == null
                ) {
                    setShow(false);
                }
            }
        };
        document.body.addEventListener("click", onClick);

        return () => {
            document.body.removeEventListener("click", onClick);
        };
    });

    function findAllOccurrences(str, word) {
        var regex = new RegExp("\\b" + word + "\\b", "gi");
        var occurrences = [];

        var match = regex.exec(str);
        while (match !== null) {
            occurrences.push(match.index);
            match = regex.exec(str);
        }
        return occurrences;
    }
    function getTextWithoutImg(sentence, word) {
        let indFound = sentence?.indexOf(word);
        let formateedSentence = sentence;
        for (let i = 0; i < sentence?.length; i++) {
            if (indFound > -1) {
                let imgNm = sentence.substring(indFound, indFound + 13);
                indFound = sentence.indexOf(word, indFound + 1);
                formateedSentence = formateedSentence.replace(imgNm, "");
            } else {
                break;
            }
        }
        return formateedSentence;
    }
    function findNextIndex(sentence, word) {
        let indFound = sentence?.indexOf(word, 0);
        let occurrences = [];
        for (let i = 0; i < sentence?.length; i++) {
            if (indFound > -1) {
                occurrences.push(indFound);
                indFound = sentence?.indexOf(word, indFound + 1);
            } else {
                break;
            }
        }
        return occurrences;
    }




    const handleRemoveAll = () => {
        setSelectedImages([]);
        setArrImg([]);
    };
    const [selectedGifpicker2, setSelectedGifPicker2] = useState([]);


    const handleGifPicker = async (gif, index) => {

        console.log("idnex", index)

        handleRemoveVideo(index)
        handleVideoRemove(index)

        if (index >= 0 && index < selectedImages.length) {
            const updatedSelectedImages = [...selectedImages];
            updatedSelectedImages[index].image = [];

            setSelectedImages(updatedSelectedImages);
        }


        if (index >= 0 && index < selectedImages.length) {
            const updatedSelectedImages = [...selectedImages];
            updatedSelectedImages[index].gif = [];

            setSelectedImages(updatedSelectedImages);
        }







        let imgnm = randomImageName(5);
        let imgnm2 = imgnm


        setSelectedGifPicker2((prevSelectedGifs) => {
            setLoadingGif(true);
            const updatedSelectedGifs = [...prevSelectedGifs];
            updatedSelectedGifs[index] = gif.images.original.url; // Assign the value at index 3

            return updatedSelectedGifs;
        });
        setLoadingGif(false);






        threads[index] = [];

        setShowGif((prevInput) => !prevInput);
        try {
            setLoadingGif(true)
            const response = await fetch(gif.images.original.url);
            const blob = await response.blob();
            const file = new File([blob], 'selectedGif.gif');


            const reader = new FileReader();

            reader.onload = () => {
                var imgurl = reader.result
                setThreads((prevThreads) => {
                    const updatedThreads = [...prevThreads];

                    updatedThreads[index] = [{ file, imgnm, imgnm2, imgurl, type: 'gif' }]; // Create a new array with the file and image name


                    return updatedThreads;
                });




            };

            reader.readAsDataURL(file);
            ;
            setLoadingGif(false)
        } catch (error) {
            console.log(error);
        }


    }


    const [videoPreviews, setVideoPreviews] = useState(null);
    const [arrImgThread, setArrImgThread] = useState([])

    console.log("hvvhvh", selectedImages)

    const [newValue, setNewValue] = useState('')
    const [originalValue, setOriginalValue] = useState('')



    // const handleImageUpload = (threadIndex) => (e) => {

    //     // handleRemoveVideo(threadIndex)

    //     handleVideoRemove(threadIndex)

    //     const updatedThreadVideos = [...threads];
    //     console.log("n saas", updatedThreadVideos[threadIndex])
    //     updatedThreadVideos[threadIndex]?.map((iute, index) => {
    //         if (iute.type == 'video') {
    //             handleRemoveVideo(threadIndex)
    //         }
    //     })



    //     if (threadIndex >= 0 && threadIndex < selectedImages?.length) {
    //         const updatedSelectedImages = [...selectedImages];
    //         updatedSelectedImages[threadIndex].gif = '';


    //         setSelectedImages(updatedSelectedImages);
    //     }

    //     let imgnm = randomImageName(5);
    //     let imgnm2 = imgnm


    //     const file = e.target.files[0]; // Extract the file from the event object
    //     const imageName = file?.name; // Get the image name

    //     setSelectedGifPicker2((prevSelectedGifs) => {
    //         const updatedSelectedGifs = prevSelectedGifs.filter((_, index) => index !== threadIndex);
    //         return updatedSelectedGifs;
    //     });

    //     const files = Array.from(e.target.files);


    //     files.forEach((file) => {
    //         const reader = new FileReader();

    //         reader.onload = () => {
    //             var imgurl = reader.result
    //             setThreads((prevThreads) => {
    //                 const updatedThreads = [...prevThreads];
    //                 if (updatedThreads[threadIndex] && updatedThreads[threadIndex].filter(f => f.type === 'gif').length === 0 || updatedThreads[threadIndex] && updatedThreads[threadIndex].filter(f => f.type === 'video').length === 0) {
    //                     updatedThreads[threadIndex].push({ file, imgnm, imgnm2, imgurl, type: 'img' }); // Store the file and image name together
    //                     console.log("updated threads", updatedThreads)
    //                 } else {
    //                     updatedThreads[threadIndex] = [{ file, imgnm, imgnm2, imgurl, type: 'img' }]; // Create a new array with the file and image name
    //                     console.log("updated threads else", updatedThreads)

    //                 }
    //                 return updatedThreads;
    //             });

    //             // newImages.push({
    //             //   img: reader.result,
    //             //   name: imgnm,
    //             //   imgFile: e.target.files[0],
    //             // });
    //             // setSelectedImages(newImages);

    //         };

    //         reader.readAsDataURL(file);
    //     });

    //     setArrImgThread(a => [...a, e.target.files[0]])



    //     setImageNames((prevNames) => [...prevNames, imgnm]); // Add the image name to the state
    // };

    const handleImageUpload = (threadIndex) => (e) => {



        handleVideoRemove(threadIndex)
        handleRemoveGif3(threadIndex)

        const updatedThreadVideos = [...threads];
        console.log("n saas", updatedThreadVideos[threadIndex])
        updatedThreadVideos[threadIndex]?.map((iute, index) => {
            if (iute.type == 'video') {
                handleRemoveVideo(threadIndex)
            }
        })



        if (threadIndex >= 0 && threadIndex < selectedImages?.length) {
            const updatedSelectedImages = [...selectedImages];
            updatedSelectedImages[threadIndex].gif = '';


            setSelectedImages(updatedSelectedImages);
        }

        let imgnm = randomImageName(5);
        let imgnm2 = imgnm


        const file = e.target.files[0]; // Extract the file from the event object
        const imageName = file?.name; // Get the image name

        setSelectedGifPicker2((prevSelectedGifs) => {
            const updatedSelectedGifs = prevSelectedGifs.filter((_, index) => index !== threadIndex);
            return updatedSelectedGifs;
        });

        const files = Array.from(e.target.files);


        files.forEach((file) => {
            const reader = new FileReader();

            reader.onload = () => {
                var imgurl = reader.result
                setThreads((prevThreads) => {
                    const updatedThreads = [...prevThreads];
                    if (updatedThreads[threadIndex] && updatedThreads[threadIndex].filter(f => f.type === 'gif').length === 0 || updatedThreads[threadIndex] && updatedThreads[threadIndex].filter(f => f.type === 'video').length === 0) {
                        updatedThreads[threadIndex].push({ file, imgnm, imgnm2, imgurl, type: 'img' }); // Store the file and image name together
                        console.log("updated threads", updatedThreads)
                    } else {
                        updatedThreads[threadIndex] = [{ file, imgnm, imgnm2, imgurl, type: 'img' }]; // Create a new array with the file and image name
                        console.log("updated threads else", updatedThreads)

                    }
                    return updatedThreads;
                });

                // newImages.push({
                //   img: reader.result,
                //   name: imgnm,
                //   imgFile: e.target.files[0],
                // });
                // setSelectedImages(newImages);

            };

            reader.readAsDataURL(file);
        });

        setArrImgThread(a => [...a, e.target.files[0]])



        setImageNames((prevNames) => [...prevNames, imgnm]); // Add the image name to the state
    };


    const handleFileChange = (event, threadIndex) => {

        handleRemoveImage(threadIndex)
        handleRemoveGif(threadIndex)

        console.log("thredas-oprte", event)
        let imgnm = randomImageName(5)
        let imgnm2 = imgnm
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            // Update the thread's video state
            const reader = new FileReader();

            reader.onload = (event) => {
                console.log("event==>", event);
                const updatedThreadVideos = [...threads];
                updatedThreadVideos[threadIndex] = [{ file: selectedFile, imgnm, imgnm2, preview: event.target.result, type: 'video' }];
                setThreads(updatedThreadVideos);
            }
            reader.readAsDataURL(selectedFile);
        }

    };

    const [mediaId, setMediaID] = useState('')


    const handleMediaUpload = (threadIndex, isImage) => (e) => {
        const files = e.target.files;
        // let imgnm = randomImageName(5)


        // Separate images and videos based on file type
        const newSelectedImages = [];
        let newSelectedVideo = null;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.type.startsWith('image/') && newSelectedImages.length < 4) {
                if (file.type === "image/jpg" || file.type === "image/png" || file.type === "image/jpeg") {

                    handleVideoRemove(threadIndex)

                    //handleRemoveGif3(threadIndex)

                    const updatedThreadVideos = [...threads];
                    console.log("n saas", updatedThreadVideos[threadIndex])
                    updatedThreadVideos[threadIndex]?.map((iute, index) => {
                        if (iute.type == 'video') {
                            handleRemoveVideo(threadIndex)
                        }
                    })

                    const updatedThreadGif = [...threads];
                    updatedThreadGif[threadIndex]?.map((iute, index) => {
                        if (iute.type == 'gif') {

                            handleRemoveGif3(threadIndex)
                        }
                    })



                    if (threadIndex >= 0 && threadIndex < selectedImages?.length) {
                        const updatedSelectedImages = [...selectedImages];
                        updatedSelectedImages[threadIndex].gif = '';


                        setSelectedImages(updatedSelectedImages);
                    }

                    let imgnm = randomImageName(5);
                    let imgnm2 = imgnm


                    const file = e.target.files[0]; // Extract the file from the event object
                    const imageName = file?.name; // Get the image name

                    setSelectedGifPicker2((prevSelectedGifs) => {
                        const updatedSelectedGifs = prevSelectedGifs.filter((_, index) => index !== threadIndex);
                        return updatedSelectedGifs;
                    });

                    const files = Array.from(e.target.files);


                    files.forEach((file) => {
                        const reader = new FileReader();

                        reader.onload = () => {
                            var imgurl = reader.result
                            setThreads((prevThreads) => {
                                const updatedThreads = [...prevThreads];
                                if (updatedThreads[threadIndex] && updatedThreads[threadIndex].filter(f => f.type === 'gif').length === 0 || updatedThreads[threadIndex] && updatedThreads[threadIndex].filter(f => f.type === 'video').length === 0) {
                                    updatedThreads[threadIndex].push({ file, imgnm, imgnm2, imgurl, type: 'img' }); // Store the file and image name together
                                    console.log("updated threads", updatedThreads)
                                } else {
                                    updatedThreads[threadIndex] = [{ file, imgnm, imgnm2, imgurl, type: 'img' }]; // Create a new array with the file and image name
                                    console.log("updated threads else", updatedThreads)

                                }
                                return updatedThreads;
                            });

                            // newImages.push({
                            //   img: reader.result,
                            //   name: imgnm,
                            //   imgFile: e.target.files[0],
                            // });
                            // setSelectedImages(newImages);

                        };

                        reader.readAsDataURL(file);
                    });

                    setArrImgThread(a => [...a, e.target.files[0]])



                    setImageNames((prevNames) => [...prevNames, imgnm]); // Add the image name to the state

                }
                else {

                    toast.error("Please upload jpg and png formate image file", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
            else if (file.type.startsWith('video/') && !newSelectedVideo) {
                handleRemoveImage(threadIndex)
                handleRemoveGif(threadIndex)
                // handleRemoveImage2(threadIndex)


                const updatedThreadGif = [...threads];
                updatedThreadGif[threadIndex]?.map((iute, index) => {
                    if (iute.type == 'gif') {

                        handleRemoveGif3(threadIndex)
                    }
                })

                if (threadIndex >= 0 && threadIndex < selectedImages?.length) {
                    const updatedSelectedImages = [...selectedImages];
                    updatedSelectedImages[threadIndex].gif = '';


                    setSelectedImages(updatedSelectedImages);
                }

                if (threadIndex >= 0 && threadIndex < selectedImages?.length) {
                    const updatedSelectedImages = [...selectedImages];
                    console.log("updates", updatedSelectedImages)
                    updatedSelectedImages[threadIndex].image = [];


                    setSelectedImages(updatedSelectedImages);
                }


                //upload video function 
                let imgnm = randomImageName(5)
                let imgnm2 = imgnm
                const selectedFile = e.target.files[0];


                if (selectedFile) {
                    // Update the thread's video state
                    const reader = new FileReader();

                    reader.onload = async (e) => {
                        console.log("e==>", e);
                        const updatedThreadVideos = [...threads];

                        // try {
                        //     const response = await handleVideoAPiUpload(selectedFile);

                        //     if (response.code === 200) {
                        //         updatedThreadVideos[threadIndex] = [{
                        //             file: selectedFile,
                        //             imgnm,
                        //             imgnm2,
                        //             preview: e.target.result,
                        //             type: 'video',
                        //             media: response.data?.media_id
                        //         }];

                        //         setThreads(updatedThreadVideos);
                        //     } else {
                        //         // Handle error scenario
                        //     }
                        // } catch (error) {
                        //     console.error("Error uploading video:", error);
                        // }

                        updatedThreadVideos[threadIndex] = [{ file: selectedFile, imgnm, imgnm2, preview: e.target.result, type: 'video', media: mediaId?.data?.media_id }];
                        setThreads(updatedThreadVideos);
                    }
                    reader.readAsDataURL(selectedFile);
                }

            }



        };

    }



    const handleVideoAPiUpload = async (file) => {
        try {
            let fdata = new FormData();
            fdata.append('video', file);
            fdata.append('provider_token', providerToken);

            const response = await fetch(`${BASE_URL}api/video/upload`, {
                method: 'POST',
                body: fdata,
                headers: {
                    Authorization: `Bearer ${tokenLogin}`,
                }
            });

            const data = await response.json();

            if (response.ok) {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });


                setMediaID(data);
            } else {
                toast.error(data.message, {
                    // your toast configuration
                });
            }

            return data; // return the response object
        } catch (error) {
            console.log("Error uploading video:", error);
            throw error; // rethrow the error
        }
    };


    // const handleVideoAPiUpload = async (file) => {
    //     try {

    //         // setLoading2(true);
    //         let fdata = new FormData();


    //         fdata.append('video', file);

    //         fdata.append('provider_token', providerToken);

    //         fetch(`${BASE_URL}api/video/upload`, {
    //             method: 'POST',
    //             body: fdata,
    //             headers: {
    //                 Authorization: `Bearer ${tokenLogin}`,
    //             }
    //         }).then(response => {


    //             return response.json()
    //         }).then(data => {
    //             console.log("tweet000", data);


    //             if (data.code == 200) {

    //                 toast.success(data.message, {
    //                     position: "top-center",
    //                     autoClose: 5000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                     theme: "light",
    //                 });


    //                 setMediaID(data)
    //             }


    //             else {

    //                 toast.error(data.message, {
    //                     position: "top-center",
    //                     autoClose: 5000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                     theme: "light",
    //                 });


    //             }

    //             // setLoading2(false);
    //         })



    //     } catch (error) {
    //         console.log(error)

    //     }
    // }




    const handleRemoveVideo = (threadIndex) => {
        // Remove the video for the specified thread
        const updatedThreadVideos = [...threads];
        updatedThreadVideos[threadIndex] = [];
        setThreads(updatedThreadVideos);
    };


    console.log("thredas", threads)


    // const handleIconClick = () => {
    //     // Trigger the hidden file input when the icon is clicked
    //     fileInputRef.current.click();
    // };
    const handleFileUploadClick = (threadIndex) => {
        fileInputRef.current[threadIndex].click();
    };

    console.log("data-cinsle", newValue)

    const handleTextareaChange = (e) => {
        const textareaValue = e.target.value;
        const imageNamesToRemove = imageNames.filter((name) => !textareaValue.includes(name));
        setThreads((prevThreads) => {
            const updatedThreads = prevThreads.map((thread) =>
                thread.filter((image) => !imageNamesToRemove.includes(image.imageName))
            );
            return updatedThreads;
        });
        setImageNames((prevNames) => prevNames.filter((name) => !imageNamesToRemove.includes(name)));
    };


    const flattenedThreads = threads?.flat();
    console.log("threads-img", flattenedThreads);

    console.log('selectedGifpicker2', selectedGifpicker2)



    // const handleGifopen = (index) => {
    //     if (openGifIndex === index) {
    //         // If the clicked index is already open, close it
    //         setOpenGifIndex(null);
    //         setShowGif(false);
    //     } else {
    //         // Open the clicked index
    //         setOpenGifIndex(index);
    //         setShowGif(true);
    //     }
    // };
    const handleGifopen = (index) => {
        if (openGifIndex === index) {
            // If the clicked index is already open, close it
            setOpenGifIndex(null);
            setShowGif(false);
        } else {
            // Open the clicked index
            setOpenGifIndex(index);
            setShowGif(true);
        }
    };




    const handleRemoveImage2 = (imgIndex, threadIndex) => {
        const updatedImages = [...selectedImages];
        if (updatedImages[threadIndex]?.image) {
            updatedImages[threadIndex].image.splice(imgIndex, 1);
            setSelectedImages(updatedImages);
        }

    }


    const handleRemoveGif2 = (imgIndex, threadIndex) => {
        const updatedGif = [...selectedGifpickerArr];
        if (updatedGif[threadIndex]?.gif) {
            updatedGif[threadIndex].gif.splice(imgIndex, 1);
            setSelectedGifPickerArr(updatedGif);
        }

    }


    const handleVideoRemove = (index) => {

        // const updatedGif = [...selectedVideo];
        // if (updatedGif[index]?.video) {
        //     updatedGif[index].video = "";
        //     setSelectedVideo(updatedGif);
        // }

        const updatedGif = { ...selectedVideo }; // Create a shallow copy of the object

        if (updatedGif[index]?.video) {
            updatedGif[index].video = "";
            setSelectedVideo(updatedGif);
        }

    }

    const handleRemoveImage = (threadIndex, imageIndex) => {


        setThreads((prevThreads) => {
            const updatedThreads = [...prevThreads];
            if (updatedThreads[threadIndex]) {
                updatedThreads[threadIndex].splice(imageIndex, 1); // Remove the image at the specified index
            }
            return updatedThreads;
        });

    };

    const handleRemoveGif3 = (threadIndex, imageIndex) => {


        // setThreads((prevThreads) => {
        //     const updatedThreads = [...prevThreads];
        //     if (updatedThreads[threadIndex]) {
        //         updatedThreads[threadIndex].splice(imageIndex, 1); // Remove the image at the specified index
        //     }
        //     return updatedThreads;
        // });


        const updatedThreadGif = [...threads];
        updatedThreadGif[threadIndex] = [];
        setThreads(updatedThreadGif);

    };



    useEffect((threadIndex, imageIndex) => {
        if (props.showthread == false) {
            setThreads([])

            // setContextData({ QUEUESaved: true })



        }
    }, [props.showthread])



    const handleRemoveGif = (index) => {

        setSelectedGifPicker2([])

    };




    const [prevThreadCount, setPrevThreadCount] = useState(0);





    // const handleDraftThread = async () => {

    //     let threadIds = { tweet_id: "", thread_tweet_id: "" };
    //     let count = 0;


    //     try {
    //         setLoadingDraft(true)
    //         let successCount = 0;
    //         let errorOccurred = false;
    //         let textthreadIndexArr = props.textthread.map(a => a.threadInd)
    //         //const idString = props?.cont?.t?.thread?.filter((item, i) => textthreadIndexArr.includes(i) && item?.id).map(el => el.id).join(',');
    //         const idString = props?.cont?.t?.thread?.map(item => item.id).join(',');
    //         for await (const element of props.textthread) {
    //             console.log(element, 'forelement', props?.cont?.t?.thread)

    //             try {

    //                 if (
    //                     element.value == "\n" ||
    //                     element.value == "" ||
    //                     element.value == null
    //                 ) {
    //                 }
    //                 else {


    //                     let fdata = new FormData();
    //                     let textValue = getTextWithoutImg(element.value, "[ img: ");


    //                     {
    //                         threads[count]?.map((item, innerIndex) => {
    //                             if (item.type == 'gif') {
    //                                 fdata.append('gif[]', item.file)

    //                             } else if (item.type == 'img') {
    //                                 fdata.append('image[]', item.file)

    //                             }
    //                             else if (item.type == 'video' && item.imgnm == item.imgnm2) {
    //                                 fdata.append('video', item.file)
    //                             }

    //                         })
    //                     }


    //                     if (props?.cont?.t?.thread) {

    //                         fdata.append('image_url', props?.cont?.t?.thread[count]?.image ? props?.cont?.t?.thread[count]?.image.join(',') : '');
    //                         fdata.append('video_url', props?.cont?.t?.thread ? props?.cont?.t?.thread[count]?.video : '');
    //                     }

    //                     // props?.cont?.t?.thread && props?.cont?.t?.thread[count]?.gif?.forEach(item => {
    //                     //     // item.forEach(imageUrl => {

    //                     //     fdata.append('gif_url', item);
    //                     //     // });
    //                     // });

    //                     // if (Array.isArray(props?.cont?.t?.thread[count]?.gif)) {
    //                     //     props?.cont?.t?.thread[count]?.gif.forEach(item => {
    //                     //         console.log(item, 'itemm-gif');
    //                     //         fdata.append('gif_url', item);
    //                     //     });
    //                     // } else {

    //                     //     console.error('props?.cont?.t?.thread[count]?.gif is not an array');
    //                     // }

    //                     const threadgif = props?.cont?.t?.thread;
    //                     if (Array.isArray(threadgif) && threadgif[count]?.gif) {
    //                         threadgif[count].gif.forEach(item => {
    //                             console.log(item, 'itemm-gif');
    //                             fdata.append('gif_url', item);
    //                         });
    //                     } else {
    //                         // Handle the case where the array or gif property doesn't exist
    //                         console.error('Cannot read properties of undefined or gif is not an array');
    //                     }


    //                     count++;

    //                     console.log("ids-=-", JSON.stringify(idString))

    //                     if (props.cont?.t?.thread) {
    //                         fdata.append('ids', JSON.stringify(idString));
    //                     }
    //                     else {
    //                         fdata.append('ids', '');
    //                     }



    //                     fdata.append("text", textValue);
    //                     fdata.append("provider_token", providerToken);
    //                     fdata.append("oauth_token_secret", OauthToken);


    //                     if (!props?.cont?.t?.thread) {

    //                         fdata.append("id", "")
    //                     }
    //                     else if (element.id) {

    //                         fdata.append("id", element.id)
    //                     }

    //                     else {
    //                         fdata.append('id', props.cont?.t?.thread[element.threadInd]?.id ? props.cont?.t?.thread[element.threadInd]?.id : '');
    //                     }



    //                     fdata.append("tweet_id", "");
    //                     fdata.append(
    //                         "thread_tweet_id",
    //                         threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
    //                     );

    //                     if (props?.cont?.t?.thread) {
    //                         const currentThreadCount = props.textthread.length;
    //                         if (currentThreadCount !== prevThreadCount) {
    //                             fdata.append('count', currentThreadCount);
    //                         }
    //                         else {


    //                             fdata.append('count', props?.cont?.t?.thread?.length);
    //                         }
    //                     }

    //                     // fdata.append('count', props.textthread.length);


    //                     await fetch(`${BASE_URL}api/draft/thread`, {
    //                         method: "POST",
    //                         body: fdata,
    //                         headers: {
    //                             Authorization: `Bearer ${tokenLogin}`,
    //                         },
    //                     }).then((response) => {
    //                         return response.json();
    //                     }).then((data) => {
    //                         setDataa(data)

    //                         if (data.code == 200) {
    //                             successCount++; // Increment the success count
    //                             threadIds.tweet_id = data.data.thread_draft ? data.data.thread_draft.tweet_id : data.data.updated_thread_draft.tweet_id;
    //                             threadIds.thread_tweet_id = data.data.thread_draft ? data.data.thread_draft.thread_tweet_id : data.data.updated_thread_draft.thread_tweet_id;

    //                         }
    //                         else if (data.code == 429) {



    //                             errorOccurred = true;
    //                         } else if (data.code == 500) {


    //                             errorOccurred = true;
    //                         } else if (data.code == 400) {

    //                             errorOccurred = true;
    //                         }
    //                         else if (data.code == 401) {

    //                             errorOccurred = true;
    //                         }
    //                         else if (data.code == 403) {

    //                             errorOccurred = true;
    //                         }
    //                     });

    //                 }
    //             } catch (error) {
    //                 console.log(error);
    //                 errorOccurred = true; // Set errorOccurred flag to true
    //             }
    //         }

    //         if (successCount > 0) {
    //             if (errorOccurred) {
    //                 toast.error(dataa.message, {
    //                     position: "top-center",
    //                     autoClose: 5000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                     theme: "light",
    //                 });


    //             } else {

    //                 toast.success('Threads drafted successfully.', {
    //                     position: "top-center",
    //                     autoClose: 5000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                     theme: "light",
    //                 });
    //                 setContextData({ DraftSaved: true })
    //                 console.log("context-thread", props.cont.t.thread = null)
    //                 // setContext({
    //                 //     ...props.cont,
    //                 //     t: {
    //                 //         ...props.cont.t,
    //                 //         thread: null
    //                 //     }
    //                 // });
    //                 props.cont.t.thread = null

    //                 // setContext({ ...context, t: { ...context.t, ...item, thread: null }, formattedDate })

    //             }
    //         } else {

    //             toast.error(dataa?.message, {
    //                 position: "top-center",
    //                 autoClose: 5000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //                 theme: "light",
    //             });
    //         }
    //         setLoadingDraft(false)
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }





    const handleDraftThread = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            let threadIds = { tweet_id: "", thread_tweet_id: "" };
            let count = 0;
            let errorMessages = [];
            let successMessages = [];
            const idString = props?.cont?.t?.thread?.map(item => item.id).join(',');

            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken, providerToken, secretKey } = targetUser;

                    setLoadingDraft(true)
                    let successCount = 0;
                    let errorOccurred = false;
                    let textthreadIndexArr = props.textthread.map(a => a.threadInd)

                    for await (const element of props.textthread) {
                        console.log(element, 'forelement', props?.cont?.t?.thread)

                        try {

                            if (
                                element.value == "\n" ||
                                element.value == "" ||
                                element.value == null
                            ) {
                            }
                            else {


                                let fdata = new FormData();
                                let textValue = getTextWithoutImg(element.value, "[ img: ");


                                {
                                    threads[count]?.map((item, innerIndex) => {
                                        if (item.type == 'gif') {
                                            fdata.append('gif[]', item.file)

                                        } else if (item.type == 'img') {
                                            fdata.append('image[]', item.file)

                                        }
                                        else if (item.type == 'video' && item.imgnm == item.imgnm2) {
                                            fdata.append('video', item.file)
                                        }

                                    })
                                }


                                if (props?.cont?.t?.thread) {

                                    fdata.append('image_url', props?.cont?.t?.thread[count]?.image ? props?.cont?.t?.thread[count]?.image.join(',') : '');
                                    fdata.append('video_url', props?.cont?.t?.thread[count]?.video ? props?.cont?.t?.thread[count]?.video : '');
                                }


                                const threadgif = props?.cont?.t?.thread;
                                if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                                    threadgif[count].gif.forEach(item => {
                                        console.log(item, 'itemm-gif');
                                        fdata.append('gif_url', item);
                                    });
                                } else {
                                    // Handle the case where the array or gif property doesn't exist
                                    console.error('Cannot read properties of undefined or gif is not an array');
                                }


                                count++;

                                console.log("ids-=-", JSON.stringify(idString))

                                if (props.cont?.t?.thread) {
                                    fdata.append('ids', JSON.stringify(idString));
                                }
                                else {
                                    fdata.append('ids', '');
                                }



                                fdata.append("text", textValue);
                                fdata.append("provider_token", providerToken);
                                fdata.append("oauth_token_secret", OauthToken);


                                if (!props?.cont?.t?.thread) {

                                    fdata.append("id", "")
                                }
                                else if (element.id) {

                                    fdata.append("id", element.id)
                                }

                                else {
                                    fdata.append('id', props.cont?.t?.thread[element.threadInd]?.id ? props.cont?.t?.thread[element.threadInd]?.id : '');
                                }



                                fdata.append("tweet_id", "");
                                fdata.append(
                                    "thread_tweet_id",
                                    threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
                                );

                                if (props?.cont?.t?.thread) {
                                    const currentThreadCount = props.textthread.length;
                                    if (currentThreadCount !== prevThreadCount) {
                                        fdata.append('count', currentThreadCount);
                                    }
                                    else {


                                        fdata.append('count', props?.cont?.t?.thread?.length);
                                    }
                                }

                                // fdata.append('count', props.textthread.length);


                                await fetch(`${BASE_URL}api/draft/thread`, {
                                    method: "POST",
                                    body: fdata,
                                    headers: {
                                        Authorization: `Bearer ${accessToken}`,
                                    },
                                }).then((response) => {
                                    return response.json();
                                }).then((data) => {
                                    setDataa(data)

                                    if (data.code == 200) {
                                        successCount++; // Increment the success count
                                        threadIds.tweet_id = data.data.thread_draft ? data.data.thread_draft.tweet_id : data.data.updated_thread_draft.tweet_id;
                                        threadIds.thread_tweet_id = data.data.thread_draft ? data.data.thread_draft.thread_tweet_id : data.data.updated_thread_draft.thread_tweet_id;

                                        successMessages.push(data.message);
                                    }
                                    else {
                                        // Collect specific error messages from the backend
                                        if (data.code === 400 && data.message && !errorMessages.includes(data.message)) {
                                            errorMessages.push(data.message);
                                        } else {
                                            // Handle other error cases
                                            console.error(`Unexpected error: ${data.message}`);
                                        }
                                    }

                                });

                            }
                        } catch (error) {
                            console.log(error);
                            errorOccurred = true; // Set errorOccurred flag to true
                        }
                    }


                    setLoadingDraft(false)
                }
                else {
                    console.log("eororon sksks  444")
                }


                if (successMessages.length > 0) {
                    toast.success(`${successMessages.length} threads drafted successfully!`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setContextData({ DraftSaved: true })

                }

                if (errorMessages.length > 0) {
                    errorMessages.forEach((errorMessage) => {
                        toast.error(errorMessage, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    });
                }
            } catch (error) {
                console.log(error);
            }
        }
        else {
            let threadIds = { tweet_id: "", thread_tweet_id: "" };
            let count = 0;
            let errorMessages = [];
            let successMessages = [];
            const idString = props?.cont?.t?.thread?.map(item => item.id).join(',');

            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken, providerToken, secretKey } = targetUser;

                    setLoadingDraft(true)
                    let successCount = 0;
                    let errorOccurred = false;
                    let textthreadIndexArr = props.textthread.map(a => a.threadInd)

                    for await (const element of props.textthread) {
                        console.log(element, 'forelement', props?.cont?.t?.thread)

                        try {

                            if (
                                element.value == "\n" ||
                                element.value == "" ||
                                element.value == null
                            ) {
                            }
                            else {


                                let fdata = new FormData();
                                let textValue = getTextWithoutImg(element.value, "[ img: ");


                                {
                                    threads[count]?.map((item, innerIndex) => {
                                        if (item.type == 'gif') {
                                            fdata.append('gif[]', item.file)

                                        } else if (item.type == 'img') {
                                            fdata.append('image[]', item.file)

                                        }
                                        else if (item.type == 'video' && item.imgnm == item.imgnm2) {
                                            fdata.append('video', item.file)
                                        }

                                    })
                                }


                                if (props?.cont?.t?.thread) {

                                    fdata.append('image_url', props?.cont?.t?.thread[count]?.image ? props?.cont?.t?.thread[count]?.image.join(',') : '');
                                    fdata.append('video_url', props?.cont?.t?.thread[count]?.video ? props?.cont?.t?.thread[count]?.video : '');
                                }


                                const threadgif = props?.cont?.t?.thread;
                                if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                                    threadgif[count].gif.forEach(item => {
                                        console.log(item, 'itemm-gif');
                                        fdata.append('gif_url', item);
                                    });
                                } else {
                                    // Handle the case where the array or gif property doesn't exist
                                    console.error('Cannot read properties of undefined or gif is not an array');
                                }


                                count++;

                                console.log("ids-=-", JSON.stringify(idString))

                                if (props.cont?.t?.thread) {
                                    fdata.append('ids', JSON.stringify(idString));
                                }
                                else {
                                    fdata.append('ids', '');
                                }



                                fdata.append("text", textValue);
                                fdata.append("provider_token", providerToken);
                                fdata.append("oauth_token_secret", OauthToken);


                                if (!props?.cont?.t?.thread) {

                                    fdata.append("id", "")
                                }
                                else if (element.id) {

                                    fdata.append("id", element.id)
                                }

                                else {
                                    fdata.append('id', props.cont?.t?.thread[element.threadInd]?.id ? props.cont?.t?.thread[element.threadInd]?.id : '');
                                }



                                fdata.append("tweet_id", "");
                                fdata.append(
                                    "thread_tweet_id",
                                    threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
                                );

                                if (props?.cont?.t?.thread) {
                                    const currentThreadCount = props.textthread.length;
                                    if (currentThreadCount !== prevThreadCount) {
                                        fdata.append('count', currentThreadCount);
                                    }
                                    else {


                                        fdata.append('count', props?.cont?.t?.thread?.length);
                                    }
                                }

                                // fdata.append('count', props.textthread.length);


                                await fetch(`${BASE_URL}api/draft/thread`, {
                                    method: "POST",
                                    body: fdata,
                                    headers: {
                                        Authorization: `Bearer ${accessToken}`,
                                    },
                                }).then((response) => {
                                    return response.json();
                                }).then((data) => {
                                    setDataa(data)

                                    if (data.code == 200) {
                                        successCount++; // Increment the success count
                                        threadIds.tweet_id = data.data.thread_draft ? data.data.thread_draft.tweet_id : data.data.updated_thread_draft.tweet_id;
                                        threadIds.thread_tweet_id = data.data.thread_draft ? data.data.thread_draft.thread_tweet_id : data.data.updated_thread_draft.thread_tweet_id;

                                        successMessages.push(data.message);
                                    }
                                    else {
                                        // Collect specific error messages from the backend
                                        if (data.code === 400 && data.message && !errorMessages.includes(data.message)) {
                                            errorMessages.push(data.message);
                                        } else {
                                            // Handle other error cases
                                            console.error(`Unexpected error: ${data.message}`);
                                        }
                                    }

                                });

                            }
                        } catch (error) {
                            console.log(error);
                            errorOccurred = true; // Set errorOccurred flag to true
                        }
                    }


                    setLoadingDraft(false)
                }
                else {
                    console.log("eororon sksks  444")
                }


                if (successMessages.length > 0) {
                    toast.success(`${successMessages.length} threads drafted successfully!`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setContextData({ DraftSaved: true })

                }

                if (errorMessages.length > 0) {
                    errorMessages.forEach((errorMessage) => {
                        toast.error(errorMessage, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    });
                }
            } catch (error) {
                console.log(error);
            }
        }

    }




    // const handleQueueThread = async () => {

    //     let threadIds = { tweet_id: "", thread_tweet_id: "" };
    //     let count = 0;

    //     try {
    //         setLoadingQueue(true)
    //         let successCount = 0;
    //         let errorOccurred = false;

    //         for await (const element of props.textthread) {


    //             try {

    //                 if (
    //                     element.value == "\n" ||
    //                     element.value == "" ||
    //                     element.value == null
    //                 ) {
    //                 }
    //                 else {


    //                     let fdata = new FormData();
    //                     let textValue = getTextWithoutImg(element.value, "[ img: ");


    //                     {
    //                         threads[count]?.map((item, innerIndex) => {
    //                             if (item.type == 'gif') {
    //                                 fdata.append('gif[]', item.file)

    //                             } else if (item.type == 'img') {
    //                                 fdata.append('image[]', item.file)

    //                             }
    //                             else if (item.type == 'video' && item.imgnm == item.imgnm2) {
    //                                 fdata.append('video', item.file)
    //                             }

    //                         })
    //                     }
    //                     console.log(props?.cont?.t?.thread, 'counttttt');


    //                     if (props?.cont?.t?.thread)
    //                         fdata.append('image_url', props?.cont?.t?.thread[count]?.image ? props?.cont?.t?.thread[count]?.image.join(',') : '');

    //                     const threadgif = props?.cont?.t?.thread;
    //                     if (Array.isArray(threadgif) && threadgif[count]?.gif) {
    //                         threadgif[count].gif.forEach(item => {
    //                             console.log(item, 'itemm-gif');
    //                             fdata.append('gif_url', item);
    //                         });
    //                     } else {
    //                         // Handle the case where the array or gif property doesn't exist
    //                         console.error('Cannot read properties of undefined or gif is not an array');
    //                     }


    //                     count++;

    //                     fdata.append("text", textValue);
    //                     fdata.append("provider_token", providerToken);

    //                     fdata.append('time', moment(queueTime, 'hh:mm:ss').format('hh:mm A'))

    //                     fdata.append('date', moment(props.cont.formattedDate).format('DD-MM-YYYY'))
    //                     fdata.append('day', moment(props.cont.formattedDate).format('dddd'))
    //                     // if (displayProfileDetails) {
    //                     //     fdata.append('timezone', displayProfileDetails.timezone)
    //                     // }
    //                     // else {
    //                     //     fdata.append('timezone', timeZone ? timeZone : '')
    //                     // }

    //                     if (displayProfileDetails?.timezone == null || displayProfileDetails?.timezone == '' || displayProfileDetails?.timezone == undefined) {
    //                         fdata.append('timezone', '')
    //                     }
    //                     else {
    //                         fdata.append('timezone', displayProfileDetails.timezone ? displayProfileDetails.timezone : '')
    //                     }
    //                     // fdata.append('timezone', timeZone ? timeZone : '')
    //                     // fdata.append('is_thread', 1)


    //                     if (!props?.cont?.t?.thread) {
    //                         fdata.append("id", "")


    //                     }
    //                     else if (props?.cont?.type == 'draft') {
    //                         fdata.append("id", "")
    //                     }
    //                     else if (props?.cont?.type == 'published') {
    //                         fdata.append("id", "")
    //                     }

    //                     else if (element.id) {
    //                         fdata.append("id", element.id)

    //                     }
    //                     else {
    //                         fdata.append('id', props.cont?.t?.thread[element.threadInd]?.id ? props.cont?.t?.thread[element.threadInd]?.id : '');

    //                     }

    //                     if (!threadIds.tweet_id) {
    //                         // If tweet_id is not set, set it to the response's id
    //                         fdata.append("tweet_id", "");
    //                     } else {
    //                         // Use the same tweet_id as the first response
    //                         fdata.append("tweet_id", threadIds.tweet_id);
    //                     }

    //                     fdata.append(
    //                         "thread_tweet_id",
    //                         threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
    //                     );

    //                     fdata.append('ids', '');
    //                     fdata.append('count', props.textthread.length);
    //                     await fetch(`${BASE_URL}api/queue/thread`, {
    //                         method: "POST",
    //                         body: fdata,
    //                         headers: {
    //                             Authorization: `Bearer ${tokenLogin}`,
    //                         },
    //                     }).then((response) => {
    //                         return response.json();
    //                     }).then((data) => {

    //                         setDataa(data)

    //                         if (data.code == 200) {
    //                             successCount++; // Increment the success count
    //                             // threadIds.tweet_id = data.data.queue.id
    //                             if (!threadIds.tweet_id) {
    //                                 threadIds.tweet_id = data.data.queue.id ? data.data.queue.id : ''; // Set the tweet_id for the first response
    //                             }
    //                             threadIds.thread_tweet_id = data.data.queue.id ? data.data.queue.id : ''
    //                             setContextData({ QUEUESaved: true })
    //                         }
    //                         else if (data.code == 429) {

    //                             toast.error(data.code, {
    //                                 position: "top-center",
    //                                 autoClose: 5000,
    //                                 hideProgressBar: false,
    //                                 closeOnClick: true,
    //                                 pauseOnHover: true,
    //                                 draggable: true,
    //                                 progress: undefined,
    //                                 theme: "light",
    //                             });
    //                             errorOccurred = true;
    //                         } else if (data.code == 500) {
    //                             toast.error(data.error_message, {
    //                                 position: "top-center",
    //                                 autoClose: 5000,
    //                                 hideProgressBar: false,
    //                                 closeOnClick: true,
    //                                 pauseOnHover: true,
    //                                 draggable: true,
    //                                 progress: undefined,
    //                                 theme: "light",
    //                             });

    //                             errorOccurred = true;
    //                         } else if (data.code == 400) {
    //                             toast.error(data.message, {
    //                                 position: "top-center",
    //                                 autoClose: 5000,
    //                                 hideProgressBar: false,
    //                                 closeOnClick: true,
    //                                 pauseOnHover: true,
    //                                 draggable: true,
    //                                 progress: undefined,
    //                                 theme: "light",
    //                             });

    //                             errorOccurred = true;
    //                         }
    //                         else if (data.code == 401) {
    //                             toast.error(data.message, {
    //                                 position: "top-center",
    //                                 autoClose: 5000,
    //                                 hideProgressBar: false,
    //                                 closeOnClick: true,
    //                                 pauseOnHover: true,
    //                                 draggable: true,
    //                                 progress: undefined,
    //                                 theme: "light",
    //                             });

    //                             errorOccurred = true;
    //                         }
    //                         else if (data.code == 403) {
    //                             toast.error(data.message, {
    //                                 position: "top-center",
    //                                 autoClose: 5000,
    //                                 hideProgressBar: false,
    //                                 closeOnClick: true,
    //                                 pauseOnHover: true,
    //                                 draggable: true,
    //                                 progress: undefined,
    //                                 theme: "light",
    //                             });

    //                             errorOccurred = true;
    //                         }

    //                     });
    //                 }
    //             } catch (error) {
    //                 console.log(error);
    //                 errorOccurred = true; // Set errorOccurred flag to true
    //             }
    //         }

    //         if (successCount > 0) {
    //             if (errorOccurred) {

    //                 toast.error(dataa.message, {
    //                     position: "top-center",
    //                     autoClose: 5000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                     theme: "light",
    //                 });

    //             } else {

    //                 toast.success('Threads queued successfully.', {
    //                     position: "top-center",
    //                     autoClose: 5000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                     theme: "light",
    //                 });

    //                 setContextData({ QUEUESaved: true })
    //             }
    //         } else {
    //             toast.error(dataa?.message, {
    //                 position: "top-center",
    //                 autoClose: 5000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //                 theme: "light",
    //             });

    //         }
    //         // window.location.reload()
    //         setLoadingQueue(false)
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }


    const handleQueueThread = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            let threadIds = { tweet_id: "", thread_tweet_id: "" };
            let count = 0;
            let errorMessages = [];
            let successMessages = [];
            const idString = props?.cont?.t?.thread?.map(item => item.id).join(',');
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken, providerToken, secretKey } = targetUser;
                    setLoadingQueue(true)
                    let successCount = 0;
                    let errorOccurred = false;

                    for await (const element of props.textthread) {

                        try {

                            let fdata = new FormData();
                            let textValue = getTextWithoutImg(element.value, "[ img: ");


                            {
                                threads[count]?.map((item, innerIndex) => {
                                    if (item.type == 'gif') {
                                        fdata.append('gif[]', item.file)

                                    } else if (item.type == 'img') {
                                        fdata.append('image[]', item.file)

                                    }
                                    else if (item.type == 'video' && item.imgnm == item.imgnm2) {
                                        fdata.append('video', item.file)
                                    }

                                })
                            }
                            console.log(props?.cont?.t?.thread, 'counttttt');


                            if (props?.cont?.t?.thread) {
                                fdata.append('image_url', props?.cont?.t?.thread[count]?.image ? props?.cont?.t?.thread[count]?.image.join(',') : '');
                                fdata.append('video_url', props?.cont?.t?.thread[count]?.video ? props?.cont?.t?.thread[count]?.video : '');
                            }
                            const threadgif = props?.cont?.t?.thread;
                            if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                                threadgif[count].gif.forEach(item => {
                                    console.log(item, 'itemm-gif');
                                    fdata.append('gif_url', item);
                                });
                            } else {
                                // Handle the case where the array or gif property doesn't exist
                                console.error('Cannot read properties of undefined or gif is not an array');
                            }


                            count++;

                            const linkRegex = /(https?:\/\/[^\s]+)/g;
                            const formattedText = textValue.replace(linkRegex, (_, link) => `"${link}"`);
                            if (props.isChecked) {

                                fdata.append('text', formattedText);

                            }
                            else {
                                fdata.append('text', textValue);
                            }

                            // fdata.append("text", textValue);

                            fdata.append("provider_token", providerToken);

                            fdata.append('time', moment(queueTime, 'hh:mm:ss').format('hh:mm A'))

                            fdata.append('date', moment(props.cont.formattedDate).format('DD-MM-YYYY'))
                            fdata.append('day', moment(props.cont.formattedDate).format('dddd'))
                            // if (displayProfileDetails) {
                            //     fdata.append('timezone', displayProfileDetails.timezone)
                            // }
                            // else {
                            //     fdata.append('timezone', timeZone ? timeZone : '')
                            // }

                            if (displayProfileDetails?.timezone == null || displayProfileDetails?.timezone == '' || displayProfileDetails?.timezone == undefined) {
                                fdata.append('timezone', '')
                            }
                            else {
                                fdata.append('timezone', displayProfileDetails.timezone ? displayProfileDetails.timezone : '')
                            }
                            // fdata.append('timezone', timeZone ? timeZone : '')
                            // fdata.append('is_thread', 1)


                            if (!props?.cont?.t?.thread) {
                                fdata.append("id", "")


                            }
                            else if (props?.cont?.type == 'draft') {
                                fdata.append("id", "")
                            }
                            else if (props?.cont?.type == 'published') {
                                fdata.append("id", "")
                            }

                            else if (element.id) {
                                fdata.append("id", element.id)

                            }
                            else {
                                fdata.append('id', props.cont?.t?.thread[element.threadInd]?.id ? props.cont?.t?.thread[element.threadInd]?.id : '');

                            }



                            if (props.cont?.t?.thread) {
                                fdata.append('ids', JSON.stringify(idString));
                            }
                            else {
                                fdata.append('ids', '');
                            }

                            if (!threadIds.tweet_id) {
                                // If tweet_id is not set, set it to the response's id
                                fdata.append("tweet_id", "");
                            } else {
                                // Use the same tweet_id as the first response
                                fdata.append("tweet_id", threadIds.tweet_id);
                            }

                            fdata.append(
                                "thread_tweet_id",
                                threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
                            );


                            fdata.append('count', props.textthread.length);
                            await fetch(`${BASE_URL}api/queue/thread`, {
                                method: "POST",
                                body: fdata,
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            }).then((response) => {
                                return response.json();
                            }).then((data) => {

                                setDataa(data)

                                if (data.code == 200) {
                                    successCount++;

                                    if (!threadIds.tweet_id) {
                                        threadIds.tweet_id = data.data.queue.id ? data.data.queue.id : '';
                                    }
                                    threadIds.thread_tweet_id = data.data.queue.id ? data.data.queue.id : ''
                                    // setContextData({ QUEUESaved: true })

                                    successMessages.push(data.message);


                                } else {
                                    // Collect specific error messages from the backend
                                    if (data.code === 400 && data.message && !errorMessages.includes(data.message)) {
                                        errorMessages.push(data.message);
                                    } else {
                                        // Handle other error cases
                                        console.error(`Unexpected error: ${data.message}`);
                                    }
                                }

                            });

                        } catch (error) {
                            console.log(error);
                            errorOccurred = true; // Set errorOccurred flag to true
                        }
                    }


                    setLoadingQueue(false)

                }
                else {
                    console.log("erroror akakakak thyread")
                }


                if (successMessages.length > 0) {
                    toast.success(`${successMessages.length} threads queued successfully!`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setContextData({ QUEUESaved: true })

                }

                if (errorMessages.length > 0) {
                    errorMessages.forEach((errorMessage) => {
                        toast.error(errorMessage, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    });
                }

            } catch (error) {
                console.log(error);
            }
        }
        else {
            let threadIds = { tweet_id: "", thread_tweet_id: "" };
            let count = 0;
            let errorMessages = [];
            let successMessages = [];
            const idString = props?.cont?.t?.thread?.map(item => item.id).join(',');
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken, providerToken, secretKey } = targetUser;
                    setLoadingQueue(true)
                    let successCount = 0;
                    let errorOccurred = false;

                    for await (const element of props.textthread) {

                        try {

                            let fdata = new FormData();
                            let textValue = getTextWithoutImg(element.value, "[ img: ");


                            {
                                threads[count]?.map((item, innerIndex) => {
                                    if (item.type == 'gif') {
                                        fdata.append('gif[]', item.file)

                                    } else if (item.type == 'img') {
                                        fdata.append('image[]', item.file)

                                    }
                                    else if (item.type == 'video' && item.imgnm == item.imgnm2) {
                                        fdata.append('video', item.file)
                                    }

                                })
                            }
                            console.log(props?.cont?.t?.thread, 'counttttt');


                            if (props?.cont?.t?.thread) {
                                fdata.append('image_url', props?.cont?.t?.thread[count]?.image ? props?.cont?.t?.thread[count]?.image.join(',') : '');
                                fdata.append('video_url', props?.cont?.t?.thread[count]?.video ? props?.cont?.t?.thread[count]?.video : '');
                            }
                            const threadgif = props?.cont?.t?.thread;
                            if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                                threadgif[count].gif.forEach(item => {
                                    console.log(item, 'itemm-gif');
                                    fdata.append('gif_url', item);
                                });
                            } else {
                                // Handle the case where the array or gif property doesn't exist
                                console.error('Cannot read properties of undefined or gif is not an array');
                            }


                            count++;

                            const linkRegex = /(https?:\/\/[^\s]+)/g;
                            const formattedText = textValue.replace(linkRegex, (_, link) => `"${link}"`);
                            if (props.isChecked) {

                                fdata.append('text', formattedText);

                            }
                            else {
                                fdata.append('text', textValue);
                            }

                            // fdata.append("text", textValue);

                            fdata.append("provider_token", providerToken);

                            fdata.append('time', moment(queueTime, 'hh:mm:ss').format('hh:mm A'))

                            fdata.append('date', moment(props.cont.formattedDate).format('DD-MM-YYYY'))
                            fdata.append('day', moment(props.cont.formattedDate).format('dddd'))
                            // if (displayProfileDetails) {
                            //     fdata.append('timezone', displayProfileDetails.timezone)
                            // }
                            // else {
                            //     fdata.append('timezone', timeZone ? timeZone : '')
                            // }

                            if (displayProfileDetails?.timezone == null || displayProfileDetails?.timezone == '' || displayProfileDetails?.timezone == undefined) {
                                fdata.append('timezone', '')
                            }
                            else {
                                fdata.append('timezone', displayProfileDetails.timezone ? displayProfileDetails.timezone : '')
                            }
                            // fdata.append('timezone', timeZone ? timeZone : '')
                            // fdata.append('is_thread', 1)


                            if (!props?.cont?.t?.thread) {
                                fdata.append("id", "")


                            }
                            else if (props?.cont?.type == 'draft') {
                                fdata.append("id", "")
                            }
                            else if (props?.cont?.type == 'published') {
                                fdata.append("id", "")
                            }

                            else if (element.id) {
                                fdata.append("id", element.id)

                            }
                            else {
                                fdata.append('id', props.cont?.t?.thread[element.threadInd]?.id ? props.cont?.t?.thread[element.threadInd]?.id : '');

                            }



                            if (props.cont?.t?.thread) {
                                fdata.append('ids', JSON.stringify(idString));
                            }
                            else {
                                fdata.append('ids', '');
                            }

                            if (!threadIds.tweet_id) {
                                // If tweet_id is not set, set it to the response's id
                                fdata.append("tweet_id", "");
                            } else {
                                // Use the same tweet_id as the first response
                                fdata.append("tweet_id", threadIds.tweet_id);
                            }

                            fdata.append(
                                "thread_tweet_id",
                                threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
                            );


                            fdata.append('count', props.textthread.length);
                            await fetch(`${BASE_URL}api/queue/thread`, {
                                method: "POST",
                                body: fdata,
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            }).then((response) => {
                                return response.json();
                            }).then((data) => {

                                setDataa(data)

                                if (data.code == 200) {
                                    successCount++;

                                    if (!threadIds.tweet_id) {
                                        threadIds.tweet_id = data.data.queue.id ? data.data.queue.id : '';
                                    }
                                    threadIds.thread_tweet_id = data.data.queue.id ? data.data.queue.id : ''
                                    // setContextData({ QUEUESaved: true })

                                    successMessages.push(data.message);


                                } else {
                                    // Collect specific error messages from the backend
                                    if (data.code === 400 && data.message && !errorMessages.includes(data.message)) {
                                        errorMessages.push(data.message);
                                    } else {
                                        // Handle other error cases
                                        console.error(`Unexpected error: ${data.message}`);
                                    }
                                }

                            });

                        } catch (error) {
                            console.log(error);
                            errorOccurred = true; // Set errorOccurred flag to true
                        }
                    }


                    setLoadingQueue(false)

                }
                else {
                    console.log("erroror akakakak thyread")
                }


                if (successMessages.length > 0) {
                    toast.success(`${successMessages.length} threads queued successfully!`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setContextData({ QUEUESaved: true })

                }

                if (errorMessages.length > 0) {
                    errorMessages.forEach((errorMessage) => {
                        toast.error(errorMessage, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    });
                }

            } catch (error) {
                console.log(error);
            }
        }
    }






    const handleScheduleLater = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            let threadIds = { tweet_id: "", thread_tweet_id: "" };
            let count = 0;
            let errorMessages = [];
            let successMessages = [];
            const idString = props?.cont?.t?.thread?.map(item => item.id).join(',');

            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken, providerToken, secretKey } = targetUser;
                    setLoadingSchdule(true)
                    let successCount = 0;
                    let errorOccurred = false;

                    for await (const element of props.textthread) {

                        try {

                            let fdata = new FormData();
                            let textValue = getTextWithoutImg(element.value, "[ img: ");


                            {
                                threads[count]?.map((item, innerIndex) => {
                                    if (item.type == 'gif') {
                                        fdata.append('gif[]', item.file)

                                    } else if (item.type == 'img') {
                                        fdata.append('image[]', item.file)

                                    }
                                    else if (item.type == 'video' && item.imgnm == item.imgnm2) {
                                        fdata.append('video', item.file)
                                    }

                                })
                            }
                            console.log(props?.cont?.t?.thread, 'counttttt');


                            if (props?.cont?.t?.thread) {
                                fdata.append('image_url', props?.cont?.t?.thread[count]?.image ? props?.cont?.t?.thread[count]?.image.join(',') : '');
                                fdata.append('video_url', props?.cont?.t?.thread[count]?.video ? props?.cont?.t?.thread[count]?.video : '');
                            }
                            const threadgif = props?.cont?.t?.thread;
                            if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                                threadgif[count].gif.forEach(item => {
                                    console.log(item, 'itemm-gif');
                                    fdata.append('gif_url', item);
                                });
                            } else {
                                // Handle the case where the array or gif property doesn't exist
                                console.error('Cannot read properties of undefined or gif is not an array');
                            }




                            // fdata.append('time', props?.cont?.t?.thread ? moment(props?.cont?.t?.thread[count]?.schedule_time, 'hh:mm:ss').format('hh:mm A') : selectedTim)
                            // fdata.append('date', props?.cont?.t?.thread ? moment(props?.cont?.t?.thread[count]?.schedule_date, 'DD-MM-YYYY').format('DD/MM/YYYY') : selectedDat)
                            // fdata.append('day', props?.cont?.t?.thread ? moment(props?.cont?.t?.thread[count]?.schedule_date, 'DD-MM-YYYY').format('dddd') : moment(selectedDat, 'DD-MM-YYYY').format('dddd'))

                            fdata.append('time', selectedTim ? selectedTim : moment(selectedTime, 'hh:mm:ss').format('hh:mm A'))

                            fdata.append('date', selectedDat ? selectedDat : moment(selectedDate, 'DD-MM-YYYY').format('DD/MM/YYYY'))
                            fdata.append('day', selectedDat ? moment(selectedDat, 'DD-MM-YYYY').format('dddd') : selectedDay)


                            count++;

                            fdata.append("text", textValue);
                            fdata.append("provider_token", providerToken);



                            if (displayProfileDetails?.timezone == null || displayProfileDetails?.timezone == '' || displayProfileDetails?.timezone == undefined) {
                                fdata.append('timezone', '')
                            }
                            else {
                                fdata.append('timezone', displayProfileDetails.timezone ? displayProfileDetails.timezone : '')
                            }
                            // fdata.append('timezone', timeZone ? timeZone : '')
                            // fdata.append('is_thread', 1)


                            if (!props?.cont?.t?.thread) {
                                fdata.append("id", "")


                            }
                            else if (props?.cont?.type == 'draft') {
                                fdata.append("id", "")
                            }
                            else if (props?.cont?.type == 'published') {
                                fdata.append("id", "")
                            }

                            else if (element.id) {
                                fdata.append("id", element.id)

                            }
                            else {
                                fdata.append('id', props.cont?.t?.thread[element.threadInd]?.id ? props.cont?.t?.thread[element.threadInd]?.id : '');

                            }

                            if (!threadIds.tweet_id) {
                                // If tweet_id is not set, set it to the response's id
                                fdata.append("tweet_id", "");
                            } else {
                                // Use the same tweet_id as the first response
                                fdata.append("tweet_id", threadIds.tweet_id);
                            }

                            fdata.append(
                                "thread_tweet_id",
                                threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
                            );

                            if (props.cont?.t?.thread) {
                                fdata.append('ids', JSON.stringify(idString));
                            }
                            else {
                                fdata.append('ids', '');
                            }

                            fdata.append('count', props.textthread.length);
                            await fetch(`${BASE_URL}api/schedule/thread`, {
                                method: "POST",
                                body: fdata,
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            }).then((response) => {
                                return response.json();
                            }).then((data) => {

                                setDataa(data)

                                if (data.code == 200) {
                                    successCount++;

                                    if (!threadIds.tweet_id) {
                                        threadIds.tweet_id = data.data.queue.id ? data.data.queue.id : '';
                                    }
                                    threadIds.thread_tweet_id = data.data.queue.id ? data.data.queue.id : ''
                                    // setContextData({ QUEUESaved: true })

                                    successMessages.push(data.message);


                                } else {
                                    // Collect specific error messages from the backend
                                    if (data.code === 400 && data.message && !errorMessages.includes(data.message)) {
                                        errorMessages.push(data.message);
                                    } else {
                                        // Handle other error cases
                                        console.error(`Unexpected error: ${data.message}`);
                                    }
                                }

                            });

                        } catch (error) {
                            console.log(error);
                            errorOccurred = true; // Set errorOccurred flag to true
                        }
                    }


                    setLoadingSchdule(false)
                }
                else {
                    console.log("scjnsjncs cdes schduler")
                }


                if (successMessages.length > 0) {
                    toast.success(`${successMessages.length} threads scheduled successfully!`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setContextData({ QUEUESaved: true })
                    setSelectedDateTime('')
                    setIsOpen(false)




                }

                if (errorMessages.length > 0) {
                    errorMessages.forEach((errorMessage) => {
                        toast.error(errorMessage, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    });
                }

            } catch (error) {
                console.log(error);
            }
        }
        else {
            let threadIds = { tweet_id: "", thread_tweet_id: "" };
            let count = 0;
            let errorMessages = [];
            let successMessages = [];
            const idString = props?.cont?.t?.thread?.map(item => item.id).join(',');

            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken, providerToken, secretKey } = targetUser;
                    setLoadingSchdule(true)
                    let successCount = 0;
                    let errorOccurred = false;

                    for await (const element of props.textthread) {

                        try {

                            let fdata = new FormData();
                            let textValue = getTextWithoutImg(element.value, "[ img: ");


                            {
                                threads[count]?.map((item, innerIndex) => {
                                    if (item.type == 'gif') {
                                        fdata.append('gif[]', item.file)

                                    } else if (item.type == 'img') {
                                        fdata.append('image[]', item.file)

                                    }
                                    else if (item.type == 'video' && item.imgnm == item.imgnm2) {
                                        fdata.append('video', item.file)
                                    }

                                })
                            }
                            console.log(props?.cont?.t?.thread, 'counttttt');


                            if (props?.cont?.t?.thread) {
                                fdata.append('image_url', props?.cont?.t?.thread[count]?.image ? props?.cont?.t?.thread[count]?.image.join(',') : '');
                                fdata.append('video_url', props?.cont?.t?.thread[count]?.video ? props?.cont?.t?.thread[count]?.video : '');
                            }
                            const threadgif = props?.cont?.t?.thread;
                            if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                                threadgif[count].gif.forEach(item => {
                                    console.log(item, 'itemm-gif');
                                    fdata.append('gif_url', item);
                                });
                            } else {
                                // Handle the case where the array or gif property doesn't exist
                                console.error('Cannot read properties of undefined or gif is not an array');
                            }




                            // fdata.append('time', props?.cont?.t?.thread ? moment(props?.cont?.t?.thread[count]?.schedule_time, 'hh:mm:ss').format('hh:mm A') : selectedTim)
                            // fdata.append('date', props?.cont?.t?.thread ? moment(props?.cont?.t?.thread[count]?.schedule_date, 'DD-MM-YYYY').format('DD/MM/YYYY') : selectedDat)
                            // fdata.append('day', props?.cont?.t?.thread ? moment(props?.cont?.t?.thread[count]?.schedule_date, 'DD-MM-YYYY').format('dddd') : moment(selectedDat, 'DD-MM-YYYY').format('dddd'))

                            fdata.append('time', selectedTim ? selectedTim : moment(selectedTime, 'hh:mm:ss').format('hh:mm A'))

                            fdata.append('date', selectedDat ? selectedDat : moment(selectedDate, 'DD-MM-YYYY').format('DD/MM/YYYY'))
                            fdata.append('day', selectedDat ? moment(selectedDat, 'DD-MM-YYYY').format('dddd') : selectedDay)


                            count++;

                            fdata.append("text", textValue);
                            fdata.append("provider_token", providerToken);



                            if (displayProfileDetails?.timezone == null || displayProfileDetails?.timezone == '' || displayProfileDetails?.timezone == undefined) {
                                fdata.append('timezone', '')
                            }
                            else {
                                fdata.append('timezone', displayProfileDetails.timezone ? displayProfileDetails.timezone : '')
                            }
                            // fdata.append('timezone', timeZone ? timeZone : '')
                            // fdata.append('is_thread', 1)


                            if (!props?.cont?.t?.thread) {
                                fdata.append("id", "")


                            }
                            else if (props?.cont?.type == 'draft') {
                                fdata.append("id", "")
                            }
                            else if (props?.cont?.type == 'published') {
                                fdata.append("id", "")
                            }

                            else if (element.id) {
                                fdata.append("id", element.id)

                            }
                            else {
                                fdata.append('id', props.cont?.t?.thread[element.threadInd]?.id ? props.cont?.t?.thread[element.threadInd]?.id : '');

                            }

                            if (!threadIds.tweet_id) {
                                // If tweet_id is not set, set it to the response's id
                                fdata.append("tweet_id", "");
                            } else {
                                // Use the same tweet_id as the first response
                                fdata.append("tweet_id", threadIds.tweet_id);
                            }

                            fdata.append(
                                "thread_tweet_id",
                                threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
                            );

                            if (props.cont?.t?.thread) {
                                fdata.append('ids', JSON.stringify(idString));
                            }
                            else {
                                fdata.append('ids', '');
                            }

                            fdata.append('count', props.textthread.length);
                            await fetch(`${BASE_URL}api/schedule/thread`, {
                                method: "POST",
                                body: fdata,
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            }).then((response) => {
                                return response.json();
                            }).then((data) => {

                                setDataa(data)

                                if (data.code == 200) {
                                    successCount++;

                                    if (!threadIds.tweet_id) {
                                        threadIds.tweet_id = data.data.queue.id ? data.data.queue.id : '';
                                    }
                                    threadIds.thread_tweet_id = data.data.queue.id ? data.data.queue.id : ''
                                    // setContextData({ QUEUESaved: true })

                                    successMessages.push(data.message);


                                } else {
                                    // Collect specific error messages from the backend
                                    if (data.code === 400 && data.message && !errorMessages.includes(data.message)) {
                                        errorMessages.push(data.message);
                                    } else {
                                        // Handle other error cases
                                        console.error(`Unexpected error: ${data.message}`);
                                    }
                                }

                            });

                        } catch (error) {
                            console.log(error);
                            errorOccurred = true; // Set errorOccurred flag to true
                        }
                    }


                    setLoadingSchdule(false)
                }
                else {
                    console.log("scjnsjncs cdes schduler")
                }


                if (successMessages.length > 0) {
                    toast.success(`${successMessages.length} threads scheduled successfully!`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setContextData({ QUEUESaved: true })
                    setSelectedDateTime('')
                    setIsOpen(false)




                }

                if (errorMessages.length > 0) {
                    errorMessages.forEach((errorMessage) => {
                        toast.error(errorMessage, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    });
                }

            } catch (error) {
                console.log(error);
            }
        }
    }





    // console.log("selectedVideo", selectedVideo, selectedGifpickerArr, selectedImages)


    const wordCounts = props.textthread?.map(string => {

        // const words = string.value.split(' ');
        // return words.length;

        const stringWithoutNewlines = string.value.replace(/\n/g, ' '); // Replace newline characters with spaces
        const words = stringWithoutNewlines;
        // return words.length;
        return { lines: string.value, wordCount: words.length };



    });

    console.log("loader", loader)

    const toggleEmojiPicker = () => {
        setShowEmojiPicker(!showEmojiPicker);
        setShowGif(false)
        // setShowEmojiPicker(false)
    };
    const handleEmojiSelect = (event) => {

        // setTweetText(tweetText + event.emoji);
        console.log("emoji", event.emoji)
        // setEmoji(event.emoji)
    };

    const [backupData, setBackupData] = useState([]);


    // const handleImageNameChange = (Imageindex, index) => {
    //     console.log("njkjkb", Imageindex, index)


    //     // // Create a copy of the images array to avoid mutating state directly.
    //     // const updatedImages = [...threads];
    //     // const originalName = updatedImages[Imageindex].imgnm;

    //     // // Update the image name at the specified index.
    //     // updatedImages[Imageindex].imgnm = newName;





    //     // setThreads((prevThreads) => {
    //     //     const updatedThreads = [...prevThreads];
    //     //     if (updatedThreads[threadIndex]) {
    //     //         updatedThreads[threadIndex].splice(Imageindex, 1); // Remove the image at the specified index
    //     //     }
    //     //     return updatedThreads;
    //     // });



    // };

    // const handleInputChange = (threadIndex, imageIndex, newValue, e) => {
    //     const updatedThreads = [...threads]; 
    //     const image = updatedThreads[threadIndex][imageIndex];




    //     if (newValue !== image.imgnm) {
    //         // Update the image name in the copy of the threads array
    //         updatedThreads[threadIndex][imageIndex] = {
    //             ...image,
    //             imgnm: newValue,
    //         };

    //         // Update the state or data structure with the modified threads array
    //         // setThreads(updatedThreads);
    //     }
    // };




    // useEffect(() => {
    //     // Initialize backupData with the initial threads data
    //     setBackupData([...threads]);
    // }, [threads]);

    // const handleImageNameChange = (threadIndex, imageIndex) => {
    //     // Create a backup of the original image properties when the input is focused
    //     const image = threads[threadIndex][imageIndex];
    //     const backup = [...backupData];
    //     backup[threadIndex][imageIndex] = {
    //         ...image,
    //         originalName: image.imgnm,
    //     };
    //     setBackupData(backup);
    // };

    // const handleInputChange = (threadIndex, imageIndex, newValue) => {
    //     const updatedThreads = [...threads]; // Create a copy of the threads array
    //     const image = updatedThreads[threadIndex][imageIndex];

    //     // Check if the new value is different from the original value
    //     if (newValue.target.value !== image.imgnm) {
    //         // Remove the entire thread if the input value is different
    //         // image.file = "";
    //         // image.imgurl = "";
    //         image.imgnm = newValue.target.value
    //         console.log("Data0-thread new value")
    //     }
    //     else {
    //         const backup = backupData[threadIndex]?.[imageIndex];
    //         if (backup) {
    //             image.imgurl = backup.imgurl;
    //             image.file = backup.file;
    //         }
    //         console.log("Data0-thread old", backup)
    //     }

    //     // Update the state with the modified threads array
    //     setThreads(updatedThreads);
    //     console.log("Data0-thread new", newValue.target.value)
    // };

    const handleInputChange = (threadIndex, imageIndex, newValue) => {
        const updatedThreads = [...threads]; // Create a copy of the threads array
        const image = updatedThreads[threadIndex][imageIndex];

        if (newValue.target.value) {
            image.imgnm2 = newValue.target.value
        }
        else {
            image.imgnm2 = newValue.target.value
        }


        // // Check if the new value is different from the original value
        // if (newValue.target.value !== image.imgnm) {
        //     // Remove the entire thread if the input value is different
        //     // image.file = "";
        //     // image.imgurl = "";
        //     image.imgnm2 = newValue.target.value
        //     console.log("Data0-thread new value", newValue.target.value, image.imgnm)
        // }
        // else {

        //     console.log("Data0-thread old",)
        // }

        // Update the state with the modified threads array
        setThreads(updatedThreads);
        console.log("Data0-thread new", newValue.target.value)
    };

    console.log("Data0-thread", threads)

    const [foundLinks, setFoundLinks] = useState([]);


    // useEffect(() => {
    //     props?.textthread?.forEach((item, index) => {
    //         console.log("index-1", index);

    //         // Split text into lines
    //         const lines = item?.value?.split("\n");

    //         // Array to collect links for the current item
    //         const itemLinks = [];

    //         // Check each line for links
    //         lines.forEach((line, lineIndex) => {
    //             const linkMatches = findLinks(line);

    //             if (linkMatches.length > 0) {
    //                 console.log(`Links found in line ${lineIndex + 1} of item ${index + 1}:`, linkMatches);

    //                 // Add links to the itemLinks array
    //                 itemLinks.push(...linkMatches);
    //             }
    //         });

    //         // Update state with links for the current item
    //         setFoundLinks(prevLinks => [...prevLinks, ...itemLinks]);
    //     });
    // }, [props.textthread]); // Run effect when textthread prop changes

    // // Function to find links in a text
    // function findLinks(text) {
    //     const urlRegex = /(https?:\/\/[^\s]+)/g;
    //     return text.match(urlRegex) || [];
    // }

    // console.log("foundLinks", foundLinks)



    function findLinksInText(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        //const urlRegex = /(https?:\/\/[^\s"]+)|(?:")https?:\/\/[^\s"]+(?:")/g;
        return text.match(urlRegex) || [];
    }

    return (
        <Fragment>

            <Offcanvas
                show={props.showthread}
                onHide={props.handleClosethread}
                placement={`${themeSchemeDirection === "rtl" ? "start" : "end"}`}
                backdrop={false}
                scroll={true}
                className="live-customizer"
            >
                <Offcanvas.Header closeButton className="pb-0">
                    <div className="d-flex align-items-center">
                        <h4 className="offcanvas-title" id="live-customizer-label">
                            Thread
                            {/* {loadingpro ? (
                                <ProgressBar now={loader} label={`${loader}%`} />)
                                : ''} */}
                        </h4>

                    </div>

                </Offcanvas.Header>
                <Offcanvas.Body>

                    <div className="thread-save-draft"

                        onClick={() => handleDraftThread()}
                    >
                        <span> {loadingDraft ? <Spinner animation="border" variant="light" /> : 'Save as draft'}</span>
                    </div>



                    {Array.isArray(props?.textthread) && props?.textthread?.length > 0 ? (
                        props?.textthread?.map((item, index) => {
                            console.log("idnex-1", index, item)
                            // const lines = item?.value?.split("\n");
                            const lines = item?.value?.split("\n\n\n");


                            const foundLinks = [];

                            const textLength = item?.value?.replace(/\n/g, ' ')?.length;
                            const maxLength = 280;
                            const isOverLimit = textLength == maxLength;


                            const linesWithLinks = lines.map((line, lineIndex) => {
                                const linksInLine = findLinksInText(line);
                                console.log({ line, lineIndex, linksInLine }, 'linksInLinelinksInLine')
                                if (linksInLine.length > 0) {
                                    let lineWoutLink = line
                                    {
                                        linksInLine.forEach((el) => {
                                            lineWoutLink = lineWoutLink.replaceAll(el, "")
                                        })
                                    }
                                    return (
                                        <li className="thread-li" key={lineIndex}>
                                            {/* {line.replaceAll("a", "b")} */}
                                            {lineWoutLink}
                                            {linksInLine.map((link, linkIndex) => (
                                                <span key={linkIndex} style={{ color: 'dodgerblue' }}>
                                                    {/* {link}{' '} */}
                                                    {props?.isChecked ? (

                                                        link

                                                    ) : (
                                                        <Microlink url={link}>
                                                            {({ data, loading }) => {
                                                                if (loading) return <p>Loading...</p>;
                                                                if (data) {

                                                                    console.log(data);
                                                                    return (
                                                                        <div>
                                                                            <h1>{data.title}</h1>
                                                                            <p>{data.description}</p>

                                                                        </div>
                                                                    );
                                                                }
                                                                return null;
                                                            }}
                                                        </Microlink>
                                                    )}
                                                    {' '} {/* Add a space after the link */}
                                                </span>
                                            ))}
                                        </li>
                                    );
                                }

                                // If no links, render the line as usual
                                return (
                                    <li className="thread-li" key={lineIndex}>
                                        {line}{" "}
                                    </li>
                                );
                            });

                            console.log(":::scdscs", lines)



                            {/* {Array.isArray(props?.textthread) && props?.textthread?.length > 0 ? (
                        props?.textthread?.map((item, index) => {
                            const lines = item?.value?.split("\n\n\n");

                            const renderLines = lines.map((line, lineIndex) => {
                                const linksInLine = findLinksInText(line);

                                if (linksInLine.length > 0) {
                                
                                    return (
                                        <span key={lineIndex}>
                                            {line}{' '}
                                            {linksInLine.map((link, linkIndex) => (
                                                <span key={linkIndex} style={{ color: 'dodgerblue' }}>
                                                    {props?.isChecked ? (
                                                        link
                                                    ) : (
                                                        <Microlink url={link}>
                                                            {({ data, loading }) => {
                                                                if (loading) return <p>Loading...</p>;
                                                                if (data) {
                                                                
                                                                    console.log(data);
                                                                    return (
                                                                        <div>
                                                                            <h1>{data.title}</h1>
                                                                            <p>{data.description}</p>
                                                                          
                                                                        </div>
                                                                    );
                                                                }
                                                                return null;
                                                            }}
                                                        </Microlink>
                                                    )}
                                                </span>
                                            ))}
                                        </span>
                                    );
                                }

                               
                                return <span key={lineIndex}>{line}</span>;
                            }); */}


                            // Function to find links in a text
                            // function findLinksInText(text) {
                            //     const urlRegex = /(https?:\/\/[^\s]+)/g;
                            //     //const urlRegex = /(https?:\/\/[^\s"]+)|(?:")https?:\/\/[^\s"]+(?:")/g;
                            //     return text.match(urlRegex) || [];
                            // }






                            return (
                                <Fragment key={index}>
                                    <div>
                                        <img
                                            src={displayProfileDetails ? displayProfileDetails?.image_url : noimage}
                                            className="span-icon"
                                            style={{ width: 60 }}
                                        />
                                        <span className="span-thread" style={{ marginLeft: 20 }}>
                                            {displayProfileDetails?.name}
                                        </span>
                                    </div>
                                    <ul className="thread-ul" style={{ whiteSpace: 'pre-wrap' }}>







                                        {/* {textLength <= 280 ? (
                                            <> */}


                                        {/* {lines && lines?.map((line, lineIndex) => (

                                            <li className="thread-li" key={lineIndex} >

                                               
                                                {foundLinks}

                                                  {getTextWithoutImg(line, "[ img: ")} 
                                                {line}
                                                 {foundLinks} 

                                            </li>


                                        ))} */}






                                        {linesWithLinks && linesWithLinks?.map((line, lineIndex) => (



                                            <li className="thread-li" key={lineIndex} >


                                                {line}


                                            </li>



                                        ))}







                                        {/* {renderLines} */}


                                        {/* </>
                                        )
                                            : ''} */}
                                        {/* <span className="word-count">{item.value ? item?.value?.replace(/\n/g, ' ').length : ''}</span> */}

                                        <span className={`word-count ${isOverLimit ? 'text-red' : ''}`}>

                                            {textLength}



                                        </span>

                                        <div>
                                            {/* {Array.isArray(threads) && threads[index] && threads[index]?.map((image, Imageindex) => ( */}
                                            {threads[index]?.map((image, Imageindex) => (
                                                <div key={Imageindex}>
                                                    {/* <FontAwesomeIcon icon={faPencil} style={{ fontSize: 11, color: 'dodgerblue', }} /> */}
                                                    <input
                                                        type="text"

                                                        // onClick={() => handleImageNameChange(index, Imageindex)}
                                                        defaultValue={image?.imgnm}
                                                        onChange={(e) => handleInputChange(index, Imageindex, e)}
                                                        style={{ border: 'none', color: '#545454' }}
                                                    />
                                                    <p>{image?.media}</p>

                                                    {/* {backupData.findIndex(f => f.imgnm === image?.imgnm)} */}
                                                    {image?.type == 'img' ? (
                                                        <img
                                                            src={image?.imgurl}
                                                            alt={`Selected ${index + 1}`}
                                                            style={{
                                                                width: "100%",
                                                                height: "auto",
                                                                marginBottom: "20px",
                                                                borderRadius: 10,
                                                                display: image?.imgnm === image?.imgnm2 ? 'block' : 'none'
                                                            }}
                                                        />) : ''}

                                                    {image?.type == 'gif' ? (

                                                        <img
                                                            src={image?.imgurl}
                                                            alt={`Selected ${index + 1}`}
                                                            style={{
                                                                width: "100%",
                                                                height: "auto",
                                                                marginBottom: "20px",
                                                                borderRadius: 10,
                                                                display: image?.imgnm === image?.imgnm2 ? 'block' : 'none'
                                                            }}
                                                        />) : ''}

                                                    {/* {laodingGif ? "loading..." : 'done...'} */}

                                                    {image?.preview && (
                                                        <div>

                                                            <video width="100%" height="240"
                                                                controls
                                                                src={image?.preview}
                                                                style={{

                                                                    display: image?.imgnm === image?.imgnm2 ? 'block' : 'none'
                                                                }}
                                                            >


                                                            </video>

                                                            {/* <button onClick={() => handleRemoveVideo(index)}>Remove Video</button> */}

                                                        </div>
                                                    )}

                                                </div>



                                            ))}

                                            {/* {threads[index]?.map((image, Imageindex) => {
                                                console.log("images-video", image)
                                            })} */}

                                        </div>

                                        {/* <Row> */}
                                        {/* {selectedImages[index]?.image?.map((im, imgIndex) => {
                                                return (
                                                    <Col sm={4} lg={4} md={4}>
                                                        <li className="thread-li" key={imgIndex}>
                                                            <img src={im} className="image-img" alt={`Image ${imgIndex}`} style={{ marginBottom: 10 }} />
                                                            <FontAwesomeIcon
                                                                icon={faCircleXmark}
                                                                type="button"
                                                                onClick={() => handleRemoveImage2(imgIndex, index)}
                                                                className="cross-remove-thread-icon"
                                                            />
                                                        </li>
                                                    </Col>
                                                );
                                            })} */}



                                        {/* {selectedImages[index]?.image?.map((im, imgIndex) => {
                                                return (
                                                    <Col sm={4} lg={4} md={4} key={imgIndex}>
                                                        {im ? (
                                                            <li className="thread-li">
                                                                <img src={im} className="image-img" alt={`Image ${imgIndex}`} style={{ marginBottom: 10 }} />
                                                                <FontAwesomeIcon
                                                                    icon={faCircleXmark}
                                                                    type="button"
                                                                    onClick={() => handleRemoveImage2(imgIndex, index)}
                                                                    className="cross-remove-thread-icon"
                                                                />
                                                            </li>
                                                        ) : ''}
                                                    </Col>
                                                );
                                            })} */}




                                        {/* </Row> */}


                                        {selectedImages && selectedImages[index]?.image?.map((im, imgIndex) => {
                                            // let imgnmNew = randomImageName(5);


                                            // const handleClcick = () => {
                                            //     // setImgnmNew2(imgnmNew)
                                            //     let imgnmNew2 = imgnmNew
                                            //     console.log("imhnbgsgdvvv", imgnmNew2, imgnmNew)
                                            // }


                                            return (

                                                <>
                                                    {im ? (
                                                        <li className="thread-li">
                                                            {/* <span onClick={handleClcick}> {imgnmNew}</span> */}
                                                            <img src={im} className="image-img" alt={`Image ${imgIndex}`} style={{ marginBottom: 10 }} />
                                                            <FontAwesomeIcon
                                                                icon={faCircleXmark}
                                                                type="button"
                                                                onClick={() => handleRemoveImage2(imgIndex, index)}
                                                                className="cross-remove-thread-icon-image"
                                                            />


                                                        </li>
                                                    ) : ""}

                                                </>
                                            );
                                        })}



                                        {selectedVideo && selectedVideo[index]?.video ? (

                                            <li className="thread-li" >
                                                <video width="100%" height="240"
                                                    controls
                                                    src={selectedVideo[index]?.video}
                                                >


                                                </video>
                                                <FontAwesomeIcon
                                                    icon={faCircleXmark}
                                                    type="button"
                                                    onClick={() => handleVideoRemove(index)}
                                                    className="cross-remove-thread-icon"
                                                    style={{ color: 'red' }}
                                                />


                                            </li>
                                        ) : ''}

                                        {laodingGif ? (<>

                                            loading...
                                        </>)

                                            : (
                                                <>


                                                    {selectedGifpickerArr && Array.isArray(selectedGifpickerArr[index]?.gif) ? (
                                                        selectedGifpickerArr[index]?.gif?.map((im, imgIndex) => (



                                                            <li className="thread-li" key={imgIndex}>
                                                                <img src={im} className="image-img" alt={`Image ${imgIndex}`} />
                                                                <FontAwesomeIcon
                                                                    icon={faCircleXmark}
                                                                    type="button"
                                                                    onClick={() => handleRemoveGif2(imgIndex, index)}
                                                                    className="cross-remove-thread-icon-gif"
                                                                />
                                                            </li>


                                                        ))
                                                    ) : (

                                                        <p></p>
                                                    )}
                                                </>
                                            )}










                                        {findNextIndex(item.value, "[ img: ").map((imgind, gifIndex) => {
                                            let imgnam = item.value.substring(imgind, imgind + 13);
                                            item.value.replace(imgnam, "");

                                            return (
                                                <li className="thread-li" key={gifIndex}>
                                                    {props.selectedGifpickerArr &&
                                                        props.selectedGifpickerArr.findIndex((a) => a.name === imgnam) >= 0 ? (
                                                        <img
                                                            src={
                                                                props.selectedGifpickerArr[
                                                                    props.selectedGifpickerArr &&
                                                                    props.selectedGifpickerArr.findIndex((a) => a.name === imgnam)
                                                                ]?.gif
                                                            }
                                                            className="image-gif"
                                                        />
                                                    ) : null}
                                                </li>
                                            );
                                        })}
                                    </ul>

                                    {/* <ul className="thread-ul">
                                        {console.log(props.cont?.t?.thread[index]?.image, 'image-thread-co')}
                                        {props.cont?.t?.thread[index]?.image?.map((im, imgIndex) => {
                                            console.log("image-thread-co", im)



                                            return (
                                                <li className="thread-li" key={imgIndex}>
                                                    <img
                                                        src={
                                                            im
                                                        }
                                                        className="image-img"
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faCircleXmark}
                                                        type="button"
                                                        onClick={() => handleRemoveImage2(imgIndex, index)}
                                                        className="cross-remove-thread-icon"
                                                    />
                                                </li>

                                            );

                                        })}
                                       
                                        {findNextIndex(item.value, "[ img: ").map((imgind, gifIndex) => {
                                            let imgnam = item.value.substring(imgind, imgind + 13);
                                            item.value.replace(imgnam, "");

                                            return (
                                                <li className="thread-li" key={gifIndex}>
                                                    {props.selectedGifpickerArr &&
                                                        props.selectedGifpickerArr.findIndex((a) => a.name === imgnam) >= 0 ? (
                                                        <img
                                                            src={
                                                                props.selectedGifpickerArr[
                                                                    props.selectedGifpickerArr &&
                                                                    props.selectedGifpickerArr.findIndex((a) => a.name === imgnam)
                                                                ]?.gif
                                                            }
                                                            className="image-gif"
                                                        />
                                                    ) : null}
                                                </li>
                                            );
                                        })}
                                    </ul> */}
                                    <div className="thread-image-container-ui">
                                        <div> </div>
                                        <div key={index}>
                                            {/* <label htmlFor={`threadimage-upload-${index}`}>
                                                <input
                                                    id={`threadimage-upload-${index}`}
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: "none" }}
                                                    onChange={(e) => handleImageUpload(index)(e)}
                                                />
                                                <i className="fa fa-image image-icon" />
                                            </label> */}

                                            <label htmlFor={`threadimage-upload-${index}`}>
                                                <input
                                                    id={`threadimage-upload-${index}`}
                                                    type="file"

                                                    accept="image/*, video/*"
                                                    style={{ display: "none" }}
                                                    onChange={(e) => handleMediaUpload(index)(e)}
                                                />

                                                <FontAwesomeIcon icon={faFileMedical} />
                                            </label>


                                            {/* <input

                                                type="file"
                                                accept=".mp4,.mov"
                                                style={{ display: 'none' }}
                                                ref={(el) => (fileInputRef.current[index] = el)} // Assign the ref properly
                                                onChange={(e) => handleFileChange(e, index)}

                                            />
                                            <FontAwesomeIcon icon={faFilm} onClick={() => handleFileUploadClick(index)} className='image-icon' /> */}


                                            <img src={gifimage} onClick={() => handleGifopen(index)} style={{ cursor: 'pointer' }} />
                                            {/* <FontAwesomeIcon icon={faSmile} onClick={toggleEmojiPicker} className='emoji-mar' style={{ marginLeft: 5 }} /> */}
                                        </div>
                                    </div>

                                    {/* 2@ UPLOAD IMAGES */}

                                    {/* <Row>
                                        {threads[index]?.map((image, Imageindex) => (

                                            <Col
                                                lg={3}
                                                sm={3}
                                                md={3}
                                                key={index}

                                            >
                                                <img
                                                    src={image.imgurl}
                                                    alt={`Selected ${index + 1}`}
                                                    style={{
                                                        width: "60px",
                                                        height: "60px",
                                                        marginBottom: "20px",
                                                        borderRadius: 10
                                                    }}
                                                />
                                                <FontAwesomeIcon
                                                    icon={faCircleXmark}
                                                    type="button"
                                                    onClick={() => handleRemoveImage(index, Imageindex)}
                                                    className="cross-remove-thread-icon-2"
                                                />

                                            </Col>
                                        ))}



                                    </Row> */}

                                    {/* {showEmojiPicker && (
                                        <Picker onEmojiClick={handleEmojiSelect} />
                                    )} */}

                                    {showGif && openGifIndex === index && (
                                        <div>
                                            <ReactGiphySearchbox
                                                apiKey="9Ixlv3DWC1biJRI57RanyL7RTbfzz0o7"
                                                onSelect={(gif) => handleGifPicker(gif, index)}
                                                masonryConfig={[
                                                    { columns: 2, imageWidth: 110, gutter: 5 },
                                                    { mq: "700px", columns: 3, imageWidth: 120, gutter: 5 },
                                                ]}
                                            />
                                        </div>
                                    )}
                                </Fragment>
                            );

                        })
                    ) : (
                        props.cont?.t?.thread?.length > 0 ? (
                            props.cont?.t?.thread.map((item, index) => {
                                const lines = item.text.split("\n");
                                return (
                                    <Fragment key={index}>
                                        <div>
                                            <img
                                                src={displayProfileDetails?.image_url ? displayProfileDetails?.image_url : Ellipse}
                                                className="span-icon"
                                                style={{ width: 60 }}
                                            />
                                            <span className="span-thread" style={{ marginLeft: 20 }}>
                                                {displayProfileDetails?.name}
                                            </span>
                                        </div>
                                        <ul className="thread-ul">
                                            {lines.map((line, lineIndex) => (
                                                <li className="thread-li" key={lineIndex}>
                                                    {getTextWithoutImg(line, "[ img: ")}
                                                </li>
                                            ))}
                                            {findNextIndex(item.value, "[ img: ").map((imgind, imgIndex) => {
                                                let imgnam = item.value.substring(imgind, imgind + 13);
                                                item.value.replace(imgnam, "");

                                                return (
                                                    <li className="thread-li" key={imgIndex}>
                                                        {props.selectedImages &&
                                                            props.selectedImages.findIndex((a) => a.name === imgnam) >= 0 ? (
                                                            <img
                                                                src={
                                                                    props.selectedImages[
                                                                        props.selectedImages &&
                                                                        props.selectedImages.findIndex((a) => a.name === imgnam)
                                                                    ]?.img
                                                                }
                                                                className="image-img"
                                                            />
                                                        ) : null}
                                                    </li>
                                                );
                                            })}
                                            {findNextIndex(item.value, "[ img: ").map((imgind, gifIndex) => {
                                                let imgnam = item.value.substring(imgind, imgind + 13);
                                                item.value.replace(imgnam, "");

                                                return (
                                                    <li className="thread-li" key={gifIndex}>
                                                        {props.selectedGifpickerArr &&
                                                            props.selectedGifpickerArr.findIndex((a) => a.name === imgnam) >= 0 ? (
                                                            <img
                                                                src={
                                                                    props.selectedGifpickerArr[
                                                                        props.selectedGifpickerArr &&
                                                                        props.selectedGifpickerArr.findIndex((a) => a.name === imgnam)
                                                                    ]?.gif
                                                                }
                                                                className="image-gif"
                                                            />
                                                        ) : null}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                        <div className="thread-image-container-ui">
                                            <div> </div>
                                            <div>
                                                <label htmlFor={`threadimage-upload-${index}`}>
                                                    <input
                                                        id={`threadimage-upload-${index}`}
                                                        type="file"
                                                        accept="image/*"
                                                        style={{ display: "none" }}
                                                        onChange={(e) => handleImageUpload(index)(e)}
                                                    />
                                                    <i className="fa fa-image image-icon" />
                                                </label>
                                                <FontAwesomeIcon icon={faFilm}
                                                    onClick={() => handleGifopen(index)}

                                                />
                                            </div>
                                        </div>
                                        <Row>
                                            {threads[index]?.map((image, Imageindex) => (
                                                <Col
                                                    lg={3}
                                                    sm={3}
                                                    md={3}
                                                    key={index}

                                                >
                                                    D    <img
                                                        src={image.imgurl}
                                                        alt={`Selected ${index + 1}`}
                                                        style={{
                                                            width: "80px",
                                                            height: "80px",
                                                            marginBottom: "10px",
                                                        }}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faCircleXmark}
                                                        type="button"
                                                        onClick={() => handleRemoveImage(index, Imageindex)}
                                                        className="cross-remove-thread-icon"
                                                    />
                                                </Col>
                                            ))}


                                            {selectedGifpicker2[index] && (




                                                <Col
                                                    lg={3}
                                                    sm={3}
                                                    md={3}
                                                    key={index}

                                                >
                                                    A   <img
                                                        src={selectedGifpicker2[index]}
                                                        alt={`Selected ${index + 1}`}
                                                        style={{
                                                            width: "80px",
                                                            height: "80px",
                                                            marginBottom: "10px",
                                                        }}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faCircleXmark}
                                                        type="button"

                                                        className="cross-remove-thread-icon"
                                                    />
                                                </Col>


                                            )}



                                        </Row>
                                        {showGif && openGifIndex === index && (
                                            <div>
                                                <ReactGiphySearchbox
                                                    apiKey="9Ixlv3DWC1biJRI57RanyL7RTbfzz0o7"
                                                    onSelect={(gif) => handleGifPicker(gif, index)}
                                                    masonryConfig={[
                                                        { columns: 2, imageWidth: 110, gutter: 5 },
                                                        { mq: "700px", columns: 3, imageWidth: 120, gutter: 5 },
                                                    ]}
                                                />
                                            </div>
                                        )}
                                    </Fragment>
                                );

                            })
                        ) : (
                            <div>No threads found</div>
                        )
                    )}
                    <div className="display-buttns">
                        <button onClick={handleDeleteData} className="Post-Thread">
                            {loadingTweet ? <Spinner animation="border" variant="light" /> : (
                                <>



                                    <FontAwesomeIcon icon={faPaperPlane} /> Post Thread
                                </>
                            )}
                        </button>
                        <div style={{ display: 'flex' }}>
                            <button onClick={handleQueueThread} className="Post-Thread">
                                {loadingQueue ? <Spinner animation="border" variant="light" /> :

                                    "Add thread to queue"}<br />
                                <span className='queue-text'>
                                    {/* {props.cont && props.cont?.t?.thread && moment(props.cont?.t?.thread[0]?.time, 'hh:mm:ss').isValid()
                                    ? moment(props.cont?.t?.thread[0]?.time, 'hh:mm:ss').format('h:mm A | ')
                                    : ''} */}
                                    {moment(queueTime, 'hh:mm:ss').isValid()
                                        ? moment(queueTime, 'hh:mm:ss').format('h:mm A | ')
                                        : ''}
                                    {props.cont.formattedDate && moment(props.cont.formattedDate, 'dddd | MMMM DD YYYY').isValid()
                                        ? moment(props.cont.formattedDate, 'dddd | MMMM DD YYYY').format('MMM DD YYYY')
                                        : ''}
                                </span>

                            </button>
                            <button className='button-rightsidebar-drop' onClick={toggleDropdown}> <FontAwesomeIcon icon={faCaretDown} /></button>
                        </div>
                    </div>

                    {isOpen && (
                        <div style={{ marginTop: 20 }} className="form-value-sche">
                            <label>Schedule Later</label>
                            <div className="datetime-picker-thread">

                                <DatePicker
                                    selected={selectedDateTime}
                                    onChange={handleDateChange}
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    showTimeSelect
                                    timeFormat="h:mm aa"
                                    timeIntervals={1}
                                    timeCaption="Time"
                                    customInput={<CustomInput />}
                                    placeholderText="Select date and time"
                                    minDate={new Date()}
                                // filterDate={filterPastDates}


                                />

                                <button className="thread-schedule" onClick={handleScheduleLater}> {loadingSchdule ? (
                                    <Spinner animation="border" variant="light" />
                                ) : "Submit"}</button>
                            </div>
                        </div>
                    )}
                </Offcanvas.Body>
            </Offcanvas>
        </Fragment>
    );
});

ThreadOverLay.displayName = "ThreadOverLay";
export default ThreadOverLay;
