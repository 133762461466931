import React, { useState, useEffect, useContext, useMemo, useRef } from 'react'
import './MyDraftsstyle.css'
import { Col, Row } from 'react-bootstrap'
import moment from 'moment';
import { MySideBarContext, MyContext } from '../MyQueue/MyContext'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faRetweet, faCalendar, faUpload, faFilePen, faCommentsDollar, faTrash, faEye, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import ReactGiphySearchbox from "react-giphy-searchbox";
import SettingOffCanvas from '../setting/SettingOffCanvas';
import ThreadOverLay from '../partials/dashboard/SidebarStyle/ThreadOverLay';
import { BASE_URL } from '../../utilities/Config';
import Microlink from '@microlink/react';

export default function MyDraft() {

    const [contextData, setContextData] = useContext(MySideBarContext)
    const [context, setContext] = useContext(MyContext);
    const [DraftData, setDraftData] = useState([])
    const userID = localStorage.getItem('UserID')
    const tokenLogin = localStorage.getItem('accessToken')
    const providerToken = localStorage.getItem('providerToken')
    const [dateTimeList, setDateTimeList] = useState([])
    const [tweetText, setTweetText] = useState('')
    const emailTo = localStorage.getItem('email-to')

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);


    const [modalData, setModalData] = useState('');
    const [showthread, setShowthread] = useState(false);
    const [displayProfileDetails, setDisplayProfileDetails] = useState()


    // GIF  
    const [selectedGif, setSelectedGif] = useState(null);
    const [selectedGifpicker, setSelectedGifPicker] = useState(null);
    const [NewGif, setNewGif] = useState(null)
    const gifInputRef = useRef(null);

    const [showGif, setShowGif] = useState(false)


    const formattedDate = ''
    //images
    const [selectedImages2, setSelectedImages2] = useState([]);
    const [arrImg2, setArrImg2] = useState([])
    const imageInputRef2 = useRef(null);

    const emailSwitch = localStorage.getItem('emailSwitch')



    useEffect(() => {
        if (contextData.DraftSaved) {
            displayDraft()
        }
        console.log(contextData.DraftSaved, 'drafsaved');
    }, [contextData.DraftSaved])

    useEffect(() => {
        const fetchData = async () => {

            await displayProfile();

        };

        fetchData();
    }, [])

    const displayProfile = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;


                    const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/profile", data.data);
                    if (data.code == 200) {
                        setDisplayProfileDetails(data.data)


                    }
                    else {



                        console.log("failed")


                    }
                }
                else {
                    console.log("error in display draft")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;


                    const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/profile", data.data);
                    if (data.code == 200) {
                        setDisplayProfileDetails(data.data)


                    }
                    else {



                        console.log("failed")


                    }
                }
                else {
                    console.log("error in display draft")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };

    const handleShow = (item) => {
        // setShow(true);
        // setContext(item)
        setModalData(item);

        Array.isArray(item) && item?.findIndex(s => s.is_thread === 1) > -1
            ? (
                // alert('thread')
                setShow3(true)
            )
            : setShow(true)

        // setSelectedImages2(item.image)
        // setSelectedGifPicker(item.gif)

        // const selectedImages = item.image.map((image) => ({ img: image }));
        // setSelectedImages2(selectedImages);

        // const selectedGifpicker = item.gif.map((gif) => ({ gi: gif }));
        // setSelectedGifPicker(selectedGifpicker);

    }

    console.log("mpdalData", modalData)



    console.log("contexData-draft", contextData)
    console.log("contexData-0-0", context)
    const handleShow2 = (item) => {
        setShow2(true);
        // setContext(item)
        setModalData(item);


    }



    const handleGifopen = () => {
        setShowGif(prevInput => !prevInput);
        // setShowPicker(false);
    };

    const handleGifPicker = async (gif) => {
        handleRemoveAll()
        setSelectedGifPicker(gif.images.original.url);
        setShowGif(prevInput => !prevInput);
        try {
            const response = await fetch(gif.images.original.url);
            const blob = await response.blob();
            const file = new File([blob], 'selectedGif.gif');

            // Use the file as needed
            // ...

            console.log("gif++++", file)
            setNewGif(file)

        } catch (error) {
            console.log(error);
        }
    };

    console.log("a-new", NewGif)



    const handleRemoveImage2 = (index) => {
        const newImages = [...selectedImages2];
        const newArrImg = [...arrImg2];

        newImages.splice(index, 1); // Remove image from selectedImages2 array
        newArrImg.splice(index, 1); // Remove corresponding image file from arrImg2 array

        setSelectedImages2(newImages); // Update selectedImages2 state
        setArrImg2(newArrImg); // Update arrImg2 state

    };




    const handleRemoveAll = () => {
        setSelectedImages2([])
        setArrImg2([])
    }



    const handleRemoveGif2 = () => {
        setSelectedGifPicker(null);
        setNewGif(null)
        if (gifInputRef.current) {
            gifInputRef.current.value = '';
        }
    };


    const handleImageSelect2 = (e) => {
        handleRemoveGif2();
        const files = Array.from(e.target.files);

        const newImages = [...selectedImages2];

        if (selectedImages2.length + files.length > 5) {
            alert('Only 5 images can be uploaded at a time.');
            return;
        }

        files.forEach((file) => {
            const reader = new FileReader();

            reader.onload = () => {
                newImages.push({ img: reader.result });
                setSelectedImages2(newImages);
            };

            reader.readAsDataURL(file);
        });

        setArrImg2((a) => [...a, e.target.files[0]]);
    };

    console.log("arr[][][][][]", arrImg2)
    console.log("sekected", selectedImages2)





    // const handleDeleteAPI = (item) => {
    //     setModalData(item)
    //     console.log("item-draft", item)

    //     try {

    //         let fdata = new FormData();
    //         fdata.append('id', modalData.id ? modalData.id : modalData[0].id);
    //         fetch(`${BASE_URL}api/delete/draft`, {
    //             method: 'POST',
    //             body: fdata,
    //             headers: {
    //                 Authorization: `Bearer ${tokenLogin}`,
    //             }
    //         }).then(response => {
    //             console.log('repsonse delet', response);
    //             if (response.ok) {
    //                 console.log('Data deleted successfully.');
    //                 // window.location.reload()
    //                 displayDraft()

    //                 // setDraftData(DraftData.filter(item => item.id !== modalData.id));
    //             } else {
    //                 console.error('Failed to delete data.');

    //             }
    //             return response.json()
    //         }).then(data => {
    //             // console.log("data.delete", data)
    //             // setDraftData(DraftData.filter(item => item.id == modalData.id));
    //             // window.location.reload()

    //         })
    //         setShow2(false)
    //     } catch (error) {

    //     }


    // }


    const handleDeleteAPI = (item) => {
        setModalData(item)
        console.log("item-draft", item)
        if (localStorage.getItem("userloggedin") === 'true') {

            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;


                    let fdata = new FormData();
                    fdata.append('id', modalData.id ? modalData.id : modalData[0].id);
                    fetch(`${BASE_URL}api/delete/draft`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {
                        console.log('repsonse delet', response);
                        if (response.ok) {
                            console.log('Data deleted successfully.');
                            // window.location.reload()
                            displayDraft()

                            // setDraftData(DraftData.filter(item => item.id !== modalData.id));
                        } else {
                            console.error('Failed to delete data.');

                        }
                        return response.json()
                    }).then(data => {
                        // console.log("data.delete", data)
                        // setDraftData(DraftData.filter(item => item.id == modalData.id));
                        // window.location.reload()

                    })
                    setShow2(false)
                }
                else {
                    console.log("error in handleDeleteAPI")
                }
            } catch (error) {

            }
        }
        else {
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;


                    let fdata = new FormData();
                    fdata.append('id', modalData.id ? modalData.id : modalData[0].id);
                    fetch(`${BASE_URL}api/delete/draft`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {
                        console.log('repsonse delet', response);
                        if (response.ok) {
                            console.log('Data deleted successfully.');
                            // window.location.reload()
                            displayDraft()

                            // setDraftData(DraftData.filter(item => item.id !== modalData.id));
                        } else {
                            console.error('Failed to delete data.');

                        }
                        return response.json()
                    }).then(data => {
                        // console.log("data.delete", data)
                        // setDraftData(DraftData.filter(item => item.id == modalData.id));
                        // window.location.reload()

                    })
                    setShow2(false)
                }
                else {
                    console.log("error in handleDeleteAPI")
                }
            } catch (error) {

            }
        }

    }




    console.log("modal", DraftData)



    const handleEditAPI = () => {
        try {
            let fdata = new FormData();


            fdata.append('text', tweetText);
            arrImg2.map(a => {
                fdata.append('image[]', a);
            })
            fdata.append('provider_token', providerToken)
            fdata.append('gif[]', NewGif);
            fdata.append('id', modalData.id)

            fetch(`${BASE_URL}api/draft`, {
                method: 'POST',
                body: fdata,
                headers: {
                    Authorization: `Bearer ${tokenLogin}`,
                }
            })
                .then(response => {
                    console.log("response-draft", response)
                    return response.json()
                })
                .then(data => {
                    console.log("Drafts queue data", data)
                    if (data.code === 200) {
                        alert(JSON.stringify(data.message));
                        // window.location.reload()
                        //setDraftData(data.data.draft_tweet)
                        displayDraft()

                    } else {
                        alert(JSON.stringify(data.message));
                    }
                })
                .catch(error => {
                    console.log(error);
                })

            setShow(false)

        } catch (error) {
            console.log(error);

        }
    }

    // useEffect(async () => {
    //     await displayDraft()
    // }, [])
    useEffect(() => {
        displayDraft()

    }, [])



    // const displayDraft = async () => {
    //     try {

    //         const response = await fetch(`${BASE_URL}api/draft/list/${userID}`, {
    //             headers: {
    //                 Authorization: `Bearer ${tokenLogin}`,
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    //         const data = await response.json();
    //         console.log("published/draft", data);
    //         // setDraftData(data ? data : contextData.tweetText)
    //         setDraftData(data)
    //         setContextData({ DraftSaved: false })

    //     } catch (error) {
    //         console.log('Error fetching data:', error);
    //     }
    // };


    const displayDraft = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/draft/list/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/draft", data);
                    // setDraftData(data ? data : contextData.tweetText)
                    setDraftData(data)
                    setContextData({ DraftSaved: false })
                }
                else {
                    console.log("error in display draft")
                }
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/draft/list/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/draft", data);
                    // setDraftData(data ? data : contextData.tweetText)
                    setDraftData(data)
                    setContextData({ DraftSaved: false })
                }
                else {
                    console.log("error in display draft")
                }
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        }
    };

    console.log("contecxt-draft", context)

















    // console.log("contextData", contextData)













    const handleShowthread = (event) => {
        event.preventDefault();
        setShowthread(true);
    }
    const handleClosethread = () => setShowthread(false);



    const hanldeContextDraft = (item) => {

        localStorage.removeItem('savedValue')
        console.log("contecxt-drafttt", showthread)


        //setContext({ t: item, formattedDate })

        // Array.isArray(item) ? (

        //     <>

        //         {
        //             item.findIndex(s => s.is_thread === 1) > -1 ? (
        //                 setShowthread(true),
        //                 setContext({
        //                     ...context, t: {
        //                         ...context.t, thread: item
        //                     }
        //                 })

        //             )
        //                 : null
        //             }
        //             </>
        //             // item?.map((itt, inde) => {
        //             //     console.log("thread-text", itt)
        //             //     if (itt.is_thread == 1) {
        //             //         console.log("0-----", itt)

        //             //         // setContext({ ...item, t: { ...itt } })
        //             //         // setContext({ ...context, t: { ...context.t, ...itt } })
        //             //         // setContext({
        //             //         //     ...context,
        //             //         //     t: {
        //             //         //         ...context.t,
        //             //         //         [itt.id]: { ...itt } // add test prop
        //             //         //     }
        //             //         // })


        //             //     }
        //             //     else {
        //             //         alert("no thread")
        //             //     }
        //             // })
        //     //     }
        //     // </>

        // ) :
        //     (
        //         alert("no")
        //     )


        console.log(item, "item");
        Array.isArray(item) && item?.findIndex(s => s.is_thread === 1) > -1
            ? (
                <>
                    {/* {setShowthread(true)} */}
                    {/* {setContext({
                        ...context,
                        t: { ...context.t, thread: item, image: null, gif: null },

                    })} */}
                    {setContext({
                        ...context,
                        t: { ...context.t, thread: item, image: null, gif: null, video: '' },
                        type: "draft"

                    })}

                </>)
            : setContext({ ...context, t: { ...context.t, ...item, thread: null, }, type: "draft" })








    }


    console.log("contecxt-drafttt", context)



    const getImages = (images) => {
        return (
            <div style={{ display: "flex" }}>
                {
                    images.map((image, i) => {
                        console.log("imGE0YHREAD", image)
                        return (
                            // <Col lg={4} sm={4} md={4}>
                            <div>
                                <img src={image} style={{
                                    height: 50,
                                    width: 50,
                                    marginLeft: 5,
                                    marginBottom: 10
                                }} />
                            </div>
                            // </Col>
                        )
                    })
                }
            </div>
        )
    }
    const getGif = (gif) => {
        return (
            <div>
                {
                    gif.map((gifs, i) => {

                        return (
                            <div>
                                <img src={gifs} style={{
                                    height: 100,
                                    width: 100
                                }} />
                            </div>
                        )
                    })
                }
            </div>
        )
    }


    const modalTextWithLinks = findLinksInText(modalData.text);



    function findLinksInText(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text?.split(urlRegex);
    }

    // Function to check if a text contains any link
    function containsLink(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return urlRegex.test(text);
    }





































    // useEffect(() => {
    //     if (contextData.QUEUESaved) {
    //         // displayDraft()
    //         displayDynamicQueue()
    //     }
    //     console.log(contextData.QUEUESaved, 'Queueu SAVED');
    // }, [contextData.QUEUESaved])




    return (
        <>
            {DraftData.data && DraftData.data.length > 0 ? DraftData?.data?.map((item, index) => {

                return (
                    <>
                        <div className='myDraft-box' key={index}>


                            <Row
                            // onClick={() => hanldeContextDraft(item)}
                            //onClick={() => setContext({ t: item, formattedDate })}
                            >
                                <Col lg={4} md={4} sm={4}>
                                    {/* <div>{item.text}</div> */}
                                    {Array.isArray(item) ? (
                                        <>

                                            {item?.map((hobby, index) => {

                                                return (
                                                    <>
                                                        <p className='text-container-draft'>{hobby.text} </p>

                                                    </>
                                                )
                                            })}
                                        </>
                                    ) :
                                        <p className='text-container-draft'>{item.text} </p>
                                    }
                                </Col>
                                <Col lg={6} md={6} sm={6}>

                                    {/* <div className='image-myqueue-draft'>


                                        {Array.isArray(item) ? (
                                            <>

                                                {item?.map((hobby) => {
                                                    console.log("KKKKKKK-dragt", hobby)
                                                    return (
                                                        <div>
                                                            {
                                                                getImages(hobby.image)
                                                            }
                                                        </div>
                                                    )



                                                })}
                                            </>
                                        ) : (

                                            item?.image?.map((i, ind) => {
                                                console.log("image iiii", i);
                                                return (
                                                    <div className='image-container' key={ind}>
                                                        <img src={i} className='draft-schdule-image' />
                                                    </div>
                                                );
                                            })

                                        )



                                        }

                                    </div>

                                    <div className='image-myqueue-draft'>


                                        {Array.isArray(item) ? (
                                            <>

                                                {item?.map((hobby) => {
                                                    console.log("KKKKKKK-dragt", hobby)
                                                    return (
                                                        <div>
                                                            {
                                                                getGif(hobby.gif)
                                                            }
                                                        </div>
                                                    )



                                                })}
                                            </>
                                        ) : (

                                            item?.gif?.map((i, ind) => {
                                                console.log("image iiii", i);
                                                return (
                                                    <div className='image-container' key={ind}>
                                                        <img src={i} className='draft-schdule-image' />
                                                    </div>
                                                );
                                            })

                                        )



                                        }

                                    </div>
 */}


                                </Col>
                                <Col lg={2} md={2} sm={2}>
                                    <div className='draft-icon'>
                                        <FontAwesomeIcon icon={faEye}
                                            style={{ marginRight: 10 }}
                                            onClick={() => handleShow(item)}
                                        />


                                        <FontAwesomeIcon icon={faTrash}
                                            style={{ marginRight: 10 }}
                                            onClick={() => handleShow2(item)}
                                        />

                                        <FontAwesomeIcon
                                            icon={faPenToSquare}
                                            onClick={() => hanldeContextDraft(item)} />

                                    </div>

                                </Col>
                            </Row>

                        </div>


                    </>
                )
            }) :

                <div className='text-center'>
                    <span style={{ color: 'black' }}> {DraftData.message}</span>
                </div>
            }



            {/* {DraftData?.data && DraftData.data?.length > 0 ? DraftData.data?.map((item, index) => {

                return (
                    <>
                        <div className='myDraft-box' key={index}>


                            <Row
                                onClick={() => setContext({ t: item, formattedDate })}
                            >
                                <Col lg={4} md={4} sm={4}>
                                    <div>{item.text}</div>
                                </Col>
                                <Col lg={6} md={6} sm={6}>
                                    <div className='image-myqueue'>
                                        {item.gif.map((i, ind) => {
                                            console.log("image iiii", i);
                                            return (
                                                <div className='image-container' key={ind}>
                                                    <img src={i} className='draft-schdule-image' />
                                                </div>
                                            );
                                        })}
                                    </div>

                                    <div className='image-myqueue'>
                                        {item.image.map((i, ind) => {
                                            console.log("image iiii", i);
                                            return (
                                                <div className='image-container' key={ind}>
                                                    <img src={i} className='draft-schdule-image' />
                                                </div>
                                            );
                                        })}
                                    </div>


                                </Col>
                                <Col lg={2} md={2} sm={2}>
                                    <div className='draft-icon'>
                                        <FontAwesomeIcon icon={faEye}
                                            style={{ marginRight: 10 }}
                                            onClick={() => handleShow(item)}
                                        />
                                      

                                        <FontAwesomeIcon icon={faTrash}
                                            style={{}}
                                            onClick={() => handleShow2(item)}
                                        />
                                    </div>
                                   
                                </Col>
                            </Row>

                        </div>


                    </>
                )
            }) :

                <div className='text-center'>
                    <span style={{ color: 'black' }}> No drafted tweets available</span>
                </div>
            } */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Preview Draft</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className='modal-confirm-data'>


                    </div>

                    <p className='modal-text'>


                        {/* {modalData.text} */}

                        {modalTextWithLinks?.map((segment, index) => {
                            return containsLink(segment) ? (
                                // If it's a link, render it as a link
                                <a key={index} href={segment} target="_blank" rel="noopener noreferrer">


                                    <Microlink url={segment}>
                                        {({ data, loading }) => {
                                            if (loading) return <p>Loading...</p>;
                                            if (data) {
                                                // Handle extracted data
                                                console.log(data);
                                                return (
                                                    <div>
                                                        <h1>{data.title}</h1>
                                                        <p>{data.description}</p>
                                                        {/* Add more components to display other extracted data */}
                                                    </div>
                                                );
                                            }
                                            return null;
                                        }}
                                    </Microlink>
                                </a>
                            ) : (
                                // If it's text, render it as text
                                <span key={index}>
                                    {segment}{' '}
                                </span>
                            );
                        })}
                    </p>

                    <div className='image-myqueue text-center'>
                        {modalData.gif?.map((i, ind) => {
                            console.log("image iiii", i);
                            return (
                                <div className='image-container' key={ind}>
                                    <img src={i} className='queue-schdule-image' />
                                </div>
                            );
                        })}
                    </div>

                    <div className='image-myqueue text-center'>
                        {modalData.image?.map((i, ind) => {
                            console.log("image iiii", i);
                            return (
                                <div className='image-container' key={ind}>
                                    <img src={i} className='queue-schdule-image' />
                                </div>
                            );
                        })}
                    </div>

                    <div className='image-myqueue text-center'>


                        {modalData?.video ? (

                            <video width="100%" height="240"
                                controls
                                src={modalData?.video}>
                            </video>

                        ) : ""}


                    </div>


                </Modal.Body>

            </Modal>


            <Modal show={show2} onHide={handleClose2}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Draft</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='modal-confirm-data'>
                        <p>Are you sure you want to delete this Draft?</p>

                    </div>

                    <p className='modal-text'>


                        {modalData.text}
                    </p>

                    <div className='image-myqueue text-center'>
                        {modalData.gif?.map((i, ind) => {
                            console.log("image iiii", i);
                            return (
                                <div className='image-container' key={ind}>
                                    <img src={i} className='queue-schdule-image' />
                                </div>
                            );
                        })}
                    </div>

                    <div className='image-myqueue text-center'>
                        {modalData.image?.map((i, ind) => {
                            console.log("image iiii", i);
                            return (
                                <div className='image-container' key={ind}>
                                    <img src={i} className='queue-schdule-image' />
                                </div>
                            );
                        })}
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose2}>
                        No
                    </Button>
                    <Button variant="primary" onClick={() => handleDeleteAPI(modalData)}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={show3} onHide={handleClose3}>
                <Modal.Header closeButton>
                    <Modal.Title>Thread Draft</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    {Array.isArray(modalData) ? (
                        <>

                            {modalData?.map((hobby, index) => {
                                console.log("hobbry", hobby)
                                const lines = hobby?.text?.split("\r\n");

                                const linesWithLinks = lines?.map((line, lineIndex) => {
                                    const linksInLine = findLinksInText(line);

                                    if (linksInLine.length > 0) {
                                        // If links are found, apply a different style to the link
                                        return (
                                            <li className="thread-li" key={lineIndex}>
                                                {linksInLine.map((link, linkIndex) => (
                                                    <span key={linkIndex} style={{ color: 'dodgerblue' }}>
                                                        {/* {link}{' '} */}

                                                        <Microlink url={link}>
                                                            {({ data, loading }) => {
                                                                if (loading) return <p>Loading...</p>;
                                                                if (data) {
                                                                    // Handle extracted data
                                                                    console.log(data);
                                                                    return (
                                                                        <div>
                                                                            <h1>{data.title}</h1>
                                                                            <p>{data.description}</p>
                                                                            {/* Add more components to display other extracted data */}
                                                                        </div>
                                                                    );
                                                                }
                                                                return null;
                                                            }}
                                                        </Microlink>

                                                    </span>
                                                ))}
                                            </li>
                                        );
                                    }

                                    // If no links, render the line as usual
                                    return (
                                        <li className="thread-li" key={lineIndex}>
                                            {line}
                                        </li>
                                    );
                                });

                                // Function to find links in a text
                                function findLinksInText(text) {
                                    const urlRegex = /(https?:\/\/[^\s]+)/g;
                                    return text.match(urlRegex) || [];
                                }

                                return (
                                    <>
                                        <div>
                                            <img
                                                src={displayProfileDetails?.user.image_url ? displayProfileDetails?.user.image_url : ''}

                                                className="span-icon"
                                                style={{ width: 60 }}
                                            />
                                            <span className="span-thread" style={{ marginLeft: 20 }}>
                                                {displayProfileDetails?.user.name}

                                            </span>
                                        </div>



                                        <ul className="thread-ul">

                                            {/* {lines && lines?.map((line, lineIndex) => (

                                                <li className="thread-li" key={lineIndex}>

                                                    {line}

                                                </li>



                                            ))} */}

                                            {linesWithLinks && linesWithLinks?.map((line, lineIndex) => (

                                                <li className="thread-li" key={lineIndex} >


                                                    {line}


                                                </li>


                                            ))}


                                            <Row>
                                                {
                                                    hobby?.image?.map((item, index) => {
                                                        return (
                                                            <>
                                                                {/* <Col lg={4} md={4} sm={4}> */}


                                                                <img src={item} className='thread-preview-image' />


                                                                {/* </Col> */}
                                                            </>
                                                        )
                                                    })
                                                }
                                            </Row>

                                            {
                                                hobby?.gif?.map((item, index) => {
                                                    return (
                                                        <>
                                                            <li>
                                                                <img src={item} className='thread-preview-image' />

                                                            </li>
                                                        </>
                                                    )
                                                })
                                            }
                                            {
                                                Array.isArray(hobby) && hobby?.gif?.map((item, index) => {
                                                    console.log("gifffffmydrat", item)
                                                    return (
                                                        <li key={index}>
                                                            <img src={item} className='thread-preview-image' alt={`Image ${index}`} />
                                                        </li>
                                                    );
                                                })
                                            }
                                            {hobby?.video ? (
                                                <video width="100%" height="240"
                                                    controls
                                                    src={hobby?.video}>


                                                </video>
                                            ) : ""}

                                        </ul>


                                    </>
                                )
                            })}
                        </>
                    ) :
                        "no thread to show"
                    }


                    <p className='modal-text'>


                        {modalData.text}
                    </p>

                    <div className='image-myqueue text-center'>
                        {modalData.gif?.map((i, ind) => {
                            console.log("image iiii", i);
                            return (
                                <div className='image-container' key={ind}>
                                    <img src={i} className='queue-schdule-image' />
                                </div>
                            );
                        })}
                    </div>

                    <div className='image-myqueue text-center'>
                        {modalData.image?.map((i, ind) => {
                            console.log("image iiii", i);
                            return (
                                <div className='image-container' key={ind}>
                                    <img src={i} className='queue-schdule-image' />
                                </div>
                            );
                        })}
                    </div>


                </Modal.Body>

            </Modal>
        </>
    )
} 