import React, { useEffect, useState, useRef, useContext, useMemo, } from 'react'
import { Container, Row, Col, Alert, Card } from 'react-bootstrap'
import './analyticsStyle.css'
import ApexCharts from 'apexcharts'
import ReactApexChart from 'react-apexcharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { faSearch, faUpRightFromSquare, faStar, faPaperPlane, faQuoteLeft, faQuoteRight, faCircleInfo, faCalendar, faBedPulse, faEyeSlash, faLocationDot, faHeart, faRetweet, faLink } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import * as moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';

import Ellipse from "../../../../assets/images/Ellipse.png";
import noimage from '../../../../assets/noimage.png'

// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { MyContext, MySideBarContext } from '../../../../components/MyQueue/MyContext';
import { BASE_URL } from '../../../../utilities/Config';




export default function Analytics() {
    const userID = localStorage.getItem('UserID')
    const tokenLogin = localStorage.getItem('accessToken')
    const providerToken = localStorage.getItem('providerToken')
    const navigate = useNavigate()
    const [context, setContext] = useContext(MyContext);

    const [displayEngagement, setDisplayEngagement] = useState([])

    const [displayAImessage, setDisplayAImessage] = useState(null)
    const [selectedValue, setSelectedValue] = useState(7);
    const [selectedDate, setSelectedDate] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [selectedOption, setSelectedOption] = useState('last seven days');
    const [selctedDataOption, setSelectedDataOption] = useState([])
    const timeZone = localStorage.getItem('TimeZone')

    const emailTo = localStorage.getItem('email-to')
    const emailSwitch = localStorage.getItem('emailSwitch')
    const [TimeZoneV, setTimeZoneV] = useState('')


    useEffect(() => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    setLoading(true)
                    let fdata = new FormData();

                    fdata.append('id', userID);
                    fdata.append('days', 'last seven days');

                    fetch(`${BASE_URL}api/daily/follower/gain`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response follwe gain", response)
                        return response.json();
                    }).then(data => {
                        console.log("follwe gain data", data);
                        if (data.code == 200) {
                            // toast.success(data.message, {
                            //     position: "top-center",
                            //     autoClose: 5000,
                            //     hideProgressBar: false,
                            //     closeOnClick: true,
                            //     pauseOnHover: true,
                            //     draggable: true,
                            //     progress: undefined,
                            //     theme: "light",
                            // });
                            setSelectedDataOption(data)

                        }
                        else {

                            // toast.error(data.message, {
                            //     position: "top-center",
                            //     autoClose: 5000,
                            //     hideProgressBar: false,
                            //     closeOnClick: true,
                            //     pauseOnHover: true,
                            //     draggable: true,
                            //     progress: undefined,
                            //     theme: "light",
                            // });

                        }
                        setLoading(false)

                    })
                }
                else {
                    console.log("console error is invalid")
                }



            } catch (error) {
                console.log(error)
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    setLoading(true)
                    let fdata = new FormData();

                    fdata.append('id', userID);
                    fdata.append('days', 'last seven days');

                    fetch(`${BASE_URL}api/daily/follower/gain`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response follwe gain", response)
                        return response.json();
                    }).then(data => {
                        console.log("follwe gain data", data);
                        if (data.code == 200) {
                            // toast.success(data.message, {
                            //     position: "top-center",
                            //     autoClose: 5000,
                            //     hideProgressBar: false,
                            //     closeOnClick: true,
                            //     pauseOnHover: true,
                            //     draggable: true,
                            //     progress: undefined,
                            //     theme: "light",
                            // });
                            setSelectedDataOption(data)

                        }
                        else {

                            // toast.error(data.message, {
                            //     position: "top-center",
                            //     autoClose: 5000,
                            //     hideProgressBar: false,
                            //     closeOnClick: true,
                            //     pauseOnHover: true,
                            //     draggable: true,
                            //     progress: undefined,
                            //     theme: "light",
                            // });

                        }
                        setLoading(false)

                    })
                }
                else {
                    console.log("console error is invalid")
                }



            } catch (error) {
                console.log(error)
            }
        }

        AutoButtonClick()
    }, [])

    const AutoButtonClick = () => {

        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    setLoading2(true)
                    let fdata = new FormData();

                    fdata.append('id', userID);
                    fdata.append('days', 7);
                    fdata.append('date', '');

                    fetch(`${BASE_URL}api/tweet/engagement`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response engagement", response)
                        return response.json();
                    }).then(data => {
                        console.log("engament data", data);
                        if (data.code == 200) {

                            setDisplayEngagement(data.data)


                        }


                        else {

                        }
                        setLoading2(false)

                    })
                }
                else {
                    console.log("error")
                }



            } catch (error) {
                console.log(error)
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    setLoading2(true)
                    let fdata = new FormData();

                    fdata.append('id', userID);
                    fdata.append('days', 7);
                    fdata.append('date', '');

                    fetch(`${BASE_URL}api/tweet/engagement`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response engagement", response)
                        return response.json();
                    }).then(data => {
                        console.log("engament data", data);
                        if (data.code == 200) {

                            setDisplayEngagement(data.data)


                        }


                        else {

                        }
                        setLoading2(false)

                    })
                }
                else {
                    console.log("error")
                }



            } catch (error) {
                console.log(error)
            }
        }



    };

    const AutoButtonOption = (event) => {

        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);

        try {
            setLoading(true)
            let fdata = new FormData();

            fdata.append('id', userID);
            fdata.append('days', 'last seven days');

            fetch(`${BASE_URL}api/daily/follower/gain`, {
                method: 'POST',
                body: fdata,
                headers: {
                    Authorization: `Bearer ${tokenLogin}`,
                }
            }).then(response => {

                console.log("response follwe gain", response)
                return response.json();
            }).then(data => {
                console.log("follwe gain data", data);
                if (data.code == 200) {
                    toast.success(data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setSelectedDataOption(data)

                }
                else {

                    toast.error(data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                }
                setLoading(false)

            })



        } catch (error) {
            console.log(error)
        }
    };

    const handleOptionChange = (event) => {

        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    setLoading(true)
                    let fdata = new FormData();

                    fdata.append('id', userID);
                    fdata.append('days', selectedValue);

                    fetch(`${BASE_URL}api/daily/follower/gain`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response follwe gain", response)
                        return response.json();
                    }).then(data => {
                        console.log("follwe gain data", data);
                        if (data.code == 200) {
                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            setSelectedDataOption(data)

                        }
                        else {

                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });

                        }
                        setLoading(false)

                    })
                }
                else {
                    console.log("asxjksajcs cdscs")
                }



            } catch (error) {
                console.log(error)
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    setLoading(true)
                    let fdata = new FormData();

                    fdata.append('id', userID);
                    fdata.append('days', selectedValue);

                    fetch(`${BASE_URL}api/daily/follower/gain`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response follwe gain", response)
                        return response.json();
                    }).then(data => {
                        console.log("follwe gain data", data);
                        if (data.code == 200) {
                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            setSelectedDataOption(data)

                        }
                        else {

                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });

                        }
                        setLoading(false)

                    })
                }
                else {
                    console.log("asxjksajcs cdscs")
                }



            } catch (error) {
                console.log(error)
            }
        }

    };


    const chartData = [
        {
            name: 'followers',
            // data: [30, 40, 35, 50, 49, 60, 70, 91, 125, 140, 160, 220]
            data: selctedDataOption?.data?.data ? selctedDataOption?.data?.data?.map(item2 => item2.total) : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },

    ];

    const getXAxisCategories = (option) => {
        // Logic to generate the appropriate x-axis categories based on the selected option
        if (option === 'this month') {
            // return ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'];

            selctedDataOption?.data?.data?.map((item, index) => {
                return (
                    <>
                        <p>dcd </p>
                    </>
                )


            })
        } else if (option === 'lastMonth') {


        }

        return [];
    };

    const chartOptions = {
        chart: {
            id: 'gradientLine',
            toolbar: {
                show: false
            }
        },
        stroke: {
            width: 3,
            curve: 'smooth'
        },

        xaxis: {

            categories: selctedDataOption?.data?.data ? selctedDataOption?.data?.data?.map(item => item.date ? moment(item.date, 'YYYY-MM-DD').format('DD MMM') : item.month) : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], // Assuming date is the key in each data object



        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: 'vertical',
                shadeIntensity: 0.5,
                opacityFrom: 0.7,
                opacityTo: 0.3,
                stops: [0, 100]
            }
        }
    };


    useEffect(() => {

        displayProfile()
    }, [])



    const displayProfile = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/profileeeee", data.data);
                    if (data.code == 200) {
                        setDisplayAImessage(data.data)
                        setTimeZoneV(data.data.user.timezone)
                        if (data?.data?.message_for_user_subscription == '' || data?.data?.message_for_user_subscription == null || data?.data?.message_for_user_subscription == undefined) {
                            // alert("subscribed")
                        }
                        else {
                            navigate('/app/payments')
                        }



                    }
                    else {
                        console.log("failed")
                    }
                }
                else {
                    console.log("scjhbshbcjs cnsjhcsa")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/profileeeee", data.data);
                    if (data.code == 200) {
                        setDisplayAImessage(data.data)
                        setTimeZoneV(data.data.user.timezone)
                        if (data?.data?.message_for_user_subscription == '' || data?.data?.message_for_user_subscription == null || data?.data?.message_for_user_subscription == undefined) {
                            // alert("subscribed")
                        }
                        else {
                            navigate('/app/payments')
                        }



                    }
                    else {
                        console.log("failed")
                    }
                }
                else {
                    console.log("scjhbshbcjs cnsjhcsa")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };


    const handleNavigateAISettings = () => {

        navigate('/dashboard/app/settings')
    }




    // Event handler to update the state with the selected value
    const handleButtonClick = (value) => {
        setSelectedValue(value);
        setSelectedDate('')
        if (localStorage.getItem("userloggedin") === 'true') {

            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    setLoading(true)
                    let fdata = new FormData();

                    fdata.append('id', userID);
                    fdata.append('days', value);
                    fdata.append('date', '');

                    fetch(`${BASE_URL}api/tweet/engagement`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response engagement", response)
                        return response.json();
                    }).then(data => {
                        console.log("engament data", data);
                        if (data.code == 200) {
                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            setDisplayEngagement(data.data)


                        }


                        else {

                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            setDisplayEngagement([])

                        }
                        setLoading(false)

                    })
                }
                else {
                    console.log("sjhbcsbhjcs dncdhjs")
                }



            } catch (error) {
                console.log(error)
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    setLoading(true)
                    let fdata = new FormData();

                    fdata.append('id', userID);
                    fdata.append('days', value);
                    fdata.append('date', '');

                    fetch(`${BASE_URL}api/tweet/engagement`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response engagement", response)
                        return response.json();
                    }).then(data => {
                        console.log("engament data", data);
                        if (data.code == 200) {
                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            setDisplayEngagement(data.data)


                        }


                        else {

                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            setDisplayEngagement([])

                        }
                        setLoading(false)

                    })
                }
                else {
                    console.log("sjhbcsbhjcs dncdhjs")
                }



            } catch (error) {
                console.log(error)
            }
        }



    };




    const handleDateChange = (date) => {
        setSelectedDate(date);
        setSelectedValue('');
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    let fdata = new FormData();

                    fdata.append('id', userID);
                    fdata.append('days', '');
                    fdata.append('date', moment(date).format('DD-MM-YYYY'));

                    fetch(`${BASE_URL}api/tweet/engagement`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response engagement", response)
                        return response.json();
                    }).then(data => {
                        console.log("engament data", data);
                        if (data.code == 200) {
                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            setDisplayEngagement(data.data)


                        }


                        else {

                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }

                    })
                }
                else {
                    console.log("cdjhbdsc dscdschsdc2 2 2 2")
                }



            } catch (error) {
                console.log(error)
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    let fdata = new FormData();

                    fdata.append('id', userID);
                    fdata.append('days', '');
                    fdata.append('date', moment(date).format('DD-MM-YYYY'));

                    fetch(`${BASE_URL}api/tweet/engagement`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response engagement", response)
                        return response.json();
                    }).then(data => {
                        console.log("engament data", data);
                        if (data.code == 200) {
                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            setDisplayEngagement(data.data)


                        }


                        else {

                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }

                    })
                }
                else {
                    console.log("cdjhbdsc dscdschsdc2 2 2 2")
                }



            } catch (error) {
                console.log(error)
            }
        }
    };




    const handleExport = () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    let fdata = new FormData();

                    fdata.append('id', userID);
                    fdata.append('days', selectedValue ? selectedValue : '');
                    fdata.append('date', selectedDate ? moment(selectedDate).format('DD-MM-YYYY') : '');

                    fetch(`${BASE_URL}api/export`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response export", response)
                        return response.json();
                    }).then(data => {
                        console.log("export data", data);
                        if (data.code == 200) {
                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            window.open(data.data.file_url, '_blank');

                        }

                        else {

                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }

                    })
                }
                else {
                    console.log("dscjbdscs dcdhscs")
                }



            } catch (error) {
                console.log(error)
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    let fdata = new FormData();

                    fdata.append('id', userID);
                    fdata.append('days', selectedValue ? selectedValue : '');
                    fdata.append('date', selectedDate ? moment(selectedDate).format('DD-MM-YYYY') : '');

                    fetch(`${BASE_URL}api/export`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response export", response)
                        return response.json();
                    }).then(data => {
                        console.log("export data", data);
                        if (data.code == 200) {
                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            window.open(data.data.file_url, '_blank');

                        }

                        else {

                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }

                    })
                }
                else {
                    console.log("dscjbdscs dcdhscs")
                }



            } catch (error) {
                console.log(error)
            }
        }
    }

    console.log("selctedate", selectedValue, selectedDate)



    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })

        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }


    const exportToCsv = e => {
        e.preventDefault()

        // Headers for each column
        let headers = ['ID,DATE,TEXT & LABELS,URL,IMPRESSIONS,ENGAGEMENT,ENGAGEMENT%,LIKES,REPLIES,RETWEETS,QUOTE,']


        // Convert users data to a csv
        let usersCsv = displayEngagement?.detailed_performance?.reduce((acc, user) => {
            const { tweet_id, tweet_date, tweet_text, tweet_url, impression_count, min_engagement_count, min_engagement_rate, like_count, reply_count, retweet_count, quote_count } = user
            const cleanedString = tweet_text.replace(/\r\n|\r|\n/g, '').replace(/,{2,}/g, '');

            acc.push([tweet_id, tweet_date, cleanedString, tweet_url, impression_count, min_engagement_count, min_engagement_rate, like_count, reply_count, retweet_count, quote_count].join(','))
            return acc
        }, [])


        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'users.csv',
            fileType: 'text/csv',
        })
    }



    const handleURL = async (dataa, index) => {
        console.log("jbscvjhas", dataa)
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/visit/count/${dataa}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/count", data);
                    if (data.code == 200) {
                        window.open(data.data.profile_url, '_blank')
                    }
                }
                else {
                    console.log("asxhjshdc scuyshcesa")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/visit/count/${dataa}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/count", data);
                    if (data.code == 200) {
                        window.open(data.data.profile_url, '_blank')
                    }
                }
                else {
                    console.log("asxhjshdc scuyshcesa")
                }




            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }

    }










































    const [contextData, setContextData] = useContext(MySideBarContext)
    console.log("contextData", contextData)



    const currentDat = new Date();
    const initialDate = currentDat.toLocaleDateString('en-GB');
    const startDate = moment(initialDate, 'DD-MM-YYYY');
    const [currentMonth, setCurrentMonth] = useState(startDate.month());
    const year = startDate.year();
    // const [selectedDate, setSelectedDate] = useState(null);
    const [modalText, setModalText] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [sectionTexts, setSectionTexts] = useState([]);
    const [time, setTime] = useState([
        '2:30 PM', '4:23 AM', '5:34 PM'
    ])
    const [dateTimeList, setDateTimeList] = useState([])


    const [dynamictime, setDynamicTime] = useState([])
    const [dynamicQueue, setDynamicQueue] = useState([])

















    useEffect(() => {

        const fetchData = async () => {
            await displayDynamicQueue();
            await myQueuueTimeSlotDynanmic();
        };

        fetchData();

    }, [])




    const myQueuueTimeSlotDynanmic = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/get/timeslot/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/tweet/list", data.data.timeslot);
                    setDynamicTime(data.data.timeslot)

                }
                else {
                    console.log("eroro in myQueuueTimeSlotDynanmic ")
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/get/timeslot/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/tweet/list", data.data.timeslot);
                    setDynamicTime(data.data.timeslot)

                }
                else {
                    console.log("eroro in myQueuueTimeSlotDynanmic ")
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };

    const displayDynamicQueue = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/queue/list/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/queue", data);
                    if (data.code == 200) {
                        setDynamicQueue(data.data)
                        setContextData({ QUEUESaved: false })

                    }

                }
                else {
                    console.log("error in dynamic queue")
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/queue/list/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/queue", data);
                    if (data.code == 200) {
                        setDynamicQueue(data.data)
                        setContextData({ QUEUESaved: false })

                    }

                }
                else {
                    console.log("error in dynamic queue")
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };





    //THIS IS THE ONE ---------
    useMemo(() => {
        let timeIndex = -1;
        let availableSlot = null;

        if (contextData?.handleDate) {
            let dateFilter = dateTimeList.filter(obj => moment(obj.formattedDate, 'dddd | MMMM D, YYYY').format('dddd') === moment(contextData?.handleDate, 'dddd | MMMM D, YYYY').format('dddd'))

            dateFilter.map(el => {

                timeIndex = el.time.findIndex(obj => obj.time === contextData?.handleTime)

                if (el && el.time[timeIndex]) {
                    el.time[timeIndex].text = contextData?.tweetText
                    el.time[timeIndex].image = contextData?.selectedImages
                    el.time[timeIndex].gif = contextData?.selectedGifpicker
                }

            }

            )
            setDateTimeList([...dateTimeList])

            let list = dateTimeList;

            for (let i = 0; i < list.length; i++) {
                const currentTime = moment();
                const currentDate = moment(list[i].formattedDate, 'dddd | MMMM D, YYYY h:mm A').format('DD/MM/YYYY')
                let blankTextInd = list[i].time.findIndex(obj => obj.text === '');
                if (blankTextInd > -1) {
                    if (list[i] && list[i].time && list[i].time[blankTextInd]?.time && moment(currentDate + " " + list[i].time[blankTextInd]?.time, 'DD/MM/YYYY h:mm A').isSameOrAfter(currentTime)) {

                        const timeFirst = list[i].time[blankTextInd]

                        setContext({ t: timeFirst, formattedDate: list[i].formattedDate })
                        break;
                    }
                }


            }
        } else {
            dateTimeList.some((el) => {

                const timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
                if (timeIndex !== -1) {
                    el.time[timeIndex].text = contextData?.tweetText;
                    el.time[timeIndex].image = contextData?.selectedImages;
                    el.time[timeIndex].gif = contextData?.selectedGifpicker;

                    return true; // Stop iterating after updating the first available slot


                }
                return false;
            });



        }


    }, [contextData])




    useEffect(() => {
        const updateCurrentMonth = () => {
            const newMonth = moment().month();
            if (newMonth !== currentMonth) {
                setCurrentMonth(newMonth);
            }
        };

        const intervalId = setInterval(updateCurrentMonth, 60000); // Check every minute for date update

        return () => {
            clearInterval(intervalId); // Clear interval on component unmount
        };
    }, [currentMonth]);

    useEffect(() => {


        let list = []
        let currentDate = moment(startDate); // Create a moment object for currentDate
        const endMonth = currentMonth + 2; // Display three months at a time
        const sectionKey = currentDate.format('YYYY-MM-DD');
        const sectionText = sectionTexts[sectionKey] || '';




        while (currentDate.year() === year && currentDate.month() <= endMonth) {


            // const slotForDays = dynamictime.filter(el => el.weekdays.includes(currentDate.format('dddd')))
            const slotForDays = (dynamictime ?? []).filter(el => el.weekdays.includes(currentDate.format('dddd')));



            let timeslotArr = slotForDays?.map(a => {


                let currentDate2 = new Date(); // use the current date
                let inputTime = a.timeslot; // replace this with the time you have

                // Parse the input time and set it to the current date
                let inputTimeParts = inputTime?.split(":");
                if (inputTimeParts) {

                    currentDate2.setHours(parseInt(inputTimeParts[0], 10));
                    currentDate2.setMinutes(parseInt(inputTimeParts[1], 10));

                    if (inputTimeParts.length === 3) {
                        currentDate2.setSeconds(parseInt(inputTimeParts[2], 10));
                    }

                } else {
                    // Handle the case where inputTime is not in the expected format
                    console.log('Input time is not in the expected format');
                }


                // Convert the time to the America/New_York time zone
                // let newYorkTime = currentDate2.toLocaleString("en-US", { timeZone: TimeZoneV ? TimeZoneV : timeZone, hour12: false, hour: "numeric", minute: "numeric", second: "numeric" });

                let newYorkTime;

                if (TimeZoneV || timeZone) {
                    newYorkTime = currentDate2.toLocaleString("en-US", {
                        timeZone: TimeZoneV || timeZone,
                        hour12: false,
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric"
                    });
                } else {
                    newYorkTime = a.timeslot
                }

                console.log(a.timeslot, "aaaaaa New York time: " + newYorkTime);


                const ATime = newYorkTime

                let textValue = ""
                let GivValue = ""
                let ImageValue = ""
                let id = ""
                let is_thread = ""
                if (dynamicQueue && dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime) > -1
                    && dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].text
                ) {
                    id = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].id
                    textValue = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].text

                    GivValue = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].gif

                    ImageValue = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].image



                    return {
                        id: id, time: ATime, text: textValue, date: "", image: ImageValue ? ImageValue : '', gif: GivValue ? GivValue : ''
                    }
                }

                else if (dynamicQueue && dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime) > -1
                    && dynamicQueue[dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)][0].text
                ) {
                    let dynamicArrayIndex = dynamicQueue && dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)
                    let dynamicArray = dynamicQueue[dynamicArrayIndex].map(element => {
                        id = element.id
                        textValue = element.text

                        GivValue = element.gif

                        ImageValue = element.image
                        is_thread = dynamicQueue[dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)][0].is_thread

                        return {
                            id: id, time: ATime, text: textValue, date: "", image: ImageValue ? ImageValue : '', gif: GivValue ? GivValue : '', is_thread: is_thread
                        }

                    })
                    return dynamicArray;




                }
                else {
                    return {
                        id: id, time: ATime, text: textValue, date: "", image: ImageValue ? ImageValue : '', gif: GivValue ? GivValue : ''
                    }
                }


            }
            )

            const formattedDate = currentDate.format('dddd | MMMM D, YYYY');
            list.push({
                formattedDate: formattedDate,
                time: timeslotArr,
                sectionKey: sectionKey
            })
            currentDate.add(1, 'day'); // Move to the next day

        }

        setDateTimeList([...list])


        for (let i = 0; i < list.length; i++) {
            const currentTime = moment();
            const currentDate = moment(list[i].formattedDate, 'dddd | MMMM D, YYYY HH:mm').format('DD/MM/YYYY')
            let blankTextInd = list[i].time.findIndex(obj => obj?.text === '' &&
                moment(currentDate + " " + obj?.time, 'DD/MM/YYYY HH:mm').isSameOrAfter(currentTime)
            );
            console.log("listFor", list[i], i, blankTextInd)
            if (blankTextInd > -1) {
                console.log("listFor, !time,,,", list[i].time[blankTextInd]?.time, currentDate, currentTime)
                // if (list[i] && list[i].time && list[i].time[blankTextInd]?.time &&
                //     moment(currentDate + " " + list[i].time[blankTextInd]?.time, 'DD/MM/YYYY HH:mm').isSameOrAfter(currentTime)) {
                console.log('future time', list[i].formattedDate);
                const timeFirst = list[i].time[blankTextInd]
                console.log("listFor, !st,,,", timeFirst, list[i], blankTextInd, i)
                setContext({ t: timeFirst, formattedDate: list[i].formattedDate })
                break;
                // }
            }
            console.log(",,,", i, currentDate, currentTime);
        }








    }, [dynamictime])

    console.log(dateTimeList, 'dateTimeList');






    const tooltipContent = (
        <Popover style={{ width: '23%' }}>
            <Popover.Header as="h3" className='popover-header-dailyins'>
                <div className="DailyIns-popover-header">
                    <div className='DailyIns-popover-image'>
                        <img src={displayEngagement?.user_data?.most_reached_tweet?.user_profile_image_url ? displayEngagement?.user_data?.most_reached_tweet?.user_profile_image_url : noimage} className="popover-img-fluid" alt="" />
                    </div>
                    <div className='popver-paragrapgh'>
                        <h6>{displayEngagement?.user_data?.most_reached_tweet?.user_name}</h6>

                        <span>

                            @{displayEngagement?.user_data?.most_reached_tweet?.user_screen_name}



                        </span>
                    </div>
                </div>
            </Popover.Header>
            <Popover.Body className='popover-body-dailyIns'>
                {displayEngagement?.user_data?.most_reached_tweet?.user_profile?.description}
                <div className='popover-followers'>
                    <span> <strong>{displayEngagement?.user_data?.most_reached_tweet?.user_followings_count}</strong> Following</span>
                    <span> <strong>{displayEngagement?.user_data?.most_reached_tweet?.user_followers_count}</strong> Followers</span>
                </div>
                <div className='popover-followers'>


                    <button className='popver-link-button' onClick={() => handleURL(displayEngagement?.user_data?.most_reached_tweet?.tweet_id)}> <FontAwesomeIcon icon={faLink} /> Visit Profile</button>



                </div>
            </Popover.Body>
        </Popover>
    );



    console.log("header-der", loading)

    return (
        <div id='content-page' className='content-page' style={{ backgroundColor: 'white' }}>


            {(displayAImessage?.message_for_AI_Settings !== ''
                && displayAImessage?.message_for_AI_Settings !== undefined
            ) ||
                (displayAImessage?.message_for_user_subscription !== '' &&
                    displayAImessage?.message_for_user_subscription !== undefined) ?

                <Alert variant="danger" className='alert-text'>
                    <FontAwesomeIcon icon={faCircleInfo} className='alert-icon-1' />
                    {displayAImessage?.message_for_AI_Settings || displayAImessage?.message_for_user_subscription}. <span className='span-alert' onClick={handleNavigateAISettings}>
                        Please update them here
                    </span>
                </Alert>
                :
                ''
            }

            <Container>
                <div className='search-tweet-ai'>
                    <h4>Analytics</h4>



                    <hr className='aiborder' />
                </div>

                <div className='analytics-box-row'>
                    <Row>
                        <Col lg={3} sm={6} md={6} xs={6}>
                            <div className='analytics-box'>
                                <h6>FOLLOWERS GROWTH</h6>
                                <h4>{displayEngagement?.user_data ? displayEngagement?.user_data?.follower_growth : '0'}</h4>
                            </div>
                        </Col>
                        <Col lg={3} sm={6} md={6} xs={6}>
                            <div className='analytics-box'>
                                <h6>PROFILE VISITS</h6>
                                <h4>{displayEngagement?.user_data ? displayEngagement?.user_data?.profile_visit : '0'}</h4>
                            </div>
                        </Col>
                        <Col lg={3} sm={6} md={6} xs={6}>
                            <div className='analytics-box'>
                                <h6>TOTAL ENGAGEMENT</h6>
                                <h4>{displayEngagement?.user_data ? displayEngagement?.user_data?.total_engagement_count_min : '0'}</h4>
                            </div>
                        </Col>
                        <Col lg={3} sm={6} md={6} xs={6}>
                            <div className='analytics-box'>
                                <h6>IMPRESSIONS</h6>
                                <h4>{displayEngagement?.user_data ? displayEngagement?.user_data?.total_impression_count : '0'}</h4>
                            </div>
                        </Col>
                    </Row>

                </div>





                <div>
                    <div className='graph-title'>
                        <div className='table-display-flex-2'>
                            <h5> Daily follower gain </h5>
                            <div>
                                <Form.Select aria-label="Default select example" className='table-form' value={selectedOption} onChange={handleOptionChange}>
                                    <option>Select..</option>

                                    <option value="last seven days">Last seven days</option>
                                    <option value="this week">This week</option>
                                    <option value="last month">Last month</option>
                                    <option value="this month">This month</option>
                                    <option value="this year">This year</option>


                                </Form.Select>
                            </div>
                        </div>


                    </div>
                    <div className='graph-box'>
                        <ReactApexChart
                            options={chartOptions}
                            series={chartData}
                            type="line"
                            height={350}
                        />
                    </div>
                </div>



                <div className='most-search-box'>

                    <h4>
                        Most reached posts
                    </h4>
                    {loading2 == true ?
                        <div className='text-center'>
                            <Spinner animation="border" size="lg" variant="primary" className='mr-5' style={{ marginRight: 5 }} />
                        </div>
                        : (
                            <>
                                {displayEngagement?.user_data ?
                                    <Row>
                                        <Col sm={12} md={4} lg={4}>




                                            <Card className='DailyIns-card'>
                                                <Card.Header as="h5">
                                                    <div className="DailyIns-card-header">
                                                        <div className='DailyIns-card-image'>
                                                            <OverlayTrigger

                                                                trigger="click"
                                                                placement="right"
                                                                overlay={tooltipContent}

                                                            >
                                                                <img src={displayEngagement?.user_data?.most_reached_tweet?.user_profile_image_url ? displayEngagement?.user_data?.most_reached_tweet?.user_profile_image_url : noimage} className="DailyIns-img-fluid" alt="" />
                                                            </OverlayTrigger>

                                                        </div>

                                                        <div className='dailyIns-span'>
                                                            <span>{displayEngagement?.user_data?.most_reached_tweet?.tweet_date}</span>


                                                        </div>

                                                    </div>


                                                </Card.Header>
                                                <Card.Body>

                                                    <Card.Text >
                                                        {displayEngagement?.user_data?.most_reached_tweet?.tweet_text}



                                                    </Card.Text>

                                                </Card.Body>




                                            </Card>



                                        </Col>
                                    </Row> : (
                                        <>

                                            <div className='text-center daily-notext'>

                                                <h5>
                                                    <FontAwesomeIcon icon={faCircleInfo} className='icon-text' />   No posts found
                                                </h5>
                                                <div>

                                                </div>


                                            </div>
                                        </>
                                    )

                                }
                            </>
                        )
                    }

                </div>






                <div className='Table-analytics'>
                    <div className='table-display-flex'>

                        <h5>Detailed post performance</h5>
                        <div className='button-fillter'>

                            <button
                                className={selectedValue === 7 ? 'selected-filter' : 'button-days'}
                                onClick={() => handleButtonClick(7)}
                                style={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}>
                                {selectedValue === 7 && loading ?
                                    <Spinner animation="border" size="sm" variant="light" className='mr-5' style={{ marginRight: 5 }} />
                                    :
                                    '7d'
                                }</button>


                            <button
                                className={selectedValue === 30 ? 'selected-filter' : 'button-days'}
                                onClick={() => handleButtonClick(30)}>
                                {selectedValue === 30 && loading ?
                                    <Spinner animation="border" size="sm" variant="light" className='mr-5' style={{ marginRight: 5 }} />
                                    :
                                    '30d'
                                }</button>


                            <button
                                className={selectedValue === 90 ? 'selected-filter' : 'button-days'}
                                onClick={() => handleButtonClick(90)}>
                                {selectedValue === 90 && loading ?
                                    <Spinner animation="border" size="sm" variant="light" className='mr-5' style={{ marginRight: 5 }} />
                                    :
                                    '90d'}</button>
                            <DatePicker selected={selectedDate} onChange={handleDateChange} placeholderText="Select a date" className={selectedValue === selectedDate ? 'selected-date' : "date-filter"} />







                        </div>
                    </div>




                    <div className='table-analytics-box'>
                        {loading ? (
                            <div className='text-center'>
                                <Spinner animation="border" size="lg" variant="primary" className='mr-5' style={{ marginRight: 5 }} />
                            </div>
                        ) :
                            displayEngagement && displayEngagement?.detailed_performance?.length > 0 ? (
                                <Table responsive>
                                    <thead>
                                        <tr className='table-header text-center'>

                                            <th>
                                                DATE </th>
                                            <th>TEXT & LABELS</th>

                                            <th>IMPRESSIONS</th>
                                            <th>ENGAGEMENT</th>
                                            <th>ENGAGEMENT%</th>
                                            <th>LIKES</th>
                                            <th>REPLIES</th>
                                            <th>RETWEETS</th>
                                            <th className='text-center'>QUOTE RETWEETS</th>



                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayEngagement?.detailed_performance?.map((item, index) => (
                                            <tr key={index} className='text-center'>

                                                <td style={{ width: 100 }}>{moment(item.tweet_date, 'DD-MM-YYYY').format('DD MMM YYYY')}</td>

                                                <td className='text-tabke'>

                                                    {item.tweet_text.length > 20 ? item.tweet_text.substring(0, 10) + '...' : item.tweet_text}
                                                </td>


                                                <td >{item.impression_count}</td>
                                                <td>{item.min_engagement_count}</td>
                                                <td>{item.min_engagement_rate}</td>
                                                <td>{item.like_count}</td>
                                                <td >{item.reply_count}</td>
                                                <td>{item.retweet_count}</td>
                                                <td >{item.quote_count}</td>


                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <h4 className='text-center'>No data available</h4>

                            )
                        }
                    </div>

                    <div>

                        <button onClick={exportToCsv} className={displayEngagement?.detailed_performance ? 'export-button' : 'export-no-button'}
                            disabled={!displayEngagement?.detailed_performance}
                        >Export</button>
                    </div>



                </div>
            </Container>

        </div>
    )
}





