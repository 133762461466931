import React, { useEffect, useState } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2";


export default function RefreshTweet() {

    let params = useParams()
    const Navigate = useNavigate()

    const Timezone = localStorage.getItem("TimeZone")
    const emailOR = localStorage.getItem("emailFist")
    console.log("kjdsc", Timezone)
    const [loginData, setLogindata] = useState({})
    const userDataArrayString = localStorage.getItem('userDataArray');

    const logoutSingleUserStatus = localStorage.getItem('logoutSingleUser')
    console.log("laoggg", logoutSingleUserStatus)

    const [emptyText, setEmptyText] = useState("")


    console.log("dsbjcs", params)

    useEffect(() => {
        try {

            let fdata = new FormData();
            fdata.append('provider_type', "twitter");
            fdata.append('provider_token', params.accessToken);
            fdata.append('oauth_token_secret', params.accessTokenSecret);
            if (userDataArrayString === null || userDataArrayString === '') {

                fdata.append('multiple_account_email', '');

            }
            else {

                const userDataArray = userDataArrayString ? JSON.parse(userDataArrayString) : [];

                const targetUser = userDataArray.find(user => user.email !== params.email);


                console.log("targeet", targetUser, params.email, userDataArray)
                if (targetUser && Object.keys(targetUser).length > 0 && localStorage.getItem("accountAdd") === 'true') {
                    fdata.append('multiple_account_email', localStorage.getItem('previousEmail'));

                }
                else {
                    fdata.append('multiple_account_email', '');
                }
            }


            // fetch('https://app.tweetnow.ai/TweetNow/api/user/auth', {
            fetch('https://app.xblue.ai/Xblue/api/user/auth', {
                method: 'POST',
                body: fdata,
                // headers: {
                //     Authorization: `Bearer ${authToken}`,
                // }
            }).then(response => {

                console.log("response signin", response)
                return response.json();
            }).then(data => {
                console.log("hjjhb", data);

                if (data.code === 200) {
                    localStorage.setItem('emailFist', params.email)

                    if (data.data.is_loggedin == "true") {


                        localStorage.setItem('accessToken-login', data.data.token)
                        // localStorage.setItem('UserID', data.data.session_data.id)
                        // localStorage.setItem('providerToken-2', data.data.session_data.access_token)
                        // localStorage.setItem('oAuthToken', data.data.session_data.access_token_secret)
                        localStorage.setItem('providerToken-login', params.accessToken)
                        // localStorage.setItem('secretKey', params.accessTokenSecret)
                        // localStorage.setItem('email', params.email)
                        // localStorage.removeItem('selectedCategories')

                        //  Navigate('/dashboard/app/notification')


                        localStorage.setItem('email-to', params.email)

                        const userData = {
                            accessToken: data.data.token,
                            userID: data.data.session_data.id,
                            providerToken2: data.data.session_data.access_token,
                            oAuthToken: data.data.session_data.access_token_secret,
                            providerToken: params.accessToken,
                            secretKey: params.accessTokenSecret,
                            email: params.email
                        };


                        // let userDataArray = [];

                        // if (localStorage.getItem('userDataArray')) {

                        //     userDataArray = JSON.parse(localStorage.getItem('userDataArray'));
                        // }                   
                        // userDataArray.push(userData);        

                        const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];

                        // Check if the user already exists in the array
                        const existingUserIndex = userDataArray.findIndex(user => user.email === userData.email);

                        if (existingUserIndex !== -1) {
                            // Update the existing user entry
                            userDataArray[existingUserIndex] = userData;
                        } else {
                            // Add a new user entry
                            userDataArray.push(userData);
                        }

                        localStorage.setItem('userDataArray', JSON.stringify(userDataArray));
                        localStorage.setItem('userloggedin', 'true')


                        Navigate('/dashboard/app/notification')



                    }
                    else {


                        localStorage.setItem('email-to', params.email)
                        const userData = {
                            accessToken: data.data.token,
                            userID: data.data.session_data.id,
                            providerToken2: data.data.session_data.access_token,
                            oAuthToken: data.data.session_data.access_token_secret,
                            providerToken: params.accessToken,
                            secretKey: params.accessTokenSecret,
                            email: params.email
                        };
                        const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];

                        // Check if the user already exists in the array
                        const existingUserIndex = userDataArray.findIndex(user => user.email === userData.email);

                        if (existingUserIndex !== -1) {
                            // Update the existing user entry
                            userDataArray[existingUserIndex] = userData;
                        } else {
                            // Add a new user entry
                            userDataArray.push(userData);
                        }

                        localStorage.setItem('userDataArray', JSON.stringify(userDataArray));
                        localStorage.setItem('userloggedin', 'true')

                        // localStorage.setItem('accessToken', data.data.token)
                        // localStorage.setItem('UserID', data.data.session_data.id)
                        // localStorage.setItem('providerToken-2', data.data.session_data.access_token)
                        // localStorage.setItem('oAuthToken', data.data.session_data.access_token_secret)
                        // localStorage.setItem('providerToken', params.accessToken)
                        // localStorage.setItem('secretKey', params.accessTokenSecret)
                        // localStorage.setItem('email-to', params.email)
                        // localStorage.removeItem('selectedCategories')
                        localStorage.setItem('accessToken-login', data.data.token)

                        localStorage.setItem('providerToken-login', params.accessToken)

                        Navigate('/app/user-interest')

                        // window.location.reload()

                    }

                }
                // else if (data.code == 400) {
                //     toast.error(data.message, {
                //         position: "top-center",
                //         autoClose: 5000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         draggable: true,
                //         progress: undefined,
                //         theme: "light",
                //     });
                // }

                // else if (data.code == 401) {
                //     toast.error(data.message, {
                //         position: "top-center",
                //         autoClose: 5000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         draggable: true,
                //         progress: undefined,
                //         theme: "light",
                //     });
                // }


                else {

                    // toast.error(data.message, {
                    //     position: "top-center",
                    //     autoClose: 5000,
                    //     hideProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    //     theme: "light",

                    // })
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: data.message,
                        // footer: '<a href="/">go back</a>'
                    })
                    Navigate('/')


                }


            })



        } catch (error) {
            console.log(error.message);
        }


    })


    const handleSubmit = () => {
        try {

            let fdata = new FormData();
            fdata.append('provider_type', "twitter");
            fdata.append('provider_token', params.accessToken);
            fdata.append('oauth_token_secret', params.accessTokenSecret);



            fetch('https://app.tweetnow.ai/TweetNow/api/user/auth', {
                method: 'POST',
                body: fdata,
                // headers: {
                //     Authorization: `Bearer ${authToken}`,
                // }
            }).then(response => {

                console.log("response signin", response)
                return response.json();
            }).then(data => {
                console.log("hjjhb", data);
                // console.log("-----", data.data.session_data.provider_id);
                if (data.code == 200) {


                    // window.location.reload()

                    console.log('Page loaded');

                    // if (data.data.is_loggedin == "true") {
                    //     localStorage.setItem('accessToken', data.data.token)
                    //     localStorage.setItem('UserID', data.data.session_data.id)
                    //     localStorage.setItem('providerToken', data.data.session_data.access_token)
                    //     localStorage.setItem('oAuthToken', data.data.session_data.access_token_secret)
                    //     Navigate('/dashboard/app/notification')
                    //     // window.location.reload()

                    // }
                    // else {

                    //     localStorage.setItem('accessToken', data.data.token)
                    //     localStorage.setItem('UserID', data.data.session_data.id)
                    //     localStorage.setItem('providerToken', data.data.session_data.access_token)
                    //     localStorage.setItem('oAuthToken', data.data.session_data.access_token_secret)
                    //     Navigate('/app/user-interest')
                    //     // window.location.reload()

                    // }

                }

                else {

                    console.log("session failed")
                }

            })



        } catch (error) {
            console.log(error.message);
        }
    }

    return (

        <div style={{ backgroundColor: 'white', height: '100rem', width: 'cover' }}>
            <div className='text-center'>
                {/* <h1>Welcome to TweetNow</h1> */}

                <p>Please wait.....</p>




                {/* <button onClick={handleSubmit}>Let's start</button> */}

            </div>
            <ToastContainer />

        </div>
    )
}