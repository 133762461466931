import React, { useState, useContext, useEffect } from 'react';
import { faEye, faPenNib, faPenToSquare, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as moment from 'moment';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Col, Row, Modal, Card, Button } from 'react-bootstrap';
import { MyContext, MySideBarContext } from './MyContext';
import noimage from '../../assets/noimage.png'
import { BASE_URL } from '../../utilities/Config';
import Microlink from '@microlink/react';

const items = [{
    time: [
        { id: 'item-1', text: 'Item 1' },
        { id: 'item-2', text: 'Item 2' },
        { id: 'item-3', text: 'Item 3' },
    ]
}, {
    time: [
        { id: 'item-11', text: 'Item 1' },
        { id: 'item-21', text: 'Item 2' },
        { id: 'item-31', text: 'Item 3' },
    ]
}];

const items1 = [
    { id: 'item-11', text: 'Item 1' },
    { id: 'item-21', text: 'Item 2' },
    { id: 'item-31', text: 'Item 3' },
];

const items2 = [
    { id: 'item-11', text: 'Item 1' },
    { id: 'item-21', text: 'Item 2' },
    { id: 'item-31', text: 'Item 3' },
];

const Source = ({ items, droppableId, formattedDate }) => {


    const [show, setShow] = useState(false);
    const [show3, setShow3] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showDelete2, setShowDelete2] = useState(false);
    const timeZone = localStorage.getItem('TimeZone')
    const userID = localStorage.getItem('UserID')
    const tokenLogin = localStorage.getItem('accessToken')
    const providerToken = localStorage.getItem('providerToken')

    const [sendModalData, setSendModalData] = useState()
    const [displayProfileDetails, setDisplayProfileDetails] = useState()

    const [context, setContext] = useContext(MyContext);
    const [contextData, setContextData] = useContext(MySideBarContext)


    const emailTo = localStorage.getItem('email-to')

    const emailSwitch = localStorage.getItem('emailSwitch')

    const userLoggedin = localStorage.getItem('userloggedin')


    const handleClose = () => {
        setShow(false)
        setShowDelete(false)
    };
    const handleClose2 = () => {
        setShow3(false)
        setShowDelete2(false)

    };



    const getImages = (images) => {
        return (
            <div>

                {images?.map((image, i) => (
                    // <Col lg={2} sm={2} md={2}>
                    <div key={i}>
                        <img src={image} style={{ height: 50, width: 50, marginLeft: 5, marginBottom: 10 }} className='draft-schdule-image--' />

                    </div>


                    // </Col>
                ))}
            </div>
        );
    };

    const getGif = (images) => {
        return (
            <div>
                {
                    images?.map((image, i) => {

                        return (
                            <div>
                                <img src={image} style={{
                                    height: 50,
                                    width: 50,
                                    marginLeft: 20

                                }} />
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const handleShow = (t) => {
        // setShow(true)
        // setContext({ time })
        console.log("jknscsdch", t)
        setSendModalData(t)


        Array.isArray(t) && t?.findIndex(s => s.is_thread === 1) > -1
            ? <>
                {displayProfile()
                }
                {
                    // alert('thread')
                    setShow3(true)

                }

            </>
            : setShow(true)

    };


    const handleShowDelete = (t) => {
        // setShowDelete(true)
        // setContext({ time })

        setSendModalData(t)


        Array.isArray(t) && t?.findIndex(s => s.is_thread === 1) > -1
            ? <>
                {displayProfile()
                }
                {
                    //alert('thread')
                    // setShow3(true)
                    setShowDelete2(true)

                }

            </>
            : setShowDelete(true)

    }


    const displayProfile = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();

                    if (data.code == 200) {
                        setDisplayProfileDetails(data.data)

                    }
                    else {

                        console.log("failed")

                    }
                }
                else {
                    console.log("error in display profile")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();

                    if (data.code == 200) {
                        setDisplayProfileDetails(data.data)

                    }
                    else {

                        console.log("failed")

                    }
                }
                else {
                    console.log("error in display profile")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };


    const handleDeletAPI = () => {

        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    let fdata = new FormData();
                    fdata.append('id', sendModalData?.id ? sendModalData?.id : sendModalData[0]?.id);
                    fetch(`${BASE_URL}api/delete/queue`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {
                        console.log('repsonse delet', response);
                        if (response.ok) {
                            console.log('Data deleted successfully.');
                            setContextData({ QUEUESaved: true })
                            //window.location.reload()

                            // setTimeSlots(timeSlots.filter(item => item.id !== context.t?.id));

                        } else {
                            console.error('Failed to delete data.');

                        }
                    })
                    setShowDelete(false)
                }
                else {
                    console.log("error in handleDeletAPI")
                }
            } catch (error) {

            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    let fdata = new FormData();
                    fdata.append('id', sendModalData?.id ? sendModalData?.id : sendModalData[0]?.id);
                    fetch(`${BASE_URL}api/delete/queue`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {
                        console.log('repsonse delet', response);
                        if (response.ok) {
                            console.log('Data deleted successfully.');
                            setContextData({ QUEUESaved: true })
                            //window.location.reload()

                            // setTimeSlots(timeSlots.filter(item => item.id !== context.t?.id));

                        } else {
                            console.error('Failed to delete data.');

                        }
                    })
                    setShowDelete(false)
                }
                else {
                    console.log("error in handleDeletAPI")
                }
            } catch (error) {

            }
        }
    }


    const hanldeContextthread = (item, formattedDate) => {
        localStorage.removeItem('savedValue')


        Array.isArray(item) && item?.findIndex(s => s.is_thread === 1) > -1
            ? (


                <>
                    {/* {setShowthread(true)} */}

                    {setContext({
                        ...context,
                        t: { ...context.t, thread: item, image: null, gif: null, text: null, time: item[0].time },
                        formattedDate,
                        type: "queue"

                    })}

                </>
            )
            :

            setContext({ ...context, t: { ...context.t, ...item, thread: null }, formattedDate, type: "queue" })




    }





    const [filteredTimeSlots, setFilteredTimeSlots] = useState([]);


    useEffect(() => {
        const filterTimeSlots = () => {
            const currentTime = moment();
            const filteredSlots = items?.filter((t) => {

                const timeSlotDateTime = moment(t?.time ? t?.time : t[0]?.time, 'h:mm A');



                const timeSlotDate = moment(t?.time ? t?.time : t[0]?.time, 'h:mm A').startOf('day'); // Use startOf('day') to compare only the date part
                const currentDate = moment(formattedDate, 'dddd | MMMM D, YYYY h:mm A').startOf('day');

                const isCurrentOrFutureDate = timeSlotDate.isSameOrAfter(currentDate);

                return isCurrentOrFutureDate ? timeSlotDateTime.isSameOrAfter(currentTime) : true;
            });

            setFilteredTimeSlots(filteredSlots);

        };


        filterTimeSlots();

        const interval = setInterval(filterTimeSlots, 60000);

        return () => clearInterval(interval);
    }, [items, formattedDate]);



    // console.log("filter tyime slot", filteredTimeSlots)




    const modalTextWithLinks = findLinksInText(sendModalData?.text);



    function findLinksInText(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text?.split(urlRegex);
    }

    // Function to check if a text contains any link
    function containsLink(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return urlRegex.test(text);
    }

    return (
        <>
            <Droppable droppableId={droppableId} direction="vertical" >
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                            // background: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey',
                            // padding: 8,
                            // width: 250,
                            // minHeight: 200,
                        }}
                    >
                        {filteredTimeSlots.length > 0 ? (filteredTimeSlots.map((t, index) => {



                            let previousTime = null;
                            let timeSlotIndex = items.findIndex(ti => (Array.isArray(t) && Array.isArray(ti) ? ti.findIndex(tii => tii.time === t[0].time) > -1 : ti.time === t.time))
                            { Array.isArray(t) }

                            return <div
                                style={{
                                    userSelect: 'none',
                                    // padding: 16,
                                    // margin: '0 0 8px 0',
                                    minHeight: '50px',
                                }}
                            >
                                <Draggable key={t.id} draggableId={String(droppableId + ' ' + timeSlotIndex)} index={timeSlotIndex}>
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef} style={{
                                            // backgroundColor: snapshot.isDragging ? '#263B4A' : '#456C86',
                                            color: 'white',
                                            ...provided.draggableProps.style,
                                        }}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <div
                                                //onClick={() => setContext({ t, formattedDate })}
                                                className='queue-schedule-list mb-3'>
                                                {/* {timeSlotIndex} ---- {Array.isArray(t) ? t[0].time : t.time} ---- {Array.isArray(t) ? '111' : '2222'} */}
                                                <Row>

                                                    <Col lg={2} sm={2} md={2}>
                                                        {/* <div>{moment(t?.time, 'h:mm A').format('h:mm A')}</div> */}

                                                        {Array.isArray(t) ? (
                                                            <>

                                                                {t?.map((hobby, index) => {
                                                                    const formattedTime = moment(hobby.time, 'HH:mm:ss').format('hh:mm A');

                                                                    const isDifferentTime = formattedTime !== previousTime;
                                                                    previousTime = formattedTime; // Update the p

                                                                    return (
                                                                        <>
                                                                            {/* <p>{hobby.time} </p> */}
                                                                            {isDifferentTime && <span className="text-center">{formattedTime}</span>}

                                                                        </>
                                                                    )
                                                                })}
                                                            </>
                                                        ) :
                                                            <p>{moment(t?.time, 'h:mm A').format('h:mm A')} </p>
                                                        }
                                                    </Col>
                                                    <Col lg={10} md={10} sm={10}>
                                                        <Row>
                                                            <Col lg={4} md={4} sm={4}>


                                                                {Array.isArray(t) ? (
                                                                    <>

                                                                        {t?.map((hobby, index) => {

                                                                            return (
                                                                                <>
                                                                                    <p className='text-container'>{hobby.text} </p>

                                                                                </>
                                                                            )
                                                                        })}
                                                                    </>
                                                                ) :
                                                                    <p className='text-container'>{t?.text ? t.text : 'Click on “Add to Queue” to place post here'} </p>
                                                                }
                                                            </Col>
                                                            <Col lg={4} md={4} sm={4}>
                                                                {/* 
                                                                <div className='image-myqueue'>
                                                               
                                                                    {Array.isArray(t) ? (
                                                                        <>

                                                                            {t?.map((hobby) => {

                                                                                return (
                                                                                    <div>
                                                                                        {
                                                                                            getImages(hobby?.image)
                                                                                        }
                                                                                    </div>
                                                                                )

                                                                            })}
                                                                        </>
                                                                    ) : (
                                                                        
                                                                        t?.image?.length > 0 && t?.image?.map((i, ind) => {

                                                                            return (
                                                                           
                                                                                <div className='image-container' key={ind}>

                                                                                    <img src={i} className='draft-schdule-image' />
                                                                                </div>
                                                                              
                                                                            );
                                                                        })
                                                                      
                                                                    )



                                                                    }




                                                                    {Array.isArray(t) && t?.gif?.length > 0 ? (
                                                                        <>

                                                                            {t?.map((hobby) => {

                                                                                return (
                                                                                    <div>
                                                                                        {
                                                                                            getGif(hobby.gif)
                                                                                        }
                                                                                    </div>
                                                                                )



                                                                            })}
                                                                        </>
                                                                    ) : (

                                                                        t?.gif?.length > 0 && t?.gif?.map((i, ind) => {

                                                                            return (
                                                                                <div className='image-container' key={ind}>
                                                                                    <img src={i} className='draft-schdule-image' />
                                                                                </div>
                                                                            );
                                                                        })

                                                                    )



                                                                    }


                                                                </div> */}
                                                            </Col>
                                                            <Col sm={4} md={4} lg={4}>

                                                                {t?.text || (t?.image && t?.image.length > 0) || (t?.gif && t?.gif.length > 0 || t[0]?.text) ? (
                                                                    <div style={{ display: 'flex', float: 'right', marginTop: 10 }}>
                                                                        <FontAwesomeIcon icon={faEye} onClick={() => handleShow(t)} />
                                                                        <FontAwesomeIcon icon={faTrash} onClick={() => handleShowDelete(t)} style={{ marginLeft: 10 }} />
                                                                        <FontAwesomeIcon
                                                                            icon={faPenToSquare}
                                                                            onClick={() => hanldeContextthread(t, formattedDate)}
                                                                            style={{ marginLeft: 10 }}
                                                                        />

                                                                    </div>
                                                                ) :
                                                                    <div style={{ display: 'flex', float: 'right', marginTop: 10 }}>
                                                                        <FontAwesomeIcon icon={faPencil} onClick={() => setContext({ t, formattedDate })} />
                                                                    </div>
                                                                }
                                                            </Col>
                                                        </Row>

                                                    </Col>

                                                </Row>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            </div>

                        })) : (
                            <div className='queue-schedule-list text-center'>
                                <h6>No queue slot available.</h6>
                            </div>
                        )}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>



            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='schdeule-card'>

                        <Card.Body>
                            <Card.Title>
                                <div className="schdeule-card-header">


                                    <div className='schdeule-card-text '>


                                        {/* <p> {sendModalData?.text}</p> */}

                                        {modalTextWithLinks?.map((segment, index) => {
                                            return containsLink(segment) ? (
                                                // If it's a link, render it as a link
                                                <a key={index} href={segment} target="_blank" rel="noopener noreferrer">


                                                    <Microlink url={segment}>
                                                        {({ data, loading }) => {
                                                            if (loading) return <p>Loading...</p>;
                                                            if (data) {
                                                                // Handle extracted data
                                                                console.log(data);
                                                                return (
                                                                    <div>
                                                                        <h1>{data.title}</h1>
                                                                        <p>{data.description}</p>
                                                                        {/* Add more components to display other extracted data */}
                                                                    </div>
                                                                );
                                                            }
                                                            return null;
                                                        }}
                                                    </Microlink>
                                                </a>
                                            ) : (
                                                // If it's text, render it as text
                                                <span key={index}>
                                                    {segment}{' '}
                                                </span>
                                            );
                                        })}


                                        <div className='image-myqueue text-center'>
                                            {/* {sendModalData?.gif?.map((i, ind) => {
                                                console.log("image iiii", i);
                                                return (
                                                    <div className='image-container' key={ind}>
                                                        <img src={i} className='queue-schdule-image' />
                                                    </div>
                                                );
                                            })} */}
                                            {Array.isArray(sendModalData?.gif) ? (
                                                sendModalData.gif.map((i, ind) => (
                                                    <div className='image-container' key={ind}>
                                                        <img src={i} className='queue-schedule-image' />
                                                    </div>
                                                ))
                                            ) : (
                                                <p></p>
                                            )}
                                        </div>

                                        <div className='image-myqueue-modal text-center'>
                                            {sendModalData?.image?.map((i, ind) => {
                                                console.log("image iiii", i);
                                                return (
                                                    <div className='image-container-modal' key={ind}>
                                                        <img src={i} className='queue-schdule-image' />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {sendModalData?.video ? (
                                            <div className='image-published text-center'>

                                                <video width="100%" height="240"
                                                    controls
                                                    src={sendModalData?.video}>


                                                </video>

                                            </div>
                                        ) : ""}
                                        {/* {context.t?.image && context.t?.image.map((image, index) => {
                                                return (
                                                    <>
                                                        <img src={image} style={{ width: '80px', height: '80px', marginBottom: '10px', marginRight: 10 }} />
                                                    </>
                                                )
                                            })}


                                          
                                            {Array.isArray(context.t?.gif) && context.t?.gif.map((gif, index) => {
                                                return (
                                                    <div>
                                                        <img src={gif} alt="Selected GIF" style={{ width: '100px', height: '100px', marginBottom: 10 }} />


                                                    </div>
                                                )
                                            }
                                            )} */}



                                    </div>

                                </div>
                            </Card.Title>

                            <Card.Text>



                            </Card.Text>



                        </Card.Body>





                    </Card>


                </Modal.Body>

            </Modal>


            <Modal show={show3} onHide={handleClose2}>
                <Modal.Header closeButton>
                    <Modal.Title> Thread preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    {Array.isArray(sendModalData) ? (
                        <>

                            {sendModalData?.map((hobby, index) => {
                                console.log("hobbry", hobby)
                                const lines = hobby?.text?.split("\r\n");
                                const linesWithLinks = lines?.map((line, lineIndex) => {
                                    const linksInLine = findLinksInText(line);

                                    if (linksInLine.length > 0) {
                                        // If links are found, apply a different style to the link
                                        return (
                                            <li className="thread-li" key={lineIndex}>
                                                {linksInLine?.map((link, linkIndex) => (
                                                    <span key={linkIndex} style={{ color: 'dodgerblue' }}>
                                                        {/* {link}{' '} */}

                                                        <Microlink url={link}>
                                                            {({ data, loading }) => {
                                                                if (loading) return <p>Loading...</p>;
                                                                if (data) {
                                                                    // Handle extracted data
                                                                    console.log(data);
                                                                    return (
                                                                        <div>
                                                                            <h1>{data.title}</h1>
                                                                            <p>{data.description}</p>
                                                                            {/* Add more components to display other extracted data */}
                                                                        </div>
                                                                    );
                                                                }
                                                                return null;
                                                            }}
                                                        </Microlink>

                                                    </span>
                                                ))}
                                            </li>
                                        );
                                    }

                                    // If no links, render the line as usual
                                    return (
                                        <li className="thread-li" key={lineIndex}>
                                            {line}
                                        </li>
                                    );
                                });

                                // Function to find links in a text
                                function findLinksInText(text) {
                                    const urlRegex = /(https?:\/\/[^\s]+)/g;
                                    return text.match(urlRegex) || [];
                                }

                                return (
                                    <>
                                        <div>
                                            <img
                                                src={displayProfileDetails?.user.image_url ? displayProfileDetails?.user.image_url : noimage}

                                                className="span-icon"
                                                style={{ width: 60 }}
                                            />
                                            <span className="span-thread" style={{ marginLeft: 20 }}>
                                                {displayProfileDetails?.user.name}

                                            </span>
                                        </div>



                                        <ul className="thread-ul">

                                            {/* {lines && lines?.map((line, lineIndex) => (

                                                <li className="thread-li" key={lineIndex}>

                                                    {line}

                                                </li>



                                            ))} */}

                                            {linesWithLinks && linesWithLinks?.map((line, lineIndex) => (

                                                <li className="thread-li" key={lineIndex} >


                                                    {line}


                                                </li>


                                            ))}
                                            <div>
                                                {
                                                    hobby?.image.map((item, index) => {
                                                        return (
                                                            <>
                                                                {/* <li> */}
                                                                <img src={item ? item : noimage} className='thread-preview-image' style={{ marginLeft: 10 }} />

                                                                {/* </li> */}
                                                            </>
                                                        )
                                                    })
                                                }
                                            </div>

                                            {
                                                Array.isArray(hobby?.gif) ? hobby?.gif.map((item, index) => {
                                                    return (
                                                        <>
                                                            <li>
                                                                <img src={item} className='thread-preview-image' />

                                                            </li>
                                                        </>
                                                    )
                                                }) : ""
                                            }

                                            <div className='image-published text-center'>
                                                {hobby?.video ? (
                                                    <video width="100%" height="240"
                                                        controls
                                                        src={hobby?.video}>


                                                    </video>
                                                ) : ""}
                                            </div>


                                        </ul>

                                    </>
                                )
                            })}
                        </>
                    ) :
                        "no thread to show"
                    }




                </Modal.Body>

            </Modal>

            <Modal show={showDelete} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faTrash} /> Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='schdeule-card'>

                        <Card.Body>
                            <Card.Title>
                                <div className="schdeule-card-header">


                                    <div className='schdeule-card-text '>


                                        <p> {sendModalData?.text}</p>


                                        <div className='image-myqueue text-center'>
                                            {sendModalData?.gif?.map((i, ind) => {
                                                console.log("image iiii", i);
                                                return (
                                                    <div className='image-container' key={ind}>
                                                        <img src={i} className='queue-schdule-image' />
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        <div className='image-myqueue text-center'>
                                            {sendModalData?.image?.map((i, ind) => {
                                                console.log("image iiii", i);
                                                return (
                                                    <div className='image-container' key={ind}>
                                                        <img src={i} className='queue-schdule-image' />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {/* {context.t?.image && context.t?.image.map((image, index) => {
                                                return (
                                                    <>
                                                        <img src={image} style={{ width: '80px', height: '80px', marginBottom: '10px', marginRight: 10 }} />
                                                    </>
                                                )
                                            })}


                                          
                                            {Array.isArray(context.t?.gif) && context.t?.gif.map((gif, index) => {
                                                return (
                                                    <div>
                                                        <img src={gif} alt="Selected GIF" style={{ width: '100px', height: '100px', marginBottom: 10 }} />


                                                    </div>
                                                )
                                            }
                                            )} */}



                                    </div>

                                </div>
                            </Card.Title>

                            <Card.Text>



                            </Card.Text>



                        </Card.Body>





                    </Card>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleDeletAPI}>
                        Yes
                    </Button>
                </Modal.Footer>

            </Modal>



            <Modal show={showDelete2} onHide={handleClose2}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Delete Thread</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    {Array.isArray(sendModalData) ? (
                        <>

                            {sendModalData?.map((hobby, index) => {
                                console.log("hobbry", hobby)
                                const lines = hobby?.text.split("\r\n");

                                return (
                                    <>
                                        <div>
                                            <img
                                                src={displayProfileDetails?.user.image_url ? displayProfileDetails?.user.image_url : ''}

                                                className="span-icon"
                                                style={{ width: 60 }}
                                            />
                                            <span className="span-thread" style={{ marginLeft: 20 }}>
                                                {displayProfileDetails?.user.name}

                                            </span>
                                        </div>



                                        <ul className="thread-ul">

                                            {lines && lines?.map((line, lineIndex) => (

                                                <li className="thread-li" key={lineIndex}>

                                                    {line}

                                                </li>



                                            ))}
                                            {
                                                hobby?.image.map((item, index) => {
                                                    return (
                                                        <>
                                                            <li>
                                                                <img src={item} className='thread-preview-image' />

                                                            </li>
                                                        </>
                                                    )
                                                })
                                            }

                                            {
                                                hobby?.gif.map((item, index) => {
                                                    return (
                                                        <>
                                                            <li>
                                                                <img src={item} className='thread-preview-image' />

                                                            </li>
                                                        </>
                                                    )
                                                })
                                            }


                                        </ul>

                                    </>
                                )
                            })}
                        </>
                    ) :
                        "no thread to show"
                    }




                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleDeletAPI}>
                        Yes
                    </Button>
                </Modal.Footer>

            </Modal>

        </>

    )
};

const Destination = ({ items, droppableId }) => (
    <Droppable droppableId={droppableId} direction="vertical">
        {(provided, snapshot) => (
            <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                    background: snapshot.isDraggingOver ? 'lightgreen' : 'lightgrey',
                    padding: 8,
                    width: 250,
                    minHeight: 200,
                    marginLeft: 20,
                }}
            >
                {items.map((item, index) => (
                    <div
                        key={item.id}
                        style={{
                            userSelect: 'none',
                            padding: 16,
                            margin: '0 0 8px 0',
                            minHeight: '50px',
                            backgroundColor: '#456C86',
                            color: 'white',
                        }}
                    >
                        {item.text}
                    </div>
                ))}
                {provided.placeholder}
            </div>
        )}
    </Droppable>
);


const TestDrag = ({ dateTimeList }) => {
    const [dateSlotData, setDateSlotData] = useState([])
    const [isAPIProcessing, setAPIProcessing] = useState(false);

    const providerToken = localStorage.getItem('providerToken')
    const [context, setContext] = useContext(MyContext);
    const emailTo = localStorage.getItem('email-to')
    const [contextData, setContextData] = useContext(MySideBarContext)
    const [displayProfileData, setDisplayProfileData] = useState([])
    const userID = localStorage.getItem('UserID')
    const tokenLogin = localStorage.getItem('accessToken')



    const emailSwitch = localStorage.getItem('emailSwitch')

    const userLoggedin = localStorage.getItem('userloggedin')

    useEffect(() => {
        setDateSlotData(dateTimeList)
        displayProfile()
    }, [])



    // const displayProfile = async () => {

    //     try {

    //         const response = await fetch(`${BASE_URL}api/user/${userID}`, {
    //             headers: {
    //                 Authorization: `Bearer ${tokenLogin}`,
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    //         const data = await response.json();
    //         console.log("data-drag", data)

    //         if (data.code == 200) {
    //             setDisplayProfileData(data.data)


    //         }
    //         else {



    //             console.log("failed")


    //         }


    //     } catch (error) {
    //         console.error('Error fetching buyer data:', error);
    //     }
    // };


    const displayProfile = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("data-drag", data)

                    if (data.code == 200) {
                        setDisplayProfileData(data.data)


                    }
                    else {



                        console.log("failed")


                    }
                }
                else {
                    console.log("error in display profile")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("data-drag", data)

                    if (data.code == 200) {
                        setDisplayProfileData(data.data)


                    }
                    else {



                        console.log("failed")


                    }
                }
                else {
                    console.log("error in display profile")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };





    const handleDragEnd = async (result) => {
        console.log(result, 'handleDragEndresult');
        if (!result.destination) return; // Item dropped outside the droppable area

        const { source, destination } = result;
        const updatedTimeSlots = [...dateTimeList]; // Create a copy of the original timeSlots array
        // Get the source and destination indexes
        const sourceIndex = source.index;
        const destinationIndex = destination.index;
        console.log(updatedTimeSlots[source.droppableId.split('-')[1]], 'handleDragEndresultaa', sourceIndex, destinationIndex);

        // Retrieve the source and destination time objects
        const sourceTime = updatedTimeSlots[source.droppableId.split('-')[1]].time[sourceIndex];
        const destinationTime = updatedTimeSlots[destination.droppableId.split('-')[1]].time[destinationIndex];

        // Swap the text values between the source and destination time objects
        // const sourceText = sourceTime.text;
        // sourceTime.text = destinationTime.text;
        // destinationTime.text = sourceText;

        // const sourceImage = sourceTime.image;
        // sourceTime.image = destinationTime.image;
        // destinationTime.image = sourceImage;

        // Swap the gif values between the source and destination time objects
        // const sourceGif = sourceTime.gif;
        // sourceTime.gif = destinationTime.gif;
        // destinationTime.gif = sourceGif;
        console.log(updatedTimeSlots[destination.droppableId.split('-')[1]], 'handleDragEndresultbb', updatedTimeSlots);
        console.log(updatedTimeSlots[destination.droppableId.split('-')[1]], 'handleDragEndresultbb',);
        console.log(updatedTimeSlots[source.droppableId.split('-')[1]], 'handleDragEndresultbb',);


        const destinationValue = updatedTimeSlots[destination.droppableId.split('-')[1]].time[destinationIndex]
        const destinationFormated = updatedTimeSlots[destination.droppableId.split('-')[1]]
        const sourceValue = updatedTimeSlots[source.droppableId.split('-')[1]].time[sourceIndex]
        const sourceFormated = updatedTimeSlots[source.droppableId.split('-')[1]]
        console.log({ destinationValue, sourceValue }, 'handleDragEndresultbb')


        let sourceCalcTime = Array.isArray(sourceValue) ? sourceValue[0].time : sourceValue.time
        let destCalcTime = Array.isArray(destinationValue) ? destinationValue[0].time : destinationValue.time

        if (destinationValue && Array.isArray(destinationValue) && destinationValue.filter(f => f.is_thread).length > 0) {
            destinationValue.forEach(element => {
                console.log("destinatoon", element, sourceCalcTime)


                callPostAPIdestinationIndex({ ...element, time: sourceCalcTime }, { ...destinationFormated, formattedDate: sourceFormated.formattedDate });

            });
        } else {
            callPostAPIdestinationIndex({
                ...destinationValue, time: sourceCalcTime
            }, { ...destinationFormated, formattedDate: sourceFormated.formattedDate });

        }
        if (sourceValue && Array.isArray(sourceValue) && sourceValue.filter(f => f.is_thread).length > 0) {
            sourceValue.forEach(element => {

                console.log("destinatoon", element, destCalcTime)
                callPostAPIsourceIndex({ ...element, time: destCalcTime }, { ...sourceFormated, formattedDate: destinationFormated.formattedDate });

            });
        } else {
            callPostAPIsourceIndex({ ...sourceValue, time: destCalcTime }, { ...sourceFormated, formattedDate: destinationFormated.formattedDate });
        }
        // Update the state with the rearranged items
        //setDateSlotData(updatedTimeSlots);

        // callPostAPI(updatedTimeSlots[destination.droppableId.split('-')[1]].time[destinationIndex]);
        // callPostAPI(updatedTimeSlots[source.droppableId.split('-')[1]].time[sourceIndex]);



    };





    // const callPostAPIdestinationIndex = async (destinationValue, destinationFormated) => {
    //     console.log("des", destinationValue)


    //     try {
    //         console.log("des", destinationValue)
    //         setAPIProcessing(true); // Set the API call status to true
    //         // setLoaderQueue(true)
    //         let fdata = new FormData();


    //         fdata.append('text', destinationValue ? destinationValue?.text : 'no text');

    //         fdata.append('image[]', '');

    //         fdata.append('provider_token', providerToken)
    //         fdata.append('time', destinationValue ? moment(destinationValue?.time, 'hh:mm:ss').format('hh:mm A') : '')
    //         fdata.append('date', destinationFormated ? moment(destinationFormated.formattedDate).format('DD-MM-YYYY') : '')
    //         fdata.append('day', destinationFormated ? moment(destinationFormated.formattedDate).format('dddd') : '')
    //         fdata.append('timezone', displayProfileData.user.timezone)
    //         fdata.append('gif[]', '');
    //         fdata.append('video', '');
    //         fdata.append('id', destinationValue ? destinationValue?.id : '')
    //         fdata.append('image_url', destinationValue ? destinationValue?.image : '')
    //         fdata.append('gif_url', destinationValue ? destinationValue?.gif : '')
    //         fdata.append('video_url', destinationValue ? destinationValue?.video : '')

    //         if (destinationValue.is_thread) {
    //             console.log("handleDragEndresultbb-new", destinationValue, ",,", destinationFormated)
    //             fdata.append('thread_tweet_id', destinationValue ? destinationValue?.thread_tweet_id : '')
    //             fdata.append('tweet_id', destinationValue ? destinationValue?.tweet_id : '')

    //             await fetch(`${BASE_URL}api/queue/thread`, {
    //                 method: "POST",
    //                 body: fdata,
    //                 headers: {
    //                     Authorization: `Bearer ${tokenLogin}`,
    //                 },
    //             }).then((response) => {
    //                 return response.json();
    //             }).then((data) => {
    //                 if (data.code == 200) {
    //                     setContextData({ QUEUESaved: true })
    //                 }
    //                 else {
    //                     console.log("sckjsbj")
    //                 }
    //                 console.log("data-thread-update", data)
    //             })
    //         } else {
    //             console.log("handleDragEndresultbb-new", destinationValue, ",,", destinationFormated)
    //             fdata.append('is_thread', 0)

    //             fetch(`${BASE_URL}api/queue/tweet`, {
    //                 method: 'POST',
    //                 body: fdata,
    //                 headers: {
    //                     Authorization: `Bearer ${tokenLogin}`,
    //                 }
    //             })
    //                 .then(response => response.json())
    //                 .then(data => {
    //                     console.log("queue dtaa", data)
    //                     if (data.code === 200) {
    //                         // alert(JSON.stringify(data.message));
    //                         // setContextData({ tweetText, handleTime, handleDate, selectedGifpicker, selectedImages });
    //                         // toast.success(data.message, {
    //                         //     position: "top-center",
    //                         //     autoClose: 5000,
    //                         //     hideProgressBar: false,
    //                         //     closeOnClick: true,
    //                         //     pauseOnHover: true,
    //                         //     draggable: true,
    //                         //     progress: undefined,
    //                         //     theme: "light",
    //                         // });
    //                         // setContextData({ QUEUESaved: true })

    //                         // displayDynamicQueue()
    //                         // window.location.reload()

    //                     } else {
    //                         // alert(JSON.stringify(data.message));
    //                         // toast.error(data.message, {
    //                         //     position: "top-center",
    //                         //     autoClose: 5000,
    //                         //     hideProgressBar: false,
    //                         //     closeOnClick: true,
    //                         //     pauseOnHover: true,
    //                         //     draggable: true,
    //                         //     progress: undefined,
    //                         //     theme: "light",
    //                         // });

    //                     }
    //                     // setLoaderQueue(false)
    //                     // setTweetText('')

    //                 })
    //                 .catch(error => {
    //                     console.log(error);
    //                 })
    //                 .finally(() => {
    //                     setAPIProcessing(false); // Reset the API call status
    //                 });
    //         }

    //     } catch (error) {
    //         console.log("des,", error);
    //     }

    // };

    // const callPostAPIsourceIndex = async (sourceValue, sourceFormated) => {


    //     try {

    //         setAPIProcessing(true); // Set the API call status to true
    //         // setLoaderQueue(true)
    //         let fdata = new FormData();


    //         fdata.append('text', sourceValue ? sourceValue?.text : 'no text');

    //         fdata.append('image[]', '');

    //         fdata.append('provider_token', providerToken)
    //         fdata.append('time', sourceValue ? moment(sourceValue?.time, 'hh:mm:ss').format('hh:mm A') : '')
    //         fdata.append('date', sourceFormated ? moment(sourceFormated.formattedDate).format('DD-MM-YYYY') : '')
    //         fdata.append('day', sourceFormated ? moment(sourceFormated.formattedDate).format('dddd') : '')
    //         fdata.append('timezone', displayProfileData.user.timezone)
    //         fdata.append('gif[]', '');
    //         fdata.append('video', '')
    //         fdata.append('id', sourceValue ? sourceValue?.id : '')
    //         fdata.append('image_url', sourceValue ? sourceValue?.image : '')
    //         fdata.append('gif_url', sourceValue ? sourceValue?.gif : '')
    //         fdata.append('video_url', sourceValue ? sourceValue?.video : '')
    //         // fdata.append('is_thread', 0)

    //         if (sourceValue.is_thread) {
    //             console.log("handleDragEndresultbb-new", sourceFormated, ",,,", sourceValue)
    //             fdata.append('thread_tweet_id', sourceValue ? sourceValue?.thread_tweet_id : '')
    //             fdata.append('tweet_id', sourceValue ? sourceValue?.tweet_id : '')
    //             await fetch(`${BASE_URL}api/queue/thread`, {
    //                 method: "POST",
    //                 body: fdata,
    //                 headers: {
    //                     Authorization: `Bearer ${tokenLogin}`,
    //                 },
    //             }).then((response) => {
    //                 return response.json();
    //             }).then((data) => {
    //                 setContextData({ QUEUESaved: true })
    //                 console.log("data-thread-update", data)
    //             })

    //         } else {
    //             console.log("handleDragEndresultbb-new", sourceFormated, ",,,", sourceValue)
    //             fetch(`${BASE_URL}api/queue/tweet`, {
    //                 method: 'POST',
    //                 body: fdata,
    //                 headers: {
    //                     Authorization: `Bearer ${tokenLogin}`,
    //                 }
    //             })
    //                 .then(response => response.json())
    //                 .then(data => {
    //                     console.log("queue dtaa", data)
    //                     if (data.code === 200) {
    //                         // alert(JSON.stringify(data.message));
    //                         // setContextData({ tweetText, handleTime, handleDate, selectedGifpicker, selectedImages });
    //                         // toast.success(data.message, {
    //                         //     position: "top-center",
    //                         //     autoClose: 5000,
    //                         //     hideProgressBar: false,
    //                         //     closeOnClick: true,
    //                         //     pauseOnHover: true,
    //                         //     draggable: true,
    //                         //     progress: undefined,
    //                         //     theme: "light",
    //                         // });
    //                         setContextData({ QUEUESaved: true })

    //                         // displayDynamicQueue()
    //                         // window.location.reload()

    //                     } else {
    //                         // alert(JSON.stringify(data.message));
    //                         // toast.error(data.message, {
    //                         //     position: "top-center",
    //                         //     autoClose: 5000,
    //                         //     hideProgressBar: false,
    //                         //     closeOnClick: true,
    //                         //     pauseOnHover: true,
    //                         //     draggable: true,
    //                         //     progress: undefined,
    //                         //     theme: "light",
    //                         // });

    //                     }
    //                     // setLoaderQueue(false)
    //                     // setTweetText('')

    //                 })
    //                 .catch(error => {
    //                     console.log(error);
    //                 })
    //                 .finally(() => {
    //                     setAPIProcessing(false); // Reset the API call status
    //                 });
    //         }

    //     } catch (error) {
    //         console.log(error);
    //     }
    // }




    const callPostAPIdestinationIndex = async (destinationValue, destinationFormated) => {
        console.log("des", destinationValue)
        if (localStorage.getItem("userloggedin") === 'true') {

            try {


                console.log("des", destinationValue)
                setAPIProcessing(true); // Set the API call status to true
                // setLoaderQueue(true)


                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken, providerToken } = targetUser;



                    let fdata = new FormData();


                    fdata.append('text', destinationValue ? destinationValue?.text : 'no text');

                    fdata.append('image[]', '');

                    fdata.append('provider_token', providerToken)
                    fdata.append('time', destinationValue ? moment(destinationValue?.time, 'hh:mm:ss').format('hh:mm A') : '')
                    fdata.append('date', destinationFormated ? moment(destinationFormated.formattedDate).format('DD-MM-YYYY') : '')
                    fdata.append('day', destinationFormated ? moment(destinationFormated.formattedDate).format('dddd') : '')
                    fdata.append('timezone', displayProfileData.user.timezone)
                    // fdata.append('gif[]', '');
                    fdata.append('video', '');
                    fdata.append('id', destinationValue ? destinationValue?.id : '')
                    fdata.append('image_url', destinationValue ? destinationValue?.image : '')
                    fdata.append('gif_url', destinationValue ? destinationValue?.gif : '')
                    fdata.append('video_url', destinationValue ? destinationValue?.video : '')

                    if (destinationValue.is_thread) {
                        console.log("handleDragEndresultbb-new", destinationValue, ",,", destinationFormated)
                        fdata.append('thread_tweet_id', destinationValue ? destinationValue?.thread_tweet_id : '')
                        fdata.append('tweet_id', destinationValue ? destinationValue?.tweet_id : '')

                        await fetch(`${BASE_URL}api/queue/thread`, {
                            method: "POST",
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }).then((response) => {
                            return response.json();
                        }).then((data) => {
                            if (data.code == 200) {
                                setContextData({ QUEUESaved: true })
                            }
                            else {
                                console.log("sckjsbj")
                            }
                            console.log("data-thread-update", data)
                        })
                    } else {
                        console.log("handleDragEndresultbb-new", destinationValue, ",,", destinationFormated)
                        fdata.append('is_thread', 0)

                        fetch(`${BASE_URL}api/queue/tweet`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        })
                            .then(response => response.json())
                            .then(data => {
                                console.log("queue dtaa", data)
                                if (data.code === 200) {
                                    // alert(JSON.stringify(data.message));
                                    // setContextData({ tweetText, handleTime, handleDate, selectedGifpicker, selectedImages });
                                    // toast.success(data.message, {
                                    //     position: "top-center",
                                    //     autoClose: 5000,
                                    //     hideProgressBar: false,
                                    //     closeOnClick: true,
                                    //     pauseOnHover: true,
                                    //     draggable: true,
                                    //     progress: undefined,
                                    //     theme: "light",
                                    // });
                                    setContextData({ QUEUESaved: true })

                                    // displayDynamicQueue()
                                    // window.location.reload()

                                } else {
                                    // alert(JSON.stringify(data.message));
                                    // toast.error(data.message, {
                                    //     position: "top-center",
                                    //     autoClose: 5000,
                                    //     hideProgressBar: false,
                                    //     closeOnClick: true,
                                    //     pauseOnHover: true,
                                    //     draggable: true,
                                    //     progress: undefined,
                                    //     theme: "light",
                                    // });

                                }
                                // setLoaderQueue(false)
                                // setTweetText('')

                            })
                            .catch(error => {
                                console.log(error);
                            })
                            .finally(() => {
                                setAPIProcessing(false); // Reset the API call status
                            });
                    }

                }
                else {
                    console.log("error in callPostAPIdestinationIndex")
                }

            } catch (error) {
                console.log("des,", error);
            }
        }
        else {
            try {


                console.log("des", destinationValue)
                setAPIProcessing(true); // Set the API call status to true
                // setLoaderQueue(true)


                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken, providerToken } = targetUser;



                    let fdata = new FormData();


                    fdata.append('text', destinationValue ? destinationValue?.text : 'no text');

                    fdata.append('image[]', '');

                    fdata.append('provider_token', providerToken)
                    fdata.append('time', destinationValue ? moment(destinationValue?.time, 'hh:mm:ss').format('hh:mm A') : '')
                    fdata.append('date', destinationFormated ? moment(destinationFormated.formattedDate).format('DD-MM-YYYY') : '')
                    fdata.append('day', destinationFormated ? moment(destinationFormated.formattedDate).format('dddd') : '')
                    fdata.append('timezone', displayProfileData.user.timezone)
                    // fdata.append('gif[]', '');
                    fdata.append('video', '');
                    fdata.append('id', destinationValue ? destinationValue?.id : '')
                    fdata.append('image_url', destinationValue ? destinationValue?.image : '')
                    fdata.append('gif_url', destinationValue ? destinationValue?.gif : '')
                    fdata.append('video_url', destinationValue ? destinationValue?.video : '')

                    if (destinationValue.is_thread) {
                        console.log("handleDragEndresultbb-new", destinationValue, ",,", destinationFormated)
                        fdata.append('thread_tweet_id', destinationValue ? destinationValue?.thread_tweet_id : '')
                        fdata.append('tweet_id', destinationValue ? destinationValue?.tweet_id : '')

                        await fetch(`${BASE_URL}api/queue/thread`, {
                            method: "POST",
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }).then((response) => {
                            return response.json();
                        }).then((data) => {
                            if (data.code == 200) {
                                setContextData({ QUEUESaved: true })
                            }
                            else {
                                console.log("sckjsbj")
                            }
                            console.log("data-thread-update", data)
                        })
                    } else {
                        console.log("handleDragEndresultbb-new", destinationValue, ",,", destinationFormated)
                        fdata.append('is_thread', 0)

                        fetch(`${BASE_URL}api/queue/tweet`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        })
                            .then(response => response.json())
                            .then(data => {
                                console.log("queue dtaa", data)
                                if (data.code === 200) {
                                    // alert(JSON.stringify(data.message));
                                    // setContextData({ tweetText, handleTime, handleDate, selectedGifpicker, selectedImages });
                                    // toast.success(data.message, {
                                    //     position: "top-center",
                                    //     autoClose: 5000,
                                    //     hideProgressBar: false,
                                    //     closeOnClick: true,
                                    //     pauseOnHover: true,
                                    //     draggable: true,
                                    //     progress: undefined,
                                    //     theme: "light",
                                    // });
                                    setContextData({ QUEUESaved: true })

                                    // displayDynamicQueue()
                                    // window.location.reload()

                                } else {
                                    // alert(JSON.stringify(data.message));
                                    // toast.error(data.message, {
                                    //     position: "top-center",
                                    //     autoClose: 5000,
                                    //     hideProgressBar: false,
                                    //     closeOnClick: true,
                                    //     pauseOnHover: true,
                                    //     draggable: true,
                                    //     progress: undefined,
                                    //     theme: "light",
                                    // });

                                }
                                // setLoaderQueue(false)
                                // setTweetText('')

                            })
                            .catch(error => {
                                console.log(error);
                            })
                            .finally(() => {
                                setAPIProcessing(false); // Reset the API call status
                            });
                    }

                }
                else {
                    console.log("error in callPostAPIdestinationIndex")
                }

            } catch (error) {
                console.log("des,", error);
            }
        }

    };

    const callPostAPIsourceIndex = async (sourceValue, sourceFormated) => {
        console.log("des -sor", sourceValue)
        if (localStorage.getItem("userloggedin") === 'true') {

            try {

                setAPIProcessing(true); // Set the API call status to true
                // setLoaderQueue(true)
                let fdata = new FormData();



                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken, providerToken } = targetUser;


                    fdata.append('text', sourceValue ? sourceValue?.text : 'no text');

                    fdata.append('image[]', '');

                    fdata.append('provider_token', providerToken)
                    fdata.append('time', sourceValue ? moment(sourceValue?.time, 'hh:mm:ss').format('hh:mm A') : '')
                    fdata.append('date', sourceFormated ? moment(sourceFormated.formattedDate).format('DD-MM-YYYY') : '')
                    fdata.append('day', sourceFormated ? moment(sourceFormated.formattedDate).format('dddd') : '')
                    fdata.append('timezone', displayProfileData.user.timezone)
                    // fdata.append('gif[]', '');
                    fdata.append('video', '')
                    fdata.append('id', sourceValue ? sourceValue?.id : '')
                    fdata.append('image_url', sourceValue ? sourceValue?.image : '')
                    fdata.append('gif_url', sourceValue ? sourceValue?.gif : '')
                    fdata.append('video_url', sourceValue ? sourceValue?.video : '')
                    // fdata.append('is_thread', 0)

                    if (sourceValue.is_thread) {
                        console.log("handleDragEndresultbb-new", sourceFormated, ",,,", sourceValue)
                        fdata.append('thread_tweet_id', sourceValue ? sourceValue?.thread_tweet_id : '')
                        fdata.append('tweet_id', sourceValue ? sourceValue?.tweet_id : '')
                        await fetch(`${BASE_URL}api/queue/thread`, {
                            method: "POST",
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }).then((response) => {
                            return response.json();
                        }).then((data) => {
                            setContextData({ QUEUESaved: true })
                            console.log("data-thread-update", data)
                        })

                    } else {
                        console.log("handleDragEndresultbb-new", sourceFormated, ",,,", sourceValue)
                        fdata.append('is_thread', 0)
                        fetch(`${BASE_URL}api/queue/tweet`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        })
                            .then(response => response.json())
                            .then(data => {
                                console.log("queue dtaa", data)
                                if (data.code === 200) {
                                    // alert(JSON.stringify(data.message));
                                    // setContextData({ tweetText, handleTime, handleDate, selectedGifpicker, selectedImages });
                                    // toast.success(data.message, {
                                    //     position: "top-center",
                                    //     autoClose: 5000,
                                    //     hideProgressBar: false,
                                    //     closeOnClick: true,
                                    //     pauseOnHover: true,
                                    //     draggable: true,
                                    //     progress: undefined,
                                    //     theme: "light",
                                    // });
                                    setContextData({ QUEUESaved: true })

                                    // displayDynamicQueue()
                                    // window.location.reload()

                                } else {
                                    // alert(JSON.stringify(data.message));
                                    // toast.error(data.message, {
                                    //     position: "top-center",
                                    //     autoClose: 5000,
                                    //     hideProgressBar: false,
                                    //     closeOnClick: true,
                                    //     pauseOnHover: true,
                                    //     draggable: true,
                                    //     progress: undefined,
                                    //     theme: "light",
                                    // });

                                }
                                // setLoaderQueue(false)
                                // setTweetText('')

                            })
                            .catch(error => {
                                console.log(error);
                            })
                            .finally(() => {
                                setAPIProcessing(false); // Reset the API call status
                            });
                    }
                }
                else {
                    console.log("error in callPostAPIsourceIndex")
                }

            } catch (error) {
                console.log(error);
            }
        }
        else {
            try {

                setAPIProcessing(true); // Set the API call status to true
                // setLoaderQueue(true)
                let fdata = new FormData();



                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken, providerToken } = targetUser;


                    fdata.append('text', sourceValue ? sourceValue?.text : 'no text');

                    fdata.append('image[]', '');

                    fdata.append('provider_token', providerToken)
                    fdata.append('time', sourceValue ? moment(sourceValue?.time, 'hh:mm:ss').format('hh:mm A') : '')
                    fdata.append('date', sourceFormated ? moment(sourceFormated.formattedDate).format('DD-MM-YYYY') : '')
                    fdata.append('day', sourceFormated ? moment(sourceFormated.formattedDate).format('dddd') : '')
                    fdata.append('timezone', displayProfileData.user.timezone)
                    // fdata.append('gif[]', '');
                    fdata.append('video', '')
                    fdata.append('id', sourceValue ? sourceValue?.id : '')
                    fdata.append('image_url', sourceValue ? sourceValue?.image : '')
                    fdata.append('gif_url', sourceValue ? sourceValue?.gif : '')
                    fdata.append('video_url', sourceValue ? sourceValue?.video : '')
                    // fdata.append('is_thread', 0)

                    if (sourceValue.is_thread) {
                        console.log("handleDragEndresultbb-new", sourceFormated, ",,,", sourceValue)
                        fdata.append('thread_tweet_id', sourceValue ? sourceValue?.thread_tweet_id : '')
                        fdata.append('tweet_id', sourceValue ? sourceValue?.tweet_id : '')
                        await fetch(`${BASE_URL}api/queue/thread`, {
                            method: "POST",
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }).then((response) => {
                            return response.json();
                        }).then((data) => {
                            setContextData({ QUEUESaved: true })
                            console.log("data-thread-update", data)
                        })

                    } else {
                        console.log("handleDragEndresultbb-new", sourceFormated, ",,,", sourceValue)
                        fetch(`${BASE_URL}api/queue/tweet`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        })
                            .then(response => response.json())
                            .then(data => {
                                console.log("queue dtaa", data)
                                if (data.code === 200) {
                                    // alert(JSON.stringify(data.message));
                                    // setContextData({ tweetText, handleTime, handleDate, selectedGifpicker, selectedImages });
                                    // toast.success(data.message, {
                                    //     position: "top-center",
                                    //     autoClose: 5000,
                                    //     hideProgressBar: false,
                                    //     closeOnClick: true,
                                    //     pauseOnHover: true,
                                    //     draggable: true,
                                    //     progress: undefined,
                                    //     theme: "light",
                                    // });
                                    setContextData({ QUEUESaved: true })

                                    // displayDynamicQueue()
                                    // window.location.reload()

                                } else {
                                    // alert(JSON.stringify(data.message));
                                    // toast.error(data.message, {
                                    //     position: "top-center",
                                    //     autoClose: 5000,
                                    //     hideProgressBar: false,
                                    //     closeOnClick: true,
                                    //     pauseOnHover: true,
                                    //     draggable: true,
                                    //     progress: undefined,
                                    //     theme: "light",
                                    // });

                                }
                                // setLoaderQueue(false)
                                // setTweetText('')

                            })
                            .catch(error => {
                                console.log(error);
                            })
                            .finally(() => {
                                setAPIProcessing(false); // Reset the API call status
                            });
                    }
                }
                else {
                    console.log("error in callPostAPIsourceIndex")
                }

            } catch (error) {
                console.log(error);
            }
        }
    }




    const [ar, setAr] = useState([1, 2]);
    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            {/* {ar.map((el, i) => {
                    return <Source items={items[i].time} droppableId={`droppable--${i}`} />

                })} */}

            {dateTimeList.map((el, i) => {

                return <div className='queue-schedule-list-1' >
                    <span key={el.formattedDate} style={{ color: 'black' }}>{el.formattedDate}</span>

                    <Source items={el.time} droppableId={`droppable-${i}`} formattedDate={el.formattedDate} />
                </div>

            })}
            {/* <Source items={items[0].time} droppableId="droppable-1" />
                <Source items={items[1].time} droppableId="droppable-2" /> */}
            {/* <Source items={items1} droppableId="droppable-1" />
                <Source items={items2} droppableId="droppable-2" /> */}

            {/* <Destination items={[]} /> */}
        </DragDropContext>
    );
};

export default TestDrag;
