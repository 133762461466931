import React, { useEffect, useState, useContext, useMemo } from 'react'
import Masonry from 'react-masonry-css';

// import Slider from '@mui/material/Slider';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFilter, faX, faSliders, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Container, Dropdown, Button, Card, Alert, Form, Spinner } from 'react-bootstrap'
import { MySideBarContext, MyContext } from '../../../components/MyQueue/MyContext'
import Ellipse from '../../../assets/images/Ellipse.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faRetweet, faCircleInfo, faEllipsisVertical, faStar, faBolt, faLocationDot, faLink, faChevronRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { ToastContainer, toast } from 'react-toastify';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import * as moment from 'moment';
import { Slider } from '@mui/material';
import { BASE_URL } from '../../../utilities/Config';


export default function SearchTabs() {
    const [displayAImessage, setDisplayAImessage] = useState(null)
    const [displaySearchTweet, setDisplaySearchTweet] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingSearch, setLoadingSearch] = useState(false)
    const [searchTweet, setSearchTweet] = useState('')
    const [context, setContext] = useContext(MyContext);
    const userID = localStorage.getItem('UserID')
    const tokenLogin = localStorage.getItem('accessToken')
    const navigate = useNavigate()
    const [sliderValue, setSliderValue] = useState(0)
    const [sliderValue2, setSliderValue2] = useState(0)
    const [sliderValue3, setSliderValue3] = useState(0)
    const [sliderValue4, setSliderValue4] = useState(0)
    const [liked, setLiked] = useState(false);
    const [retweet, setReTweet] = useState(false);
    const [suggestion, setSuggestion] = useState(false)



    const breakpointColumnsObj = {
        default: 3,
        1200: 3,
        1000: 2,
        992: 2,
        768: 1
    };

    const [options, setOptions] = useState([
        { value: 'option1', label: 'Option 1', checked: false },
        { value: 'option2', label: 'Option 2', checked: false },
        { value: 'option3', label: 'Option 3', checked: false },
    ]);
    const [isOpen, setIsOpen] = useState(false);

    // const handleCheckboxChange = (event) => {
    //     // const { name, checked } = event.target;
    //     // handleOptionChange(name, checked);
    //     // setIsOpen(true)

    // };

    const [isChecked, setIsChecked] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [isChecked3, setIsChecked3] = useState(false);
    const [isChecked4, setIsChecked4] = useState(false);
    const [isChecked5, setIsChecked5] = useState(false);



    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };
    const handleCheckboxChange2 = (event) => {
        setIsChecked2(event.target.checked);
    };
    const handleCheckboxChange3 = (event) => {
        setIsChecked3(event.target.checked);
    };
    const handleCheckboxChange4 = (event) => {
        setIsChecked4(event.target.checked);
    };
    const handleCheckboxChange5 = (event) => {
        setIsChecked5(event.target.checked);
    };

    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
    };

    const handleSliderChange2 = (event, newValue) => {
        setSliderValue2(newValue);
    };
    const handleSliderChange3 = (event, newValue) => {
        setSliderValue3(newValue);
    };
    const handleSliderChange4 = (event, newValue) => {
        setSliderValue4(newValue);
    };


    const handleOpenSuggestion = () => {
        setSuggestion(!suggestion)
    }










    useEffect(() => {

        console.log('Updated Options:', options);
        displayProfile()
    }, [options]);






    const handleOptionChange = (optionValue, isChecked) => {
        setOptions((prevOptions) =>
            prevOptions.map((option) =>
                option.value === optionValue ? { ...option, checked: isChecked } : option
            )
        );
    };

    const handleSearchAI = () => {

        if (searchTweet == '') {

            toast.error("Please enter topic", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            console.log("print")
        }
        else {

            try {

                setLoadingSearch(true);
                let fdata = new FormData();

                fdata.append('id', userID)
                fdata.append('topic', searchTweet);
                fdata.append('minimum_likes', sliderValue)
                fdata.append('minimum_retweets', sliderValue2);
                fdata.append('published(atleast)', sliderValue3)
                fdata.append('published(atmost)', sliderValue4);
                fdata.append('No_hashtag', isChecked)
                fdata.append('No_ticker', isChecked2);
                fdata.append('No_crypto', isChecked3);
                fdata.append('No_thread', isChecked4)
                fdata.append('Only_thread', isChecked5);


                fetch(`${BASE_URL}api/search/viral`, {
                    method: 'POST',
                    body: fdata,
                    headers: {
                        Authorization: `Bearer ${tokenLogin}`,
                    }
                }).then(response => {

                    console.log("response search", response)
                    return response.json()
                }).then(data => {
                    console.log("tweet search", data);

                    if (data.code == 200) {
                        // window.location.reload()

                        // alert("fvrfg")
                        setDisplaySearchTweet(data.data)

                    }
                    else if (data.code == 400) {

                        toast.error(data.message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }


                    else {



                        toast.error(data.data.error.message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                    setLoadingSearch(false);
                })



            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (searchTweet == '') {

                toast.error("Please enter topic", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                console.log("print")
            }
            else {

                try {

                    setLoadingSearch(true);
                    let fdata = new FormData();

                    fdata.append('id', userID)
                    fdata.append('topic', searchTweet);
                    fdata.append('minimum_likes', sliderValue)
                    fdata.append('minimum_retweets', sliderValue2);
                    fdata.append('published(atleast)', sliderValue3)
                    fdata.append('published(atmost)', sliderValue4);
                    fdata.append('No_hashtag', isChecked)
                    fdata.append('No_ticker', isChecked2);
                    fdata.append('No_crypto', isChecked3);
                    fdata.append('No_thread', isChecked4)
                    fdata.append('Only_thread', isChecked5);


                    fetch(`${BASE_URL}api/search/viral`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${tokenLogin}`,
                        }
                    }).then(response => {

                        console.log("response search", response)
                        return response.json()
                    }).then(data => {
                        console.log("tweet search", data);

                        if (data.code == 200) {
                            // window.location.reload()

                            // alert("fvrfg")
                            setDisplaySearchTweet(data.data)

                        }
                        else if (data.code == 400) {

                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }


                        else {



                            toast.error(data.data.error.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                        setLoadingSearch(false);
                    })



                } catch (error) {
                    console.log(error)
                }
            }
        }

    }

    const displayProfile = async () => {

        try {
            const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                headers: {
                    Authorization: `Bearer ${tokenLogin}`,
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            console.log("published/profileeeee", data.data);
            if (data.code == 200) {
                setDisplayAImessage(data.data)

            }
            else {
                console.log("failed")
            }


        } catch (error) {
            console.error('Error fetching buyer data:', error);
        }
    };


    const handleNavigateAISettings = () => {

        navigate('/dashboard/app/settings')
    }


    const handleSub = () => {
        setIsOpen(true);
    };
    const handleCancelFilter = () => {
        setIsOpen(false)
    }

    const handleAdvanceFilter = () => {
        console.log("ischecked", isChecked, isChecked2, isChecked3, isChecked4, isChecked5, sliderValue, sliderValue2, sliderValue3, sliderValue4)
        setIsOpen(false)
    }

    const handleResetdvanceFilter = () => {
        setIsChecked(false)
        setIsChecked2(false)
        setIsChecked3(false)
        setIsChecked4(false)
        setIsChecked5(false)
        setSliderValue(0)
        setSliderValue2(0)
        setSliderValue3(0)
        setSliderValue4(0)
    }












































    const [contextData, setContextData] = useContext(MySideBarContext)
    console.log("contextData", contextData)
    const [dataLoading, setDataLoading] = useState(false)





    const currentDat = new Date();
    const initialDate = currentDat.toLocaleDateString('en-GB');
    const startDate = moment(initialDate, 'DD-MM-YYYY');
    const [currentMonth, setCurrentMonth] = useState(startDate.month());
    const year = startDate.year();
    const [selectedDate, setSelectedDate] = useState(null);
    const [modalText, setModalText] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [sectionTexts, setSectionTexts] = useState([]);
    const [time, setTime] = useState([
        '2:30 PM', '4:23 AM', '5:34 PM'
    ])
    const [dateTimeList, setDateTimeList] = useState([])


    const [dynamictime, setDynamicTime] = useState([])
    const [dynamicQueue, setDynamicQueue] = useState([])


    useEffect(() => {


        const fetchData = async () => {
            await displayDynamicQueue();
            await myQueuueTimeSlotDynanmic();
        };


        if (contextData.QUEUESaved == true) {


            fetchData();
        }
        console.log(contextData.QUEUESaved, 'Queueu SAVED');
    }, [contextData.QUEUESaved])


    useEffect(() => {

        const fetchData = async () => {
            await displayDynamicQueue();
            await myQueuueTimeSlotDynanmic();
        };

        fetchData();

    }, [])







    const myQueuueTimeSlotDynanmic = async () => {
        try {
            setDataLoading(true)
            const response = await fetch(`${BASE_URL}api/get/timeslot/${userID}`, {
                headers: {
                    Authorization: `Bearer ${tokenLogin}`,
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            console.log("published/tweet/list", data.data.timeslot);
            setDynamicTime(data.data.timeslot)
            setDataLoading(false)

        } catch (error) {
            console.error('Error fetching buyer data:', error);
        }
    };






    // const [context, setContext] = useContext(MyContext);
    const displayDynamicQueue = async () => {
        try {
            setDataLoading(true)
            const response = await fetch(`${BASE_URL}api/queue/list/${userID}`, {
                headers: {
                    Authorization: `Bearer ${tokenLogin}`,
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            console.log("published/queue", data);
            if (data.code == 200) {
                setDynamicQueue(data.data)
                setContextData({ QUEUESaved: false })

            }
            setDataLoading(false)


        } catch (error) {
            console.error('Error fetching buyer data:', error);
        }
    };


    console.log("dynamic-queue-1", dynamicQueue);



    //THIS IS THE ONE ---------
    useMemo(() => {
        let timeIndex = -1;
        let availableSlot = null;
        // let dateIndex = dateTimeList.findIndex(obj => moment(obj.formattedDate, 'dddd | MMMM D, YYYY').format('dddd') === moment(contextData?.handleDate, 'dddd | MMMM D, YYYY').format('dddd'))
        // console.log(dateIndex, moment(contextData?.handleDate, 'dddd | MMMM D, YYYY').format('dddd'), 'asdsad');
        // if(dateTimeList[dateIndex]){
        //     timeIndex = dateTimeList[dateIndex].time.findIndex(obj => obj.time === contextData?.handleTime)
        // }
        // if (dateTimeList[dateIndex] && dateTimeList[dateIndex].time[timeIndex]){
        // dateTimeList[dateIndex].time[timeIndex].text = contextData?.tweetText
        // dateTimeList[dateIndex].time[timeIndex].image = contextData?.selectedImages
        // dateTimeList[dateIndex].time[timeIndex].gif = contextData?.selectedGifpicker
        //     setDateTimeList([...dateTimeList])
        // }



        if (contextData?.handleDate) {
            let dateFilter = dateTimeList.filter(obj => moment(obj.formattedDate, 'dddd | MMMM D, YYYY').format('dddd') === moment(contextData?.handleDate, 'dddd | MMMM D, YYYY').format('dddd'))
            console.log("dvdsv", dateFilter)
            dateFilter.map(el => {

                timeIndex = el.time.findIndex(obj => obj.time === contextData?.handleTime)

                if (el && el.time[timeIndex]) {
                    el.time[timeIndex].text = contextData?.tweetText
                    el.time[timeIndex].image = contextData?.selectedImages
                    el.time[timeIndex].gif = contextData?.selectedGifpicker
                }

            }

            )
            setDateTimeList([...dateTimeList])
            console.log(dateTimeList, 'dateTimeLista');
            let list = dateTimeList;

            for (let i = 0; i < list.length; i++) {
                const currentTime = moment();
                const currentDate = moment(list[i].formattedDate, 'dddd | MMMM D, YYYY h:mm A').format('DD/MM/YYYY')
                let blankTextInd = list[i].time.findIndex(obj => obj.text === '');
                if (blankTextInd > -1) {
                    if (list[i] && list[i].time && list[i].time[blankTextInd]?.time && moment(currentDate + " " + list[i].time[blankTextInd]?.time, 'DD/MM/YYYY h:mm A').isSameOrAfter(currentTime)) {
                        console.log('future time');
                        const timeFirst = list[i].time[blankTextInd]
                        console.log("!st,,,", timeFirst)
                        setContext({ t: timeFirst, formattedDate: list[i].formattedDate })
                        break;
                    }
                }

                console.log(",,,", i);
            }
        } else {
            dateTimeList.some((el) => {
                console.log("e---l", el)
                const timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
                if (timeIndex !== -1) {
                    el.time[timeIndex].text = contextData?.tweetText;
                    el.time[timeIndex].image = contextData?.selectedImages;
                    el.time[timeIndex].gif = contextData?.selectedGifpicker;

                    return true; // Stop iterating after updating the first available slot


                }
                return false;
            });

            //     dateTimeList.some((el) => {
            //                 const timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
            //                 if (timeIndex !== -1) {
            //                   availableSlot = el.time[timeIndex];
            //                   el.time[timeIndex].text = contextData?.tweetText;
            //                   el.time[timeIndex].image = contextData?.selectedImages;
            //                   el.time[timeIndex].gif = contextData?.selectedGifpicker;
            //                   return true; // Stop iterating after updating the first available slot
            //                 }
            //                 return false;
            //               });

            //       setDateTimeList([...dateTimeList]);




        }
        //    console.log('Available Slot:', availableSlot);

    }, [contextData])
    console.log("contextData", contextData, dateTimeList);

    // useEffect(() => {
    //     let timeIndex = -1;
    //     let availableSlot = null;

    //     dateTimeList.some((el) => {
    //       timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
    //       if (timeIndex !== 1) {
    //         availableSlot = el.time[timeIndex];
    //         el.time[timeIndex].text = contextData?.tweetText;
    //         el.time[timeIndex].image = contextData?.selectedImages;
    //         el.time[timeIndex].gif = contextData?.selectedGifpicker;
    //         return true; // Stop iterating after updating the first available slot
    //       }
    //       return false;
    //     });

    //     setDateTimeList([...dateTimeList]);

    //     console.log('Available Slot:', availableSlot);
    //   }, [contextData]);

    //   console.log("contextData", contextData, dateTimeList);




    // useEffect(() => {
    //     let availableSlot = null;

    //     if (contextData?.handleDate) {
    //       let dateFilter = dateTimeList.filter(
    //         (obj) =>
    //           moment(obj.formattedDate, 'dddd | MMMM D, YYYY').format('dddd') ===
    //           moment(contextData?.handleDate, 'dddd | MMMM D, YYYY').format('dddd')
    //       );

    //       dateFilter.some((el) => {
    //         const timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
    //         if (timeIndex !== -1) {
    //           availableSlot = el.time[timeIndex];
    //           el.time[timeIndex].text = contextData?.tweetText;
    //           el.time[timeIndex].image = contextData?.selectedImages;
    //           el.time[timeIndex].gif = contextData?.selectedGifpicker;
    //           return true; // Stop iterating after updating the first available slot
    //         }
    //         return false;
    //       });

    //       setDateTimeList([...dateTimeList]);
    //     } else {
    //       dateTimeList.some((el) => {
    //         const timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
    //         if (timeIndex !== -1) {
    //           availableSlot = el.time[timeIndex];
    //           el.time[timeIndex].text = contextData?.tweetText;
    //           el.time[timeIndex].image = contextData?.selectedImages;
    //           el.time[timeIndex].gif = contextData?.selectedGifpicker;
    //           return true; // Stop iterating after updating the first available slot
    //         }
    //         return false;
    //       });

    //       setDateTimeList([...dateTimeList]);
    //     }

    //     console.log('Available Slot:', availableSlot);
    //   }, [contextData, dateTimeList]);



    useEffect(() => {
        const updateCurrentMonth = () => {
            const newMonth = moment().month();
            if (newMonth !== currentMonth) {
                setCurrentMonth(newMonth);
            }
        };

        const intervalId = setInterval(updateCurrentMonth, 60000); // Check every minute for date update

        return () => {
            clearInterval(intervalId); // Clear interval on component unmount
        };
    }, [currentMonth]);



    useEffect(() => {
        console.log('currentDate');

        let list = []
        let currentDate = moment(startDate); // Create a moment object for currentDate
        const endMonth = currentMonth + 2; // Display three months at a time
        const sectionKey = currentDate.format('YYYY-MM-DD');
        const sectionText = sectionTexts[sectionKey] || '';
        console.log('currentDate', currentDate);



        while (currentDate.year() === year && currentDate.month() <= endMonth) {

            //const slotForDays = dynamictime.filter(el => el.weekdays.includes(currentDate.format('dddd')))
            const slotForDays = (dynamictime ?? []).filter(el => el.weekdays.includes(currentDate.format('dddd')));

            // const slottext = dynamicQueue.map(es => es.text)
            console.log(dynamictime, "---dynamictime", slotForDays, "---slotForDays", dynamicQueue, "dynamicQueue", currentDate.format('dddd'), 'asasas');
            let timeslotArr = slotForDays?.map(a => {
                // console.log(a.weekdays, 'aaaaaa');
                // const ATime = moment(a.timeslot,'hh:mm:ss').format('h:mm A ')
                const ATime = a.timeslot
                //const queueItem = dynamicQueue.findIndex(item => item.schedule_time === ATime && item.schedule_date === currentDate.format('D MMMM YYYY'));
                // console.log("qute", queueItem)
                let textValue = ""
                let GivValue = ""
                let ImageValue = ""
                let id = ""
                let is_thread = ""
                let tweet_id = ""
                let thread_tweet_id = ""
                if (dynamicQueue && dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime) > -1
                    && dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].text
                ) {
                    id = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].id
                    textValue = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].text

                    GivValue = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].gif

                    ImageValue = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].image

                    console.log("images", ImageValue)

                    return {
                        id: id, time: ATime, text: textValue, date: "", image: ImageValue ? ImageValue : '', gif: GivValue ? GivValue : ''
                    }
                }

                else if (dynamicQueue && dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime) > -1
                    && dynamicQueue[dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)][0].text
                ) {
                    let dynamicArrayIndex = dynamicQueue && dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)
                    let dynamicArray = dynamicQueue[dynamicArrayIndex].map(element => {
                        id = element.id
                        textValue = element.text

                        GivValue = element.gif

                        ImageValue = element.image
                        is_thread = dynamicQueue[dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)][0].is_thread
                        tweet_id = dynamicQueue[dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)][0].tweet_id
                        thread_tweet_id = dynamicQueue[dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)][0].thread_tweet_id
                        console.log("is_thread", is_thread)
                        return {
                            id: id, time: ATime, text: textValue, date: "", image: ImageValue ? ImageValue : '', gif: GivValue ? GivValue : '', is_thread: is_thread, tweet_id: tweet_id, thread_tweet_id: thread_tweet_id
                        }

                    })
                    return dynamicArray;




                }
                else {
                    return {
                        id: id, time: ATime, text: textValue, date: "", image: ImageValue ? ImageValue : '', gif: GivValue ? GivValue : ''
                    }
                }

                // return {
                //     id: id, time: ATime, text: textValue, date: "", image: ImageValue ? ImageValue : '', gif: GivValue ? GivValue : ''
                // }

            }
            )

            const formattedDate = currentDate.format('dddd | MMMM D, YYYY');
            list.push({
                formattedDate: formattedDate,
                time: timeslotArr,
                sectionKey: sectionKey
            })
            currentDate.add(1, 'day'); // Move to the next day

        }

        setDateTimeList([...list])


        for (let i = 0; i < list.length; i++) {
            const currentTime = moment();
            const currentDate = moment(list[i].formattedDate, 'dddd | MMMM D, YYYY HH:mm').format('DD/MM/YYYY')
            let blankTextInd = list[i].time.findIndex(obj => obj?.text === '' &&
                moment(currentDate + " " + obj?.time, 'DD/MM/YYYY HH:mm').isSameOrAfter(currentTime)
            );
            console.log("listFor", list[i], i, blankTextInd)
            if (blankTextInd > -1) {
                console.log("listFor, !time,,,", list[i].time[blankTextInd]?.time, currentDate, currentTime)
                // if (list[i] && list[i].time && list[i].time[blankTextInd]?.time &&
                //     moment(currentDate + " " + list[i].time[blankTextInd]?.time, 'DD/MM/YYYY HH:mm').isSameOrAfter(currentTime)) {
                console.log('future time', list[i].formattedDate);
                const timeFirst = list[i].time[blankTextInd]
                console.log("listFor, !st,,,", timeFirst, list[i], blankTextInd, i)
                setContext({ t: timeFirst, formattedDate: list[i].formattedDate })
                break;
                // }
            }
            console.log(",,,", i, currentDate, currentTime);
        }





        // for (let i = 0; i < list.length; i++) {

        //     const currentTime = moment();
        //     const futureTimeSlotIndex = list[i].time.findIndex(
        //       (obj) => moment(obj.time, "h:mm A").isSameOrAfter(currentTime)
        //     );

        //     if (futureTimeSlotIndex > -1) {
        //       const timeFirst = list[i].time[futureTimeSlotIndex];
        //       console.log("!st,,,", timeFirst);
        //       setContext({ t: timeFirst, formattedDate: list[i].formattedDate });
        //       break;
        //     }
        //     console.log(",,,", i);
        //   }


    }, [dynamictime])

    console.log(dateTimeList, 'dateTimeList');




    // const handleDragEnd = async (result) => {
    //     console.log(result, 'handleDragEndresult');
    //     if (!result.destination) return; // Item dropped outside the droppable area

    //     // const { source, destination } = result;
    //     // const updatedTimeSlots = [...dateTimeList]; // Create a copy of the original timeSlots array
    //     // // Get the source and destination indexes
    //     // const sourceIndex = source.index;
    //     // const destinationIndex = destination.index;
    //     // console.log(updatedTimeSlots[source.droppableId.split('-')[1]], 'handleDragEndresultaa', sourceIndex, destinationIndex);

    //     // // Retrieve the source and destination time objects
    //     // const sourceTime = updatedTimeSlots[source.droppableId.split('-')[1]].time[sourceIndex];
    //     // const destinationTime = updatedTimeSlots[destination.droppableId.split('-')[1]].time[destinationIndex];

    //     // // Swap the text values between the source and destination time objects
    //     // const sourceText = sourceTime.text;
    //     // sourceTime.text = destinationTime.text;
    //     // destinationTime.text = sourceText;

    //     // const sourceImage = sourceTime.image;
    //     // sourceTime.image = destinationTime.image;
    //     // destinationTime.image = sourceImage;

    //     // // Swap the gif values between the source and destination time objects
    //     // const sourceGif = sourceTime.gif;
    //     // sourceTime.gif = destinationTime.gif;
    //     // destinationTime.gif = sourceGif;
    //     // console.log(updatedTimeSlots[destination.droppableId.split('-')[1]], 'handleDragEndresultbb');

    //     // // Update the state with the rearranged items
    //     // setDateTimeList(updatedTimeSlots);
    //     // callPostAPI(updatedTimeSlots);

    // };








    return (
        <div id='content-page' className='content-page' style={{ backgroundColor: 'white' }} >
            {(displayAImessage?.message_for_AI_Settings !== ''
                && displayAImessage?.message_for_AI_Settings !== undefined
            ) ||
                (displayAImessage?.message_for_user_subscription !== '' &&
                    displayAImessage?.message_for_user_subscription !== undefined) ?

                <Alert variant="danger" className='alert-text'>
                    <FontAwesomeIcon icon={faCircleInfo} className='alert-icon-1' />
                    {displayAImessage?.message_for_AI_Settings || displayAImessage?.message_for_user_subscription}. <span className='span-alert' onClick={handleNavigateAISettings}>
                        Please update them here
                    </span>
                </Alert>
                :
                ''
            }


            <div className='search-tweet'>
                <h5>Search posts</h5>
                <hr />
            </div>
            <Container>
                <div className='search-box-conatiner'>
                    <h3>Search for any topic & find viral posts to get inspired by</h3>
                    <p>We use AI to analyze tweets & match them with your search</p>

                    <div className='search-input'>
                        <FontAwesomeIcon icon={faSearch} className='search-icon' onClick={handleSearchAI} />
                        <input type="text"
                            placeholder='Search'
                            className='search-input-text'
                            value={searchTweet}
                            onChange={(e) => setSearchTweet(e.target.value)}
                            onKeyPress={handleKeyPress}

                        />


                        <FontAwesomeIcon icon={faSliders} className='search-icon' onClick={handleSub} />

                    </div>
                    <div className='search-tips-serachtabs' onClick={handleOpenSuggestion}>
                        <p>Search tips {suggestion ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faChevronRight} />}</p>
                        {suggestion && (
                            <div className='search-tips-serachtabs-ul'>
                                <ul>
                                    <li>Use "Keyword" for exact-match search.</li>
                                    <li>Use @ to search for an account (ex: @tibo_maker)</li>
                                    <li>Use "Keyword", "-excluding keyword"</li>
                                </ul>
                            </div>
                        )}
                    </div>


                    {isOpen && (
                        <div className="filter-options">

                            <div className='filter-cancel'>
                                <FontAwesomeIcon icon={faX} onClick={handleCancelFilter} />
                            </div>


                            <div className='display-maximum-slider'>
                                <p>Minimum likes</p>
                                <span>at least {sliderValue}</span>


                            </div>
                            <div>

                                <Slider
                                    defaultValue={0.0}
                                    aria-label="Default"
                                    valueLabelDisplay="auto"
                                    color="warning"
                                    size="large"
                                    value={sliderValue}
                                    onChange={handleSliderChange}
                                    step={10}
                                    max={1000}
                                    min={0}
                                    className='slider-filter'
                                />


                            </div>

                            <div className='display-maximum-slider'>
                                <p>Minimum retweets</p>
                                <span>at least {sliderValue2}</span>


                            </div>

                            <div>
                                <Slider
                                    defaultValue={0.0}
                                    aria-label="Default"
                                    valueLabelDisplay="auto"
                                    color="warning"
                                    size="large"
                                    value={sliderValue2}
                                    onChange={handleSliderChange2}
                                    step={10}
                                    max={1000}
                                    min={0}
                                    className='slider-filter'
                                />
                            </div>

                            <div className='display-maximum-slider'>
                                <p>Published</p>
                                <span>at least {sliderValue3} days ago</span>


                            </div>

                            <div>
                                <Slider
                                    defaultValue={0.0}
                                    aria-label="Default"
                                    valueLabelDisplay="auto"
                                    color="warning"
                                    size="large"
                                    value={sliderValue3}
                                    onChange={handleSliderChange3}
                                    step={10}
                                    max={1000}
                                    min={0}
                                    className='slider-filter'
                                />
                            </div>



                            <div className='display-maximum-slider'>
                                <p>Published</p>
                                <span>at most {sliderValue4} days ago</span>


                            </div>

                            <div>
                                <Slider
                                    defaultValue={0.0}
                                    aria-label="Default"
                                    valueLabelDisplay="auto"
                                    color="warning"
                                    size="large"
                                    value={sliderValue4}
                                    onChange={handleSliderChange4}
                                    step={10}
                                    max={1000}
                                    min={0}
                                    className='slider-filter'
                                />
                            </div>
                            <div>

                                <Form.Check
                                    // inline
                                    label="No Hashtag"
                                    name="group1"
                                    type='checkbox'
                                    id='checkbox'

                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                            </div>
                            <div>
                                <Form.Check
                                    // inline
                                    label="No Ticker"
                                    name="group1"
                                    type='checkbox'
                                    id='checkbox'
                                    className='checkbox-filter'
                                    checked={isChecked2}
                                    onChange={handleCheckboxChange2}
                                />
                            </div>
                            <div>
                                <Form.Check
                                    // inline
                                    label="No Crypto"
                                    name="group1"
                                    type='checkbox'
                                    id='checkbox'

                                    checked={isChecked3}
                                    onChange={handleCheckboxChange3}
                                />
                            </div>
                            <div>
                                <Form.Check
                                    // inline
                                    label="No Thread"
                                    name="group1"
                                    type='checkbox'
                                    id='checkbox'

                                    checked={isChecked4}
                                    onChange={handleCheckboxChange4}
                                />
                            </div>
                            <div>
                                <Form.Check
                                    // inline
                                    label="Only Thread"
                                    name="group1"
                                    type='checkbox'
                                    id='checkbox'

                                    checked={isChecked5}
                                    onChange={handleCheckboxChange5}
                                />
                            </div>

                            <div className='button-filter'>

                                <button onClick={handleResetdvanceFilter} className='button-reset'>Reset </button>

                                <button onClick={handleAdvanceFilter} className='button-submit'>Submit </button>
                            </div>


                        </div>
                    )}


                </div>

                {displaySearchTweet == [] ? (
                    <div className='text-center searchtab-notext'>
                        <h5>No data available</h5>
                    </div>
                ) : (
                    <div className='dail-masornry'>
                        {loadingSearch ? (
                            <div className='text-center'>
                                <Spinner animation="border" size="lg" variant="primary" className='mr-5' style={{ marginRight: 5 }} />
                            </div>
                        ) : (
                            <Masonry
                                breakpointCols={breakpointColumnsObj}
                                className="my-masonry-grid"
                                columnClassName="my-masonry-grid_column"
                            >
                                {displaySearchTweet?.map((data, index) => {
                                    console.log("displa", data)


                                    const renderTooltip = (
                                        <Popover id={`popover-${index}`}>
                                            <Popover.Header as="h3" className='popover-header-dailyins'>
                                                <div className="DailyIns-popover-header">
                                                    <div className='DailyIns-popover-image'>
                                                        <img src={data?.user_profile?.profile_image_url} className="popover-img-fluid" alt="" />
                                                    </div>
                                                    <div className='popver-paragrapgh'>
                                                        <h6>{data?.user_profile?.name}</h6>
                                                        <span>@{data?.user_profile?.screen_name}</span>
                                                    </div>
                                                </div>
                                            </Popover.Header>
                                            <Popover.Body className='popover-body-dailyIns'>
                                                {data?.user_profile?.description}
                                                <div className='popover-followers'>
                                                    <span> <strong>{data?.user_profile?.followings_count}</strong> Following</span>
                                                    <span> <strong>{data?.user_profile?.followers_count}</strong> Followers</span>
                                                </div>
                                                <div className='popover-followers'>
                                                    <span> <FontAwesomeIcon icon={faLocationDot} /> {data?.user_profile?.location}</span>
                                                    <span className='popver-link'> <FontAwesomeIcon icon={faLink} /> <a href={data?.user_profile?.profile_url} target="_blank" rel="noopener noreferrer">Profile Link</a></span>
                                                </div>
                                            </Popover.Body>
                                        </Popover>
                                    );
                                    const handleTrendingTweetContext = (data) => {
                                        localStorage.removeItem('savedValue')
                                        setContext({ ...context, t: { ...context.t, text: data.tweet_text } })


                                    }


                                    const handleLikeAndDislike = (e) => {
                                        e.preventDefault()
                                        try {

                                            let fdata = new FormData();


                                            fdata.append('id', userID);
                                            fdata.append('tweet_id', data.tweet_id);

                                            fetch(`${BASE_URL}api/like/tweet`, {
                                                method: 'POST',
                                                body: fdata,
                                                headers: {
                                                    Authorization: `Bearer ${tokenLogin}`,
                                                }
                                            }).then(response => {

                                                console.log("response like", response)
                                                return response.json();
                                            }).then(data => {
                                                console.log("like and dislike", data);
                                                if (data.code == 200) {
                                                    toast.success(data.message, {
                                                        position: "top-center",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                        theme: "light",
                                                    });
                                                    // displayTrendingTweets()
                                                    setReTweet(!retweet);

                                                }

                                                else {
                                                    toast.error(data.message, {
                                                        position: "top-center",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                        theme: "light",
                                                    });
                                                    // displayTrendingTweets()


                                                }

                                            })

                                        } catch (error) {
                                            console.log(error)
                                        }
                                        console.log("tweet-id", data.tweet_id)
                                    }


                                    const handleRetweet = (e) => {
                                        e.preventDefault()
                                        try {

                                            let fdata = new FormData();


                                            fdata.append('id', userID);
                                            fdata.append('tweet_id', data.tweet_id);

                                            fetch(`${BASE_URL}api/retweet/tweet`, {
                                                method: 'POST',
                                                body: fdata,
                                                headers: {
                                                    Authorization: `Bearer ${tokenLogin}`,
                                                }
                                            }).then(response => {

                                                console.log("response like", response)
                                                return response.json();
                                            }).then(data => {
                                                console.log("like and dislike", data);
                                                if (data.code == 200) {
                                                    toast.success(data.message, {
                                                        position: "top-center",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                        theme: "light",
                                                    });
                                                    // displayTrendingTweets()
                                                    setReTweet(!retweet);

                                                }

                                                else {

                                                    toast.error(data.message, {
                                                        position: "top-center",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                        theme: "light",
                                                    });
                                                }

                                            })

                                        } catch (error) {
                                            console.log(error)
                                        }
                                    }

                                    const handleHideApi = (e) => {
                                        e.preventDefault()

                                        try {

                                            let fdata = new FormData();
                                            fdata.append('id', userID);
                                            fdata.append('tweet_id', data.tweet_id)


                                            fetch(`${BASE_URL}api/hide/search/tweet`, {
                                                method: 'POST',
                                                body: fdata,
                                                headers: {
                                                    Authorization: `Bearer ${tokenLogin}`,
                                                }
                                            }).then(response => {
                                                console.log('repsonse hide', response);
                                                if (response.ok) {
                                                    console.log('Data deleted successfully.');
                                                    // window.location.reload()


                                                    // setDraftData(DraftData.filter(item => item.id !== modalData.id));
                                                } else {
                                                    console.error('Failed to delete data.');

                                                }
                                                return response.json()
                                            }).then(data => {
                                                console.log("data.delete", data)
                                                if (data.code == 200) {
                                                    toast.success(data.message, {
                                                        position: "top-center",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                        theme: "light",
                                                    });
                                                }
                                                else {
                                                    toast.error(data.message, {
                                                        position: "top-center",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                        theme: "light",
                                                    });
                                                }
                                            })

                                        } catch (error) {

                                        }

                                    }

                                    return (
                                        <>
                                            <Card className='DailyIns-card'>
                                                <Card.Header as="h5">
                                                    <div className="DailyIns-card-header">
                                                        <div className='DailyIns-card-image'>
                                                            <OverlayTrigger
                                                                placement="right"
                                                                // delay={{ show: 250, hide: 400 }}
                                                                overlay={renderTooltip}
                                                                trigger="click"

                                                            >
                                                                <img src={data?.user_profile?.profile_image_url} className="DailyIns-img-fluid" alt="" />
                                                            </OverlayTrigger>
                                                        </div>

                                                        <div className='dailyIns-span'>
                                                            <span>{moment(data.tweet_created_at).format('DD/MM/YYYY')}</span>
                                                            {/* <button> <FontAwesomeIcon icon={faBolt} /></button> */}

                                                        </div>

                                                    </div>


                                                </Card.Header>
                                                <Card.Body>

                                                    <Card.Text>
                                                        {data.tweet_text}



                                                    </Card.Text>
                                                    <Row>
                                                        {data?.media?.map((item, index2) => {
                                                            return (
                                                                <>
                                                                    <Col lg={4} md={4} sm={4}>
                                                                        <img src={item} className='daily-image-card' />
                                                                    </Col>
                                                                </>
                                                            )

                                                        })}

                                                    </Row>
                                                </Card.Body>


                                                <Card.Footer className="DailyIns-Card-footer">

                                                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: 10, paddingLeft: 10, paddingTop: 10, paddingBottom: 10 }}>
                                                        <div>
                                                            <span><FontAwesomeIcon icon={faHeart} style={{ color: 'red' }} onClick={handleLikeAndDislike} /> {data.favourites}
                                                            </span>

                                                            <span><FontAwesomeIcon icon={faRetweet} style={{ color: 'green', paddingLeft: 10 }} onClick={handleRetweet} />
                                                                {data.retweets}    </span>
                                                            <span><FontAwesomeIcon icon={faEyeSlash} style={{ color: 'grey', paddingLeft: 10 }} onClick={handleHideApi} />
                                                                {data.retweets}    </span>
                                                        </div>

                                                        <div className='DailyIns-Card-footer-right'>
                                                            {/* <button onClick={() => setContext({ ...context, t: { ...context.t, text: data.tweet_text } })} >Edit</button> */}
                                                            <button onClick={() => handleTrendingTweetContext(data)}>Edit</button>
                                                            {/* <FontAwesomeIcon icon={faEllipsisVertical} className='DailyIns-Card-footer-right-icon' /> */}
                                                        </div>
                                                    </div>
                                                </Card.Footer>

                                            </Card>
                                        </>
                                    )
                                })}

                            </Masonry>)}
                    </div>)}




            </Container>
        </div>
    )
}
