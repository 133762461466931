import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Row, Col, Form, Button, Image } from 'react-bootstrap';

import logo from '../../../assets/images/tweetnowlogo.png'
import xblue from '../../../assets/images/xblue-logo-blue.png'
import signuplogo from '../../../assets/images/signuplogo.png'
import { useNavigate } from 'react-router-dom'
import './styleAuth.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import X from '../../../assets/images/twitter-X.png'




export default function Header() {

  const emailTo = localStorage.getItem('email-to')
  const emailSwitch = localStorage.getItem('emailSwitch')
  const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
  // static propTypes = {
  //   authenticated: PropTypes.bool.isRequired
  // };

  const navigate = useNavigate()


  const _handleSignInClick = () => {


    // if (localStorage.getItem('accessToken')) {
    //   navigate('dashboard/app/notification')
    //   window.location.reload()
    // }
    // else {
    //   window.open("https://app.xblue.ai/Xblue/login/twitter", "_self")
    // }



    if (localStorage.getItem('accessToken-login')) {
      navigate('dashboard/app/notification')
      window.location.reload()
    }
    else {
      window.open("https://app.xblue.ai/Xblue/login/twitter", "_self")
    }




    //   if (localStorage.getItem("userloggedin") === 'true') {

    //     const targetUser = userDataArray.find(user => user.email === emailTo);

    //     if (targetUser) {
    //       const { userID, accessToken } = targetUser;

    //       if (accessToken) {

    //         navigate('dashboard/app/notification')
    //         // window.location.reload()
    //       }
    //       else {

    //         window.open("https://app.xblue.ai/Xblue/login/twitter", "_self")
    //       }

    //     }
    //   }
    //   else if (localStorage.getItem("userloggedin") === 'false') {
    //     const targetUser = userDataArray.find(user => user.email === emailSwitch);

    //     if (targetUser) {
    //       const { userID, accessToken } = targetUser;

    //       if (accessToken) {
    //         navigate('dashboard/app/notification')
    //         // window.location.reload()
    //       }
    //       else {

    //         window.open("https://app.xblue.ai/Xblue/login/twitter", "_self")
    //       }

    //     }

    //   }
    //   else {
    //     window.open("https://app.xblue.ai/Xblue/login/twitter", "_self")
    //   }
  }



  // _handleLogoutClick = () => {
  //   // Logout using Twitter passport api
  //   // Set authenticated state to false in the HomePage
  //   window.open("http://localhost:4000/auth/logout", "_self");
  //   this.props.handleNotAuthenticated();
  // };








  console.log("singin", localStorage.getItem('providerToken'))
  // console.log("singin-2", localStorage.getItem('secretKey'))




  return (
    <>
      <section className="sign-in-page">

        {/* <Container className="p-0"> */}
        <Row className="no-gutters">
          <Col md="6" className="bg-white pt-5 pt-5 pb-lg-0 pb-5">

            <div className="sign-in-from">
              {/* <Link className="sign-up-logo mb-5" to="#">
                           <Image src={signuplogo} className="img-fluid" alt="logo"/>
                        </Link> */}
              {/* <p class="authtext">Authorize post Now to access your account..</p> */}
              <div>
                <Link className="sign-in-logo mb-5" to="#">
                  <Image src={xblue} className="img-fluid" alt="logo" />
                </Link>
                <p class="atext">Welcome to Xblue<br />Automate your posts publishing</p>


              </div>

              {/* <br></br> */}
              <Form className="mt-4">
                <div className="btndiv">

                  <button type="button" onClick={_handleSignInClick} className="button-login">Sign in with X</button>

                </div>

              </Form>


            </div>
          </Col>
          <Col md="6">
            <div >
              {/* <div className="sign-in-detail text-white">
                <ul>
                  <p>This application will be able to: </p>
                  <li>See posts from your timeline (including protected posts) as well as your Lists and collections.</li>
                  <li>See your X profile information and account settings.</li>
                  <li>See accounts you follow, mute, and block.</li>
                  <li>Follow and unfollow accounts for you.</li>
                  <li>Update your profile and account settings.</li>
                  <li>Post and delete posts for you, and engage with posts posted by others (Like, un-Like, or reply to a post, Retweet, etc.) for you.</li>
                  <li>Create, manage, and delete Lists and collections for you.</li>
                  <li>Mute, block, and report accounts for you.</li>
                  <li>Send Direct Messages for you and read, manage, and delete your Direct Messages.</li>
                  <li>See your email address.</li>
                </ul>
                <div className="authtext">
                  <Link to="app/privacy-policy" style={{ color: 'white' }}>
                    <span>Privacy policy</span> </Link>||<Link to="app/terms-condition" style={{ color: 'white' }}> <span>Terms and conditions</span></Link></div>
              </div> */}

              <div className="text-center title-text-center">
                <h1>Your personal assistant to grow & monetize your <img src={X} /> audience  </h1>

              </div>


            </div>


          </Col>

        </Row>

      </section>
    </>
  );
}



