import React, { useState, useEffect, useCallback, useContext, useRef } from 'react'
import { Row, Col, Container, Dropdown, Button, Card, Modal } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Ellipse from '../../../assets/images/Ellipse.png'
import 'moment/locale/en-gb';
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import Spinner from 'react-bootstrap/Spinner';
import noimage from '../../../assets/noimage.png'
import { ToastContainer, toast } from 'react-toastify';
import Microlink from '@microlink/react';


// import Card from '../../../components/Card'
import { Link } from 'react-router-dom'

// img
import user1 from '../../../assets/images/user/01.jpg'
import user2 from '../../../assets/images/user/02.jpg'
import user3 from '../../../assets/images/user/03.jpg'
import user4 from '../../../assets/images/user/04.jpg'
import user5 from '../../../assets/images/user/05.jpg'
import user6 from '../../../assets/images/user/06.jpg'
import user7 from '../../../assets/images/user/07.jpg'
import user8 from '../../../assets/images/user/08.jpg'
import user9 from '../../../assets/images/user/09.jpg'
import user10 from '../../../assets/images/user/10.jpg'
import user11 from '../../../assets/images/user/11.jpg'
import user12 from '../../../assets/images/user/12.jpg'
import user13 from '../../../assets/images/user/13.jpg'
import user14 from '../../../assets/images/user/14.jpg'
import icon12 from '../../../assets/images/icon/12.png'
import rec from '../../../assets/images/Rectangle.png'


import './style.css'
import { MySideBarContext, MyContext } from '../../../components/MyQueue/MyContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faRetweet, faCalendar, faUpload, faFilePen, faCommentsDollar, faCalendarAlt, faFileArrowUp, faCircleInfo, faLocationDot, faLink, faPenNib, faEye, faEyeSlash, faLanguage, faCalendarPlus, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import MyQueue from '../../../components/MyQueue';
import MyDraft from '../../../components/MyDrafts';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../utilities/Config';


const SplideArrow = ({ arrowType }) => {
   // return <button className={`splide-arrow splide-arrow--${arrowType}`} />;
};


const Notification = () => {



   moment.locale('en-gb');

   const navigate = useNavigate()

   const [displayQueueData, setDisplayQueueData] = useState([])
   const [displayPostData, setDisplayPostData] = useState([])
   const [displayTending, setDisplayTrending] = useState([])
   const [displayMessageTrending, setDisplayMessageTrending] = useState('')
   const [context, setContext] = useContext(MyContext);
   const [trendingLoader, setTrendingLoader] = useState(false)

   const tokenLogin = localStorage.getItem('accessToken')
   const userID = localStorage.getItem('UserID')
   const [contextData, setContextData] = useContext(MySideBarContext)
   const [modalData, setModalData] = useState('');

   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);

   const [show2, setShow2] = useState(false);
   const handleClose2 = () => setShow2(false);

   const [show3, setShow3] = useState(false);
   const handleClose3 = () => setShow3(false);


   const [showSch, setShowSch] = useState(false);
   const handleCloseSch = () => setShowSch(false);


   const [showSchThr, setShowSchThr] = useState(false);
   const handleCloseSchThr = () => setShowSchThr(false);


   const [showDelete, setShowDelete] = useState(false);
   const [showDelete2, setShowDelete2] = useState(false);

   const handleCloseDelete = () => {

      setShowDelete(false)
   };
   const handleCloseDelete2 = () => {

      setShowDelete2(false)

   };


   const [liked, setLiked] = useState(false);
   const [retweet, setReTweet] = useState(false);

   const [languageLoading, setLanguageLoading] = useState(false)
   const [langDropdownIndex, setLangDropdownIndex] = useState(null)
   const Language = ["English",
      "Spanish",
      "French",
      "Albanian",
      "German",
      "Portuguese",
      "Vietnamese",
      "Italian",
      "Dutch",
      "Hindi",
      "Ukrainian",
      "Russian",
      "Arabic",
      "Chinese"]
   const [isOpenDrop, setIsOpenDrop] = useState(false)
   const [selectedValue, setSelectedValue] = useState(null);


   // const popoverRef = useRef(null);

   const [openPopoverIndex, setOpenPopoverIndex] = useState(null);
   const popoverRef = useRef(null);
   const popoverRef2 = useRef(null);

   const togglePopover = (index) => {
      if (openPopoverIndex === index) {
         // Clicking on the same icon again should close the popover
         setOpenPopoverIndex(null);
      } else {
         // Clicking on a different icon should open the popover
         setOpenPopoverIndex(index);
      }
   };

   const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
         // Click occurred outside the popover, so close it
         setOpenPopoverIndex(null);
      }
   };

   useEffect(() => {
      // Attach the click event listener when the component mounts
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
         // Clean up the event listener when the component unmounts
         document.removeEventListener('mousedown', handleClickOutside);
      };
   }, []);






   const handleClickOutside2 = (event) => {
      if (popoverRef2.current && !popoverRef2.current.contains(event.target)) {
         // Click occurred outside the popover, so close it
         setIsOpenDrop(null);
      }
   };

   useEffect(() => {
      // Attach the click event listener when the component mounts
      document.addEventListener('mousedown', handleClickOutside2);
      return () => {
         // Clean up the event listener when the component unmounts
         document.removeEventListener('mousedown', handleClickOutside2);
      };
   }, []);




   useEffect(() => {
      if (contextData.publishedPost) {
         displayPusblished()
      }

   }, [contextData.publishedPost])

   useEffect(() => {
      if (contextData.QUEUESaved) {
         displayschduleLater()
      }

   }, [contextData.QUEUESaved])


   const emailSwitch = localStorage.getItem('emailSwitch')

   const userLoggedin = localStorage.getItem('userloggedin')

   useEffect(() => {
      displayTrendingTweets()
      const fetchData = async () => {
         // await displayschdulePost();
         await displayPusblished();
         await displayProfile()
         await displayschduleLater();
      };
      fetchData();

   }, []);

   const displayTrendingTweets = async () => {
      // try {
      //    setTrendingLoader(true)

      //    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


      //    const targetUser = userDataArray.find(user => user.email === emailTo);

      //    if (targetUser) {
      //       const { userID, accessToken } = targetUser;

      //       const response = await fetch(`${BASE_URL}api/trending/tweet/${userID}`, {
      //          headers: {
      //             Authorization: `Bearer ${accessToken}`,
      //             'Content-Type': 'application/json'
      //          }
      //       });
      //       const data = await response.json();
      //       console.log("data,trending", data)

      //       if (data.code == 200) {
      //          setDisplayTrending(data)
      //       }
      //       else if (data.code == 400) {
      //          setDisplayMessageTrending(data)
      //          setDisplayTrending(data)
      //       }



      //       setTrendingLoader(false)
      //    } else {
      //       console.log(`User with email ${emailTo} not found`);
      //    }
      // } catch (error) {
      //    console.error('Error fetching buyer data:', error);
      // }

      if (localStorage.getItem("userloggedin") === 'true') {

         try {
            setTrendingLoader(true)

            const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


            const targetUser = userDataArray.find(user => user.email === emailTo);

            if (targetUser) {
               const { userID, accessToken } = targetUser;

               const response = await fetch(`${BASE_URL}api/trending/tweet/${userID}`, {
                  headers: {
                     Authorization: `Bearer ${accessToken}`,
                     'Content-Type': 'application/json'
                  }
               });
               const data = await response.json();


               if (data.code == 200) {
                  setDisplayTrending(data)
               }
               else if (data.code == 400) {
                  setDisplayMessageTrending(data)
                  setDisplayTrending(data)
               }



               setTrendingLoader(false)
            } else {
               console.log(`User with email ${emailTo} not found`);
            }
         } catch (error) {
            console.error('Error fetching buyer data:', error);
         }
      }
      else {
         try {
            setTrendingLoader(true)

            const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


            const targetUser = userDataArray.find(user => user.email === emailSwitch);

            if (targetUser) {
               const { userID, accessToken } = targetUser;

               const response = await fetch(`${BASE_URL}api/trending/tweet/${userID}`, {
                  headers: {
                     Authorization: `Bearer ${accessToken}`,
                     'Content-Type': 'application/json'
                  }
               });
               const data = await response.json();
               console.log("data,trending", data)

               if (data.code == 200) {
                  setDisplayTrending(data)
               }
               else if (data.code == 400) {
                  setDisplayMessageTrending(data)
                  setDisplayTrending(data)
               }



               setTrendingLoader(false)
            } else {
               console.log(`User with email ${emailTo} not found`);
            }
         } catch (error) {
            console.log(error)
         }
      }
   }





   const providerToken = localStorage.getItem('providerToken')
   const providerID = localStorage.getItem('providerID')
   const OauthToken = localStorage.getItem('oAuthToken')

   const [seleGif, setSeleGif] = useState([])

   const convertURLToFile = async (url) => {
      try {
         const response = await fetch(url);
         const blob = await response.blob();
         const file = new File([blob], 'gif_file.gif', { type: 'image/gif' });
         return file;
      } catch (error) {
         console.error('Error converting URL to file:', error);
         return null;
      }
   };










   const displayPusblished = async () => {
      if (localStorage.getItem("userloggedin") === 'true') {
         try {


            const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


            const targetUser = userDataArray.find(user => user.email === emailTo);

            if (targetUser) {
               const { userID, accessToken } = targetUser;

               const response = await fetch(`${BASE_URL}api/published/tweet/list/${userID}`, {
                  headers: {
                     Authorization: `Bearer ${accessToken}`,
                     'Content-Type': 'application/json'
                  }
               });
               const data = await response.json();
               console.log("published/tweet/list", data);
               if (data.code == 200) {
                  setDisplayPostData(data.data)
                  setContextData({ publishedPost: false })

               }
               else if (data.code == 429) {
                  // alert("cse")

               }
            } else {
               console.log("error")
            }


         } catch (error) {
            console.error('Error fetching buyer data:', error);
         }
      }
      else {
         try {


            const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


            const targetUser = userDataArray.find(user => user.email === emailSwitch);

            if (targetUser) {
               const { userID, accessToken } = targetUser;

               const response = await fetch(`${BASE_URL}api/published/tweet/list/${userID}`, {
                  headers: {
                     Authorization: `Bearer ${accessToken}`,
                     'Content-Type': 'application/json'
                  }
               });
               const data = await response.json();
               console.log("published/tweet/list", data);
               if (data.code == 200) {
                  setDisplayPostData(data.data)
                  setContextData({ publishedPost: false })

               }
               else if (data.code == 429) {
                  // alert("cse")

               }
            } else {
               console.log("error")
            }


         } catch (error) {
            console.error('Error fetching buyer data:', error);
         }
      }
   };










   const displayschduleLater = async () => {
      if (localStorage.getItem("userloggedin") === 'true') {
         try {


            const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


            const targetUser = userDataArray.find(user => user.email === emailTo);

            if (targetUser) {
               const { userID, accessToken } = targetUser;

               const response = await fetch(`${BASE_URL}api/schedule/list/${userID}`, {
                  headers: {
                     Authorization: `Bearer ${accessToken}`,
                     'Content-Type': 'application/json'
                  }
               });
               const data = await response.json();
               console.log("schduled/tweet/list", data);
               if (data.code == 200) {
                  setDisplayQueueData(data?.data)
                  setContextData({ ScheduleData: false })

               }
               else {
                  // alert("cse")

               }
            } else {
               console.log("error")
            }


         } catch (error) {
            console.error('Error fetching buyer data:', error);
         }
      }
      else {
         try {


            const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


            const targetUser = userDataArray.find(user => user.email === emailSwitch);

            if (targetUser) {
               const { userID, accessToken } = targetUser;

               const response = await fetch(`${BASE_URL}api/schedule/list/${userID}`, {
                  headers: {
                     Authorization: `Bearer ${accessToken}`,
                     'Content-Type': 'application/json'
                  }
               });
               const data = await response.json();
               console.log("schduled/tweet/list", data);
               if (data.code == 200) {
                  setDisplayQueueData(data?.data)
                  setContextData({ ScheduleData: false })

               }
               else {
                  // alert("cse")

               }
            } else {
               console.log("error")
            }


         } catch (error) {
            console.error('Error fetching buyer data:', error);
         }
      }
   };



   const [displayAImessage, setDisplayAImessage] = useState('')


   const emailTo = localStorage.getItem('email-to')




   const displayProfile = async () => {


      if (localStorage.getItem("userloggedin") === 'true') {
         try {

            const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


            const targetUser = userDataArray.find(user => user.email === emailTo);

            if (targetUser) {
               const { userID, accessToken } = targetUser;


               const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                  headers: {
                     Authorization: `Bearer ${accessToken}`,
                     'Content-Type': 'application/json'
                  }
               });
               const data = await response.json();

               if (data.code == 200) {
                  setDisplayAImessage(data.data)
                  // localStorage.setItem('email', data.data.user.email)
                  if (data?.data?.message_for_user_subscription == '' || data?.data?.message_for_user_subscription == null || data?.data?.message_for_user_subscription == undefined) {

                  }
                  else {
                     navigate('/app/payments')
                  }



               }
               else {



                  console.log("failed")


               }
            } else {
               console.log(`User with email ${emailTo} not found`);
            }


         } catch (error) {
            console.error('Error fetching buyer data:', error);
         }
      }
      else {
         try {

            const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


            const targetUser = userDataArray.find(user => user.email === emailSwitch);

            if (targetUser) {
               const { userID, accessToken } = targetUser;


               const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                  headers: {
                     Authorization: `Bearer ${accessToken}`,
                     'Content-Type': 'application/json'
                  }
               });
               const data = await response.json();

               if (data.code == 200) {
                  setDisplayAImessage(data.data)
                  // localStorage.setItem('email', data.data.user.email)
                  if (data?.data?.message_for_user_subscription == '' || data?.data?.message_for_user_subscription == null || data?.data?.message_for_user_subscription == undefined) {
                     // alert("subscribed")
                  }
                  else {
                     navigate('/app/payments')
                  }


               }
               else {



                  console.log("failed")


               }
            } else {
               window.open("/", "_self")
               localStorage.removeItem('accessToken-login')

               localStorage.removeItem('providerToken-login')
               console.log(`User with email ${emailTo} not found`);
            }


         } catch (error) {
            console.error('Error fetching buyer data:', error);
         }
      }

   };



   const handleNavigateAISettings = () => {

      navigate('/dashboard/app/settings')
   }


   const getImages = (images) => {
      return (
         <div>
            {
               images.map((image, i) => {

                  return (
                     <div>
                        <img src={image} style={{
                           height: 100,
                           width: 100
                        }} />
                     </div>
                  )
               })
            }
         </div>
      )
   }

   const getGif = (gif) => {
      return (
         <div>
            {
               gif.map((gifs, i) => {

                  return (
                     <div>
                        <img src={gif} style={{
                           height: 100,
                           width: 100
                        }} />
                     </div>
                  )
               })
            }
         </div>
      )
   }


   const handleShow = (item) => {
      // setShow(true);
      // setContext(item)
      setModalData(item);

      Array.isArray(item) && item?.findIndex(s => s.is_thread === 1) > -1
         ? (
            // alert('thread')
            setShow3(true)
         )
         : setShow(true)

      // setSelectedImages2(item.image)
      // setSelectedGifPicker(item.gif)

      // const selectedImages = item.image.map((image) => ({ img: image }));
      // setSelectedImages2(selectedImages);

      // const selectedGifpicker = item.gif.map((gif) => ({ gi: gif }));
      // setSelectedGifPicker(selectedGifpicker);

   }

   const handleShowSchdule = (item) => {

      setModalData(item);

      Array.isArray(item) && item?.findIndex(s => s.is_thread === 1) > -1
         ? (
            // alert('thread')
            setShowSchThr(true)
         )
         : setShowSch(true)



   }



   const handleDeleteSchdule = (item) => {
      // setShowDelete(true)
      // setContext({ time })

      setModalData(item);


      Array.isArray(item) && item?.findIndex(s => s.is_thread === 1) > -1
         ? <>
            {displayschduleLater()
            }
            {
               //alert('thread')
               // setShow3(true)
               setShowDelete2(true)

            }

         </>
         : setShowDelete(true)

   }




   const modalTextWithLinks = findLinksInText(modalData.text);

   // function findLinksInText(text) {
   //    const urlRegex = /(https?:\/\/[^\s]+)/g;
   //    return text.match(urlRegex) || [];
   // }

   // function containsLink(text) {
   //    const urlRegex = /(https?:\/\/[^\s]+)/g;
   //    return urlRegex.test(text);
   // }



   function findLinksInText(text) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return text?.split(urlRegex);
   }

   // Function to check if a text contains any link
   function containsLink(text) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return urlRegex.test(text);
   }





   const handleDeletAPI = () => {

      if (localStorage.getItem("userloggedin") === 'true') {
         try {

            const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


            const targetUser = userDataArray.find(user => user.email === emailTo);

            if (targetUser) {
               const { userID, accessToken, providerToken, secretKey } = targetUser;

               let fdata = new FormData();
               fdata.append('id', modalData?.id ? modalData?.id : modalData[0]?.id);
               fetch(`${BASE_URL}api/delete/schedule`, {
                  method: 'POST',
                  body: fdata,
                  headers: {
                     Authorization: `Bearer ${accessToken}`,
                  }
               }).then(response => {
                  console.log('repsonse delet', response);
                  if (response.ok) {
                     console.log('Data deleted successfully.');

                     //window.location.reload()

                     // setTimeSlots(timeSlots.filter(item => item.id !== context.t?.id));
                     displayschduleLater()
                     handleCloseDelete2()

                  } else {
                     console.error('Failed to delete data.');

                  }
               })
               setShowDelete(false)

            }
            else {

            }
         } catch (error) {

         }
      }
      else {
         try {

            const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


            const targetUser = userDataArray.find(user => user.email === emailSwitch);

            if (targetUser) {
               const { userID, accessToken, providerToken, secretKey } = targetUser;

               let fdata = new FormData();
               fdata.append('id', modalData?.id ? modalData?.id : modalData[0]?.id);
               fetch(`${BASE_URL}api/delete/schedule`, {
                  method: 'POST',
                  body: fdata,
                  headers: {
                     Authorization: `Bearer ${accessToken}`,
                  }
               }).then(response => {

                  if (response.ok) {
                     console.log('Data deleted successfully.');

                     //window.location.reload()

                     // setTimeSlots(timeSlots.filter(item => item.id !== context.t?.id));
                     displayschduleLater()
                     handleCloseDelete2()

                  } else {
                     console.error('Failed to delete data.');

                  }
               })
               setShowDelete(false)

            }
            else {

            }
         } catch (error) {

         }
      }
   }






   const splideOptions = {
      type: 'slide',
      perPage: getPerPageValue(), // Number of products shown at once
      perMove: 1,
      gap: '0rem', // Adjust spacing between products
      pagination: false,
      arrows: true,
   };

   function getPerPageValue() {
      if (window.innerWidth <= 768) {
         return 1; // Show only 1 product on mobile devices
      } else {
         return 3; // Show 3 products on larger screens
      }
   }



   const items = [
      {
         name: '1',

      },
      {
         name: '2 '
      },
      {
         name: '3',

      },
      {
         name: '4 '
      },
      {
         name: '5',

      },
      {
         name: '6 '
      },
      {
         name: '7',

      },
      {
         name: '8 '
      }
   ]
   const handleLikeAndDislike2 = () => {
      // Toggle the 'liked' state
      setLiked(!liked);
   };


   const renderTooltip = (
      <Popover id="popover-basic">
         <Popover.Header as="h3" className='popover-header-dailyins'>
            <div className="DailyIns-popover-header">
               <div className='DailyIns-popover-image'>
                  <img src={Ellipse} className="popover-img-fluid" alt="You tube" />
               </div>
               <div className='popver-paragrapgh'>
                  <h6>Aditya Singh</h6>
                  <span>@aditya322</span>

               </div>
            </div>

         </Popover.Header>
         <Popover.Body className='popover-body-dailyIns'>
            Snapdragon delivers the premium experiences your devices deserve.
            Follow us for the latest news, unique experiences, and giveaways.
            <div className='popover-followers'>
               <span> <strong>259</strong> Following</span>
               <span> <strong>4k</strong> Followers</span>

            </div>
            <div className='popover-followers'>
               <span> <FontAwesomeIcon icon={faLocationDot} /> Grow here</span>
               <span className='popver-link'> <FontAwesomeIcon icon={faLink} /> bitly.ws/ISQj</span>

            </div>
         </Popover.Body>

      </Popover>
   );


   const hanldeContextDraft = (item) => {

      localStorage.removeItem('savedValue')
      Array.isArray(item) && item?.findIndex(s => s.is_thread === 1) > -1
         ? (
            <>
               {setContext({
                  ...context,
                  t: { ...context.t, thread: item, image: null, gif: null, video: '' },
                  type: "published"

               })}

            </>)
         : setContext({ ...context, t: { ...context.t, ...item, thread: null, id: '' } })








   }

   const hanldeContextSchdule = (item) => {

      localStorage.removeItem('savedValue')
      Array.isArray(item) && item?.findIndex(s => s.is_thread === 1) > -1
         ? (
            <>
               {setContext({
                  ...context,
                  t: { ...context.t, thread: item, image: null, gif: null, video: '' },
                  type: "schduleLater"

               })}

            </>)
         : setContext({ ...context, t: { ...context.t, ...item, thread: null, }, type: "schduleLater" })
   }

   // const hanldeContextSchdule = (item, formattedDate) => {
   //    localStorage.removeItem('savedValue')


   //    Array.isArray(item) && item?.findIndex(s => s.is_thread === 1) > -1
   //       ? (


   //          <>
   //             {/* {setShowthread(true)} */}

   //             {setContext({
   //                ...context,
   //                t: { ...context.t, thread: item, image: null, gif: null, text: null, time: item[0].time },
   //                formattedDate,
   //                type: "queue"

   //             })}

   //          </>
   //       )
   //       :

   //       setContext({ ...context, t: { ...context.t, ...item, thread: null }, formattedDate, type: "queue" })




   // }






   const [key, setKey] = useState('Queue');



   return (

      <div id='content-page' className='content-page' style={{ backgroundColor: 'white' }}>



         {(displayAImessage?.message_for_AI_Settings !== ''
            && displayAImessage?.message_for_AI_Settings !== undefined
         ) ||
            (displayAImessage?.message_for_user_subscription !== '' &&
               displayAImessage?.message_for_user_subscription !== undefined) ?

            <Alert variant="danger" className='alert-text'>
               <FontAwesomeIcon icon={faCircleInfo} className='alert-icon-1' />
               {displayAImessage?.message_for_AI_Settings || displayAImessage?.message_for_user_subscription}. <span className='span-alert' onClick={handleNavigateAISettings}>
                  Please update them here
               </span>
            </Alert>
            :
            ''
         }






         <Container>








            {/* <Row>
               <Col sm="12">
                  <h4 className="card-title mb-3">Trending posts</h4>
                  <hr />
               </Col>

            </Row>
            {trendingLoader ?
               <div className='text-center'>
                  <Spinner animation="border" size="lg" variant="primary" className='mr-5' style={{ marginRight: 5 }} />
               </div>
               :


               <div className="product-carousel-container">


                  {
                     displayTending.code == 200 ? (
                        <>

                           <div className="carousel-arrows">
                              <SplideArrow arrowType="prev" />
                              <SplideArrow arrowType="next" />
                           </div>

                           <Splide options={splideOptions}>
                              {displayTending?.data?.map((data, index) => {

                                 const tooltipContent = (
                                    <Popover id={`popover-${index}`}>
                                       <Popover.Header as="h3" className='popover-header-dailyins'>
                                          <div className="DailyIns-popover-header">
                                             <div className='DailyIns-popover-image'>
                                                <img src={data?.user_profile?.profile_image_url} className="popover-img-fluid" alt="You tube" />
                                             </div>
                                             <div className='popver-paragrapgh'>
                                                <h6>{data?.user_profile?.name}</h6>
                                                <span>@{data?.user_profile?.screen_name}</span>
                                             </div>
                                          </div>
                                       </Popover.Header>
                                       <Popover.Body className='popover-body-dailyIns'>
                                          {data?.user_profile?.description}
                                          <div className='popover-followers'>
                                             <span> <strong>{data?.user_profile?.followings_count}</strong> Following</span>
                                             <span> <strong>{data?.user_profile?.followers_count}</strong> Followers</span>
                                          </div>
                                          <div className='popover-followers'>
                                             <span> <FontAwesomeIcon icon={faLocationDot} /> {data?.user_profile?.location}</span>
                                             <span className='popver-link'> <FontAwesomeIcon icon={faLink} /> <a href={data?.user_profile?.profile_url} target="_blank" rel="noopener noreferrer">Profile Link</a></span>

                                          </div>
                                       </Popover.Body>
                                    </Popover>
                                 );


                                 const handleLikeAndDislike = (e) => {
                                    if (localStorage.getItem("userloggedin") === 'true') {
                                       e.preventDefault()

                                       try {
                                          const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                                          const targetUser = userDataArray.find(user => user.email === emailTo);

                                          if (targetUser) {
                                             const { userID, accessToken, providerToken } = targetUser;
                                             let fdata = new FormData();


                                             fdata.append('id', userID);
                                             fdata.append('tweet_id', data.tweet_id);

                                             fetch(`${BASE_URL}api/like/tweet`, {
                                                method: 'POST',
                                                body: fdata,
                                                headers: {
                                                   Authorization: `Bearer ${tokenLogin}`,
                                                }
                                             }).then(response => {

                                                console.log("response like", response)
                                                return response.json();
                                             }).then(data => {
                                                console.log("like and dislike", data);
                                                if (data.code == 200) {
                                                   toast.success(data.message, {
                                                      position: "top-center",
                                                      autoClose: 5000,
                                                      hideProgressBar: false,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      progress: undefined,
                                                      theme: "light",
                                                   });
                                                   setLiked(!liked);


                                                }

                                                else {
                                                   toast.error(data.message, {
                                                      position: "top-center",
                                                      autoClose: 5000,
                                                      hideProgressBar: false,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      progress: undefined,
                                                      theme: "light",
                                                   });

                                                }

                                             })
                                          }
                                          else {
                                             console.log("webcjkdecew fuewgy")
                                          }

                                       } catch (error) {
                                          console.log(error)
                                       }
                                    }
                                    else {
                                       try {
                                          const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                                          const targetUser = userDataArray.find(user => user.email === emailSwitch);

                                          if (targetUser) {
                                             const { userID, accessToken, providerToken } = targetUser;
                                             let fdata = new FormData();


                                             fdata.append('id', userID);
                                             fdata.append('tweet_id', data.tweet_id);

                                             fetch(`${BASE_URL}api/like/tweet`, {
                                                method: 'POST',
                                                body: fdata,
                                                headers: {
                                                   Authorization: `Bearer ${accessToken}`,
                                                }
                                             }).then(response => {

                                                console.log("response like", response)
                                                return response.json();
                                             }).then(data => {
                                                console.log("like and dislike", data);
                                                if (data.code == 200) {
                                                   toast.success(data.message, {
                                                      position: "top-center",
                                                      autoClose: 5000,
                                                      hideProgressBar: false,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      progress: undefined,
                                                      theme: "light",
                                                   });
                                                   setLiked(!liked);


                                                }

                                                else {
                                                   toast.error(data.message, {
                                                      position: "top-center",
                                                      autoClose: 5000,
                                                      hideProgressBar: false,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      progress: undefined,
                                                      theme: "light",
                                                   });

                                                }

                                             })
                                          }
                                          else {
                                             console.log("webcjkdecew fuewgy")
                                          }

                                       } catch (error) {
                                          console.log(error)
                                       }
                                    }

                                 }


                                 const handleRetweet = (data) => {
                                    if (localStorage.getItem("userloggedin") === 'true') {
                                       console.log("tweet_id", data)
                                       try {
                                          const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                                          const targetUser = userDataArray.find(user => user.email === emailTo);

                                          if (targetUser) {
                                             const { userID, accessToken, providerToken } = targetUser;

                                             let fdata = new FormData();


                                             fdata.append('id', userID);
                                             fdata.append('tweet_id', data);

                                             fetch(`${BASE_URL}api/retweet/tweet`, {
                                                method: 'POST',
                                                body: fdata,
                                                headers: {
                                                   Authorization: `Bearer ${accessToken}`,
                                                }
                                             }).then(response => {

                                                console.log("response like", response)
                                                return response.json();
                                             }).then(data => {
                                                console.log("like and dislike", data);
                                                if (data.code == 200) {
                                                   toast.success(data.message, {
                                                      position: "top-center",
                                                      autoClose: 5000,
                                                      hideProgressBar: false,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      progress: undefined,
                                                      theme: "light",
                                                   });

                                                   setReTweet(!retweet);
                                                }

                                                else {
                                                   toast.error(data.message, {
                                                      position: "top-center",
                                                      autoClose: 5000,
                                                      hideProgressBar: false,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      progress: undefined,
                                                      theme: "light",
                                                   });

                                                }

                                             })
                                          }
                                          else {
                                             console.log("eororor dijvfdvdfv dfuvdfu")
                                          }

                                       } catch (error) {
                                          console.log(error)
                                       }
                                    }
                                    else {
                                       try {
                                          const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                                          const targetUser = userDataArray.find(user => user.email === emailSwitch);

                                          if (targetUser) {
                                             const { userID, accessToken, providerToken } = targetUser;

                                             let fdata = new FormData();


                                             fdata.append('id', userID);
                                             fdata.append('tweet_id', data);

                                             fetch(`${BASE_URL}api/retweet/tweet`, {
                                                method: 'POST',
                                                body: fdata,
                                                headers: {
                                                   Authorization: `Bearer ${accessToken}`,
                                                }
                                             }).then(response => {

                                                console.log("response like", response)
                                                return response.json();
                                             }).then(data => {
                                                console.log("like and dislike", data);
                                                if (data.code == 200) {
                                                   toast.success(data.message, {
                                                      position: "top-center",
                                                      autoClose: 5000,
                                                      hideProgressBar: false,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      progress: undefined,
                                                      theme: "light",
                                                   });

                                                   setReTweet(!retweet);
                                                }

                                                else {
                                                   toast.error(data.message, {
                                                      position: "top-center",
                                                      autoClose: 5000,
                                                      hideProgressBar: false,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      progress: undefined,
                                                      theme: "light",
                                                   });

                                                }

                                             })
                                          }
                                          else {
                                             console.log("eororor dijvfdvdfv dfuvdfu")
                                          }

                                       } catch (error) {
                                          console.log(error)
                                       }
                                    }
                                 }

                                 const handleHideApi = (dataa, index) => {
                                    if (localStorage.getItem("userloggedin") === 'true') {
                                       try {
                                          const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                                          const targetUser = userDataArray.find(user => user.email === emailTo);

                                          if (targetUser) {
                                             const { userID, accessToken, providerToken } = targetUser;

                                             let fdata = new FormData();
                                             fdata.append('id', userID);
                                             fdata.append('tweet_id', dataa)


                                             fetch(`${BASE_URL}api/hide/trending/tweet`, {
                                                method: 'POST',
                                                body: fdata,
                                                headers: {
                                                   Authorization: `Bearer ${accessToken}`,
                                                }
                                             }).then(response => {
                                                console.log('repsonse hide', response);
                                                if (response.ok) {
                                                   console.log('Data deleted successfully.');

                                                } else {
                                                   console.error('Failed to delete data.');

                                                }
                                                return response.json()
                                             }).then(data => {
                                                console.log("data.delete", data)
                                                if (data.code == 200) {
                                                   toast.success(data.message, {
                                                      position: "top-center",
                                                      autoClose: 5000,
                                                      hideProgressBar: false,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      progress: undefined,
                                                      theme: "light",
                                                   });
                                                   setDisplayTrending(data)
                                                }
                                                else if (data.code == 500) {
                                                   toast.error(data.error_message, {
                                                      position: "top-center",
                                                      autoClose: 5000,
                                                      hideProgressBar: false,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      progress: undefined,
                                                      theme: "light",
                                                   });

                                                }
                                                else {
                                                   toast.error(data.message, {
                                                      position: "top-center",
                                                      autoClose: 5000,
                                                      hideProgressBar: false,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      progress: undefined,
                                                      theme: "light",
                                                   });
                                                }
                                             })
                                          }
                                          else {
                                             console.log("scdscdsc dscdscs 111")
                                          }

                                       } catch (error) {

                                       }
                                    }
                                    else {
                                       try {
                                          const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                                          const targetUser = userDataArray.find(user => user.email === emailSwitch);

                                          if (targetUser) {
                                             const { userID, accessToken, providerToken } = targetUser;

                                             let fdata = new FormData();
                                             fdata.append('id', userID);
                                             fdata.append('tweet_id', dataa)


                                             fetch(`${BASE_URL}api/hide/trending/tweet`, {
                                                method: 'POST',
                                                body: fdata,
                                                headers: {
                                                   Authorization: `Bearer ${accessToken}`,
                                                }
                                             }).then(response => {
                                                console.log('repsonse hide', response);
                                                if (response.ok) {
                                                   console.log('Data deleted successfully.');

                                                } else {
                                                   console.error('Failed to delete data.');

                                                }
                                                return response.json()
                                             }).then(data => {
                                                console.log("data.delete", data)
                                                if (data.code == 200) {
                                                   toast.success(data.message, {
                                                      position: "top-center",
                                                      autoClose: 5000,
                                                      hideProgressBar: false,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      progress: undefined,
                                                      theme: "light",
                                                   });
                                                   setDisplayTrending(data)
                                                }
                                                else if (data.code == 500) {
                                                   toast.error(data.error_message, {
                                                      position: "top-center",
                                                      autoClose: 5000,
                                                      hideProgressBar: false,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      progress: undefined,
                                                      theme: "light",
                                                   });

                                                }
                                                else {
                                                   toast.error(data.message, {
                                                      position: "top-center",
                                                      autoClose: 5000,
                                                      hideProgressBar: false,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      progress: undefined,
                                                      theme: "light",
                                                   });
                                                }
                                             })
                                          }
                                          else {
                                             console.log("scdscdsc dscdscs 111")
                                          }

                                       } catch (error) {

                                       }
                                    }

                                 }

                                 const maxCharacterLimit = 310;


                                 const truncatedText =
                                    data.tweet_text.length > maxCharacterLimit
                                       ? `${data.tweet_text.substring(0, maxCharacterLimit)}...`
                                       : data.tweet_text;


                                 const handleTrendingTweetContext = (data) => {
                                    localStorage.removeItem('savedValue')
                                    setContext({ ...context, t: { ...context.t, text: data.tweet_text, thread: null } })


                                 }

                                 const handleTranslate = (dataa, index) => {
                                    console.log("dataaa", dataa)
                                    try {

                                       let fdata = new FormData();
                                       fdata.append('id', userID);

                                       fdata.append('text', dataa.tweet_text);
                                       fdata.append('provider_token', providerToken);
                                       fdata.append('language', displayAImessage?.user?.ai_language);



                                       fetch(`${BASE_URL}api/ai/translate`, {
                                          method: 'POST',
                                          body: fdata,
                                          headers: {
                                             Authorization: `Bearer ${tokenLogin}`,
                                          }
                                       }).then(response => {
                                          console.log('repsonse hide', response);
                                          if (response.ok) {
                                             console.log('Data deleted successfully.');

                                          } else {
                                             console.error('Failed to delete data.');

                                          }
                                          return response.json()
                                       }).then(data => {
                                          console.log("data.delete", data)

                                          setContext({ ...context, t: { ...context.t, text: data.content, thread: null } })




                                       })

                                    } catch (error) {

                                    }

                                 }


                                 const handleOpenDrop = (dataa, i) => {
                                    console.log("heeelo index", index, "iii", i)
                                    if (index == i) {
                                       setIsOpenDrop(!isOpenDrop)
                                       setLangDropdownIndex(i)

                                    }



                                 }


                                 const handleSelectValue = (dataa, value) => {
                                    localStorage.removeItem('savedValue')
                                    setSelectedValue(value);
                                    setIsOpenDrop(false);
                                    if (localStorage.getItem("userloggedin") === 'true') {
                                       try {
                                          const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                                          const targetUser = userDataArray.find(user => user.email === emailTo);

                                          if (targetUser) {
                                             const { userID, accessToken, providerToken } = targetUser;

                                             setLanguageLoading(true)


                                             let fdata = new FormData();
                                             fdata.append('id', userID);

                                             fdata.append('text', dataa.tweet_text);
                                             fdata.append('provider_token', providerToken);
                                             fdata.append('language', value);



                                             fetch(`${BASE_URL}api/ai/translate`, {
                                                method: 'POST',
                                                body: fdata,
                                                headers: {
                                                   Authorization: `Bearer ${accessToken}`,
                                                }
                                             }).then(response => {
                                                console.log('repsonse hide', response);
                                                if (response.ok) {
                                                   console.log('Data deleted successfully.');

                                                } else {
                                                   console.error('Failed to delete data.');

                                                }
                                                return response.json()
                                             }).then(data => {
                                                console.log("data.delete", data)



                                                setContext({ ...context, t: { ...context.t, text: data.content, thread: null } })

                                                setLanguageLoading(false)
                                                localStorage.setItem('savedValue', data.content);

                                             })
                                          }
                                          else {
                                             console.log("erroro ")
                                          }

                                       } catch (error) {

                                       }
                                    }
                                    else {
                                       try {
                                          const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                                          const targetUser = userDataArray.find(user => user.email === emailSwitch);

                                          if (targetUser) {
                                             const { userID, accessToken, providerToken } = targetUser;

                                             setLanguageLoading(true)


                                             let fdata = new FormData();
                                             fdata.append('id', userID);

                                             fdata.append('text', dataa.tweet_text);
                                             fdata.append('provider_token', providerToken);
                                             fdata.append('language', value);



                                             fetch(`${BASE_URL}api/ai/translate`, {
                                                method: 'POST',
                                                body: fdata,
                                                headers: {
                                                   Authorization: `Bearer ${accessToken}`,
                                                }
                                             }).then(response => {
                                                console.log('repsonse hide', response);
                                                if (response.ok) {
                                                   console.log('Data deleted successfully.');

                                                } else {
                                                   console.error('Failed to delete data.');

                                                }
                                                return response.json()
                                             }).then(data => {
                                                console.log("data.delete", data)

                                                setContext({ ...context, t: { ...context.t, text: data.content, thread: null } })

                                                setLanguageLoading(false)
                                                localStorage.setItem('savedValue', data.content);

                                             })
                                          }
                                          else {
                                             console.log("erroro ")
                                          }

                                       } catch (error) {

                                       }
                                    }
                                 };



                                 return (
                                    <>

                                       <SplideSlide key={index}>
                                          <div className="product">

                                             <Card className='myqueue-card' >
                                                <Card.Header as="h5">
                                                   <div className="myqueue-card-header">
                                                      <div className='myqueue-card-image'>

                                                         <img src={data?.user_profile?.profile_image_url ? data?.user_profile?.profile_image_url : noimage} className="DailyIns-img-fluid" alt='' onClick={() => togglePopover(index)} />

                                                         {openPopoverIndex === index && (
                                                            <div className="popover-noti" ref={popoverRef}>
                                                               <div className="DailyIns-popover-header">
                                                                  <div className='DailyIns-popover-image'>
                                                                     <img src={data?.user_profile?.profile_image_url} className="popover-img-fluid" alt={noimage} />
                                                                  </div>
                                                                  <div className='popver-paragrapgh'>
                                                                     <h6>{data?.user_profile?.name}</h6>
                                                                     <span>
                                                                        <a href={data?.user_profile?.profile_url} target="_blank" rel="noopener noreferrer">    @{data?.user_profile?.screen_name} </a>
                                                                     </span>
                                                                  </div>
                                                               </div>
                                                               <span>  {data?.user_profile?.description}</span>
                                                               <div className='popover-followers'>
                                                                  <span> <strong>{data?.user_profile?.followings_count}</strong> Following</span>
                                                                  <span> <strong>{data?.user_profile?.followers_count}</strong> Followers</span>
                                                               </div>
                                                               <div className='popover-followers'>
                                                                  <span> <FontAwesomeIcon icon={faLocationDot} /> {data?.user_profile?.location}</span>

                                                                  <span className='popver-link' ><FontAwesomeIcon icon={faLink} /> <a href={data?.tweet_url} target="_blank" rel="noopener noreferrer">Tweet link</a>
                                                                  </span>
                                                               </div>
                                                            </div>
                                                         )}

                                                      </div>

                                                      <div className='span-date'>
                                                         <span>{moment(data.tweet_created_at).format('DD/MM/YYYY')}</span>



                                                         {((isOpenDrop) && (index == langDropdownIndex)) ? (

                                                            <div className='over-drop' ref={popoverRef2}>

                                                               <span className='tweet-span'>
                                                                  {Language?.map(opt => (
                                                                     <li key={opt} value={opt} onClick={() => handleSelectValue(data, opt)}
                                                                        style={{

                                                                           color: selectedValue === opt ? 'dodgerblue' : 'black',
                                                                        }}

                                                                     >
                                                                        {opt}

                                                                     </li>

                                                                  ))}
                                                               </span>
                                                            </div>

                                                         ) : ""}
                                                      </div>


                                                   </div>


                                                </Card.Header>
                                                <Card.Body>

                                                   <Card.Text className='truncate-slide' style={{ height: '200px', overflow: 'hidden' }}>

                                                      {truncatedText}


                                                   </Card.Text>

                                                </Card.Body>


                                                <Card.Footer className="DailyIns-Card-footer">

                                                   <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: 10, paddingLeft: 10, paddingTop: 10, paddingBottom: 10 }}>
                                                      <div>
                                                         <span className='span-like' ><FontAwesomeIcon icon={faHeart}
                                                            style={{ color: 'red' }}

                                                            onClick={handleLikeAndDislike} />
                                                            &nbsp;{data.favourites}
                                                         </span>

                                                         <span className='span-like'><FontAwesomeIcon icon={faRetweet}
                                                            style={{ color: 'green', paddingLeft: 10 }}

                                                            onClick={() => handleRetweet(data?.tweet_id)}
                                                         />
                                                            &nbsp; {data.retweets}
                                                         </span>

                                                         <span className='span-like'><FontAwesomeIcon icon={faEyeSlash}
                                                            style={{ color: 'grey', paddingLeft: 10 }}


                                                            onClick={() => handleHideApi(data?.tweet_id)}
                                                         />

                                                         </span>
                                                      </div>

                                                      <div className='DailyIns-Card-footer-right'>

                                                         {(index == langDropdownIndex) && languageLoading ?
                                                            <Spinner animation="border" variant="primary" size="sm" style={{ marginRight: 10 }} /> :
                                                            <FontAwesomeIcon icon={faLanguage} onClick={() => handleOpenDrop(data, index)} style={{ fontSize: 12, paddingRight: 10 }} />}

                                                         <button onClick={() => handleTrendingTweetContext(data)}>Edit</button>
                                                      </div>
                                                   </div>
                                                </Card.Footer>


                                             </Card>
                                          </div>
                                       </SplideSlide>

                                    </>
                                 )
                              })}
                           </Splide>
                        </>
                     ) : (
                        <div className='trending-empty-box'>
                           <h5 className='text-center'><FontAwesomeIcon icon={faCircleInfo} className='alert-icon' />{displayTending.message} </h5>
                        </div>



                     )
                  }



               </div>} */}





            <Row>
               <Col sm="12">
                  <h4 className="card-title mb-3 ">My Queue</h4>



                  <Tabs
                     id="controlled-tab-example"
                     activeKey={key}
                     onSelect={(k) => setKey(k)}
                     className="mb-3 tabs-myqueue"
                  >

                     <Tab eventKey="Queue" title={<span style={{ fontWeight: 400 }}><FontAwesomeIcon icon={faCalendarPlus} /> &nbsp; Scheduled Posts  </span>} >
                        <div className='schdule-meeting'>
                           <MyQueue />

                        </div>
                     </Tab>

                     <Tab eventKey="schdule" title={<span style={{ fontWeight: 400 }}><FontAwesomeIcon icon={faCalendarAlt} className='icon-not' /> &nbsp; Scheduled Later</span>} className="tabs-list-myqueue">
                        <div className='schdule-meeting'>

                           {displayQueueData && displayQueueData.length > 0 ? displayQueueData?.map((item, index) => {

                              const formattedDate = moment(item?.schedule_date, 'DD-MM-YYYY').format('DD MMMM YYYY');
                              const formattedDate2 = moment(item?.schedule_date, 'DD-MM-YYYY').format('dddd | D MMMM YYYY');
                              const isToday = moment(item?.schedule_date, 'DD-MM-YYYY').isSame(moment(), 'day');
                              const formattedTime = moment(item?.schedule_time, 'HH:mm:ss').format('hh:mm A');
                              let previousTime = null;


                              return (
                                 <>

                                    {Array.isArray(item) ? (
                                       <>

                                          {item?.map((hobby, index) => {

                                             const formattedDate = moment(hobby.updated_at, 'YYYY-MM-DD').format('DD MMMM YYYY');
                                             const formattedDate2 = moment(hobby.updated_at, 'YYYY-MM-DD').format('dddd | D MMMM YYYY');
                                             const isToday = moment(hobby.updated_at, 'YYYY-MM-DD').isSame(moment(), 'day');


                                             const isDifferentTime = formattedDate !== previousTime;
                                             previousTime = formattedDate; // Update the previousTime with the current time

                                             return (
                                                <>
                                                   {isDifferentTime && <span className="text-center" style={{ color: 'black' }}>{isToday ? 'Today | ' + '' + formattedDate : formattedDate2}</span>}

                                                   {/* <span className="text-center" key={index}>{formattedTime}</span> */}

                                                </>
                                             )
                                          })}
                                       </>
                                    ) :
                                       <span style={{ color: 'black' }}>{isToday ? 'Today | ' + '' + formattedDate : formattedDate2}</span>
                                    }
                                    <div className='queue-schedule-list'>
                                       <Row>
                                          <Col lg={2} md={2} sm={2}>
                                             {Array.isArray(item) ? (
                                                <>
                                                   <span className="text-center">{moment(item[0]?.schedule_time, 'hh:mm:ss').format('hh:mm A')}     <br /></span>
                                                </>
                                             ) :
                                                <span className="text-center">{formattedTime}</span>
                                             }

                                          </Col>
                                          <Col lg={8} md={8} sm={8}>
                                             {Array.isArray(item) ? (
                                                <>

                                                   {item?.map((hobby, index) => {


                                                      return (
                                                         <>
                                                            <p className='text-container'>{hobby?.text} </p>

                                                         </>
                                                      )
                                                   })}
                                                </>
                                             ) :
                                                <p className='text-container'>{item?.text} </p>
                                             }
                                          </Col>
                                          <Col lg={2} md={2} sm={2}>

                                             <div className='draft-icon'>

                                                <FontAwesomeIcon icon={faEye}
                                                   style={{ marginRight: 10 }}
                                                   onClick={() => handleShowSchdule(item)}
                                                />
                                                <FontAwesomeIcon icon={faTrash}
                                                   style={{ marginRight: 10 }}
                                                   onClick={() => handleDeleteSchdule(item)}
                                                />

                                                <FontAwesomeIcon
                                                   icon={faPencil}
                                                   onClick={() => hanldeContextSchdule(item)} />



                                             </div>


                                          </Col>
                                       </Row>
                                    </div>
                                 </>
                              )
                           }) :

                              <div className='text-center'>
                                 <span style={{ color: 'black' }}> No scheduled  posts available</span>
                              </div>
                           }



                        </div>
                     </Tab>

                     <Tab eventKey="profile" title={<span style={{ fontWeight: 400 }}><FontAwesomeIcon icon={faUpload} /> &nbsp; Published Posts </span>} >
                        <div className='schdule-meeting'>

                           {displayPostData && displayPostData.length > 0 ? displayPostData?.map((itemi, index) => {



                              const formattedDate = moment(itemi.updated_at, 'YYYY-MM-DD').format('DD MMMM YYYY');
                              const formattedDate2 = moment(itemi.updated_at, 'YYYY-MM-DD').format('dddd | D MMMM YYYY');
                              const isToday = moment(itemi.updated_at, 'YYYY-MM-DD').isSame(moment(), 'day');
                              const formattedTime = moment(itemi.schedule_time, 'HH:mm:ss').format('hh:mm A');

                              let previousTime = null;
                              return (
                                 <>

                                    {/* <span>{isToday ? 'Today, ' + '' + formattedDate : formattedDate2}</span> */}
                                    {Array.isArray(itemi) ? (
                                       <>

                                          {itemi.map((hobby, index) => {

                                             const formattedDate = moment(hobby.updated_at, 'YYYY-MM-DD').format('DD MMMM YYYY');
                                             const formattedDate2 = moment(hobby.updated_at, 'YYYY-MM-DD').format('dddd | D MMMM YYYY');
                                             const isToday = moment(hobby.updated_at, 'YYYY-MM-DD').isSame(moment(), 'day');


                                             const isDifferentTime = formattedDate !== previousTime;
                                             previousTime = formattedDate; // Update the previousTime with the current time

                                             return (
                                                <>
                                                   {isDifferentTime && <span className="text-center" style={{ color: 'black' }}>{isToday ? 'Today | ' + '' + formattedDate : formattedDate2}</span>}

                                                   {/* <span className="text-center" key={index}>{formattedTime}</span> */}

                                                </>
                                             )
                                          })}
                                       </>
                                    ) :
                                       <span style={{ color: 'black' }}>{isToday ? 'Today | ' + '' + formattedDate : formattedDate2}</span>
                                    }

                                    <div className='queue-schedule-list'>
                                       <Row>

                                          <Col lg={2} md={2} sm={2}>
                                             {Array.isArray(itemi) ? (
                                                <>

                                                   {/* {itemi?.map((hobby, index) => {
                                                      console.log("return,", hobby)
                                                      const formattedTime = moment(hobby?.schedule_time, 'HH:mm:ss').format('hh:mm A');
                                                     
                                                      const isDifferentTime = formattedTime !== previousTime;
                                                      previousTime = formattedTime; 

                                                      return (
                                                         <>
                                                            {isDifferentTime && <span className="text-center">{formattedTime}     <br /></span>}


                                                         </>
                                                      )
                                                   })} */}
                                                   <span className="text-center">{moment(itemi[0].schedule_time, 'hh:mm:ss').format('hh:mm A')}     <br /></span>
                                                </>
                                             ) :
                                                <span className="text-center">{formattedTime}</span>
                                             }


                                          </Col>
                                          <Col lg={8} md={8} sm={8} className='text-center'>

                                             {Array.isArray(itemi) ? (
                                                <>

                                                   {itemi?.map((hobby, index) => {


                                                      return (
                                                         <>
                                                            <p className='text-container'>{hobby?.text} </p>

                                                         </>
                                                      )
                                                   })}
                                                </>
                                             ) :
                                                <p className='text-container'>{itemi?.text} </p>
                                             }
                                          </Col>





                                          <Col lg={2} md={2} sm={2}>
                                             {/* <Col lg={2} md={2} sm={2}> */}
                                             <div className='draft-icon'>

                                                <FontAwesomeIcon icon={faEye}
                                                   style={{ marginRight: 10 }}
                                                   onClick={() => handleShow(itemi)}
                                                />

                                                <FontAwesomeIcon
                                                   icon={faRetweet}
                                                   onClick={() => hanldeContextDraft(itemi)} />

                                             </div>

                                             {/* </Col> */}
                                          </Col>



                                       </Row>
                                    </div >
                                 </>
                              )
                           }) :

                              <div className='text-center'>
                                 <span style={{ color: 'black' }}> No published posts available</span>
                              </div>
                           }



                        </div>
                     </Tab>


                     <Tab eventKey="contact" title={<span style={{ fontWeight: 400 }}><FontAwesomeIcon icon={faFilePen} /> &nbsp; Drafts </span>}>
                        <div className='schdule-meeting'>
                           <MyDraft />
                        </div>
                     </Tab>
                  </Tabs>

               </Col>

            </Row>




            <Modal show={show} onHide={handleClose}>
               <Modal.Header closeButton>
                  <Modal.Title>Preview</Modal.Title>
               </Modal.Header>

               <Modal.Body>
                  <div className='modal-confirm-data'>


                  </div>

                  <p className='modal-text'>



                     {modalTextWithLinks?.map((segment, index) => {
                        return containsLink(segment) ? (
                           // If it's a link, render it as a link
                           <a key={index} href={segment} target="_blank" rel="noopener noreferrer">


                              <Microlink url={segment}>
                                 {({ data, loading }) => {
                                    if (loading) return <p>Loading...</p>;
                                    if (data) {
                                       // Handle extracted data
                                       console.log(data);
                                       return (
                                          <div>
                                             <h1>{data.title}</h1>
                                             <p>{data.description}</p>
                                             {/* Add more components to display other extracted data */}
                                          </div>
                                       );
                                    }
                                    return null;
                                 }}
                              </Microlink>
                           </a>
                        ) : (
                           // If it's text, render it as text
                           <span key={index}>
                              {segment}{' '}
                           </span>
                        );
                     })}
                  </p>

                  <div className='image-published text-center'>
                     {modalData.gif?.map((i, ind) => {

                        return (
                           <div className='image-container-published' key={ind}>
                              <img src={i} className='queue-schdule-image' />
                           </div>
                        );
                     })}
                  </div>

                  <div className='image-published text-center'>
                     {modalData.image?.map((i, ind) => {

                        return (
                           <div className='image-container' key={ind}>
                              <img src={i} className='queue-schdule-image' />
                           </div>
                        );
                     })}
                  </div>

                  <div className='image-published text-center'>
                     {modalData?.video ? (
                        <video width="100%" height="240"
                           controls
                           src={modalData?.video}>


                        </video>
                     ) : ""}
                  </div>


               </Modal.Body>

            </Modal>



            <Modal show={show3} onHide={handleClose3}>
               <Modal.Header closeButton>
                  <Modal.Title> Thread preview </Modal.Title>
               </Modal.Header>
               <Modal.Body>


                  {Array.isArray(modalData) ? (
                     <>

                        {modalData?.map((hobby, index) => {

                           const lines = hobby?.text?.split("\r\n");


                           const linesWithLinks = lines?.map((line, lineIndex) => {
                              const linksInLine = findLinksInText(line);

                              if (linksInLine.length > 0) {
                                 // If links are found, apply a different style to the link
                                 return (
                                    <li className="thread-li" key={lineIndex}>
                                       {linksInLine.map((link, linkIndex) => (
                                          <span key={linkIndex} style={{ color: 'dodgerblue' }}>
                                             {/* {link}{' '} */}

                                             {/* <Microlink url={link}>
                                                {({ data, loading }) => {
                                                   if (loading) return <p>Loading...</p>;
                                                   if (data) {
                                                      // Handle extracted data
                                                      console.log(data);
                                                      return (
                                                         <div>
                                                            <h1>{data.title}</h1>
                                                            <p>{data.description}</p>
                                                         
                                                         </div>
                                                      );
                                                   }
                                                   return null;
                                                }}
                                             </Microlink> */}


                                             <Microlink url={link}>
                                                {({ data, loading }) => {
                                                   console.log("datta", data, loading)
                                                   if (loading && data && data.loading_time < 20000) {
                                                      // Display a loading indicator if loading time is within the threshold
                                                      return <p>Loading...</p>;
                                                   }
                                                   if (data && data.title) {
                                                      // Display the link preview if available
                                                      return (
                                                         <div>
                                                            <h1>{data.title}</h1>
                                                            <p>{data.description}</p>
                                                            {/* Add more components to display other extracted data */}
                                                         </div>
                                                      );
                                                   }
                                                   // If no data or title is found, display a simple link
                                                   return <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>;
                                                }}
                                             </Microlink>

                                          </span>
                                       ))}
                                    </li>
                                 );
                              }

                              // If no links, render the line as usual
                              return (
                                 <li className="thread-li" key={lineIndex}>
                                    {line}
                                 </li>
                              );
                           });

                           // Function to find links in a text
                           function findLinksInText(text) {
                              const urlRegex = /(https?:\/\/[^\s]+)/g;
                              return text.match(urlRegex) || [];
                           }

                           return (
                              <>
                                 <div>
                                    <img
                                       src={displayAImessage?.user?.image_url ? displayAImessage?.user?.image_url : noimage}

                                       className="span-icon"
                                       style={{ width: 60 }}
                                    />
                                    <span className="span-thread" style={{ marginLeft: 20 }}>
                                       {displayAImessage ? displayAImessage?.user?.name : ''}


                                    </span>
                                 </div>



                                 <ul className="thread-ul">

                                    {/* {lines && lines?.map((line, lineIndex) => (

                                       <li className="thread-li" key={lineIndex}>

                                          {line}

                                       </li>



                                    ))} */}

                                    {linesWithLinks && linesWithLinks?.map((line, lineIndex) => (

                                       <li className="thread-li" key={lineIndex} >

                                          {/* {getTextWithoutImg(line, "[ img: ")} */}
                                          {line}
                                          {/* {foundLinks} */}

                                       </li>


                                    ))}
                                    <div>
                                       {
                                          hobby?.image.map((item, index) => {
                                             return (
                                                <>
                                                   {/* <li> */}
                                                   <img src={item ? item : noimage} className='thread-preview-image' style={{ marginLeft: 10 }} />

                                                   {/* </li> */}
                                                </>
                                             )
                                          })
                                       }
                                    </div>

                                    {
                                       hobby?.gif.map((item, index) => {
                                          return (
                                             <>
                                                <li>
                                                   <img src={item} className='thread-preview-image' />

                                                </li>
                                             </>
                                          )
                                       })
                                    }


                                    {hobby?.video ? (
                                       <video width="100%" height="240"
                                          controls
                                          src={hobby?.video}>


                                       </video>
                                    ) : ""}

                                 </ul>

                              </>
                           )
                        })}
                     </>
                  ) :
                     "no thread to show"
                  }




               </Modal.Body>

            </Modal>


            <Modal show={showSch} onHide={handleCloseSch}>
               <Modal.Header closeButton>
                  <Modal.Title>Preview</Modal.Title>
               </Modal.Header>

               <Modal.Body>
                  <div className='modal-confirm-data'>


                  </div>

                  <p className='modal-text'>


                     {/* {modalData.text} */}
                     {/* {modalTextWithLinks} */}

                     {modalTextWithLinks?.map((segment, index) => {
                        return containsLink(segment) ? (
                           // If it's a link, render it as a link
                           <a key={index} href={segment} target="_blank" rel="noopener noreferrer">


                              <Microlink url={segment}>
                                 {({ data, loading }) => {
                                    if (loading) return <p>Loading...</p>;
                                    if (data) {

                                       return (
                                          <div>
                                             <h1>{data.title}</h1>
                                             <p>{data.description}</p>
                                             {/* Add more components to display other extracted data */}
                                          </div>
                                       );
                                    }
                                    return null;
                                 }}
                              </Microlink>
                           </a>
                        ) : (
                           // If it's text, render it as text
                           <span key={index}>
                              {segment}{' '}
                           </span>
                        );
                     })}
                  </p>

                  <div className='image-published text-center'>
                     {modalData.gif?.map((i, ind) => {
                        console.log("image iiii", i);
                        return (
                           <div className='image-container-published' key={ind}>
                              <img src={i} className='queue-schdule-image' />
                           </div>
                        );
                     })}
                  </div>

                  <div className='image-published text-center'>
                     {modalData.image?.map((i, ind) => {
                        console.log("image iiii", i);
                        return (
                           <div className='image-container' key={ind}>
                              <img src={i} className='queue-schdule-image' />
                           </div>
                        );
                     })}
                  </div>

                  <div className='image-published text-center'>
                     {modalData?.video ? (
                        <video width="100%" height="240"
                           controls
                           src={modalData?.video}>


                        </video>
                     ) : ""}
                  </div>


               </Modal.Body>

            </Modal>


            <Modal show={showSchThr} onHide={handleCloseSchThr}>
               <Modal.Header closeButton>
                  <Modal.Title> Thread preview </Modal.Title>
               </Modal.Header>
               <Modal.Body>


                  {Array.isArray(modalData) ? (
                     <>

                        {modalData?.map((hobby, index) => {
                           console.log("hobbry", hobby)
                           const lines = hobby?.text?.split("\r\n");


                           const linesWithLinks = lines?.map((line, lineIndex) => {
                              const linksInLine = findLinksInText(line);

                              if (linksInLine.length > 0) {
                                 // If links are found, apply a different style to the link
                                 return (
                                    <li className="thread-li" key={lineIndex}>
                                       {linksInLine.map((link, linkIndex) => (
                                          <span key={linkIndex} style={{ color: 'dodgerblue' }}>
                                             {/* {link}{' '} */}

                                             {/* <Microlink url={link}>
                                                {({ data, loading }) => {
                                                   if (loading) return <p>Loading...</p>;
                                                   if (data) {
                                                      // Handle extracted data
                                                      console.log(data);
                                                      return (
                                                         <div>
                                                            <h1>{data.title}</h1>
                                                            <p>{data.description}</p>
                                                         
                                                         </div>
                                                      );
                                                   }
                                                   return null;
                                                }}
                                             </Microlink> */}


                                             <Microlink url={link}>
                                                {({ data, loading }) => {
                                                   console.log("datta", data, loading)
                                                   if (loading && data && data.loading_time < 20000) {
                                                      // Display a loading indicator if loading time is within the threshold
                                                      return <p>Loading...</p>;
                                                   }
                                                   if (data && data.title) {
                                                      // Display the link preview if available
                                                      return (
                                                         <div>
                                                            <h1>{data.title}</h1>
                                                            <p>{data.description}</p>
                                                            {/* Add more components to display other extracted data */}
                                                         </div>
                                                      );
                                                   }
                                                   // If no data or title is found, display a simple link
                                                   return <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>;
                                                }}
                                             </Microlink>

                                          </span>
                                       ))}
                                    </li>
                                 );
                              }

                              // If no links, render the line as usual
                              return (
                                 <li className="thread-li" key={lineIndex}>
                                    {line}
                                 </li>
                              );
                           });

                           // Function to find links in a text
                           function findLinksInText(text) {
                              const urlRegex = /(https?:\/\/[^\s]+)/g;
                              return text.match(urlRegex) || [];
                           }

                           return (
                              <>
                                 <div>
                                    <img
                                       src={displayAImessage ? displayAImessage?.user.image_url : noimage}

                                       className="span-icon"
                                       style={{ width: 60 }}
                                    />
                                    <span className="span-thread" style={{ marginLeft: 20 }}>
                                       {displayAImessage ? displayAImessage?.user.name : ''}


                                    </span>
                                 </div>



                                 <ul className="thread-ul">

                                    {/* {lines && lines?.map((line, lineIndex) => (

                                       <li className="thread-li" key={lineIndex}>

                                          {line}

                                       </li>



                                    ))} */}

                                    {linesWithLinks && linesWithLinks?.map((line, lineIndex) => (

                                       <li className="thread-li" key={lineIndex} >

                                          {/* {getTextWithoutImg(line, "[ img: ")} */}
                                          {line}
                                          {/* {foundLinks} */}

                                       </li>


                                    ))}
                                    <div>
                                       {
                                          hobby?.image.map((item, index) => {
                                             return (
                                                <>
                                                   {/* <li> */}
                                                   <img src={item ? item : noimage} className='thread-preview-image' style={{ marginLeft: 10 }} />

                                                   {/* </li> */}
                                                </>
                                             )
                                          })
                                       }
                                    </div>

                                    {
                                       hobby?.gif.map((item, index) => {
                                          return (
                                             <>
                                                <li>
                                                   <img src={item} className='thread-preview-image' />

                                                </li>
                                             </>
                                          )
                                       })
                                    }


                                    {hobby?.video ? (
                                       <video width="100%" height="240"
                                          controls
                                          src={hobby?.video}>


                                       </video>
                                    ) : ""}

                                 </ul>

                              </>
                           )
                        })}
                     </>
                  ) :
                     "no thread to show"
                  }




               </Modal.Body>

            </Modal>



            <Modal show={showDelete} onHide={handleCloseDelete}>
               <Modal.Header closeButton>
                  <Modal.Title>
                     <FontAwesomeIcon icon={faTrash} /> Delete </Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <Card className='schdeule-card'>

                     <Card.Body>
                        <Card.Title>
                           <div className="schdeule-card-header">


                              <div className='schdeule-card-text '>


                                 <p> {modalData?.text}</p>


                                 <div className='image-myqueue text-center'>
                                    {modalData?.gif?.map((i, ind) => {
                                       console.log("image iiii", i);
                                       return (
                                          <div className='image-container' key={ind}>
                                             <img src={i} className='queue-schdule-image' />
                                          </div>
                                       );
                                    })}
                                 </div>

                                 <div className='image-myqueue text-center'>
                                    {modalData?.image?.map((i, ind) => {
                                       console.log("image iiii", i);
                                       return (
                                          <div className='image-container' key={ind}>
                                             <img src={i} className='queue-schdule-image' />
                                          </div>
                                       );
                                    })}
                                 </div>


                              </div>

                           </div>
                        </Card.Title>

                        <Card.Text>



                        </Card.Text>



                     </Card.Body>





                  </Card>


               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                     No
                  </Button>
                  <Button variant="primary" onClick={handleDeletAPI}>
                     Yes
                  </Button>
               </Modal.Footer>

            </Modal>



            <Modal show={showDelete2} onHide={handleCloseDelete2}>
               <Modal.Header closeButton>
                  <Modal.Title>
                     Delete Thread</Modal.Title>
               </Modal.Header>
               <Modal.Body>


                  {Array.isArray(modalData) ? (
                     <>

                        {modalData?.map((hobby, index) => {
                           console.log("hobbry", hobby)
                           const lines = hobby?.text?.split("\r\n");

                           return (
                              <>
                                 <div>
                                    <img
                                       src={displayAImessage ? displayAImessage?.user.image_url : ''}

                                       className="span-icon"
                                       style={{ width: 60 }}
                                    />
                                    <span className="span-thread" style={{ marginLeft: 20 }}>
                                       {displayAImessage ? displayAImessage?.user.name : ''}

                                    </span>
                                 </div>



                                 <ul className="thread-ul">

                                    {lines && lines?.map((line, lineIndex) => (

                                       <li className="thread-li" key={lineIndex}>

                                          {line}

                                       </li>



                                    ))}
                                    {
                                       hobby?.image.map((item, index) => {
                                          return (
                                             <>
                                                <li>
                                                   <img src={item} className='thread-preview-image' />

                                                </li>
                                             </>
                                          )
                                       })
                                    }

                                    {
                                       hobby?.gif.map((item, index) => {
                                          return (
                                             <>
                                                <li>
                                                   <img src={item} className='thread-preview-image' />

                                                </li>
                                             </>
                                          )
                                       })
                                    }


                                 </ul>

                              </>
                           )
                        })}
                     </>
                  ) :
                     "no thread to show"
                  }




               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                     No
                  </Button>
                  <Button variant="primary" onClick={handleDeletAPI}>
                     Yes
                  </Button>
               </Modal.Footer>

            </Modal>



            {/* <ToastContainer /> */}




         </Container>
      </div >



   )
}

export default Notification   