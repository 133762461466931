import React, { useState, useEffect } from "react";
import Select from "react-select";
// import { RxCross2 } from "react-icons/rx";
import { Button, Container, Row, Col } from "react-bootstrap";
import './Userstyle.css';
import { useNavigate, useParams } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

function NewUserInterest() {

  //   let params = useParams()
  //   const Navigate = useNavigate()



  //   // useEffect(() => {
  //   //   try {

  //   //     let fdata = new FormData();
  //   //     fdata.append('provider_type', "twitter");
  //   //     fdata.append('provider_token', params.accessToken);
  //   //     fdata.append('oauth_token_secret', params.accessTokenSecret);



  //   //     fetch('https://app.tweetnow.ai/TweetNow/api/user/auth', {
  //   //       method: 'POST',
  //   //       body: fdata,
  //   //       // headers: {
  //   //       //     Authorization: `Bearer ${authToken}`,
  //   //       // }
  //   //     }).then(response => {

  //   //       console.log("response signin", response)
  //   //       return response.json();
  //   //     }).then(data => {
  //   //       console.log("hjjhb", data.data.is_loggedin);
  //   //       console.log("-----", data.data.session_data.provider_id);
  //   //       if (data.code == 200) {
  //   //         console.log("success")
  //   //         // if (Timezone === null) {


  //   //         //     localStorage.setItem('accessToken', data.data.token)
  //   //         //     localStorage.setItem('UserID', data.data.session_data.id)
  //   //         //     localStorage.setItem('providerToken', data.data.session_data.access_token)
  //   //         //     localStorage.setItem('oAuthToken', data.data.session_data.access_token_secret)
  //   //         //     Navigate('/app/user-interest')


  //   //         // }
  //   //         // else {



  //   //         //     localStorage.setItem('accessToken', data.data.token)
  //   //         //     localStorage.setItem('UserID', data.data.session_data.id)
  //   //         //     localStorage.setItem('providerToken', data.data.session_data.access_token)
  //   //         //     localStorage.setItem('oAuthToken', data.data.session_data.access_token_secret)
  //   //         //     Navigate('/dashboard/app/notification')


  //   //         // }
  //   //         if (data.data.is_loggedin === "true") {
  //   //           localStorage.setItem('accessToken', data.data.token)
  //   //           localStorage.setItem('UserID', data.data.session_data.id)
  //   //           localStorage.setItem('providerToken', data.data.session_data.access_token)
  //   //           localStorage.setItem('oAuthToken', data.data.session_data.access_token_secret)
  //   //           Navigate('/dashboard/app/notification')

  //   //         }
  //   //         else {
  //   //           localStorage.setItem('accessToken', data.data.token)
  //   //           localStorage.setItem('UserID', data.data.session_data.id)
  //   //           localStorage.setItem('providerToken', data.data.session_data.access_token)
  //   //           localStorage.setItem('oAuthToken', data.data.session_data.access_token_secret)
  //   //           // Navigate('/app/user-interest')

  //   //         }




  //   //       }


  //   //       else {

  //   //         console.log("failed")
  //   //       }

  //   //     })



  //   //   } catch (error) {
  //   //     console.log(error.message);
  //   //   }


  //   // })


  //   const [selectedValue, setSelectedValue] = useState([]);
  //   const [interestList, setInterestlist] = useState([])
  //   const [storedData, setStoredData] = useState([])


  const tokenLogin = localStorage.getItem('accessToken')
  //   let tokenLogin;
  const ID = localStorage.getItem('UserID')
  //   const T = localStorage.getItem('accessToken')
  //   console.log("bkdc", ID)


  //   console.log("intereast", interestList)




  //   const handleChange = (e) => {
  //     let val = e.map((v) => v.value);

  //     setSelectedValue((prevValue) => {
  //       const uniqueValues = [...new Set([...prevValue, ...val])];
  //       console.log("cd", uniqueValues)
  //       return uniqueValues;

  //     });


  //   };




  //   localStorage.setItem("data", selectedValue)


  //   // const removeCountryHandler = (i) => {
  //   //   console.log("remove==>", selectedValue);
  //   //   selectedValue.splice(i, 1);
  //   //   setSelectedValue((r) => [...selectedValue]);
  //   // };



  //   const removeSelectedValue = (value) => {
  //     setSelectedValue((prevValue) => prevValue.filter((v) => v !== value));
  //   };

  //   const navigate = useNavigate()

  //   console.log("fdd", T)



  //   const handleNext = () => {
  //     console.log("tken", tokenLogin)
  //     try {
  //       const myStrring = selectedValue.toString()

  //       let fdata = new FormData();

  //       fdata.append('interest', myStrring);
  //       fdata.append('id', ID);


  //       // fetch('https://app.tweetnow.ai/TweetNow/api/saveInterest', {
  //       fetch('https://app.tweetnow.ai/TweetNow/api/save/interest', {
  //         method: 'POST',
  //         body: fdata,
  //         headers: {
  //           Authorization: `Bearer ${T}`,
  //         }
  //       }).then(response => {

  //         console.log("response interest", response)
  //         return response.json();
  //       }).then(data => {
  //         console.log("data interes", data);
  //         if (data.code == 200) {
  //           console.log("success hello")

  //           navigate('/app/user-info')
  //           // localStorage.setItem("data2", String(data.data.profile[0].interest))


  //           if (selectedValue.length == 0) {
  //             setSelectedValue([])
  //             localStorage.setItem("data2", selectedValue)
  //             console.log("0000", selectedValue)
  //           }
  //           else {
  //             localStorage.setItem("data2", String(data.data.profile[0].interest))
  //           }




  //         }

  //         else {

  //           console.log("failed hello")
  //         }

  //       })






  //     } catch (error) {
  //       console.log(error)
  //     }


  //   }

  //   const customStyles = {
  //     control: (provided, state) => ({
  //       ...provided,
  //       backgroundColor: "#eeeeee",
  //       border: "1px solid #ced4da",
  //       borderRadius: "10px",
  //       minHeight: "35px",
  //       height: "40px",
  //       width: "100%",
  //       marginBottom: "15px",
  //     }),
  //     multiValueLabel: (provided) => ({
  //       ...provided,
  //       display: "block", // Hide the selected values
  //     }),
  //     multiValueRemove: (provided) => ({
  //       ...provided,
  //       display: "block", // Hide the remove button for selected values
  //     }),
  //     multiValueRemove: (base, state) => ({
  //       ...base,
  //       display: 'none', // Hide the cross button
  //     }),
  //     placeholder: (defaultStyles) => {
  //       return {
  //         ...defaultStyles,
  //         color: "rgb(131, 145, 161)",
  //         fontFamily: "urbanist",
  //         fontSize: "1rem",
  //         fontWeight: "400",
  //         justifyContent: "flex-start",
  //       };
  //     },
  //     menuPortal: (base) => ({
  //       ...base,
  //       zIndex: 9999,
  //       color: "#000000",
  //       fontFamily: "poppins",
  //       fontSize: "16px",
  //       fontWeight: "400",
  //     }),
  //     MenuList: (base) => ({
  //       ...base,
  //       // zIndex: 9999,
  //       color: "#000000",
  //       fontFamily: "poppins",
  //       fontSize: "16px",
  //       fontWeight: "400",
  //     }),
  //     valueContainer: (provided, state) => ({
  //       ...provided,
  //       height: "30px",
  //       padding: "0 6px",
  //       display: "flex",
  //       justifyContent: "flex-start",
  //       gap: "10px",
  //     }),
  //     indicatorSeparator: (base) => ({
  //       ...base,
  //       display: "none",
  //     }),
  //   };
  //   const buttonStyles = {
  //     color: "#ffffff",
  //   };
  //   console.log("selectedValue==>", selectedValue);





  //   console.log("token", tokenLogin)


  //   async function CallingInterest(token) {
  //     tokenLogin = localStorage.getItem(token)
  //     console.log("token now", tokenLogin)
  //     return tokenLogin
  //   }


  //   useEffect(() => {
  //     CallingInterest("accessToken")
  //   }, [])

  //   useEffect(() => {

  //     try {

  //       // fetch('https://app.tweetnow.ai/TweetNow/api/interestKeyword', {
  //       fetch('https://app.tweetnow.ai/TweetNow/api/interest/keyword', {
  //         method: 'GET',

  //         headers: {
  //           Authorization: `Bearer ${tokenLogin}`,
  //           'Content-Type': 'application/json'
  //         }
  //       }).then(response => {

  //         console.log("response-----", response)
  //         return response.json();
  //       }).then(data => {
  //         console.log("Dahahhahah", data);
  //         setInterestlist(data.data.interestList)
  //         if (data.code == 200) {
  //           console.log("success")
  //         }
  //         else {
  //           console.log("failed")
  //         }

  //       })



  //     } catch (error) {
  //       console.log(error.message);
  //     }


  //   }, [])


  //   // useEffect(() => {
  //   //   const array = localStorage.getItem("data2")
  //   //   setSelectedValue(array.split(","))
  //   //   console.log("setoooo[][][]", storedData)
  //   // }, [])


  //   // this

  //   useEffect(() => {
  //     const storedData = localStorage.getItem("data2");
  //     if (storedData) {
  //       // setSelectedValue(storedData.split(","));
  //       const parsedData = storedData.split(",");
  //       setSelectedValue(parsedData || []);

  //       console.log("setoooo[-----", storedData);
  //     }




  //   }, []);

  //   console.log("cd-1", selectedValue)

  //   // const handleChange222 = () => {
  //   //   let val = interestList.map((v) => v);

  //   //   console.log('Selected Values:', val);
  //   //   console.log('Local Data Array:', selectedValue);

  //   //   const duplicateValue = val.find((selected) => selectedValue.includes(selected));

  //   //   console.log('Duplicate Value:', duplicateValue);

  //   //   if (duplicateValue) {
  //   //     alert(`Duplicate value "${duplicateValue}" selected from local storage data.`);
  //   //     return;
  //   //   }

  //   //   setSelectedValue((prevValue) => {
  //   //     const uniqueValues = [...new Set([...prevValue, ...val])];
  //   //     return uniqueValues;
  //   //   });
  //   // };





  //   const MultiValueRemove = () => {

  //   };
  //   const options = interestList.map((value) => ({ value, label: value }))



  //   return (


  //     <div className="user-interset-body">


  //       <Container className="user-interset-form-body">
  //         <div className="form-heading text-center">

  //           <h4> Welcome to Tweet Now </h4>

  //           <p>Define your interests</p>

  //         </div>

  //         <div className="form-user-interset">
  //           <Container>
  //             <div>
  //               <h5> Type in or select your topics from suggestions</h5>



  //               <Select
  //                 styles={customStyles}
  //                 className="dropdown"
  //                 placeholder=""
  //                 value={options.filter((obj) => selectedValue.includes(obj.value))}
  //                 options={options}

  //                 onChange={(e) => handleChange(e)}
  //                 isMulti
  //                 isClearable
  //                 components={{ MultiValueRemove }} // Disable the cross button for selected values

  //               />
  //             </div>

  //             <div className="form-display-data-user-interset">


  //               {selectedValue.map((s, i) => {

  //                 return (
  //                   <Button
  //                     variant="warning"
  //                     size="sm"
  //                     className="rounded-pill mb-1 display-data"
  //                     key={i}
  //                     styles={buttonStyles}
  //                   >
  //                     {selectedValue.length == 0 ? "" : s}
  //                     <FontAwesomeIcon
  //                       style={{ fontSize: "13px", cursor: "pointer", }}
  //                       // onClick={() => removeCountryHandler(i)}
  //                       onClick={() => removeSelectedValue(s)}
  //                       className="cross-interest"
  //                       icon={faXmark}
  //                     />
  //                   </Button>

  //                 );
  //               })}

  //             </div>

  //             <div className="suggestion-user-interset">
  //               <h5>Suggestions</h5>
  //               <div className="button-container">
  //                 {options.map((d, i) => {
  //                   return (
  //                     <Button
  //                       variant="success"
  //                       size="sm"
  //                       className="rounded-pill mb-1 user-button"
  //                       key={i}
  //                       onClick={() => {
  //                         handleChange([{ label: "", value: d.label }])
  //                         // setSelectedValue([...selectedValue, d.label]);
  //                       }}
  //                     >
  //                       {d.label}
  //                     </Button>

  //                   );
  //                 })}
  //               </div>

  //             </div>



  //             <div className="nextbtn text-center">

  //               <button className="next-btn" onClick={handleNext}>
  //                 Next
  //               </button>
  //             </div>





  //           </Container>

  //         </div>



  //       </Container >
  //     </div >
  //   );





  const categories = ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5', 'Category 6'];
  const suggestionCategories = ['Suggestion 1', 'Suggestion 2', 'Suggestion 3', 'Suggestion 4', 'Suggestion 5', 'Suggestion 6'];
  const navigate = useNavigate()





  // const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    const storedCategories = JSON.parse(localStorage.getItem('selectedCategories')) || [];
    setSelectedCategories(storedCategories);
  }, []);

  // const handleCategoryChange = (event) => {
  //   const selectedCategory = event.target.value;
  //   if (selectedCategories.length < 100 && !selectedCategories.includes(selectedCategory)) {
  //     const updatedCategories = [...selectedCategories, selectedCategory];
  //     setSelectedCategories(updatedCategories);
  //     localStorage.setItem('selectedCategories', JSON.stringify(updatedCategories));
  //   }
  // };

  // const handleCategoryRemove = (categoryToRemove) => {
  //   const updatedCategories = selectedCategories.filter(category => category !== categoryToRemove);
  //   setSelectedCategories(updatedCategories);
  //   localStorage.setItem('selectedCategories', JSON.stringify(updatedCategories));
  // };

  // const availableCategories = categories.filter(category => !selectedCategories.includes(category));
  // const availableSuggestionCategories = suggestionCategories.filter(category => !selectedCategories.includes(category));

  // console.log("select", selectedCategories)


  const [selectedCategories, setSelectedCategories] = useState([]);
  const [interestCategories, setInterestCategories] = useState([]);
  const emailTo = localStorage.getItem('email-to')

  useEffect(() => {

    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


    const targetUser = userDataArray.find(user => user.email === emailTo);

    if (targetUser) {
      const { userID, accessToken, providerToken, secretKey } = targetUser;
      const tokenLogin = localStorage.getItem('accessToken'); // Replace this with your actual access token
      fetchInterestCategories(accessToken);
    }
    // const tokenLogin = localStorage.getItem('accessToken'); // Replace this with your actual access token
    // fetchInterestCategories(accessToken);
  }, []);

  const fetchInterestCategories = (accessToken) => {
    try {
      // fetch('https://app.tweetnow.ai/TweetNow/api/interest/keyword', {
      fetch('https://app.xblue.ai/Xblue/api/interest/keyword', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => {
          if (data.code === 200) {
            setInterestCategories(data.data.interestList);
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  console.log("interes", interestCategories)

  // const handleCategoryChange = (event) => {
  //   const selectedCategory = event.target.value;
  //   if (selectedCategories.length < 100 && !selectedCategories.includes(selectedCategory)) {
  //     const updatedCategories = [...selectedCategories, selectedCategory];
  //     setSelectedCategories(updatedCategories);
  //     localStorage.setItem('selectedCategories', JSON.stringify(updatedCategories));
  //   }
  // };

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;


    const trimmedCategory = selectedCategory.trim();


    if (selectedCategories.length < 100 && !selectedCategories.includes(trimmedCategory)) {
      const updatedCategories = [...selectedCategories, trimmedCategory];
      setSelectedCategories(updatedCategories);
      localStorage.setItem('selectedCategories', JSON.stringify(updatedCategories));
    }

  };

  const handleCategoryRemove = (categoryToRemove) => {
    const updatedCategories = selectedCategories.filter(category => category !== categoryToRemove);
    setSelectedCategories(updatedCategories);
    localStorage.setItem('selectedCategories', JSON.stringify(updatedCategories));
  };

  const availableCategories = interestCategories.filter(category => !selectedCategories.includes(category));
  const noOptionsLeft = availableCategories.length === 0;

  // const handleNext = () => {
  //   navigate('/app/user-info')
  // }




  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#eeeeee",
      border: "1px solid #ced4da",
      borderRadius: "10px",
      minHeight: "35px",
      height: "40px",
      width: "100%",
      marginBottom: "15px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      display: "block", // Hide the selected values
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      display: "block", // Hide the remove button for selected values
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      display: 'none', // Hide the cross button
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "rgb(131, 145, 161)",
        fontFamily: "urbanist",
        fontSize: "1rem",
        fontWeight: "400",
        justifyContent: "flex-start",
      };
    },
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
      color: "#000000",
      fontFamily: "poppins",
      fontSize: "16px",
      fontWeight: "400",
    }),
    MenuList: (base) => ({
      ...base,
      // zIndex: 9999,
      color: "#000000",
      fontFamily: "poppins",
      fontSize: "16px",
      fontWeight: "400",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      padding: "0 6px",
      display: "flex",
      justifyContent: "flex-start",
      gap: "10px",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
  };
  const buttonStyles = {
    color: "#ffffff",
  };





  const handleNext = () => {

    try {

      const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


      const targetUser = userDataArray.find(user => user.email === emailTo);

      if (targetUser) {
        const { userID, accessToken } = targetUser;

        const myStrring = selectedCategories.toString()

        let fdata = new FormData();

        fdata.append('interest', myStrring);
        fdata.append('id', userID);



        // fetch('https://app.tweetnow.ai/TweetNow/api/save/interest', {
        fetch('https://app.xblue.ai/Xblue/api/save/interest', {
          method: 'POST',
          body: fdata,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
        }).then(response => {

          console.log("response interest", response)
          return response.json();
        }).then(data => {
          console.log("data interes", data);
          if (data.code == 200) {
            console.log("success hello")

            navigate('/app/user-info')
            // localStorage.setItem("data2", String(data.data.profile[0].interest))


            // if (selectedValue.length == 0) {
            //   setSelectedValue([])
            //   localStorage.setItem("data2", selectedValue)
            //   console.log("0000", selectedValue)
            // }
            // else {
            //   localStorage.setItem("data2", String(data.data.profile[0].interest))
            // }




          }

          else {

            console.log("failed hello")
          }

        })
      }






    } catch (error) {
      console.log(error)
    }


  }


  return (

    <>
      <div className="user-interset-body">
        <Container className="user-interset-form-body">
          <div className="form-heading text-center">

            <h4> Welcome to Xblue </h4>

            <p>Define your interests</p>

          </div>

          {/* 
          <div>
            <h1>Select Categories</h1>
            <select onChange={handleCategoryChange}>
              <option value="">Select a category</option>
              {availableCategories.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
            <h2>Selected Categories:</h2>
            <ul>
              {selectedCategories.map(category => (
                <li key={category}>
                  {category}
                  <button onClick={() => handleCategoryRemove(category)}>Remove</button>
                </li>
              ))}
            </ul>
            <div>
              <h2>Suggestion Categories:</h2>
              <div className="row">
                {interestCategories.map(category => (
                  <div className="col" key={category}>
                    {category}
                    <button onClick={() => handleCategoryChange({ target: { value: category } })}>Select</button>
                  </div>
                ))}
              </div>
            </div>
          </div> */}


          <div className="form-user-interset">
            <Container>
              <div>
                <h5> Type in or select your topics from suggestions</h5>



                <select onChange={handleCategoryChange} className="select-interest" disabled={noOptionsLeft}>
                  <option value="">Select a category</option>
                  {availableCategories.map(category => (
                    <option key={category} value={category}>{category}</option>
                  ))}
                </select>
              </div>

              <div className="form-display-data-user-interset">


                {selectedCategories.map(category => {

                  return (
                    <Button
                      variant="warning"
                      size="sm"
                      className="rounded-pill mb-1 display-data"
                      key={category}
                      styles={buttonStyles}
                    >
                      {category}
                      <FontAwesomeIcon
                        style={{ fontSize: "13px", cursor: "pointer", }}
                        onClick={() => handleCategoryRemove(category)}
                        className="cross-interest"
                        icon={faXmark}
                      />
                    </Button>

                  );
                })}

              </div>

              <div className="suggestion-user-interset">
                <h5>Suggestions</h5>
                <div className="button-container">
                  {interestCategories.map((category) => {
                    return (
                      <Button
                        variant="success"
                        size="sm"
                        className="rounded-pill mb-1 user-button"
                        key={category}
                        onClick={() => handleCategoryChange({ target: { value: category } })}
                      >
                        {category}
                      </Button>

                    );
                  })}
                </div>

              </div>



              <div className="nextbtn text-center">

                <button className="next-btn" onClick={handleNext}>
                  Next
                </button>
              </div>





            </Container>

          </div>



        </Container>


        {/* <div className="nextbtn text-center">

          <button className="next-btn" onClick={handleNext}>
            Next
          </button>
        </div> */}
      </div>
    </>
  )


}
export default NewUserInterest;











