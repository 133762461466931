import React from 'react'
import './Receipt.css'
import { Container } from 'react-bootstrap'

export default function Receipt() {
    return (
        <div className='payment-screen-body' style={{ backgroundColor: 'white' }}>
            {/* <Container>
                <div className='receipt-table'>
                    <div className='receipt-box'>
                        <div className='receipt-header-back'>

                        </div>
                        <div className='text-center'>
                            <h3>
                                Receipt from TweetNow
                            </h3>

                            <p>Receipt #1945-9173</p>

                        </div>
                        <div className='receipt-display-flex'>
                            <div className='margin-receipt'>
                                <p>
                                    AMOUNT PAID</p>
                                <p>$1.00</p>
                            </div>
                            <div className='margin-receipt'>
                                <p>

                                    DATE PAID</p>
                                <p>Jul 18, 2023, 2:27:34 AM</p>
                            </div>
                            <div className='margin-receipt'>
                                <p>
                                    PAYMENT METHOD</p>
                                <p>Visa - 4242</p>
                            </div>
                        </div>
                        <div className='receipt-display-summary'>
                            <h4>SUMMARY</h4>

                            <div className='receipt-summary-box'>


                                <div>
                                    <p>
                                        Payment to TweetNow</p>
                                    <p>$1.00</p>

                                </div>
                                <div>
                                    <p>Amount charged</p>
                                    <p>$1.00

                                    </p>
                                </div>






                            </div>

                        </div>


                    </div>

                </div>
            </Container> */}
            <div className="container d-flex justify-content-center align-items-center vh-100">
                <div className="card-receipt">
                    <div className='receipt-header-back'>

                    </div>
                    <div className="card-body">
                        {/* <h5 className="card-title">Your Card Title</h5>
                        <p className="card-text">Your card content goes here.</p> */}

                        <div className='text-center'>
                            <h3>
                                Receipt from TweetNow
                            </h3>

                            <p>Receipt #1945-9173</p>

                        </div>
                        <div className='receipt-display-flex'>
                            <div className='margin-receipt'>
                                <p>
                                    AMOUNT PAID</p>
                                <p>$1.00</p>
                            </div>
                            <div className='margin-receipt'>
                                <p>

                                    DATE PAID</p>
                                <p>Jul 18, 2023, 2:27:34 AM</p>
                            </div>
                            <div className='margin-receipt'>
                                <p>
                                    PAYMENT METHOD</p>
                                <p>Visa - 4242</p>
                            </div>
                        </div>
                        <div className='receipt-display-summary'>
                            <h4>SUMMARY</h4>

                            <div className='receipt-summary-box'>


                                <div>
                                    <p>
                                        Payment to TweetNow</p>
                                    <p>$1.00</p>

                                </div>
                                <div>
                                    <p>Amount charged</p>
                                    <p>$1.00

                                    </p>
                                </div>






                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}