import React, { useEffect, useState, useRef } from 'react'
// Import selectors & action from setting store
import * as SettingSelector from '../../../../store/setting/selectors'
import Ellipse from '../../../../assets/images/Ellipse.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChartSimple, faClipboard, faRobot, faGear, faRightFromBracket, faPlus, faLocationDot, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2";


//react-bootstrap



// Redux Selector / Action
import { useSelector } from 'react-redux';
import { Nav } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import './StyleVerticalNav.css'
//components
import Verticalnav from './verticalnav'
import Scrollbar from 'smooth-scrollbar'
import { BASE_URL } from '../../../../utilities/Config';
const Sidebar = ({ onComposeClick, updateProfileDetails }) => {

  const sidebarType = useSelector(SettingSelector.sidebar_type) // array
  const sidebarMenuStyle = useSelector(SettingSelector.sidebar_menu_style)
  const logoutSingleUser = 0

  useEffect(
    () => {
      Scrollbar.init(document.querySelector('.data-scrollbar'))

      window.addEventListener('resize', () => {
        const tabs = document.querySelectorAll('.nav')
        const sidebarResponsive = document.querySelector('[data-sidebar="responsive"]')
        if (window.innerWidth < 1025) {
          Array.from(tabs, (elem) => {
            if (!elem.classList.contains('flex-column') && elem.classList.contains('nav-tabs') && elem.classList.contains('nav-pills')) {
              elem.classList.add('flex-column', 'on-resize');
            }
            return elem.classList.add('flex-column', 'on-resize')
          })
          if (sidebarResponsive !== null) {
            if (!sidebarResponsive.classList.contains('sidebar-mini')) {
              sidebarResponsive.classList.add('sidebar-mini', 'on-resize')
            }
          }
        } else {
          Array.from(tabs, (elem) => {
            if (elem.classList.contains('on-resize')) {
              elem.classList.remove('flex-column', 'on-resize');
            }
            return elem.classList.remove('flex-column', 'on-resize');
          })
          if (sidebarResponsive !== null) {
            if (sidebarResponsive.classList.contains('sidebar-mini') && sidebarResponsive.classList.contains('on-resize')) {
              sidebarResponsive.classList.remove('sidebar-mini', 'on-resize')
            }
          }
        }

      })
    }
  )





  const Navigate = useNavigate()
  const [displayProfileDetails, setDisplayProfileDetails] = useState()

  const navigate = useNavigate();

  const userID = localStorage.getItem('UserID')
  const tokenLogin = localStorage.getItem('accessToken')
  console.log("token-login", tokenLogin)
  console.log("userID", tokenLogin);

  const [newEmail, setNewEmail] = useState('')
  const [newEmailError, setNewEmailError] = useState('')


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)

  };


  const [showPopover, setShowPopover] = useState(false);
  const [openPopoverIndex, setOpenPopoverIndex] = useState(false);
  const popoverRef = useRef(null);

  // const togglePopover = (index) => {
  //   if (openPopoverIndex === index) {
  //     // Clicking on the same icon again should close the popover
  //     setOpenPopoverIndex(null);
  //   } else {
  //     // Clicking on a different icon should open the popover
  //     setOpenPopoverIndex(index);
  //   }
  // };
  const togglePopover = () => {
    setOpenPopoverIndex(!openPopoverIndex)
  }

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      // Click occurred outside the popover, so close it
      setOpenPopoverIndex(null);
    }
  };

  useEffect(() => {
    // Attach the click event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  const emailTo = localStorage.getItem('email-to')









  useEffect(() => {
    displayProfile()
  }, [])
  // const displayProfile = async () => {

  //   try {
  //     const response = await fetch(`${BASE_URL}api/user/${userID}`, {
  //       headers: {
  //         Authorization: `Bearer ${tokenLogin}`,
  //         'Content-Type': 'application/json'
  //       }
  //     });
  //     const data = await response.json();
  //     console.log("published/profile", data);
  //     if (data.code == 200) {

  //       setDisplayProfileDetails(data.data.user)



  //     }
  //     else {
  //       setDisplayProfileDetails([])


  //       console.log("failed")


  //     }


  //   } catch (error) {
  //     console.error('Error fetching buyer data:', error);
  //   }
  // };

  const emailSwitch = localStorage.getItem('emailSwitch')

  const userLoggedin = localStorage.getItem('userloggedin')

  const displayProfile = async () => {
    if (localStorage.getItem("userloggedin") === 'true') {
      try {
        const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


        const targetUser = userDataArray.find(user => user.email === emailTo);

        if (targetUser) {
          const { userID, accessToken } = targetUser;


          const response = await fetch(`${BASE_URL}api/user/${userID}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          });
          const data = await response.json();

          console.log("published/profile", data);
          if (data.code == 200) {

            setDisplayProfileDetails(data.data.user)

          }
          else {
            setDisplayProfileDetails([])


            console.log("failed")


          }
        } else {
          console.log(`User with email ${emailTo} not found`);
        }


      } catch (error) {
        console.error('Error fetching buyer data:', error);
      }
    }
    else {

      try {
        const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


        const targetUser = userDataArray.find(user => user.email === emailSwitch);

        if (targetUser) {
          const { userID, accessToken } = targetUser;


          const response = await fetch(`${BASE_URL}api/user/${userID}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          });
          const data = await response.json();

          console.log("published/profile", data);
          if (data.code == 200) {

            setDisplayProfileDetails(data.data.user)

          }
          else {
            setDisplayProfileDetails([])


            console.log("failed")


          }
        } else {
          window.open("/", "_self")
          localStorage.removeItem('accessToken-login')

          localStorage.removeItem('providerToken-login')
          console.log(`User with email ${emailTo} not found`);
        }


      } catch (error) {
        console.error('Error fetching buyer data:', error);
      }
    }
  };



  const oauth_token_secret = localStorage.getItem('secretKey')
  const provider_token = localStorage.getItem('providerToken')


  const handleLogout = () => {
    localStorage.setItem("accountAdd", 'false')
    localStorage.setItem('userloggedin', 'false')
    if (localStorage.getItem("userloggedin") === 'true') {
      try {


        const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


        const targetUser = userDataArray.find(user => user.email === emailTo);

        if (targetUser) {
          const { userID, accessToken, providerToken, secretKey } = targetUser;

          let fdata = new FormData();

          fdata.append('provider_token', providerToken);
          fdata.append('oauth_token_secret', secretKey);



          fetch(`${BASE_URL}api/user/logout`, {
            method: 'POST',
            body: fdata,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            }
          }).then(response => {

            console.log("response signout", response)
            return response.json();
          }).then(data => {
            console.log("response signout - 22", data);

            if (data.code == 200) {


              // window.location.reload()

              // Navigate("/")
              window.location.href = '/';
              localStorage.removeItem('accessToken')
              localStorage.removeItem('UserID')
              localStorage.removeItem('providerToken')
              localStorage.removeItem('oAuthToken')
              localStorage.removeItem('TimeZone')
              localStorage.removeItem('date2')
              localStorage.removeItem('date3')
              localStorage.removeItem('userInterestForm')
              localStorage.removeItem('selectedCategories')
              localStorage.removeItem('savedValue')
              localStorage.setItem('logoutSingleUser', logoutSingleUser)
              localStorage.removeItem('accessToken-login')

              localStorage.removeItem('providerToken-login')


              // localStorage.removeItem('userDataArray')



            }
            else if (data.code == 401) {

              window.location.href = '/';
              localStorage.removeItem('accessToken')
              localStorage.removeItem('UserID')
              localStorage.removeItem('providerToken')
              localStorage.removeItem('oAuthToken')
              localStorage.removeItem('TimeZone')
              localStorage.removeItem('date3')
              localStorage.removeItem('date2')
              localStorage.removeItem('userInterestForm')
              localStorage.removeItem('selectedCategories')
              localStorage.removeItem('accessToken-login')

              localStorage.removeItem('providerToken-login')

            }

            else {

              console.log("session failed")
            }

          })

        } else {
          window.location.href = '/';
          console.log(`User with email ${emailTo} not found`);

          // Navigate("/")
          window.location.href = '/';
          localStorage.removeItem('accessToken')
          localStorage.removeItem('UserID')
          localStorage.removeItem('providerToken')
          localStorage.removeItem('oAuthToken')
          localStorage.removeItem('TimeZone')
          localStorage.removeItem('date2')
          localStorage.removeItem('userInterestForm')
          localStorage.removeItem('selectedCategories')
          localStorage.removeItem('savedValue')
          localStorage.removeItem('accessToken-login')

          localStorage.removeItem('providerToken-login')

        }




      } catch (error) {
        console.log(error.message);
      }

      // Navigate("/")
      // window.location.href = '/';
      // localStorage.removeItem('accessToken')
      // localStorage.removeItem('UserID')
      // localStorage.removeItem('providerToken')
      // localStorage.removeItem('oAuthToken')
      // localStorage.removeItem('TimeZone')
    }
    else {
      try {


        const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


        const targetUser = userDataArray.find(user => user.email === emailSwitch);

        if (targetUser) {
          const { userID, accessToken, providerToken, secretKey } = targetUser;

          let fdata = new FormData();

          fdata.append('provider_token', providerToken);
          fdata.append('oauth_token_secret', secretKey);



          fetch(`${BASE_URL}api/user/logout`, {
            method: 'POST',
            body: fdata,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            }
          }).then(response => {

            console.log("response signout", response)
            return response.json();
          }).then(data => {
            console.log("response signout - 22", data);

            if (data.code == 200) {


              // window.location.reload()

              // Navigate("/")
              window.location.href = '/';
              localStorage.removeItem('accessToken')
              localStorage.removeItem('UserID')
              localStorage.removeItem('providerToken')
              localStorage.removeItem('oAuthToken')
              localStorage.removeItem('TimeZone')
              localStorage.removeItem('date2')
              localStorage.removeItem('date3')
              localStorage.removeItem('userInterestForm')
              localStorage.removeItem('selectedCategories')
              localStorage.removeItem('savedValue')
              localStorage.setItem('logoutSingleUser', logoutSingleUser)

              localStorage.removeItem('accessToken-login')

              localStorage.removeItem('providerToken-login')

              // localStorage.removeItem('userDataArray')



            }
            else if (data.code == 401) {

              window.location.href = '/';
              localStorage.removeItem('accessToken')
              localStorage.removeItem('UserID')
              localStorage.removeItem('providerToken')
              localStorage.removeItem('oAuthToken')
              localStorage.removeItem('TimeZone')
              localStorage.removeItem('date3')
              localStorage.removeItem('date2')
              localStorage.removeItem('userInterestForm')
              localStorage.removeItem('selectedCategories')
              localStorage.removeItem('accessToken-login')

              localStorage.removeItem('providerToken-login')
            }

            else {

              console.log("session failed")
            }

          })

        } else {
          console.log(`User with email ${emailTo} not found`);

          // Navigate("/")
          window.location.href = '/';
          localStorage.removeItem('accessToken')
          localStorage.removeItem('UserID')
          localStorage.removeItem('providerToken')
          localStorage.removeItem('oAuthToken')
          localStorage.removeItem('TimeZone')
          localStorage.removeItem('date2')
          localStorage.removeItem('userInterestForm')
          localStorage.removeItem('selectedCategories')
          localStorage.removeItem('savedValue')
          localStorage.removeItem('accessToken-login')

          localStorage.removeItem('providerToken-login')
        }




      } catch (error) {
        console.log(error.message);
      }

    }
  }

  // const handleLogout = () => {

  //   // Navigate("/")
  //   window.location.href = '/';
  //   // localStorage.removeItem('userDataArray')
  //   localStorage.removeItem('accessToken')
  //   localStorage.removeItem('UserID')
  //   localStorage.removeItem('providerToken')
  //   localStorage.removeItem('oAuthToken')
  //   localStorage.removeItem('TimeZone')
  //   localStorage.removeItem('date2')
  //   localStorage.removeItem('userInterestForm')
  //   localStorage.removeItem('selectedCategories')
  // }


  const handleLogoutMulti = () => {
    localStorage.setItem("accountAdd", 'true')


    const previousemail = localStorage.getItem('emailFist')
    localStorage.setItem('previousEmail', previousemail)
    // if (localStorage.getItem("userloggedin") === 'true') {

    //   try {

    //     const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


    //     const targetUser = userDataArray.find(user => user.email === emailTo);

    //     if (targetUser) {
    //       const { userID, accessToken, providerToken, secretKey } = targetUser;
    //       let fdata = new FormData();

    //       fdata.append('provider_token', providerToken);
    //       fdata.append('oauth_token_secret', secretKey);



    //       fetch(`${BASE_URL}api/user/logout`, {
    //         method: 'POST',
    //         body: fdata,
    //         headers: {
    //           Authorization: `Bearer ${accessToken}`,
    //         }
    //       }).then(response => {

    //         console.log("response signout", response)
    //         return response.json();
    //       }).then(data => {
    //         console.log("response signout - 22", data);

    //         if (data.code == 200) {


    //           const newTab = window.open('', '_blank');
    //           newTab.location.href = '/#/app/logout';


    //           if (newTab) {
    //             window.open("https://app.xblue.ai/Xblue/login/twitter", "_self")
    //           }
    //           // localStorage.removeItem('accessToken')
    //           // localStorage.removeItem('UserID')
    //           // localStorage.removeItem('providerToken')
    //           // localStorage.removeItem('oAuthToken')
    //           // localStorage.removeItem('TimeZone')
    //           // localStorage.removeItem('date2')
    //           // localStorage.removeItem('date3')
    //           // localStorage.removeItem('userInterestForm')
    //           // localStorage.removeItem('selectedCategories')
    //           // localStorage.removeItem('savedValue')



    //         }
    //         else if (data.code == 401) {


    //           // localStorage.removeItem('accessToken')
    //           // localStorage.removeItem('UserID')
    //           // localStorage.removeItem('providerToken')
    //           // localStorage.removeItem('oAuthToken')
    //           // localStorage.removeItem('TimeZone')
    //           // localStorage.removeItem('date3')
    //           // localStorage.removeItem('date2')
    //           // localStorage.removeItem('userInterestForm')
    //           // localStorage.removeItem('selectedCategories')
    //         }

    //         else {

    //           console.log("session failed")
    //         }

    //       })
    //     }
    //     else {
    //       localStorage.removeItem('accessToken-login')

    //       localStorage.removeItem('providerToken-login')
    //       window.location.href = '/';
    //       console.log(`User with email ${emailTo} not found`);
    //     }




    //   } catch (error) {
    //     console.log(error.message);
    //   }
    // }
    // else {
    //   try {

    //     const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


    //     const targetUser = userDataArray.find(user => user.email === emailSwitch);

    //     if (targetUser) {
    //       const { userID, accessToken, providerToken, secretKey } = targetUser;
    //       let fdata = new FormData();

    //       fdata.append('provider_token', providerToken);
    //       fdata.append('oauth_token_secret', secretKey);



    //       fetch(`${BASE_URL}api/user/logout`, {
    //         method: 'POST',
    //         body: fdata,
    //         headers: {
    //           Authorization: `Bearer ${accessToken}`,
    //         }
    //       }).then(response => {
    //         if (response.ok) {
    //           console.log("Logout successful");
    //           // Check if there is a redirect
    //           if (response.redirected) {
    //             console.log("Redirected to:", response.url);
    //             // You can handle the redirection here, e.g., open a new tab
    //             const newTab = window.open('', '_blank');
    //             newTab.location.href = response.url;
    //           } else {
    //             // Handle success without redirection
    //           }
    //         } else {
    //           console.log("Logout failed. Status code:", response.status);
    //         }


    //         console.log("response signout", response)
    //         return response.json();
    //       }).then(data => {
    //         console.log("response signout - 22", data);

    //         if (data.code == 200) {


    //           const newTab = window.open('', '_blank');
    //           newTab.location.href = '/#/app/logout';


    //           if (newTab) {
    //             window.open("https://app.xblue.ai/Xblue/login/twitter", "_self")
    //           }




    //         }
    //         else if (data.code == 401) {



    //         }

    //         else {

    //           console.log("session failed")
    //         }

    //       })
    //     }
    //     else {
    //       window.location.href = '/';
    //       localStorage.removeItem('accessToken-login')

    //       localStorage.removeItem('providerToken-login')

    //       console.log(`User with email ${emailTo} not found`);
    //     }




    //   } catch (error) {
    //     console.log(error.message);
    //   }
    // }

    const newTab = window.open('', '_blank');
    newTab.location.href = '/#/app/logout';

    if (newTab) {
      window.open("https://app.xblue.ai/Xblue/login/twitter", "_self")
    }

  }









  // const handleLogout = () => {

  //   // Navigate("/")
  //   window.location.href = '/';
  //   localStorage.removeItem('accessToken')
  //   localStorage.removeItem('UserID')
  //   localStorage.removeItem('providerToken')
  //   localStorage.removeItem('oAuthToken')
  //   localStorage.removeItem('TimeZone')
  //   localStorage.removeItem('date2')
  //   localStorage.removeItem('userInterestForm')
  //   localStorage.removeItem('selectedCategories')


  // }
  const handleComposeClick = () => {
    onComposeClick();
  };

  const handleSubmitNewAccount = () => {
    if (!newEmail) {
      setNewEmailError('*Email field is required.*');

      return;
    } else if (!/\S+@\S+\.\S+/.test(newEmail)) {
      setNewEmailError('*Please enter a valid email address.*');
      return;
    }
    else {
      setNewEmailError(null);
    }

    try {

      let fdata = new FormData();

      fdata.append('has_multiple_account', newEmail ? 1 : 0);
      fdata.append('multiple_account_email', newEmail);
      fdata.append('id', userID);



      fetch(`${BASE_URL}api/save/multiple/account`, {
        method: 'POST',
        body: fdata,
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
        }
      }).then(response => {

        console.log("response signout", response)
        return response.json();
      }).then(data => {
        console.log("response signout - 22", data);
        if (data.code == 200) {
          setShow(false)

          toast.success(data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          try {

            let fdata = new FormData();

            fdata.append('provider_token', provider_token);
            fdata.append('oauth_token_secret', oauth_token_secret);



            fetch(`${BASE_URL}api/user/logout`, {
              method: 'POST',
              body: fdata,
              headers: {
                Authorization: `Bearer ${tokenLogin}`,
              }
            }).then(response => {

              console.log("response signout", response)
              return response.json();
            }).then(dataL => {
              console.log("response signout - 22", dataL);

              if (dataL.code == 200) {
                console.log("data.new account", data?.data)
                // window.open("https://xblue.ai/Xblue/login/twitter", "_self");
                window.open("https://app.xblue.ai/Xblue/login/twitter", "_self")
                localStorage.removeItem('accessToken')
                localStorage.removeItem('UserID')
                localStorage.removeItem('providerToken')
                localStorage.removeItem('oAuthToken')
                localStorage.removeItem('TimeZone')
                localStorage.removeItem('date2')
                localStorage.removeItem('date3')
                localStorage.removeItem('userInterestForm')
                localStorage.removeItem('selectedCategories')
                localStorage.removeItem('savedValue')



              }

              else {

                console.log("session failed")
              }

            })



          } catch (error) {
            console.log(error.message);
          }

        }
        else {
          setShow(false)
          toast.error(data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }



      })



    } catch (error) {
      console.log(error.message);
    }
  }

  // const handleSwitchAccount = () => {


  //   try {

  //     let fdata = new FormData();

  //     fdata.append('provider_token', provider_token);
  //     fdata.append('oauth_token_secret', oauth_token_secret);



  //     fetch(`${BASE_URL}api/user/logout`, {
  //       method: 'POST',
  //       body: fdata,
  //       headers: {
  //         Authorization: `Bearer ${tokenLogin}`,
  //       }
  //     }).then(response => {

  //       console.log("response signout", response)
  //       return response.json();
  //     }).then(data => {
  //       console.log("response signout - 22", data);

  //       if (data.code == 200) {


  //         // window.location.reload()

  //         // Navigate("/")
  //         // window.open("https://tweetnow.ai/TweetNow/login/twitter", "_self");
  //         window.open("https://xblue.ai/Xblue/login/twitter", "_self");
  //         localStorage.removeItem('accessToken')
  //         localStorage.removeItem('UserID')
  //         localStorage.removeItem('providerToken')
  //         localStorage.removeItem('oAuthToken')
  //         localStorage.removeItem('TimeZone')
  //         localStorage.removeItem('date2')
  //         localStorage.removeItem('date3')
  //         localStorage.removeItem('userInterestForm')
  //         localStorage.removeItem('selectedCategories')



  //       }
  //       else if (data.code == 401) {

  //         window.open("https://xblue.ai/Xblue/login/twitter", "_self");
  //         localStorage.removeItem('accessToken')
  //         localStorage.removeItem('UserID')
  //         localStorage.removeItem('providerToken')
  //         localStorage.removeItem('oAuthToken')
  //         localStorage.removeItem('TimeZone')
  //         localStorage.removeItem('date3')
  //         localStorage.removeItem('date2')
  //         localStorage.removeItem('userInterestForm')
  //         localStorage.removeItem('selectedCategories')
  //       }

  //       else {

  //         console.log("session failed")
  //       }

  //     })



  //   } catch (error) {
  //     console.log(error.message);
  //   }

  // }

  const [emptyText, setEmptyText] = useState("")

  const handleSwitchAccount = (data, index) => {

    localStorage.setItem('emailSwitch', data?.email)
    localStorage.setItem('userloggedin', 'false')


    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


    const targetUser = userDataArray.find(user => user.email === data?.email);
    console.log("tare", targetUser?.email, data?.email, targetUser)

    if (targetUser) {
      const { userID, accessToken, providerToken, secretKey, email } = targetUser;


      try {

        let fdata = new FormData();
        fdata.append('provider_type', "twitter");
        fdata.append('provider_token', providerToken);
        fdata.append('oauth_token_secret', secretKey);
        if (emptyText === undefined || emptyText === null || emptyText === '') {
          fdata.append('multiple_account_email', '');
        }




        // fetch('https://app.tweetnow.ai/TweetNow/api/user/auth', {
        fetch('https://app.xblue.ai/Xblue/api/user/auth', {
          method: 'POST',
          body: fdata,
          // headers: {
          //     Authorization: `Bearer ${authToken}`,
          // }
        }).then(response => {

          console.log("response signin", response)
          return response.json();
        }).then(data => {
          console.log("hjjhb", data);

          if (data.code === 200) {


            if (data.data.is_loggedin == "true") {
              localStorage.setItem('accessToken-login', data.data.token)

              const userData = {
                accessToken: data.data.token,
                userID: data.data.session_data.id,
                providerToken2: data.data.session_data.access_token,
                oAuthToken: data.data.session_data.access_token_secret,
                providerToken: data.data.session_data.access_token,
                secretKey: data.data.session_data.access_token_secret,
                email: data.data.session_data.email
              };
              const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];

              // Check if the user already exists in the array
              const existingUserIndex = userDataArray.findIndex(user => user.email === data.data.session_data.email);

              if (existingUserIndex !== -1) {
                // Update the existing user entry
                userDataArray[existingUserIndex] = userData;
              } else {
                // Add a new user entry
                userDataArray.push(userData);
              }

              localStorage.setItem('userDataArray', JSON.stringify(userDataArray));

              console.log("tare 0", userDataArray)


              window.location.reload()
              localStorage.removeItem('accessToken-login')

              localStorage.removeItem('providerToken-login')



            }
            else {

              // localStorage.setItem('accessToken', data.data.token)
              // localStorage.setItem('UserID', data.data.session_data.id)
              // localStorage.setItem('providerToken-2', data.data.session_data.access_token)
              // localStorage.setItem('oAuthToken', data.data.session_data.access_token_secret)
              // localStorage.setItem('providerToken', params.accessToken)
              // localStorage.setItem('secretKey', params.accessTokenSecret)
              // localStorage.setItem('email-to', params.email)
              // localStorage.removeItem('selectedCategories')
              // Navigate('/app/user-interest')

              // window.location.reload()

              localStorage.setItem('accessToken-login', data.data.token)
              const userData = {
                accessToken: data.data.token,
                userID: data.data.session_data.id,
                providerToken2: data.data.session_data.access_token,
                oAuthToken: data.data.session_data.access_token_secret,
                providerToken: data.data.session_data.access_token,
                secretKey: data.data.session_data.access_token_secret,
                email: data.data.session_data.email
              };
              const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];

              // Check if the user already exists in the array
              const existingUserIndex = userDataArray.findIndex(user => user.email === data.data.session_data.email);

              if (existingUserIndex !== -1) {
                // Update the existing user entry
                userDataArray[existingUserIndex] = userData;
              } else {
                // Add a new user entry
                userDataArray.push(userData);
              }

              localStorage.setItem('userDataArray', JSON.stringify(userDataArray));
              localStorage.removeItem('accessToken-login')

              localStorage.removeItem('providerToken-login')



              window.location.reload()

            }

          }


          else {


            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: data.message,
              // footer: '<a href="/">go back</a>'
            })



          }


        })



      } catch (error) {
        console.log(error.message);
      }


    }
    else {
      window.open("https://app.xblue.ai/Xblue/login/twitter", "_self")

    }
  }

  return (
    <>
      <aside className={`${sidebarType.join(" ")} ${sidebarMenuStyle} sidebar sidebar-default sidebar-base navs-rounded-all `} id="first-tour" data-toggle="main-sidebar" data-sidebar="responsive">
        <div className="sidebar-body pt-0 data-scrollbar">
          <div className="sidebar-list">
            <Verticalnav onComposeClick={handleComposeClick} />

          </div>

        </div>


        <div className="sidebar-footer">


          <ul className="sub-nav footer-nav">
            <div>
              {openPopoverIndex ? (
                <div className="popover-notiii" ref={popoverRef}>


                  <div as="h3" className='popover-header-vertical'>
                    <div className="vertical-popover-header">
                      <div className='DailyIns-popover-image'>
                        <img src={displayProfileDetails?.image_url} className="popover-img-fluid" />
                      </div>
                      <div className='popver-paragrapgh'>
                        <h6>{displayProfileDetails?.name ? displayProfileDetails?.name : ''}</h6>
                        <span>
                          @{displayProfileDetails?.username}

                        </span>
                      </div>
                    </div>
                    {displayProfileDetails?.multiple_accounts ? (
                      <>
                        {displayProfileDetails?.multiple_accounts?.map((data, index) => {
                          return (
                            <>
                              <div className="vertical-popover-header-2" key={index}
                                // onClick={handleSwitchAccount} 

                                onClick={() => handleSwitchAccount(data, index)}
                                style={{ cursor: 'pointer' }}>
                                <div className='DailyIns-popover-image'>
                                  <img src={data?.image_url} className="popover-img-fluid" />
                                </div>
                                <div className='popver-paragrapgh'>
                                  <h6>{data?.name ? data?.name : ''}</h6>
                                  <span>

                                    @{data?.username}
                                  </span>
                                </div>
                              </div>
                            </>
                          )
                        })}

                      </>
                    ) : ''}


                  </div>



                  <div className='popover-body-verticalnav'>
                    <button onClick={handleShow}> <FontAwesomeIcon icon={faPlus} /></button> <span>Add account</span>
                  </div>
                  <hr />
                  <div className='popover-footer-vert' onClick={handleLogout}>
                    <button onClick={handleLogout} className='fotter-sidebar'> <FontAwesomeIcon icon={faRightFromBracket} />  Logout</button>
                  </div>
                </div>
              ) : ''}
            </div>
            <Nav.Item as="li" className='pt-2 footer-list'>
              <div className="nav-link0">

                <div placement="right" >

                  <img src={displayProfileDetails?.image_url} className='Ellipse-icon' />

                  <h6 style={{ color: 'white', fontWeight: 900, marginTop: 10 }}>
                    {displayProfileDetails?.name}
                  </h6>
                  <p style={{ color: '#c3c3c3' }}>
                    {displayProfileDetails?.email ? displayProfileDetails?.email : ''}
                  </p>
                </div>




                <div className="logout-icon">

                  {/* <OverlayTrigger trigger="click"
                    placement="top"
                    overlay={popover}

                  >
                    <FontAwesomeIcon icon={faEllipsisVertical} style={{ color: 'white' }} />
                  </OverlayTrigger> */}

                  <FontAwesomeIcon icon={faEllipsisVertical} style={{ color: 'white' }} onClick={togglePopover} />

                </div>



                {/* {openPopoverIndex ? (
                  <div className="popover" ref={popoverRef}>
                    <div as="h3" className='popover-header-vertical'>
                      <div className="vertical-popover-header">
                        <div className='DailyIns-popover-image'>
                          <img src={displayProfileDetails?.image_url} className="popover-img-fluid" />
                        </div>
                        <div className='popver-paragrapgh'>
                          <h6>{displayProfileDetails?.name ? displayProfileDetails?.name : ''}</h6>
                          <span>
                            @{displayProfileDetails?.username}

                          </span>
                        </div>
                      </div>
                      {displayProfileDetails?.multiple_accounts ? (
                        <>
                          {displayProfileDetails?.multiple_accounts?.map((data, index) => {
                            return (
                              <>
                                <div className="vertical-popover-header-2" key={index} onClick={handleSwitchAccount} style={{ cursor: 'pointer' }}>
                                  <div className='DailyIns-popover-image'>
                                    <img src={data?.image_url} className="popover-img-fluid" />
                                  </div>
                                  <div className='popver-paragrapgh'>
                                    <h6>{data?.name ? data?.name : ''}</h6>
                                    <span>

                                      @{data?.username}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )
                          })}

                        </>
                      ) : ''}


                    </div>

                    <div className='popover-body-verticalnav'>
                      <button onClick={handleShow}> <FontAwesomeIcon icon={faPlus} /></button> <span>Add account</span>
                    </div>

                    <div className='popover-footer-vert' >
                      <button onClick={handleLogout} className='fotter-sidebar'> <FontAwesomeIcon icon={faRightFromBracket} />  Logout</button>
                    </div>
                  </div>
                ) : ''} */}




              </div>
            </Nav.Item>

          </ul>

        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add new account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <form>
              <div>
                <div>
                  <label>Email ID</label>
                </div>
                <input type="email"
                  className='new-account'
                  placeholder='Enter email'
                  onChange={(e) => setNewEmail(e.target.value)}
                />
                {newEmailError && <div className="validation-signin">{newEmailError} </div>}
              </div>
            </form> */}

            <div className='multi-account-modal'>

              <p>Please, make sure you are signed in on Twitter with the account you want to add</p>
            </div>

          </Modal.Body>
          <Modal.Footer>

            <div className='footer-muttliaccount-modal'>
              <button className='cancel-modal' onClick={handleClose}> Cancel </button>
              <button className='connect-modal' onClick={handleLogoutMulti}> Connect </button>
            </div>



            {/* <button onClick={handleSubmitNewAccount} className="addnewaccountbutton">
              Continue
            </button> */}
          </Modal.Footer>
        </Modal>
        <ToastContainer />

      </aside>

    </>
  )
}

export default Sidebar
