import React from "react";
import { Route, HashRouter, BrowserRouter as Router, Routes, Navigate, useNavigate } from "react-router-dom";
// auth
import ConfirmMail from "../views/dashboard/auth/confirm-mail";
import LockScreen from "../views/dashboard/auth/lock-screen";
import Recoverpw from "../views/dashboard/auth/recoverpw";
import SignIn from "../views/dashboard/auth/sign-in";
import SignUp from "../views/dashboard/auth/sign-up";
import NewUserInterest from "../views/dashboard/user-interest/NewUserInterest"
import UserInterest from "../views/dashboard/user-interest/UserInterest";
import UserBio from "../views/dashboard/user-interest/UserBio";

// errors
import Error404 from "../views/dashboard/errors/error404";
import Error500 from "../views/dashboard/errors/error500";

//extrpages
import Maintenance from "../views/dashboard/extrapages/maintenance";
import ComingSoon from "../views/dashboard/extrapages/comingsoon";

import RefreshTweet from "../views/dashboard/auth/refreshTweet";
import Default from "../layouts/dashboard/default";
import DefaultRouter from "./default-router";
import Notification from "../views/dashboard/app/notification";

import File from "../views/dashboard/app/file";
import PrivacyPolicy from "../views/dashboard/extrapages/privacy-policy";
import TermsofService from "../views/dashboard/extrapages/terms-of-service";
import Settings from "../views/dashboard/app/Settings";
import SearchTabs from "../views/dashboard/app/SearchTabs";
import Payments from "../views/dashboard/app/Payment/Payments";
import DailyInspiration from "../views/dashboard/app/DailyInspiration";
import AiGenerated from "../views/dashboard/app/AiGenerated";
import PaymentForm from "../views/dashboard/app/Payment/PaymentForm";
import Receipt from "../views/dashboard/app/Receipt";
import Analytics from "../views/dashboard/app/Analytics";
import BlankPage from "../views/dashboard/extrapages/blankpage";

const SimpleRouter = [

  {
    path: '/dashboard',
    element: <Default />,
    children: [
      { path: 'app/notification', element: <Notification /> },
      { path: 'app/settings', element: <Settings /> },
      { path: 'app/searchtabs', element: <SearchTabs /> },
      { path: 'app/analitics', element: <File /> },
      {
        path: 'app/dailyinspiration', element: <DailyInspiration />,
      },

      {
        path: 'app/aigenerated', element: <AiGenerated />,
      },
      {
        path: 'app/analytics', element: <Analytics />,
      },

      {
        path: "",
        element: <Navigate to="auth/sign-in" />,
      },


    ],
  },

  {
    path: '/app/payments',
    element: <Payments />,
    // element: <UserInterest />,

  },
  {
    path: '/app/paymentform/:paymentid/:productid',
    element: <PaymentForm />,


  },

  {
    path: '/app/Receiptdownload',
    element: <Receipt />,


  },
  {
    path: 'app/terms-condition',
    element: <TermsofService />,
    // element: <UserInterest />,

  },

  {
    path: 'app/privacy-policy',
    element: <PrivacyPolicy />,
    // element: <UserInterest />,

  },

  {
    path: 'app/user-interest',
    element: <NewUserInterest />,
    // element: <UserInterest />,

  },
  {
    path: 'app/user-info',
    element: <UserBio />,
  },
  {
    path: "auth/confirm-mail",
    element: <ConfirmMail />,
  },
  {
    path: "auth/lock-screen",
    element: <LockScreen />,
  },
  {
    path: "auth/recoverpw",
    element: <Recoverpw />,
  },
  {
    path: "/",
    element: <SignIn />,
  },
  {
    path: "auth/sign-up",
    element: <SignUp />,
  },

  {
    path: "auth/refreshTweet/:accessToken/:accessTokenSecret/:email",
    element: <RefreshTweet />,
  },
  {
    path: "errors/error404",
    element: <Error404 />,
  },
  {
    path: "errors/error500",
    element: <Error500 />,
  },
  {
    path: "extra-pages/pages-maintenance",
    element: <Maintenance />,
  },
  {
    path: "extra-pages/pages-comingsoon",
    element: <ComingSoon />,
  },

  {
    path: "/app/logout",
    element: <BlankPage />,
  },


];


// const SimpleRouter = () => {
//   return (
//     <div>
//       <HashRouter>

//         <Routes>
//           {/* COMMON SIMPLE ROUTES */}

//           <Route path="/" exact element={<SignIn />} />
//           <Route path="/auth/refreshTweet/:accessToken/:accessTokenSecret" element={<RefreshTweet />} />
//           <Route path="/user-interest" element={<NewUserInterest />} />
//           <Route path="/user-info" element={<UserBio />} />
//           <Route path="/dashboard/*" element={<Default />}>
//             <Route element={<DefaultRouter />} />
//           </Route>

//           {/* COMMON SIMPLE ROUTES */}
//         </Routes>

//       </HashRouter>

//     </div>
//   )
// }

export default SimpleRouter
