import React from 'react'
import { Card, Container, Row, Col } from 'react-bootstrap'

const PrivacyPolicy = () => {
    return (
        <>
            <Container>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Privacy Policy</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>This Privacy Policy governs the manner in which Xblue ("we," "us," or "our") collects, uses, maintains, and discloses information collected from users (referred to as "you" or "user") of the Xblue website ("website") located at xblue.ai.</p>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Information We Collect</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>We may collect personal identification information from users in various ways when they visit our website, register on the site, fill out a form, interact with certain features, or engage in other activities on our website. The information we may collect includes, but is not limited to:</p>
                                    <p>Name
                                        Email address
                                        Contact information
                                        User-generated content
                                        Usage data
                                        Cookies and other tracking technologies (please refer to our Cookie Policy for more information)
                                        How We Use Collected Information
                                        We may collect and use personal information for the following purposes:</p>
                                    <p>To provide and improve our services: We use the information collected to deliver and enhance the functionality of our web application and services.
                                        To personalize user experience: We may use the information to understand how users interact with our website and to customize their experience.
                                        To send periodic emails: We may use the email address provided to send information and updates related to our services. It may also be used to respond to inquiries, questions, and/or other requests.
                                        Information Sharing
                                        We do not sell, trade, or rent users' personal identification information to others. We may share generic aggregated demographic information that is not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.</p>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Data Security</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>
                                        We implement appropriate data collection, storage, and processing practices, as well as security measures, to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our website.
                                    </p>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Third-Party Websites</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>Users may find advertising or other content on our website that links to the websites and services of our partners, suppliers, advertisers, sponsors, licensors, and other third parties. We do not control the content or links that appear on these websites and are not responsible for the practices employed by websites linked to or from our website. Browsing and interaction on any other website, including websites that have a link to our website, is subject to that website's own terms and policies.</p>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Changes to This Privacy Policy</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>We reserve the right to update or change this Privacy Policy at any time. Any changes made will be reflected by the "Last Updated" date at the top of this page. We encourage users to frequently check this page for any updates to stay informed about how we collect, use, and protect the personal information we collect.</p>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Contact Us</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>If you have any questions about this Privacy Policy or your dealings with our website, please contact us at our website xblue.ai
                                        Privacy  Policy.</p>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default PrivacyPolicy
