//router
// import IndexRouters from "./router/index"

//scss
import "./assets/scss/socialv.scss"
import "./assets/scss/customizer.scss"
import { ToastContainer, toast } from 'react-toastify';


// Redux Selector / Action
import { useDispatch } from 'react-redux';

// import state selectors
import { setSetting } from './store/setting/actions'
import SimpleRouter from "./router/simple-router";
import DefaultRouter from "./router/default-router";
import { useRoutes, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";


function App() {
  const navigate = useNavigate()
  // const dispatch = useDispatch()
  // dispatch(setSetting())
  const routing = useRoutes(SimpleRouter);






  // const initialTimeLeft = 3600; // 5 minutes in seconds
  // const [startTime, setStartTime] = useState(Date.now());
  // const [timeLeft, setTimeLeft] = useState(initialTimeLeft);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     const elapsedSeconds = Math.floor((Date.now() - startTime) / 1000);
  //     const remainingSeconds = Math.max(0, initialTimeLeft - elapsedSeconds);
  //     setTimeLeft(remainingSeconds);
  //   }, 1000);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, [startTime, initialTimeLeft]);

  // useEffect(() => {
  //   console.log("teime", timeLeft)
  //   if (timeLeft === 0) {
  //     // Implement the action to be taken when the timer reaches 0, e.g., signOut() and show a toast message.
  //     // alert('Session Time Out');
  //     // window.location.href = '/';
  //     toast.error("session time out", {
  //       position: "top-center",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });

  //     navigate('/')
  //     localStorage.removeItem('accessToken')
  //     localStorage.removeItem('UserID')
  //     localStorage.removeItem('providerToken')
  //     localStorage.removeItem('oAuthToken')
  //     localStorage.removeItem('TimeZone')
  //   }
  // }, [timeLeft]);

  // const handleResetPress = () => {
  //   setStartTime(Date.now());
  //   setTimeLeft(initialTimeLeft);
  // };


  // useEffect(() => {
  //   const handleClick = () => {
  //     handleResetPress();
  //   };
  //   document.addEventListener('click', handleClick);
  //   return () => {
  //     document.removeEventListener('click', handleClick);
  //   };
  // }, []);


  return (
    <div className="App">


      {routing}
      {/* <DefaultRouter /> */}
    </div>
  );
}

export default App;
